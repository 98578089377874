import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import Translate, { plainTranslate } from '../../../common/translate';
import { connect } from 'react-redux';
import { ACCOUNT_ADD_NEW_ACTIVITY, ACCOUNT_EDIT_ACTIVITY } from "../../../../constants/actionTypes";
import { onDeleteActivity, onFilterActivitiesByType } from '../../../../actions/actions';
import { Avatar } from './avatar';
import ReadMore from './read-more';
import Popup from "../../../common/popup";
import SlideModal from '../../../common/SlideModal';
import Form from "../../../redux-forms/form";
import SimpleForm from "../../../forms/form";
import { Select } from 'antd';
import { FallOutlined, RiseOutlined } from '@ant-design/icons'
import { withRouter } from 'react-router-dom'
import {PlusOutlined} from '@ant-design/icons'

require('./activities.css');
const { Option } = Select;

class Activities extends React.Component {

    constructor(props) {
        super(props);
        moment.locale(this.lang);
        this.state = {
            activityForm: false,
            show: false,
            thread: false
        };

        this.handleDelete = this.handleDelete.bind(this);
        this.openForm = this.openForm.bind(this);
        this.updateActivities = this.updateActivities.bind(this);
        this.filterActivitiesByType = this.filterActivitiesByType.bind(this);
    }

    handleDelete(id) {
        this.props.onDeleteActivity('/' + this.props.match.params.db + '/api/accounts/delete-activity', id, this.state.thread);
        this.setState({ deletePopup: false, thread: false });
    }

    openForm(id, type = null) {
        if (type) {
            this.setState({
                activityParent: id,
                activityForm: 'new'
            });
        } else {
            this.setState({
                activityForm: id ? id : 'new'
            });
        }
    }

    updateActivities(data) {       
        if (this.state.activityForm === 'new') {
            if (this.state.activityParent) {
                data['parent'] = this.state.activityParent;
            }
            this.props.onAddNewActivity(data);
        } else {            
            this.props.onEditActivity(data);
        }

        this.setState({ activityForm: false, activityParent: false });
    }

    filterActivitiesByType(type) {
        this.setState({filterType: type});
        this.props.onFilterActivitiesByType(this.props.match.params.db, type, this.props.accountId);
    }

    render() {
        return (
            <div>
                <span className="text-right">
                    <a className="btn-round btn-blue"
                        style={{ top: '0' }}
                        href="#"
                        onClick={() => this.openForm('new')}>
                       <PlusOutlined/>
                    </a>
                </span>
                <span className="text-left">
                {this.props.activities.length > 0 || this.state.filterType ?
                  <React.Fragment>
                    <div className="col-md-6">
                      <label>{plainTranslate(this.props.settings.locale, 'Filter by')} </label>
                      <Select 
                       onChange={(value) => this.filterActivitiesByType(value)}
                      style={{ maxWidth: '100%',minWidth: "70%", marginLeft: 10 }} defaultValue={'all'}>
                            <Option value={'all'}>
                              {plainTranslate(this.props.settings.locale, 'All')}
                            </Option>
                        {this.props.types.map(type=>
                            <Option key={type.value} value={type.value}>
                                {plainTranslate(this.props.settings.locale, type.label)}
                            </Option>
                        )}
                        </Select>
                     </div>
                    </React.Fragment>
                    : ''
                    }
                </span>
                <div className="col-md-12">
                    <div
                        className="chat-conversation">
                        <ul className="">
                            {
                                this.props.activities.map((activity, key) => (
                                    <div key={key}>
                                        <div className="crmActivities">
                                            <li className="clearfix">
                                                <div className="col-md-12 col-xs-12">
                                                    <div className="type col-md-1 col-xs-1 no-gutter">
                                                        <Avatar type={activity.type} />
                                                    </div>
                                                    <div className="col-md-11 col-xs-11 no-gutter">
                                                        <div className="col-md-12 col-xs-12 no-gutter">
                                                            <span className="pull-left">
                                                                <label><strong>
                                                                    <Translate locale={this.props.settings.locale}
                                                                        value={activity.type} />
                                                                </strong>
                                                                {activity.inOrOut == 'in' &&
                                                                    <sup><FallOutlined /></sup>
                                                                      }
                                                                      {activity.inOrOut == 'out' &&
                                                                    <sup><RiseOutlined /></sup>
                                                                    }
                                                                </label>
                                                                {activity.recipient &&
                                                                  <label>{plainTranslate(this.props.settings.locale, 'to')} </label>
                                                                }
                                                                {activity.outcome &&
                                                                <React.Fragment>
                                                                  <label> • {plainTranslate(this.props.settings.locale, 'Outcome')}</label>
                                                                  <label><strong>{plainTranslate(this.props.settings.locale, activity.outcome)}</strong></label>
                                                                  </React.Fragment>
                                                                }
                                                            </span>
                                                            <span className="tread-btn pull-right">
                                                                <a href="#" onClick={() => this.openForm(activity.id, 'parent')}>
                                                                    <Translate
                                                                        locale={this.props.settings.locale}
                                                                        value={activity.thread && activity.thread.length > 0 ? "Add Message": "Start Thread"} />
                                                                </a>
                                                            </span>
                                                            <div className="col-md-12 col-xs-12 no-gutter">
                                                              <p>{moment((activity.date).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat)} {activity.time}</p>
                                                            </div>
                                                            <div className="static-buttons">
                                                                <i id="activities-action"
                                                                    className="ion-android-more-vertical">
                                                                </i>
                                                                <div className="activities-menu">
                                                                    <ul>
                                                                        <li>
                                                                            <a href="#" onClick={() => this.openForm(activity.id)}>
                                                                                <Translate
                                                                                    locale={this.props.settings.locale}
                                                                                    value="Edit" />
                                                                            </a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="#" className="text-red" onClick={() => this.setState({ deletePopup: activity.id })}>
                                                                                <Translate
                                                                                    locale={this.props.settings.locale}
                                                                                    value="Delete" />
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-12 no-gutter">
                                                        <div className="col-md-1 no-gutter"></div>
                                                        <div className="col-md-11 col-xs-11 no-gutter">
                                                            <div className="p_wrap">
                                                                {activity.activity && activity.activity.length < 200 ?
                                                                    <div className="p_wrap" dangerouslySetInnerHTML={{ __html: activity.activity }}></div>
                                                                    :
                                                                    <ReadMore>
                                                                        <div className="p_wrap" dangerouslySetInnerHTML={{ __html: activity.activity }}></div>
                                                                    </ReadMore>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 col-xs-12 no-gutter">
                                                        <div className="col-md-1 no-gutter"></div>
                                                        <div className="col-md-11 col-xs-12 no-gutter">
                                                            <div className="date_r">
                                                                <p>
                                                                    {moment((activity.dateCreated).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat)} &nbsp; 
                                                                    <Translate locale={this.props.settings.locale}
                                                                        value="at" />&nbsp;
                                                                    {activity.timeCreated}&nbsp;
                                                                    <Translate locale={this.props.settings.locale}
                                                                        value="by" />&nbsp;
                                                                    {activity.createdBy}&nbsp;
                                                                    <img src={activity.avatar}
                                                                        alt="avatar" />&nbsp;
                                                                    {activity.modifiedBy &&
                                                                        <span>
                                                                            /&nbsp;
                                                                            <Translate locale={this.props.settings.locale}
                                                                                value="Last" />&nbsp;
                                                                            <Translate locale={this.props.settings.locale}
                                                                                value="modified" /> {activity.date_diff}&nbsp;
                                                                            <Translate locale={this.props.settings.locale}
                                                                                value="days ago" />&nbsp;
                                                                            <Translate locale={this.props.settings.locale}
                                                                                value="by" />&nbsp;
                                                                                       {activity.modifiedBy}
                                                                        </span>
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {activity.thread && activity.thread.length > 0 &&
                                                        <React.Fragment>
                                                            <div className="account-profile-other-information-more">
                                                                <div className="head-line col-md-12 col-xs-12 no-gutter">
                                                                  <span className="pull-right">
                                                                    <a className="account-profile-show" id="thread" href="#"
                                                                        onClick={() => this.setState({ show: !this.state.show })}>
                                                                        {this.state.show ? <p><i className="glyphicon glyphicon-chevron-up"></i> {plainTranslate(this.props.settings.locale, 'Close discussion')} </p> :
                                                                            <p><i className="glyphicon glyphicon-chevron-down"></i> {plainTranslate(this.props.settings.locale, 'Open discussion')} </p>}
                                                                    </a>
                                                                  </span>

                                                                </div>
                                                                {
                                                                    this.state.show &&
                                                                    <React.Fragment>
                                                                        {activity.thread.map((act, key) => (
                                                                            <div className="tread col-md-11 col-xs-11 pull-right">
                                                                                <div className="type col-md-1 col-xs-1 no-gutter">
                                                                                    <Avatar type={act.type} />
                                                                                </div>
                                                                                <div className="col-md-11 col-xs-11 no-gutter">
                                                                                    <div className="col-md-12 col-xs-12 no-gutter">
                                                                                      <span className="pull-left">
                                                                                          <label><strong>
                                                                                              <Translate locale={this.props.settings.locale}
                                                                                                  value={act.type} />
                                                                                          </strong>
                                                                                          {act.inOrOut == 'in' &&
                                                                                               <sup><FallOutlined /></sup>
                                                                                           }
                                                                                           {act.inOrOut == 'out' &&
                                                                                               <sup><RiseOutlined /></sup>
                                                                                           }
                                                                                          </label>
                                                                                          {act.recipient &&
                                                                                            <label>{plainTranslate(this.props.settings.locale, 'to')} </label>
                                                                                          }
                                                                                          {act.outcome &&
                                                                                          <React.Fragment>
                                                                                            <label> • {plainTranslate(this.props.settings.locale, 'Outcome')}</label>
                                                                                            <label><strong>{plainTranslate(this.props.settings.locale, act.outcome)}</strong></label>
                                                                                            </React.Fragment>
                                                                                          }
                                                                                      </span>
                                                                                      <div className="static-buttons">
                                                                                          <i id="activities-action"
                                                                                              className="ion-android-more-vertical">
                                                                                          </i>
                                                                                          <div className="activities-menu">
                                                                                              <ul>
                                                                                                  <li>
                                                                                                      <a href="#" onClick={() => this.openForm(act.id)}>
                                                                                                          <Translate
                                                                                                              locale={this.props.settings.locale}
                                                                                                              value="Edit" />
                                                                                                      </a>
                                                                                                  </li>
                                                                                                  <li>
                                                                                                      <a href="#" className="text-red" onClick={() => this.setState({ deletePopup: act.id, thread: true })}>
                                                                                                          <Translate
                                                                                                              locale={this.props.settings.locale}
                                                                                                              value="Delete" />
                                                                                                      </a>
                                                                                                  </li>
                                                                                              </ul>
                                                                                          </div>
                                                                                      </div>
                                                                                      <div className="col-md-12 col-xs-12 no-gutter">
                                                                                        <p>{moment((act.date).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat)} {act.time}</p>
                                                                                      </div>

                                                                                    </div>

                                                                                </div>
                                                                                <div className="col-xs-12 no-gutter">
                                                                                  <div className="col-md-1 no-gutter"></div>
                                                                                    <div className="col-md-11 col-xs-12 no-gutter">
                                                                                        <div className="p_wrap">
                                                                                            {act.activity && act.activity.length < 200 ?
                                                                                                <div className="p_wrap" dangerouslySetInnerHTML={{ __html: act.activity }}></div>
                                                                                                :
                                                                                                <ReadMore>
                                                                                                    <div className="p_wrap" dangerouslySetInnerHTML={{ __html: act.activity }}></div>
                                                                                                </ReadMore>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-12 col-xs-12 no-gutter">
                                                                                    <div className="col-md-1 no-gutter"></div>
                                                                                    <div className="col-md-11 col-xs-12 no-gutter">
                                                                                        <div className="date_r">
                                                                                            <p>
                                                                                                {moment((act.dateCreated).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat)}&nbsp;
                                                                                                    <Translate locale={this.props.settings.locale}
                                                                                                                                    value="at" />&nbsp;
                                                                                                    {act.timeCreated}&nbsp;
                                                                                                    <Translate locale={this.props.settings.locale}
                                                                                                                                    value="by" />&nbsp;
                                                                                                    {act.createdBy}&nbsp;
                                                                                                    <img src={act.avatar}
                                                                                                                                    alt="avatar" />&nbsp;
                                                                                                    {act.modifiedBy &&
                                                                                                    <span>
                                                                                                        /&nbsp;
                                                                                                    <Translate locale={this.props.settings.locale}
                                                                                                                                    value="Last" />&nbsp;
                                                                                                    <Translate locale={this.props.settings.locale}
                                                                                                                                    value="modified" /> {act.date_diff}&nbsp;
                                                                                                    <Translate locale={this.props.settings.locale}
                                                                                                                                    value="days ago" />&nbsp;
                                                                                                    <Translate locale={this.props.settings.locale}
                                                                                                                                    value="by" />&nbsp;
                                                                                                    {act.modifiedBy}
                                                                                                    </span>
                                                                                                }
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                        }
                                                                    </React.Fragment>
                                                                }

                                                            </div>
                                                        </React.Fragment>
                                                    }
                                                </div>
                                            </li>
                                        </div>
                                        <hr />
                                    </div>
                                ))
                            }
                        </ul>
                        {
                            this.props.activities && this.props.activities.length < 1 &&
                            <ul>
                                <li>
                                    <div className="conversation-text no-activities text-center">
                                        <img src='/images/no-activities.svg' />
                                        <h5><Translate locale={this.props.settings.locale} value="No activity to show" />
                                        </h5>
                                    </div>
                                </li>
                            </ul>
                        }
                    </div>
                </div>
                {
                    this.state.activityForm &&
                    <SlideModal onClose={() => this.setState({ activityForm: false, activityParent: false })} title={plainTranslate(this.props.settings.locale, 'Activity')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    {/* <SimpleForm
                                        formRoute={this.props.match.params.db + '/api/activity/form/'}
                                        saveRoute={this.state.activityForm !== 'new' ? this.props.match.params.db + '/api/activities/edit' : this.state.activityParent ? this.props.match.params.db + '/api/activities/new?accountId=' + this.props.accountId + '&parent=' + this.state.activityParent : this.props.match.params.db + '/api/activities/new?accountId=' + this.props.accountId}
                                        updateParent={(data) => this.updateActivities(data)}
                                        popup={true}
                                        onCancel={() => this.setState({ activityForm: false })}
                                        match={{ params: { id: this.state.activityForm + '?accountId=' + this.props.accountId } }}
                                    /> */}
                                    <Form
                                        formRoute={this.props.match.params.db + '/api/activity/form/'}
                                        saveRoute={this.state.activityForm !== 'new' ? 
                                        this.props.match.params.db + '/api/activities/edit' 
                                        : this.props.match.params.db + '/api/activities/new'}
                                        popup={true}
                                        onSuccessfulSave={(data) => this.updateActivities(data)}
                                        onCancel={() => this.setState({ activityForm: false })}
                                        accountId={this.state.activityParent ? this.props.accountId + '&parent=' + this.state.activityParent : this.props.accountId}
                                        form={'initializeFromState'}
                                        match={{ params: { id: this.state.activityForm } }}
                                        
                                    />   
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }
                {
                    this.state.deletePopup &&
                    <Popup locale={this.props.settings.locale}
                        title={plainTranslate(this.props.settings.locale, 'Delete activity')}
                        onClose={() => this.setState({ deletePopup: false })}>

                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <div className="text-center">

                                        <h3>
                                            <Translate locale={this.props.settings.locale} value="Are you sure?" />
                                        </h3>
                                        <button className="button-outlines green text-center"
                                            onClick={() => this.setState({ deletePopup: false })}>
                                            <Translate locale={this.props.settings.locale} value="No" />
                                        </button>
                                        <button className="button-primary-edited text-center btn-ok"
                                            onClick={() => this.handleDelete(this.state.deletePopup, this.state.thread)}>
                                            <Translate locale={this.props.settings.locale} value="Yes" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Popup>
                }
            </div>
        );
    }
}


const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({
    onAddNewActivity: (data) => dispatch({ type: ACCOUNT_ADD_NEW_ACTIVITY, payload: { activity: data } }),
    onEditActivity: (data) => dispatch({ type: ACCOUNT_EDIT_ACTIVITY, payload: { activity: data } }),
    onDeleteActivity: (route, id, thread) => dispatch(onDeleteActivity(route, id, thread)),
    onFilterActivitiesByType: (db, type, account) => dispatch(onFilterActivitiesByType(db, type, account))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Activities));
