import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Grid from '../components/ag-grid/grid';
import Form from "../components/forms/form";
import EmployeeProfile from '../components/hr/profile/profile';
import Tree from '../components/hr/structure/tree';
import HRSettings from '../components/hr/settings/hr_settings';
import LinksList from '../components/link/links-list';
import RolesGrid from '../components/settings/rolesGrid';
import Profile from "../components/crm/accounts/profile/profile";
import Setting from "../components/settings/expense/expense-setting.jsx";
import ModulesPage from "../components/settings/modules/modulesPage.jsx";
import SaleSettingsPage from "../components/settings/sale/saleSettingsPage.jsx";
import PaymentSettingsPage from "../components/settings/payment/paymentSettingsPage.jsx";
import BookingCalendar from "../components/booking/calendar.jsx";
import InvoiceSettingsPage from "../components/settings/invoice/invoiceSettingsPage.jsx";
import InventorySettingsPage from "../components/settings/inventory/inventorySettingsPage.jsx";
import ProductionSettingsPage from "../components/settings/production/productionSettingsPage.jsx";
import EcommerceSettingsPage from "../components/settings/ecommerce/ecommerceSettingsPage.jsx";
import TaskSettingsPage from "../components/settings/task/taskSettingsPage.jsx";
import CalendarSettings from "../components/settings/calendar/CalendarSettings";
import ItemSettingsPage from "../components/settings/item/itemSettingsPage.jsx";
//import IntegrationsSettingsPage from "../components/settings/integrations/integrationsSettingsPage.jsx";
import StorageSettingsPage from "../components/settings/storage/storageSettingsPage.jsx";
import DeviceSettingsPage from "../components/settings/devices/deviceSettingsPage.jsx";
import DealSettingsPage from "../components/settings/deal/dealSettingsPage.jsx";
import InvoiceProfile from "../components/profiles/invoiceProfile.jsx";
import GeneralSettingsPage from "../components/settings/general/generalSettingsPage.jsx";
import AccountSettingsPage from "../components/settings/account/accountSettingsPage.jsx";
import SalesProfile from "../components/profiles/salesProfile.jsx";
import Import from "../components/import/import.jsx";
import PrintReceipt from "../components/fiscal-printers/print-receipt.jsx";
import InitializeFromStateForm from '../components/redux-forms/form';
import PaymentsDataSourcePreview from "../components/data-source/paymentsDataSourcePreview.jsx";
import ItemsTreeView from "../components/tree/treeViewInventory";
import DeviceProfile from "../components/profiles/deviceProfile.jsx";
import ItemProfile from "../components/profiles/item/itemProfile";
import InventoryProfile from "../components/profiles/inventoryProfile.jsx";
import ExpensesProfile from "../components/profiles/expensesProfile.jsx";
import PrintDataSource from "../components/data-source/printDatasource.jsx";
import Stats from "../components/custom-pages/stats.jsx";
import Dashboard from "../components/custom-pages/dashboard.jsx";
import Forms from "../components/formnew/Forms"
import Storage from '../components/documents/Storage'
import Folder from '../components/documents/Folder'
import Trash from '../components/documents/Trash'
import UserProfilePage from "../components/settings/user/userProfilePage.jsx";
import SubscriptionDetails from "../components/custom-pages/subscriptionDetails.jsx";
import EditablePrint from '../components/custom-pages/editablePrint.jsx';
import Print from '../components/custom-pages/Print.js';
import ProjectProfile from "../components/profiles/projectProfile.jsx";
import TaskProfile from "../components/profiles/taskProfile.jsx";
import MaintenanceSettingsPage from "../components/settings/maintenance/maintenanceSettingsPage.jsx";
import ProjectSettingsPage from "../components/settings/project/projectSettingsPage.jsx";
import DealProfile from "../components/profiles/dealProfile.jsx";
import InventoryRequestsDetails from "../components/custom-pages/pages/inventoryRequestsDetailsPage.jsx";
import Pivot from "../components/custom-pages/pivot.jsx";
import WorkspaceSignin from "../components/auth/WorkspaceSignin.js";
import Login from '../components/auth/Login';
import WorkspaceCreate from '../components/auth/WorkspaceCreate'
import WorkspaceCheck from '../components/auth/WorkspaceCheck'
import Register from '../components/auth/Register';
import Confirmation from '../components/auth/Confirmation';
import ResetPassword from '../components/auth/ResetPassword';
import UserPasswordReset from '../components/auth/UserPasswordReset';
import ResetPasswordCheck from '../components/auth/ResetPasswordCheck';
import NotFound from '../components/not-found/NotFound';
import PrivateRoute from './PrivateRoute';
import TemplateRoute from './TemplateRoute';
import PublicRoute from './PublicRoute';
import PosRoute from './PosRoute';
import TemplateLayout from '../components/layout/TemplateLayout';
import getGridRoutes from './gridRoutes';
import getRoutesForm from './formRoutes';
import getFormRoutes from './formRoutesNew';
import getPosGridRoutes from './posGridRoutes';
import getPosFormRoutes from './posFormRoutes';
import getTemplateRoutes from './templateRoutes';
import Ledger from '../components/custom-pages/accounting/ledger';
import AccountingSettingsPage from '../components/settings/accounting/accountingSettingsPage.jsx';
import getGridV2Routes from './gridRoutesV2';
import GridV2 from '../components/gridV2/grid.jsx';
import SurveyVisualization from '../components/custom-pages/pages/surveyVisualization';
import Survey from '../components/custom-pages/pages/survey';
import PaymentLedger from "../components/profiles/paymentLedger.jsx";
import SurveyRespondentView from '../components/custom-pages/pages/surveyRespondentView';
import AssetProfile from "../components/profiles/assetProfile.jsx";
import ProjectBoardProfile from "../components/profiles/projectBoardProfile.jsx";
import PublicProject from "../components/profiles/publicProject.jsx";
import PublicTask from "../components/profiles/publicTask.jsx";
import PublicBoard from "../components/profiles/publicBoard.jsx";

const gridRoutes = getGridRoutes();
const formRoutes = getRoutesForm();
const routesForm = getFormRoutes();
const posGridRoutes = getPosGridRoutes();
const posFormRoutes = getPosFormRoutes();
const templateRoutes = getTemplateRoutes();
const gridV2Routes = getGridV2Routes();

let routes = [
    <Switch key={'routes'}>    
        <Route key={'main'} exact path="/">
         <Redirect to="/workspace/signin" /> 
        </Route>
                        <Route
                            key="signin"
                            exact
                                path={'/workspace/signin'}
                                render={(routeProps) => (
                                        <WorkspaceSignin  {...routeProps}/>
                                    )}
                        />
                        <Route
                            key="workspace_create"
                            exact
                                path={'/workspace/create'}
                                render={(routeProps) => (
                                        <WorkspaceCreate  {...routeProps}/>
                                    )}
                        />
                         <Route
                            key="workspace_loading"
                            exact
                                path={'/:db/workspace/loading'}
                                render={(routeProps) => (
                                        <WorkspaceCheck  {...routeProps}/>
                                    )}
                        />
                        <Route
                            key="login"
                            exact
                               path={'/:db/user/signin'}
                               render={(routeProps) => (
                                       <Login {...routeProps}/>
                                   )}
                        />
                        <Route
                        key="register"
                            exact
                               path={'/:db/auth/register/user'}
                               render={(routeProps) => (
                                       <Register {...routeProps}/>
                                   )}
                        />
                        <Route
                        key="confirmation"
                            exact
                               path={'/:db/user/confirmation'}
                               render={(routeProps) => (
                                       <Confirmation {...routeProps}/>
                                   )}
                        />
                        <Route
                            key="reset-password"
                            exact
                               path={'/:db/user/reset-password'}
                               render={(routeProps) => (
                                       <ResetPassword {...routeProps}/>
                                   )}
                        />
                        <Route
                            key="reset-password-check"
                            exact
                               path={'/:db/user/reset-password/check-email'}
                               render={(routeProps) => (
                                       <ResetPasswordCheck {...routeProps}/>
                                   )}
                        />
                        <Route
                            key="user-password-reset"
                            exact
                               path={'/:db/user/password/reset/:token'}
                               render={(routeProps) => (
                                       <UserPasswordReset {...routeProps}/>
                                   )}
                        />
                        <Route
                            key="survey"
                            exact
                               path={'/:db/public/survey/:surveyCc/:respondentCc'}
                               render={(routeProps) => (
                                       <Survey {...routeProps}/>
                                   )}
                        />
                        <PublicRoute
                            key="publicProject"
                            exact
                            path={'/:db/public/project/:id'}
                            component={PublicProject} 
                        />
                        <PublicRoute
                            key="publicBoard"
                            exact
                            path={'/:db/public/board/:id'}
                            component={PublicBoard} 
                        />
                        <PublicRoute
                            key="publicTask"
                            exact
                            path={'/:db/public/project/:id/task/:taskId'}
                            component={PublicTask} 
                        />
                        <PublicRoute
                            key="publicTask"
                            exact
                            path={'/:db/public/board/:id/task/:taskId'}
                            component={PublicTask} 
                        />
                        {templateRoutes.map((route, key) => {
                          return <TemplateRoute
                            key={"templateRoutes"+key}
                            exact
                            path={route.path}
                            title={route.title}  
                            public={route.public}  
                            additionalData={route.additionalData}
                            component={Print}    
                        />
                        })}
                        <PrivateRoute
                                    exact
                                    path={'/:db/dashboard'}
                                    component={Dashboard}
                                    key="Dashboard"
                                   />
                                  {formRoutes.map((route, key) => {
                                      
                                            if (route.isOldForm) {
                                                return <PrivateRoute
                                                exact
                                                key={'formRoutes'+key}
                                                path={route.path}
                                                formRoute={route.formRoute}
                                               saveRoute={route.saveRoute}
                                               title={route.title}
                                               isNew={route.isNew}
                                               isReport={route.isReport}
                                               isAdditionalId={route.isAdditionalId}
                                               component={Form}                                                
                                            />
                                                } else {
                                                 return <PrivateRoute
                                                 exact
                                                 key={'formRout'+key}
                                                 path={route.path}
                                                 form={'initializeFromState2'}
                                                 formRoute={route.formRoute}
                                                 saveRoute={route.saveRoute}
                                                 title={route.title}
                                                 isNew={route.isNew}
                                                 isReport={route.isReport}
                                                 issueButton={route.issueButton}
                                                 sendButton = {route.sendButton}
                                                 cancelButton={route.cancelButton}
                                                 applyButton={route.applyButton}
                                                 download = {route.download}
                                                 notSaveButton={route.notSaveButton}
                                                 updateOnSave = {route.updateOnSave}
                                                 customForm = {route.customForm}
                                                 checkDevices = {route.checkDevices}
                                                 printReceipt = {route.printReceipt}
                                                 component={InitializeFromStateForm}                                                
                                             />
                                                }
                                            })
                                        }

                                        {
                                            gridRoutes.map((route, key) => {
                                                return <PrivateRoute
                                                exact
                                                key={'grid'+key}
                                                path={route.path}
                                                gridRoute={route.gridRoute}
                                                gridExportRoute={route.gridExportRoute}
                                                gridDataRoute={route.gridDataRoute}
                                                filtersRoute={route.filtersRoute}
                                                exportTemplateRoute={route.exportTemplateRoute}
                                                filtersSaveRoute={route.filtersSaveRoute}
                                                filtersDataRoute={route.filtersDataRoute}
                                                title={route.title}
                                                isReport={route.isReport}
                                                component={Grid}
                                                customPagination={route.customPagination}
                                                customSelection={route.customSelection}
                                            />
                                            })
                                        }
                                        {
                                            posGridRoutes.map((route, key) => {
                                                return <PosRoute
                                                exact
                                                key={'grid'+key}
                                                path={route.path}
                                                gridRoute={route.gridRoute}
                                                gridExportRoute={route.gridExportRoute}
                                                gridDataRoute={route.gridDataRoute}
                                                filtersRoute={route.filtersRoute}
                                                filtersSaveRoute={route.filtersSaveRoute}
                                                filtersDataRoute={route.filtersDataRoute}
                                                title={route.title}
                                                isReport={route.isReport}
                                                component={Grid}
                                                customPagination={route.customPagination}
                                            />
                                            })
                                        }
                                         {routesForm.map((route, key) => {
                                                 return <PrivateRoute
                                                 exact
                                                    key={'routesForm'+key}
                                                    path={route.path}
                                                    formRoute={route.formRoute}
                                                    saveRoute={route.saveRoute}
                                                    dataRoute={route.dataRoute}
                                                    title={route.title}
                                                    isNew={route.isNew}
                                                    isAdditionalId={route.isAdditionalId}
                                                    component={Forms}
                                                    redirectRoute={route.redirectRoute}                                                  
                                                />
                                      })}
                                    {posFormRoutes.map((route, key) => {
                                              return <PosRoute
                                                exact
                                                key={'formRout'+key}
                                                path={route.path}
                                                form={'initializeFromState2'}
                                                formRoute={route.formRoute}
                                                saveRoute={route.saveRoute}
                                                title={route.title}
                                                isNew={route.isNew}
                                                isReport={route.isReport}
                                                issueButton={route.issueButton}
                                                cancelButton={route.cancelButton}
                                                applyButton={route.applyButton}
                                                notSaveButton={route.notSaveButton}
                                                updateOnSave = {route.updateOnSave}
                                                customForm = {route.customForm}
                                                checkDevices = {route.checkDevices}
                                                printReceipt = {route.printReceipt}
                                                component={InitializeFromStateForm} 
                                                isPos = {route.isPos}                                              
                                             />
                                      })}
                                        {
                                            gridV2Routes.map((route, key) => {
                                                return <PrivateRoute
                                                exact
                                                key={'gridV2'+key}
                                                path={route.path}
                                                gridRoute={route.gridRoute}
                                                gridDataRoute={route.gridDataRoute}
                                                gridExportRoute={route.gridExportRoute}
                                                gridSaveRoute={route.gridSaveRoute}
                                                exportTemplateRoute={route.exportTemplateRoute}
                                                title={route.title}
                                                component={GridV2}
                                            />
                                            })
                                        }
                                          <PrivateRoute
                                          path={'/:db/storage'}
                                          exact
                                          key="files-doc"
                                          component={Storage}
                                          title={['Documents']}                                          
                                        />
                                        <PrivateRoute
                                          path={'/:db/storage/trash'}
                                          exact
                                          key="trash-doc"
                                          component={Trash}
                                          title={['Trash']}
                                        />
                                        <PrivateRoute
                                          path={'/:db/storage/folder/:id'}
                                          exact
                                          key="folder-doc"
                                          component={Folder}
                                          title={['Folder']}
                                        />
                                        <PrivateRoute
                                             key="employee-prof"
                                            path={'/:db/hr/employee/:id/profile'}
                                            title={['Employee profile']}
                                            component={EmployeeProfile}
                                        />
                                        <PrivateRoute
                                            key="structure"
                                            path={'/:db/hr/organizational/structure/:id'}
                                            component={Tree}
                                            title={['Organizational structure']}
                                        />
                                         <PrivateRoute
                                            key="personnel"
                                            path={'/:db/settings/personnel'}
                                            component={HRSettings}
                                            title={['Settings', '›', 'Personnel']}
                                        />
                                        <PrivateRoute
                                            key="expense"
                                            path={'/:db/settings/expense'}
                                            component={Setting}
                                            title={['Settings', '›', 'Expenses']}                                            
                                        />
                                        <PrivateRoute
                                             key="bookmarks"
                                            path={'/:db/list/bookmarks'}
                                            component={LinksList}
                                            title={['Bookmarks']}
                                        />
                                        <PrivateRoute
                                        key={'settings-roles'}
                                            path={'/:db/settings/roles'}
                                            title={['Roles and Permissions', '›', 'List']}
                                            component={RolesGrid}
                                        />

                                        <PrivateRoute
                                            key="accProf"
                                            path={'/:db/account/:id/profile'}
                                            title={['Account Profile › Details']}
                                            component={Profile}
                                        />
                                        <PrivateRoute
                                        key="PaymentsDataSourcePreview"
                                            path={'/:db/data-source/payments/preview'}
                                            component={PaymentsDataSourcePreview}                                           
                                        />
                                        <PrivateRoute
                                            key="report"
                                            path={'/:db/report/:id'}
                                            component={Grid}
                                            gridRoute={'/api/reports/grid-properties/'}
                                            gridDataRoute={'/api/report/'}
                                            title={['Report']}                                            
                                        />
                                        <PrivateRoute
                                            key="ModulesPage"
                                            path={'/:db/modules'}
                                            component={ModulesPage}                                            
                                        />
                                        <PrivateRoute
                                            key="PaymentSettingsPage"
                                            path={'/:db/settings/payments'}
                                            component={PaymentSettingsPage}
                                            title={['Settings › Payments']}                                            
                                        />                                        
                                        <PrivateRoute
                                            key="BookingCalendar"
                                            path={'/:db/booking/calendar'}
                                            component={BookingCalendar}
                                            title={['Composity › Calendar']}                                            
                                        />
                                        <PrivateRoute
                                            key="InvoiceSettingsPage"
                                            path={'/:db/settings/invoices'}
                                            component={InvoiceSettingsPage}
                                            title={['Settings › Invoices']}
                                        />
                                        <PrivateRoute
                                            key="InventorySettingsPage"
                                            path={'/:db/settings/inventory'}
                                            component={InventorySettingsPage}
                                            title={['Settings › Inventory']}
                                        />
                                        <PrivateRoute
                                            key="ProductionSettingsPage"
                                            path={'/:db/settings/production'}
                                            component={ProductionSettingsPage}
                                            title={['Settings › Prodution']}
                                        />
                                        <PrivateRoute
                                            key="EcommerceSettingsPage"
                                            path={'/:db/settings/ecommerce'}
                                            component={EcommerceSettingsPage}
                                            title={['Settings › Ecommerce']}
                                        />
                                        <PrivateRoute
                                            key="SaleSettingsPage"
                                            path={'/:db/settings/sales'}
                                            component={SaleSettingsPage}
                                            title={['Settings › Sales']}
                                        />
                                        <PrivateRoute
                                            key="TaskSettingsPage"
                                            path={'/:db/settings/task'}
                                            component={TaskSettingsPage}
                                            title={['Settings › Tasks']}
                                        />
                                        <PrivateRoute
                                            key="CalendarSettings"
                                            path={'/:db/settings/calendars'}
                                            component={CalendarSettings}
                                            title={['Settings › Calendars']}
                                        />
                                        <PrivateRoute
                                            key="ItemSettingsPage"
                                            path={'/:db/settings/items'}
                                            component={ItemSettingsPage}
                                            title={['Settings › Items']}
                                        />
                                    {/*    <PrivateRoute
                                            key="IntegrationsSettingsPage"
                                            path={'/:db/settings/integrations'}
                                            component={IntegrationsSettingsPage}
                                            title={['Settings › Integrations']}
                                        />*/}
                                        <PrivateRoute
                                            key="DeviceSettingsPage"
                                            path={'/:db/settings/devices'}
                                            component={DeviceSettingsPage}
                                            title={['Settings › Devices']}
                                        />
                                        <PrivateRoute
                                            key="StorageSettingsPage"
                                            path={'/:db/settings/storage'}
                                            component={StorageSettingsPage}
                                            title={['Settings › Storage']}
                                        />
                                        <PrivateRoute
                                            key="GeneralSettingsPage"
                                            path={'/:db/settings/general'}
                                            component={GeneralSettingsPage}
                                            title={['Settings › General']}                                            
                                        />
                                        <PrivateRoute
                                            key="AccountSettingsPage"
                                            path={'/:db/settings/accounts'}
                                            component={AccountSettingsPage}
                                            title={['Settings › Account']}
                                        />
                                        <PrivateRoute
                                            key="DealSettingsPage"
                                            path={'/:db/settings/deal'}
                                            component={DealSettingsPage}                                            
                                            title={['Settings › Deal']}
                                        />
                                        <PrivateRoute
                                        key="InvoiceProfile"
                                            path={'/:db/invoice/:id/profile'}
                                            component={InvoiceProfile}
                                            title={['Invoice › Profile']}
                                        />
                                        <PrivateRoute
                                        key="SalesProfile"
                                            path={'/:db/sale/:id/profile'}
                                            component={SalesProfile}
                                            title={['Sales › Profile']}
                                        />
                                        <PrivateRoute
                                        key="Import"
                                            path={'/:db/import/:type/upload/file'}
                                            component={Import}
                                            title={['Import']}
                                        />
                                        <PrivateRoute
                                        key="PrintReceipt"
                                            path={'/:db/test/fiscal-printers'}
                                            component={PrintReceipt}
                                        />
                                        <PrivateRoute
                                        key="ItemsTreeView"
                                            path={'/:db/items-tree'}
                                            component={ItemsTreeView}
                                            title={['Reports › Inventory Tree']}
                                        />
                                        <PrivateRoute
                                        key="DeviceProfile"
                                            path={'/:db/device/:id/profile'}
                                            component={DeviceProfile}
                                        />
                                        <PrivateRoute
                                        key="ItemProfile"
                                            path={'/:db/item/:id/profile'}
                                            component={ItemProfile}
                                            title={['Item › Profile']}
                                        />
                                        <PrivateRoute
                                        key="InventoryProfile"
                                            path={'/:db/receipt/:id/profile'}
                                            component={InventoryProfile}
                                            title={['Inventory › Profile']}
                                        />
                                        <PrivateRoute
                                        key="Stats"
                                            path={'/:db/stats'}
                                            component={Stats}
                                            title={['Stats']}
                                        />
                                        <PrivateRoute
                                        key="ExpensesProfile"
                                                path={'/:db/expense/:id/profile'}
                                                component={ExpensesProfile}
                                                title={['Expense › Profile']}
                                        />
                                        <PrivateRoute
                                        key="PrintDataSource"
                                                path={'/:db/data-source/print/:type/:id'}
                                                component={PrintDataSource}
                                                title={'Report Print Preview'}
                                        />

                                        <PrivateRoute
                                        key="Dashboard"
                                            path={'/:db/dashboard'}
                                            component={Dashboard}
                                            title={['Dashboard']}
                                        />
                                        <PrivateRoute
                                        key="UserProfilePage"
                                            path={'/:db/user/profile'}
                                            component={UserProfilePage}
                                            title={['User › Profile']}
                                        />
                                        <PrivateRoute
                                        key="SubscriptionDetails"
                                            path={'/:db/subscription-details'}
                                            component={SubscriptionDetails}
                                            title={['Subscription Details']}
                                        />
                                        <PrivateRoute
                                        key="EditablePrint"
                                            path={'/:db/administration/template/:templateId/print/:docId'}
                                            component={EditablePrint}
                                            title={['Print']}
                                        />
                                         <PrivateRoute
                                            key="Print"
                                            path={'/:db/administration/template/:templateId/export-pdf/:docId'}
                                            component={Print}
                                            title={['Print']}
                                        />
                                        <PrivateRoute
                                        key="ProjectProfile"
                                            path={'/:db/project/:id/profile'}
                                            component={ProjectProfile}
                                            title={['Project › Profile']}
                                        />
                                        <PrivateRoute
                                        key="TaskProfile"
                                            path={'/:db/task/:id/profile'}
                                            component={TaskProfile}
                                            title={['Task › Profile']}
                                        />
                                        <PrivateRoute
                                        key="MaintenanceSettingsPage"
                                            path={'/:db/settings/maintenance'}
                                            component={MaintenanceSettingsPage}
                                            title={['Settings › Maintenance']}
                                        />
                                        <PrivateRoute
                                        key="AccountingSettingsPage"
                                            path={'/:db/settings/accounting'}
                                            component={AccountingSettingsPage}
                                            title={['Settings › Accounting']}
                                        />
                                        <PrivateRoute
                                        key="ProjectSettingsPage"
                                            path={'/:db/settings/project'}
                                            component={ProjectSettingsPage}
                                            title={['Settings › Project']}
                                        />
                                        <PrivateRoute
                                        key="DealProfile"
                                            path={'/:db/deal/:id/profile'}
                                            component={DealProfile}
                                            title={['Deal › Profile']}
                                        />
                                        <PrivateRoute
                                        key="Pivot"
                                            path={'/:db/pivoting'}
                                            component={Pivot}
                                            title={['Pivoting']}
                                        />
                                        <PrivateRoute
                                        key="InventoryRequest"
                                            path={'/:db/inventory-request/details'}
                                            component={InventoryRequestsDetails}
                                            title={['Inventory requests › Details']}
                                        />
                                        <PrivateRoute
                                        key="Ledger"
                                            path={'/:db/accounting/ledger/new'}
                                            component={Ledger}
                                            title={['Ledger › New']}
                                        />
                                        <PrivateRoute
                                        key="Ledger"
                                            path={'/:db/accounting/ledger/:id'}
                                            component={Ledger}
                                            title={['Ledger › Edit']}
                                        />
                                        <PosRoute
                                          path={'/:db/pos/data-source/payments/preview'}
                                          component={PaymentsDataSourcePreview}
                                          
                                        />  
                                        <PrivateRoute
                                        key="Survey Visualization"
                                            path={'/:db/survey/:id/visualization'}
                                            component={SurveyVisualization}
                                            title={['Survey › Visualization']}
                                        />
                                        <PrivateRoute
                                            key="paymentLedger"
                                            path={'/:db/payment/:id/ledger'}
                                            title={['Payment Ledger']}
                                            component={PaymentLedger}
                                        /> 
                                        <PrivateRoute
                                            key="surveyRespondent"
                                            path={'/:db/survey/:surveyCc/respondent/:respondentCc'}
                                            title={['Survey responses view']}
                                            component={SurveyRespondentView}
                                        />
                                        <PrivateRoute
                                        key="AssetProfile"
                                            path={'/:db/asset/:id/profile'}
                                            component={AssetProfile}
                                            title={['Asset › Profile']}
                                        />
                                        <PrivateRoute
                                        key="ProjectBoardProfile"
                                            path={'/:db/project-board/:boardId/profile'}
                                            component={ProjectBoardProfile}
                                            title={['Project › Profile']}
                                        />
    <Route exact component={NotFound} key="notFound"/>                                                                  
    </Switch> 

    

];
export default routes
