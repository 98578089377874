import React from 'react';
import PropTypes from 'prop-types';

class RecordForm extends React.Component {

  render() {
    return (
      <div className="backendropss" >    
        <div className={this.props.windowWidth}>
        <div className="text-right">
            <div className="" onClick={this.props.onClose}>
              <i className="fa fa-close" aria-hidden="true"></i>
            </div>    
          </div>
          {this.props.children}          
        </div>
      </div>
    );
  }
}

export default RecordForm;