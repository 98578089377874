import React, {Component} from 'react';
import {render} from "react-dom";
import Translate, {plainTranslate} from '../../../common/translate';
import {connect} from 'react-redux';
import {onFieldSave, onAccountFetchData} from "../../../../actions/actions";
import SimpleForm from "../../../redux-forms/form";
import Popup from '../../../common/popup';
import SlideModal from '../../../common/SlideModal';
import { Avatar, Button, Menu, Dropdown, Card } from 'antd';
import 'antd/lib/avatar/style/index.css';
import AsyncSelect from 'react-select/lib/Async';
import 'antd/lib/icon/style/index.css';
import {Link} from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import client from '../../../../actions/client';
import showAToast from '../../../common/showAToast';
import { UserOutlined,  PlusCircleOutlined } from '@ant-design/icons';

class AccountLinks extends Component {

    constructor(props) {
        super(props);

        this.state = {
            accountLinks: [],
            show: true
        };
        this.changeValue = this.changeValue.bind(this);
        this.saveField = this.saveField.bind(this);
        this.getAccountLinks = this.getAccountLinks.bind(this);
        this.getNoAccountLinks = this.getNoAccountLinks.bind(this);
        this.showNewPopup = this.showNewPopup.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.openForm = this.openForm.bind(this);
        this.onChange = this.onChange.bind(this);
        this.loadOptions = this.loadOptions.bind(this);
    }

    componentDidMount() {   
        this.setState({
            accountLinks: this.props.value ? this.props.value : [],
            popup: false,
            newPopup: false,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                accountLinks: this.props.value ? this.props.value : []
            });
        }
        if (prevState.newPopup !== this.state.newPopup && this.state.newPopup == true) {
            this.loadOptions(null);
        }
    }

    saveField() {
        this.setState({popup: false});
        this.props.onFieldSave(this.props.updateRoute, this.props.id, this.state.accountLinks, this.props.additionalData).then(()=>{showAToast('Data saved successfully!', 'succSettings', 'success');});
    }

    changeValue(data) {
        let accountLinks = this.state.accountLinks;
        let find = accountLinks.find((link, key) => {
                if (link.id === data.id) {
                    accountLinks[key] = data;
                    return true;
                }
        });

        if (!find) {
            accountLinks.push(data);
          }
        this.setState({
            accountLinks: accountLinks,
            newPopup: false,
            popup: false

        }, () => this.saveField());
    }

    openForm(id) {
        this.setState({
            popup: id
        });
    }

    onChange(data) {
        this.setState({newPopup: false});
        client
        .post('/' + this.props.match.params.db + '/api/account-link/new?accountId=' + this.props.account.id + '&accountLinkId=' + data.value)
        .then(response => response.data)
        .then(response => {
            let options = [];
            if (response) {
                this.changeValue(response.data);
            }
        });
    }

    loadOptions(inputValue, callback) {
        client
        .get('/' + this.props.match.params.db + '/api/search-accounts?q=' + inputValue + '&list=' + this.state.selectedList)
        .then(response => response.data)
        .then(response => {
            let options = [];
            if (response) {
                options = response.map(option => {
                    return {
                        ...option,
                        'value': option.value,
                        'label': option.label
                    };
                });
                this.setState({options: options});
            }
            if(callback) return callback(options ? options : this.state.options);
        });

    }

    handleDelete(id) {
        client
        .post('/' + this.props.match.params.db + '/api/accountLink/delete/' + id)
        .then(response => response.data)

        let accountLinks = this.state.accountLinks;
        let index;
        accountLinks.find((link, key) => {
                if (link.accountLinkId === id) {
                    index = key;
                    return true;
                }
        });
        if (index > -1) {
            accountLinks.splice(index, 1);
         }
        this.setState({
            accountLinks: accountLinks,
            deletePopup: false,
        }, () => this.saveField());

    }

    getAccountLinks() {
        return (
            <React.Fragment>
                             {
                                this.state.accountLinks.map((accountLink, key) => (
                                    <React.Fragment key={key}>
                                      <div className="line line-accountContact">
                                        <div className="col-md-1 col-xs-2">
                                          {accountLink.avatar && accountLink.avatar.length > 0 ?
                                            <Avatar size={30} src={accountLink.avatar} />
                                            : <Avatar size={30} icon={<UserOutlined />} />
                                          }
                                        </div>
                                        <div className="col-md-11 col-xs-8">
                                            <div className="contactName">
                                             {accountLink.title && <span>{accountLink.title} </span>}
                                             {accountLink.name && 
                                                <span>
                                                    <Link to={"/" + this.props.match.params.db + "/account/" + accountLink.id + "/profile"}>
                                                        {accountLink.name + ' '}  
                                                    </Link> 
                                                </span>
                                             }
                                             {accountLink.firstName && 
                                                    <span>
                                                        <Link to={"/" + this.props.match.params.db + "/account/" + accountLink.id + "/profile"}>
                                                            {accountLink.firstName + ' '}  
                                                        </Link> 
                                                    </span> 
                                             }
                                             {accountLink.lastName && 
                                                    <span>
                                                        <Link to={"/" + this.props.match.params.db + "/account/" + accountLink.id + "/profile"}>
                                                            {accountLink.lastName + ' '} 
                                                        </Link> 
                                                    </span>
                                             }
                                             {accountLink.companyName && 
                                                    <span>
                                                        <Link to={"/" + this.props.match.params.db + "/account/" + accountLink.id + "/profile"}>
                                                            {accountLink.companyName + ' '}
                                                        </Link> 
                                                    </span>
                                             }
                                             {accountLink.department && <span>, {accountLink.department}</span>}
                                           </div>
                                          <div className="contactInfo">{accountLink.email && <span>{accountLink.email}</span>} {accountLink.phone && <span>, {accountLink.phone}</span>}</div>
                                        </div>
                                        <div className="col-md-1 col-xs-2">
                                          <i className="fa fa-chain-broken pointer contactDelete" aria-hidden="true" title={plainTranslate(this.props.settings.locale, 'Remove contact')} onClick={() => this.setState({deletePopup: accountLink.accountLinkId})} />
                                        </div>
                                      </div>
                                    </React.Fragment>
                            ))}

                            {
                                this.state.deletePopup &&
                                <Popup locale={this.props.settings.locale}
                                       title={plainTranslate(this.props.settings.locale, 'Delete account link')}
                                       onClose={() => this.setState({deletePopup: false})}>
                                    <section className="newPanel">
                                        <div className="panel-body">
                                            <div className="row m-bot15">
                                                <div className="text-center">

                                                    <h3>
                                                        <Translate locale={this.props.settings.locale} value="Are you sure?"/>
                                                    </h3>
                                                  <div>
                                                    <button className="button-outlines green text-center"
                                                            onClick={() => this.setState({deletePopup: false})}>
                                                        <Translate locale={this.props.settings.locale} value="No"/>
                                                    </button>
                                                    <button className="button-primary-edited text-center btn-ok"
                                                            onClick={() => this.handleDelete(this.state.deletePopup)}>
                                                        <Translate locale={this.props.settings.locale} value="Yes"/>
                                                    </button>
                                                  </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </Popup>
                            }
                        </React.Fragment>
        );
    }

    getNoAccountLinks() {
        return (
           <div className="div-inline m-left15">
               <span className="text-italic" style={{color: '#a0a0a0'}}>
                   {plainTranslate(this.props.settings.locale, 'No contacts added.')}</span>
           </div>
        );
    }

    onSuccessfulSave = () => {
        this.setState({newPopup: false})
        this.props.onAccountFetchData(this.props.match.params.db, this.props.additionalData.accountId, false)
    }

    showNewPopup() {
        return (
                <SlideModal onClose={() => this.setState({newPopup: false})} title={plainTranslate(this.props.settings.locale, 'Account contacts')}>
                    <section className="newPanel">
                        <div className="panel-body">
                         <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-md-10 col-xs-12">
                                <h4 className="no-gutter">{plainTranslate(this.props.settings.locale, 'Add from existing accounts')}</h4>
                                <AsyncSelect
                                    isClearable
                                    cacheOptions
                                    isSearchable
                                    onChange={(value) => this.onChange(value)}
                                    onBlurResetsInput={false}
                                    onBlur={event => event.preventDefault()}
                                    onCloseResetsInput={false}
                                    menuPlacement={"auto"}
                                    loadOptions={this.loadOptions}
                                    defaultOptions={this.state.options}
                                />
                            </div>
                          </div>
                            <div className="row">
                              <div className="col-md-12 col-xs-12 no-gutter">
                                <div className="col-md-1"></div>
                                <div className="col-md-10 col-xs-12">
                                  <hr />
                                  <h4 className="no-gutter">{plainTranslate(this.props.settings.locale, 'Or create new account')}</h4>
                                </div>
                                <SimpleForm
                                    formRoute={this.props.match.params.db + '/api/account/form/'}
                                    saveRoute={this.props.match.params.db + '/api/accounts/new'}
                                    updateParent={(data) => this.changeValue(data)}
                                    popup={true}
                                    onCancel={() => this.setState({newPopup: false})}
                                    match={{params: {id: this.state.selectedList, accountId: this.props.additionalData.accountId}}}
                                    onSuccessfulSave={this.onSuccessfulSave}
                                    form={'initializeFromState2'}                                   
                                />
                              
                                </div>
                            </div>
                        </div>
                    </section>
                </SlideModal>
                );
    }

    render() {
        let menuOptions = [];
        if (this.props.settings && this.props.settings.lists && this.props.settings.lists.length) {
            menuOptions = (
                <Menu>
                    {this.props.settings.lists.map((option, i) => {
                        if (option.type && option.type !== this.props.account.listType) {
                            return <Menu.Item key={i}>
                                <a onClick={() => this.setState({newPopup: true, selectedList: option.id})}>
                                    <Translate locale={this.props.settings.locale} value={option.name} />
                                </a>
                            </Menu.Item>
                        }
                    })}
                </Menu>
            );
        }
        return (
            <Card 
            size="small" 
            style={{marginTop: 10}}
            title={plainTranslate(this.props.settings.locale, 'Account contacts')}
            extra={(this.props.settings && this.props.settings.lists && this.props.settings.lists.length) ?
                <Dropdown 
                overlay={menuOptions} 
                placement="bottomCenter"                
                >
                <PlusCircleOutlined />
                   {/* <Button style={{ 
                        padding: "4px", 
                        backgroundColor: "#65bf65", 
                        color: "white", 
                        fontSize: "12px", 
                        borderRadius: "3px", 
                        borderColor: "#4da64d", 
                        borderStyle: "solid", 
                        borderWidth: "1px", 
                        height: "27px", 
                        fontWeight: "bold", 
                        marginTop: "0", 
                        marginLeft: "10px" }}>
                        <i className="fa fa-plus" aria-hidden="true"></i>
                        <Translate locale={this.props.settings.locale} value="Add new" /></Button>*/}
                </Dropdown>
                :
                <span></span>
            }>
                <div className="account-profile-other-information-more">
                    <div className="head-line">                              
                             {!this.props.value &&
                                this.getNoAccountLinks()
                             }
                    </div>
                    {/*this.props.value &&
                        <a className="account-profile-show" id="accountLinks" href="javascript:void(0)"
                           onClick={() => this.setState({show: !this.state.show})}>
                            {this.state.show ? <i className="glyphicon glyphicon-chevron-up"></i> :
                                <i className="glyphicon glyphicon-chevron-down"></i>}
                        </a>
                   */ }
                    {
                        this.state.show &&
                        <React.Fragment>
                            {
                                this.props.value ? this.getAccountLinks() : ''
                            }
                        </React.Fragment>
                    }
                </div>
                {
                    this.state.newPopup && this.showNewPopup()

                }
              
               </Card>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});


export default connect(mapStateToProps, {onFieldSave, onAccountFetchData})(withRouter(AccountLinks));
