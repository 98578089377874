import React, { Component } from 'react';
import Translate, { plainTranslate } from '../../../common/translate';
import { connect } from 'react-redux';
import { saveRecord } from "../../../../actions/actions";
import SlideModal from '../../../common/SlideModal';
import RecordForm from './recordForm';
import { withRouter } from 'react-router-dom';
import client from '../../../../actions/client';
import showAToast from '../../../common/showAToast';

class BlockResultRow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            record: [],
            fields: [],
            showDelete: false,
            showEdit: false,
            originalFields: []
        };

        this.parseRecord = this.parseRecord.bind(this);
        this.deleteRecord = this.deleteRecord.bind(this);
        this.handleYesDelete = this.handleYesDelete.bind(this);
        this.editRecord = this.editRecord.bind(this);
        this.onChange = this.onChange.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
    }

    componentDidMount() {
        var fields = this.parseRecord();
        this.setState({
            record: [this.props.record],
            fields: this.parseRecord(),
            originalFields: this.parseRecord()
        });  
    }

    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(this.props.record) != JSON.stringify(prevProps.record)) {
            this.setState({
                fields: this.parseRecord(),
                originalFields: this.parseRecord()
            });
        }
    }

    parseRecord() {
        var data = JSON.parse(this.props.record.data);
        data = data.fields;
        var ordered = [];
        if (data) {
            for (var i = 0; i < this.props.order.length; i++) {
                if (this.props.order[i] in data) {
                    ordered[i] =
                            data[this.props.order[i]] == 'true' ? true : data[this.props.order[i]] == 'false' ? false : data[this.props.order[i]];
                } else {
                    ordered[i] = '';
                }
            }
        }

        var result = Object.keys(ordered).map(function (key) {
            var res = [];
            res['key'] = key;
            res['value'] = ordered[key];
            return res;
        });

        return result;
    }

    deleteRecord() {
        this.setState({
            showDelete: !this.state.showDelete
        });
    }

    editRecord(update = false) {
        this.setState({
            showEdit: !this.state.showEdit
        });
    }

    handleYesDelete() {
        this.deleteRecord();
        
         client
        .post('/' + this.props.match.params.db + '/api/data-blocks/entry/delete/' + this.props.record.id)
        .then(response => response.data) 
        .then(response => {
        this.props.updateRecord(response.id, response, true)
                if ('errors' in response) {
                    showAToast(response.errors, 'baseError', 'error');
                }
                else {
                    showAToast('Data deleted successfully!', 'baseSuccess', 'success');
                }
            });

    }

    onChange(e) {
        var fields = this.state.fields;
        if (e.target.type == 'checkbox') {
            fields[e.target.id].value = e.target.checked;
        }
        else {
            fields[e.target.id].value = e.target.value;
        }

        this.setState({
            fields: fields
        });
    }

    saveChanges() {
        var schema = this.props.formFields;
        var length = schema.length;
        var values = {};
        for (var i = 0; i < length; i++) {
            var x = document.getElementsByName(schema[i].id);
            for (var j = 0; j < x.length; j++) {
                if (x[j].type == 'checkbox') {
                    values[schema[i].id] = x[j].checked ? true : false;
                }
                else {
                    values[schema[i].id] = x[j].value;
                }
            }
        }

        let error = false;
        schema.map(field => {
            if (field.required === 'true') {
                if (!(field.id in values && values[field.id])) {
                    error = true;
                }
            }
        });

        if (error) {
            showAToast('Please fill all required fields!', 'baseError', 'error');
            return;
        }

        client
        .post('/' + this.props.match.params.db + '/api/data-blocks/entry/edit/' + this.props.record.id, {'fields' : values})
        .then(response => response.data) 
        .then(response => {
            this.props.updateRecord(response.id, response, false)
                if ('errors' in response) {
                    showAToast(response.errors, 'baseError', 'error');
                }
                else {
                    showAToast('Data saved successfully!', 'baseSuccess', 'success');
                }
                this.editRecord()
        })
        .catch(err => {
            console.log(err)
        });

        this.setState({
            originalFields: this.state.fields
        });
    }

    extraCols() {
        var cols = [];
        for (var i = 0; i < this.props.formFields.length - this.state.fields.length; i++) {
            cols.push(<td key={this.state.originalFields.length + i}></td>)
        }
        return cols;
    }

    render() {
        return (
            <tr>
                {
                    this.state.originalFields.map(function (field, i) {
                        return <td
                            key={i}>{field.value === true ? 'Yes' : field.value === false ? 'No' : field.value}</td>
                    }.bind(this))
                }
                {
                    this.extraCols()
                }
                <td>
                    <div className="static-buttons c-block">
                        <i id="activities-action" className="ion-android-more-vertical">
                        </i>
                        <div className="activities-menu">
                            <ul>
                                <li>
                                    <a href="#" onClick={this.editRecord}><Translate locale={this.props.settings.locale}
                                                                                     value="Edit"/></a>
                                </li>
                                <li>
                                    <a href="#" onClick={this.deleteRecord}><Translate locale={this.props.settings.locale}
                                                                                       value="Delete"/></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {
                        this.state.showDelete &&
                        <RecordForm onClose={this.deleteRecord} windowWidth="modalConfirm scroll">
                            <section className="newPanel">
                                <div className="panel-body">
                                    <div className="row m-bot15">
                                        <div className="text-center">
                                            <h3><Translate locale={this.props.settings.locale} value="Are you sure?"/></h3>
                                            <button className="button-outlines green text-center"
                                                    onClick={this.deleteRecord}>
                                                <Translate locale={this.props.settings.locale} value="No"/>
                                            </button>
                                            <button className="button-primary-edited text-center btn-ok"
                                                    onClick={this.handleYesDelete}>
                                                <Translate locale={this.props.settings.locale} value="Yes"/>
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            </section>
                        </RecordForm>
                    }

                    {
                        this.state.showEdit &&
                       <SlideModal onClose={() => this.editRecord()} title={plainTranslate(this.props.settings.locale, 'Edit record')}>
                            <section className="newPanel">
                                <div className="panel-body">
                                    <div className="row m-bot15">                             
                                                <div className="form-group">
                                                    {
                                                        this.props.formFields.map(function (field, i) {
                                                            let input = null;
                                                            let inputType = null;

                                                            if (typeof field.type === 'object')
                                                                inputType = field.type.value.toLowerCase();
                                                            else
                                                                inputType = field.type.toLowerCase();

                                                            if (inputType === 'dropdown') {
                                                                input = <select className="form-control"
                                                                                name={field.id}
                                                                                id={i}
                                                                                value={this.state.fields[i].value}
                                                                                onChange={this.onChange}>
                                                                    {
                                                                        field.type.options.map((option, key) => {
                                                                            return <option value={option}
                                                                                           key={key}>{option}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                            }
                                                            else {
                                                                input = <input type={field.type.toLowerCase()}
                                                                               name={field.id}
                                                                               className="form-control"
                                                                               id={i}
                                                                               value={this.state.fields[i].value}
                                                                               onChange={this.onChange}
                                                                               defaultChecked={this.state.fields[i].value}
                                                                />;
                                                            }


                                                            return (
                                                                <div
                                                                    className={(field.required === 'true' ? 'col-sm-12 col-xs-12 form-row required' : 'col-sm-12 col-xs-12 form-row')}
                                                                    key={i}>
                                                                    <label className="col-md-12">{field.name}</label>
                                                                    {input}
                                                                </div>
                                                            );
                                                        }.bind(this))
                                                    }
                                                </div>
                                            </div>

                                            <button className="button-primary-edited text-center btn-ok"
                                                    onClick={this.saveChanges}>
                                                <Translate locale={this.props.settings.locale} value="Save"/>
                                            </button>
                                            <button className="button-outlines green text-center"
                                                    onClick={this.editRecord}>
                                                <Translate locale={this.props.settings.locale} value="Cancel"/>
                                            </button>

                                            </div>                 
                            </section>
                        </SlideModal>
                    }
                </td>
            </tr>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
    accountProfileInformation: state.accountProfileInformation
});
const mapDispatchToProps = dispatch => ({
    updateRecord: (id, key, value) =>
            dispatch(saveRecord(id, key, value))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BlockResultRow));