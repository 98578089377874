import React, {Component} from 'react';
import {render} from "react-dom";
import Translate, {plainTranslate} from '../../../common/translate';
import {connect} from 'react-redux';
import {Route} from "react-router-dom";
import Popup from '../../../common/popup';
import ContactForm from './contactForm';
import { Card, Row, Col } from 'antd';
import {EditOutlined, PlusCircleOutlined} from '@ant-design/icons'
const types = [
    {value: '1', label: 'Email'},
    {value: '2', label: 'Phone'},
    {
        value: '3',
        label: 'Mobile'
    },
    {
        value: '4',
        label: 'Social. Google+'
    },
    {
        value: '5',
        label: 'Social. Facebook',
    },
    {
        value: '6',
        label: 'Social. LinkedIn'
    },
    {
        value: '7',
        label: 'Social. Twitter'
    },
    {
        value: '8',
        label: 'Skype'
    },
     {
        value: '9',
        label: 'Fax',
    },
    {
        value: '10',
        label: 'Website'
    },
    {
        value: '11',
        label: 'Personal number'
    },
    {
        value: '12',
        label: 'other'
    },
  ];

class Contacts extends Component {

    constructor(props) {
        super(props);

        this.state = {
            contacts: [],
            open: false
        }; 
    }
    
    componentDidMount() {
        this.setState({
            contacts: this.props.contacts ? this.props.contacts : [],
        });
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.contacts !== this.props.contacts ) {
            this.setState({
                contacts: this.props.contacts
            });
        }
    }
    
    addContacts = (contacts) => {       
        this.setState({
            contacts: contacts
        });
    }

    openEdit = () => {
        const {open} = this.state;
        this.setState({
            open: !open
        });
    }
    
    render() {
        return (
                <Card size="small" 
                title={plainTranslate(this.props.settings.locale, 'Contacts')}
                extra={(this.state.contacts && this.state.contacts.length > 0) ? <EditOutlined onClick={this.openEdit}/> : <PlusCircleOutlined onClick={this.openEdit}/>}
                style={{marginTop: 10}}>                      
                        {this.state.contacts && this.state.contacts.map((contact, key) => (
                        <Row style={{margin: 10}}>
                        <Col span={12} className="account-profile-card-header">{plainTranslate(this.props.settings.locale, contact.label)} : </Col> 
                        <Col span={12}>{contact.valueString}</Col>
                        </Row> 
                        ))}
                        {this.state.contacts.length < 1 &&  <div className="div-inline m-left15">
                        <span className="text-italic" style={{color: '#a0a0a0'}}>
                            {plainTranslate(this.props.settings.locale, 'No contacts added.')}</span>
                    </div>}          
                
               {this.state.open &&  <Popup locale={this.props.settings.locale}
                       title={plainTranslate(this.props.settings.locale, 'Edit contacts')}
                       onClose={() => this.setState({open: false})}>
                    <section className="newPanel">
                        <div className="panel-body">
                            <ContactForm
                            contacts={this.state.contacts}
                            website={this.props.website}
                            accountId={this.props.accountId}
                            settings={this.props.settings}
                            db={this.props.db}
                            addContacts={this.addContacts} 
                            listFields={this.props.listFields}
                            types={types}
                            openEdit={this.openEdit}/>
                        </div>
                    </section>    
                </Popup>    }        
                </Card>
                )
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({
   
});

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
