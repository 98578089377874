import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Translate, {plainTranslate} from '../common/translate';
import {connect} from 'react-redux';
import {SETTINGS_CONFIRM_DIALOG_TOGGLE} from '../../constants/actionTypes';
import {onUserRolesGridLoad, onUserRolesGridRemove} from '../../actions/actions';
import ModalConfirm from '../common/ModalConfirm';

class RolesGrid extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showMessage: false,
        };

        this.id = '';

        this.showConfirm = this.showConfirm.bind(this);
        this.acceptConfirmDialog = this.acceptConfirmDialog.bind(this);
    }

    componentDidMount() {
        this.props.onUserRolesGridLoad(this.props.match.params.db);

        if (this.props.title) {
            let titleArr = this.props.title;
            titleArr.map((item, key) => titleArr[key] = plainTranslate(this.props.settings.locale, item));
            document.title = titleArr.join(' ');
        }
    }

    acceptConfirmDialog() {
        this.props.onConfirmDialogToggle();
        this.props.onUserRolesGridRemove(this.props.match.params.db, this.id);
    }

    showConfirm(id) {
        this.id = id;
        this.props.onConfirmDialogToggle();
    }

    render() {
      console.log(this.props.auth.user.roles)
        return (
            <div id="ag-grid-div">
                <div className="moduleSubMenu">
                    <div className="col-sm-12">
                        <h3 className="module-title pull-left"><Translate locale={this.props.locale}
                                                                          value="Roles and Permissions"/></h3>
                        <ul className='nav-items'>
                            <li className="nav-item">
                                <Link to={'/' + this.props.match.params.db + '/settings/roles/new'}
                                      className="button-primary">
                                    <i className="ion-plus-round"></i>
                                    <span>
                                            <Translate locale={this.props.settings.locale} value="New"/>
                                        </span>
                                </Link>
                            </li>
                        </ul>
                        <ul className="nav navbar-nav pull-right">
                        {this.props.auth && this.props.auth.user && this.props.auth.user.roles && this.props.auth.user.roles.includes("ROLE_SUPER_ADMIN") && 
                         <li className="nav-item">
                                <Link to={'/' + this.props.match.params.db + '/administration/users'}
                                   title={plainTranslate(this.props.settings.locale, 'Users')}
                                   className={this.props.location.pathname == ("/" + this.props.match.params.db + "/administration/users") ? 'active' : ''}>
                                    <Translate locale={this.props.settings.locale} value="Users"/>
                                </Link>
                            </li>}
                            { this.props.settings.administration && this.props.settings.administration.includes('portalUsers') &&                           
                            <li className="nav-item">
                            <Link to={'/' + this.props.match.params.db + '/portal/users'}
                               title={plainTranslate(this.props.settings.locale, 'Portal Users')}
                               className={this.props.location.pathname == ("/" + this.props.match.params.db + "/portal/users") ? 'active' : ''}>
                                <Translate locale={this.props.settings.locale} value="Portal Users"/>
                            </Link>
                           </li> }
                           {this.props.settings.administration &&  this.props.settings.administration.includes('onlineUsers') &&
                           <li className="nav-item">
                            <Link to={'/' + this.props.match.params.db + '/online/users'}
                               title={plainTranslate(this.props.settings.locale, 'Online Users')}
                               className={this.props.location.pathname == ("/" + this.props.match.params.db + "/online/users") ? 'active' : ''}>
                                <Translate locale={this.props.settings.locale} value="Online Users"/>
                            </Link>
                           </li> }
                           {this.props.auth && this.props.auth.user && this.props.auth.user.roles.includes("ROLE_SUPER_ADMIN") && 
                            <li className="nav-item">
                                <Link to={'/' + this.props.match.params.db + '/settings/roles'}
                                      title={plainTranslate(this.props.settings.locale, 'Roles')}
                                      className={this.props.location.pathname == ("/" + this.props.match.params.db + "/settings/roles") ? 'active' : ''}>
                                    <Translate locale={this.props.settings.locale} value="Roles"/>
                                </Link>
                            </li>}
                        </ul>
                    </div>
                </div>
                <form name="roles_settings" className="cmxform form-horizontal bucket-form ng-pristine ng-valid">
                    <div className="row m-bot15">
                        <div className="col-lg-12">
                            <section className="panel">
                                <div className="panel-body">

                                    <div className="settings">
                                        <div className="row">
                                            <div>
                                                <div className="col-md-8 setting-item">
                                                    {plainTranslate(this.props.settings.locale, 'Role')}
                                                </div>
                                                <div className="col-md-2">
                                                    {plainTranslate(this.props.settings.locale, '# of Users')}
                                                </div>
                                                <div className="col-md-2">
                                                    {plainTranslate(this.props.settings.locale, 'Actions')}
                                                </div>
                                                <div className="clearfix"></div>
                                            </div>
                                        </div>
                                        {
                                            'userRoles' in this.props.settings &&
                                            <div>
                                                {
                                                    this.props.settings.userRoles.map((role, key) => {
                                                        return <div className="row" key={key}>
                                                            <div>
                                                                <div className="col-md-8 setting-item">
                                                                    <label>
                                                                        <Translate locale={this.props.settings.locale}
                                                                                   value={role.name}/>
                                                                    </label>
                                                                    <p>
                                                                        <Translate locale={this.props.settings.locale}
                                                                                   value={role.description}/>
                                                                    </p>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    {role.users}
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <Link className="marg"
                                                                          to={'/' + this.props.match.params.db + '/settings/roles/' + role.id + '/edit'}>
                                                                        <i className="fa fa-pencil-square-o"
                                                                           aria-hidden="true"></i>
                                                                    </Link>
                                                                    <a className="marg">
                                                                        <i className="fa fa-trash-o" aria-hidden="true"
                                                                           onClick={() => this.showConfirm(role.id)}></i>
                                                                    </a>
                                                                </div>
                                                                <div className="row m-bot50"></div>
                                                            </div>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        }

                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </form>
                {
                    'confirm' in this.props.settings && this.props.settings.confirm &&
                    <ModalConfirm onClose={() => this.props.onConfirmDialogToggle()}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <div className="text-center">

                                        <h3>
                                            <Translate locale={this.props.settings.locale} value="Are you sure?"/>
                                        </h3>
                                        <button className="button-outlines green text-center"
                                                onClick={() => this.props.onConfirmDialogToggle()}>
                                            <Translate locale={this.props.settings.locale} value="No"/>
                                        </button>
                                        <button className="button-primary-edited text-center btn-ok"
                                                onClick={this.acceptConfirmDialog}>
                                            <Translate locale={this.props.settings.locale} value="Yes"/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </ModalConfirm>
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
    formData: state.formData,
    hrSettings: state.hrSettings,
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    onUserRolesGridLoad: (db) => dispatch(onUserRolesGridLoad(db)),
    onUserRolesGridRemove: (db, id) => dispatch(onUserRolesGridRemove(db, id)),
    onConfirmDialogToggle: () => dispatch({type: SETTINGS_CONFIRM_DIALOG_TOGGLE})
});

export default connect(mapStateToProps, mapDispatchToProps)(RolesGrid);


