import React from 'react';
import {connect} from 'react-redux';
import {plainTranslate} from "../../common/translate";
import ErrorMessage from '../errorMessage';
import Text from './text';
import Select2 from './select2';
import Number from './number';
import Dropdown from './dropdown';
import Textarea from './textarea';
import Percents from "./percents";
import Datalist from "./datalist";
import Switch from './switch';
import Checkmark from './checkmark';
import UpdatedSelect from './updatedSelect';
import LineButton from './lineButton';
import SingleCheckbox from './checkbox';
import DatePicker from "./datepicker";
import Images from './images';
import ColorPicker from './colorPicker';


class LineField extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <td className={this.props.field.hide ? 'hidden' : ''} data-title={this.props.isPos ? plainTranslate(this.props.locale, this.props.field.label) : ''}>
                    {
                        this.props.field.type === 'text' &&
                        <Text {...this.props} />
                    }
                    
                    {
                        this.props.field.type === 'updatedSelect' &&
                        <UpdatedSelect {...this.props} />
                    }
                    
                    {
                        this.props.field.type === 'checkmark' &&
                        <Checkmark {...this.props} />
                    }

                    {
                        this.props.field.type === 'select2' &&
                        <Select2 {...this.props} />
                    }

                    {
                        this.props.field.type === 'number' &&
                        <Number {...this.props} />
                    }

                    {
                        this.props.field.type === 'dropdown' &&
                        <Dropdown {...this.props} />
                    }

                    {
                        this.props.field.type === 'textarea' &&
                        <Textarea {...this.props} />
                    }

                    {
                        this.props.field.type === 'percents' &&
                        <Percents
                            {...this.props}
                            rules={this.props.field.rules}   
                        />
                    }
                    
                    {
                        this.props.field.type === 'datalist' &&
                        <Datalist {...this.props} />
                    }
                    
                    {
                        this.props.field.type === 'switch' &&
                        <Switch {...this.props} />
                    }
                    
                    {
                        this.props.field.type === 'checkbox' &&
                        <SingleCheckbox {...this.props} />
                    }
                    
                    {
                        this.props.field.type === 'action' &&
                        <LineButton {...this.props} />
                    }
                    
                    {
                        this.props.field.type === 'datepicker' &&
                        <DatePicker {...this.props} />
                    }

                    {
                        this.props.field.type === 'images' &&
                        <Images {...this.props} />
                    }

                    {
                        this.props.field.type === 'color-picker' &&
                        <ColorPicker {...this.props} />
                    }

                    {
                        this.props.meta.touched && this.props.meta.error &&
                        <div>
                            <div className="forms-error-msg">
                                <ErrorMessage
                                    locale={this.props.locale}
                                    error={this.props.meta.error}
                                    message={'errorMessage' in this.props.field ? this.props.field.errorMessage : false}
                                />
                                <br/>
                            </div>
                        </div>
                    }

            </td>
    }
}

export default LineField;
