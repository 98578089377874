export default function getGridV2Routes(db) {
    return [
        {
            title: ['Tasks', '›', 'List'],
            path: '/:db/tasks-v2',
            gridRoute: '/api/grid/settings/grid-properties/tasks',
            gridDataRoute: '/api/grid/settings/grid-data/tasks',
            gridExportRoute: '/api/grid/tasks/export/',
            gridSaveRoute: '/api/grid/tasks/save'
        },
        {
            title: ['Sales', '›', 'List'],
            path: '/:db/sales-v2',
            gridRoute: '/api/grid/settings/grid-properties/sales',
            gridDataRoute: '/api/grid/settings/grid-data/sales',
            gridExportRoute: '/api/grid/sales/export/',
            gridSaveRoute: '/api/grid/sales/save'
        },
        {
            title: ['Inventory requests', '›', 'List'],
            path: '/:db/inventory-request-v2',
            gridRoute: '/api/grid/settings/grid-properties/inventory-request',
            gridDataRoute: '/api/grid/settings/grid-data/inventory-request',
            gridExportRoute: '/api/grid/inventory-request/export/',
            gridSaveRoute: '/api/grid/inventory-request/save'
        },
        {
            title: ['Location places', '›', 'List'],
            path: '/:db/administration/location-places',
            gridRoute: '/api/grid/settings/grid-properties/location-places',
            gridDataRoute: '/api/grid/settings/grid-data/location-places',
            gridExportRoute: '/api/grid/location-places/export/',
            gridSaveRoute: '/api/grid/location-places/save'
        },
        {
            title: ['Inventory places', '›', 'List'],
            path: '/:db/inventory-places',
            gridRoute: '/api/grid/settings/grid-properties/inventory-places',
            gridDataRoute: '/api/grid/settings/grid-data/inventory-places',
            gridExportRoute: '/api/grid/inventory-places/export/',
            gridSaveRoute: '/api/grid/inventory-places/save'
        },
        {
            title: ['Projects', '›', 'List'],
            path: '/:db/projects-v2',
            gridRoute: '/api/grid/settings/grid-properties/projects',
            gridDataRoute: '/api/grid/settings/grid-data/projects',
            gridExportRoute: '/api/grid/projects/export/',
            gridSaveRoute: '/api/grid/projects/save'
        },
        {
            title: ['Messages', '›', 'List'],
            path: '/:db/messages',
            gridRoute: '/api/grid/settings/grid-properties/messages',
            gridDataRoute: '/api/grid/settings/grid-data/messages',
            gridExportRoute: '/api/grid/messages/export/',
            gridSaveRoute: '/api/grid/messages/save'
        },
        {
            title: ['Integrations'],
            path: '/:db/settings/integrations',
            gridRoute: '/api/grid/settings/grid-properties/integrations',
            gridDataRoute: '/api/grid/settings/grid-data/integrations',
            gridExportRoute: '/api/grid/integrations/export/',
            gridSaveRoute: '/api/grid/integrations/save'
        },
        {
            title: ['Log Time'],
            path: '/:db/log-time-v2',
            gridRoute: '/api/grid/settings/grid-properties/log-time',
            gridDataRoute: '/api/grid/settings/grid-data/log-time',
            gridExportRoute: '/api/grid/log-time/export/',
            gridSaveRoute: '/api/grid/log-time/save'
        },
        {
            title: ['Items'],
            path: '/:db/administration/items-v2',
            gridRoute: '/api/grid/settings/grid-properties/items',
            gridDataRoute: '/api/grid/settings/grid-data/items',
            gridExportRoute: '/api/grid/items/export/',
            gridSaveRoute: '/api/grid/items/save',
            exportTemplateRoute: '/api/export/importData-v2/items'
        },
        {
            title: ['Accounts'],
            path: '/:db/accounts-v2/:refs',
            gridRoute: '/api/grid/settings/grid-properties/accounts',
            gridDataRoute: '/api/grid/settings/grid-data/accounts',
            gridExportRoute: '/api/grid/accounts/export/',
            gridSaveRoute: '/api/grid/accounts/save'
        },
        {
            title: ['Deals'],
            path: '/:db/deals-v2',
            gridRoute: '/api/grid/settings/grid-properties/deals',
            gridDataRoute: '/api/grid/settings/grid-data/deals',
            gridExportRoute: '/api/grid/deals/export/',
            gridSaveRoute: '/api/grid/deals/save'
        },
        {
            title: ['Deals details'],
            path: '/:db/deal/details-v2',
            gridRoute: '/api/grid/settings/grid-properties/deal-details',
            gridDataRoute: '/api/grid/settings/grid-data/deal-details',
            gridExportRoute: '/api/grid/deal-details/export/',
            gridSaveRoute: '/api/grid/deal-details/save'
        },
        {
            title: ['Assets'],
            path: '/:db/accounting/asset-v2',
            gridRoute: '/api/grid/settings/grid-properties/asset',
            gridDataRoute: '/api/grid/settings/grid-data/asset',
            gridExportRoute: '/api/grid/asset/export/',
            gridSaveRoute: '/api/grid/asset/save'
        },
        {
            title: ['Ledger'],
            path: '/:db/accounting/ledger-v2',
            gridRoute: '/api/grid/settings/grid-properties/ledger',
            gridDataRoute: '/api/grid/settings/grid-data/ledger',
            gridExportRoute: '/api/grid/ledger/export/',
            gridSaveRoute: '/api/grid/ledger/save'
        },
        {
            title: ['Ledger details'],
            path: '/:db/accounting/ledger-details-v2',
            gridRoute: '/api/grid/settings/grid-properties/ledgerLine',
            gridDataRoute: '/api/grid/settings/grid-data/ledgerLine',
            gridExportRoute: '/api/grid/ledgerLine/export/',
            gridSaveRoute: '/api/grid/ledgerLine/save'
        },
        {
            title: ['Chart of accounts'],
            path: '/:db/accounting/chart-of-accounts-v2',
            gridRoute: '/api/grid/settings/grid-properties/chartOfAccount',
            gridDataRoute: '/api/grid/settings/grid-data/chartOfAccount',
            gridExportRoute: '/api/grid/chartOfAccount/export/',
            gridSaveRoute: '/api/grid/chartOfAccount/save'
        },
        {
            title: ['Journal'],
            path: '/:db/accounting/journal-types-v2',
            gridRoute: '/api/grid/settings/grid-properties/journal',
            gridDataRoute: '/api/grid/settings/grid-data/journal',
            gridExportRoute: '/api/grid/journal/export/',
            gridSaveRoute: '/api/grid/journal/save'
        },
        {
            title: ['Vat sales', '›', 'List'],
            path: '/:db/vat-sales-v2',
            gridRoute: '/api/grid/settings/grid-properties/vatSales',
            gridDataRoute: '/api/grid/settings/grid-data/vatSales',
            gridExportRoute: '/api/grid/vatSales/export/',
            gridSaveRoute: '/api/grid/vatSales/save'
        },
        {
            title: ['Vat purchase', '›', 'List'],
            path: '/:db/vat-purchase-v2',
            gridRoute: '/api/grid/settings/grid-properties/vatPurchase',
            gridDataRoute: '/api/grid/settings/grid-data/vatPurchase',
            gridExportRoute: '/api/grid/vatPurchase/export/',
            gridSaveRoute: '/api/grid/vatPurchase/save'
        },
        {
            title: ['User profiles', '›', 'List'],
            path: '/:db/user-profiles-v2',
            gridRoute: '/api/grid/settings/grid-properties/userProfiles',
            gridDataRoute: '/api/grid/settings/grid-data/userProfiles',
            gridExportRoute: '/api/grid/userProfiles/export/',
            gridSaveRoute: '/api/grid/userProfiles/save'
        },
        {
            title: ['User billing', '›', 'List'],
            path: '/:db/user-billing-v2',
            gridRoute: '/api/grid/settings/grid-properties/userBilling',
            gridDataRoute: '/api/grid/settings/grid-data/userBilling',
            gridExportRoute: '/api/grid/userBilling/export/',
            gridSaveRoute: '/api/grid/userBilling/save'
        },
        {
            title: ['User address', '›', 'List'],
            path: '/:db/user-address-v2',
            gridRoute: '/api/grid/settings/grid-properties/userAddress',
            gridDataRoute: '/api/grid/settings/grid-data/userAddress',
            gridExportRoute: '/api/grid/userAddress/export/',
            gridSaveRoute: '/api/grid/userAddress/save'
        },
        {
            title: ['Expenses', '›', 'List'],
            path: '/:db/expenses-v2',
            gridRoute: '/api/grid/settings/grid-properties/expenses',
            gridDataRoute: '/api/grid/settings/grid-data/expenses',
            gridExportRoute: '/api/grid/expenses/export/',
            gridSaveRoute: '/api/grid/expenses/save'
        },
        {

            title: ['Receipts', '›', 'List'],
            path: '/:db/inventory-v2',
            gridRoute: '/api/grid/settings/grid-properties/inventory',
            gridDataRoute: '/api/grid/settings/grid-data/inventory',
            gridExportRoute: '/api/grid/inventory/export/',
            gridSaveRoute: '/api/grid/inventory/save'
        },
        {
            title: ['Delivery Methods', '›', 'List'],
            path: '/:db/administration/delivery-methods',
            gridRoute: '/api/grid/settings/grid-properties/delivery-methods',
            gridDataRoute: '/api/grid/settings/grid-data/delivery-methods',
            gridExportRoute: '/api/grid/delivery-methods/export/',
            gridSaveRoute: '/api/grid/delivery-methods/save'
        },
        {
            title: ['Payments', '›', 'List'],
            path: '/:db/payments-v2',
            gridRoute: '/api/grid/settings/grid-properties/payments',
            gridDataRoute: '/api/grid/settings/grid-data/payments',
            gridExportRoute: '/api/grid/payments/export/',
            gridSaveRoute: '/api/grid/payments/save'
        },
        {
            title: ['Production', '›', 'List'],
            path: '/:db/production-v2',
            gridRoute: '/api/grid/settings/grid-properties/production',
            gridDataRoute: '/api/grid/settings/grid-data/production',
            gridExportRoute: '/api/grid/production/export/',
            gridSaveRoute: '/api/grid/production/save'
        },
        {
            title: ['Production Details', '›', 'List'],
            path: '/:db/production-details-v2',
            gridRoute: '/api/grid/settings/grid-properties/production-details',
            gridDataRoute: '/api/grid/settings/grid-data/production-details',
            gridExportRoute: '/api/grid/production-details/export/',
            gridSaveRoute: '/api/grid/production-details/save'
        },
        {
            title: ['Project Boards', '›', 'List'],
            path: '/:db/project-boards',
            gridRoute: '/api/grid/settings/grid-properties/project-boards',
            gridDataRoute: '/api/grid/settings/grid-data/project-boards',
            gridExportRoute: '/api/grid/project-boards/export/',
            gridSaveRoute: '/api/grid/project-boards/save'
        },
        {
            title: ['Profit & Loss'],
            path: '/:db/accounting/pl-report',
            gridRoute: '/api/grid/settings/grid-properties/accounting-pl-report',
            gridDataRoute: '/api/grid/settings/grid-data/accounting-pl-report',
            gridExportRoute: '/api/grid/accounting-pl-report/export/',
            gridSaveRoute: '/api/grid/accounting-pl-report/save'
        },
        {
            title: ['Balance Sheet'],
            path: '/:db/accounting/bs-report',
            gridRoute: '/api/grid/settings/grid-properties/accounting-bs-report',
            gridDataRoute: '/api/grid/settings/grid-data/accounting-bs-report',
            gridExportRoute: '/api/grid/accounting-bs-report/export/',
            gridSaveRoute: '/api/grid/accounting-bs-report/save'
        },
        {
            title: ['Account Payable / Receivables'],
            path: '/:db/accounting/ledger-account-report',
            gridRoute: '/api/grid/settings/grid-properties/accounting-ledger-account-report',
            gridDataRoute: '/api/grid/settings/grid-data/accounting-ledger-account-report',
            gridExportRoute: '/api/grid/accounting-ledger-account-report/export/',
            gridSaveRoute: '/api/grid/accounting-ledger-account-report/save'
        }
    ];
}
