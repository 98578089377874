import React, {Component} from 'react';
import {render} from "react-dom";
import Translate, {plainTranslate} from '../../common/translate';
import Message from '../../common/message';
import { connect } from 'react-redux';
import SettingsMenu from '../../sidebar/settings-menu';
import { client} from "../../../actions/actions";
import OpenSection from './../openSection.jsx';
import SingleCheckbox from './../singleCheckbox.jsx';
import Stages from './../stages.jsx';
import { fetchSettings} from "../../../actions/actions";

class GeneralSettingsPage extends Component {

    constructor(props) {
    super(props);
            this.state = {
            showMessage: false,
            };
        this.allSettings = [
            { value: "inventoryMeasures", name: 'Inventory measures', text: 'Select whether you need measures on each item line when creating new receipts for the Inventory'},
        ];
        this.updateStages = this.updateStages.bind(this);
    }

    componentDidMount() {
        this.props.getSettings('/' + this.props.match.params.db + '/api/general/settings/general-settings', 'generalSettings');
        if (this.props.title) {
            let titleArr = this.props.title;
            titleArr.map((item, key) => titleArr[key] = plainTranslate(this.props.settings.locale, item));
            document.title = titleArr.join(' ');
        }
    }
    
    updateStages() {
        this.props.getSettings('/' + this.props.match.params.db + '/api/general/settings/general-settings', 'generalSettings');
    }

     render() {

        return (
                <div>
                    <SettingsMenu
                       location = {this.props.location.pathname} db = {this.props.match.params.db}/>
                    <form name="settings_expense" className="cmxform form-horizontal bucket-form ng-pristine ng-valid">
                       <div className="row m-bot15">
                          <div className="col-lg-12">
                             <section className="panel">
                                <div className="panel-body">
                                   <div className="settings">
                                        <OpenSection
                                            label={plainTranslate(this.props.settings.locale, 'Company Information')}
                                            description={plainTranslate(this.props.settings.locale, "Enter your company data: official name, address, VAT No, etc.")}
                                            component={'company_settings'}
                                            value ={this.props.settings.generalSettings}
                                            type={'generalSettings'}
                                            saveRoute={'/' + this.props.match.params.db + '/api/general/save/company-settings'}
                                            setting={'companySettings'}
                                         />
                                         <hr/>
                                        <OpenSection
                                            label={plainTranslate(this.props.settings.locale, 'Company Logo')}
                                            description={plainTranslate(this.props.settings.locale, "Upload your company logo")}
                                            link={"/" + this.props.match.params.db + "/administration/company-logo"}
                                        />
                                        <hr/>
                                        <OpenSection
                                            label={plainTranslate(this.props.settings.locale, 'Document Sequence')}
                                            description={plainTranslate(this.props.settings.locale, "Specify types of documents numbering")}
                                            link={"/" + this.props.match.params.db + "/administration/document-sequences"}
                                         />
                                        <hr/>
                                        <OpenSection
                                            label={plainTranslate(this.props.settings.locale, 'Form Types')}
                                            description={plainTranslate(this.props.settings.locale, "Specify different types of documents for every module")}
                                            link={"/" + this.props.match.params.db + "/form-types"}
                                         />
                                         <hr/>
                                        <OpenSection
                                            label={plainTranslate(this.props.settings.locale, 'Scale Of Decimal')}
                                            description={plainTranslate(this.props.settings.locale, "Number of digits to the right of the decimal point - for all item quantities, for the unit price of items and overall setting for the system")}
                                            component={'general_scale_of_decimal'}
                                            value ={this.props.settings.generalSettings}
                                            type={'generalSettings'}
                                            saveRoute={'/' + this.props.match.params.db + '/api/general/save/settings/individual'}
                                            setting={'scaleOfDecimal'}
                                         />
                                         <hr/>
                                        <OpenSection
                                            label={plainTranslate(this.props.settings.locale,'Discount Method')}
                                            description={plainTranslate(this.props.settings.locale,"This option allows you to prioritize if the discount on sales line is based on price, percent or it's a cumulation of both")}
                                            component={'select'}
                                            value ={this.props.settings.generalSettings}
                                            type={'generalSettings'}
                                            saveRoute={'/' + this.props.match.params.db + '/api/general/save/settings/individual'}
                                            setting={'discountType'}
                                         />
                                         <hr/>
                                        <OpenSection
                                            label={plainTranslate(this.props.settings.locale,'Open period before')}
                                            description={plainTranslate(this.props.settings.locale,"This setting allow users to create, edit and delete documents only at selected period before today.")}
                                            component={'select'}
                                            value ={this.props.settings.generalSettings}
                                            type={'generalSettings'}
                                            saveRoute={'/' + this.props.match.params.db + '/api/general/save/settings/individual'}
                                            setting={'openPeriodBefore'}
                                            hideEmptyValue={true}
                                         />
                                        <hr/>
                                        <OpenSection
                                            label={plainTranslate(this.props.settings.locale,'Open period after')}
                                            description={plainTranslate(this.props.settings.locale,"This setting allow users to create, edit and delete documents only at selected period after today.")}
                                            component={'select'}
                                            value ={this.props.settings.generalSettings}
                                            type={'generalSettings'}
                                            saveRoute={'/' + this.props.match.params.db + '/api/general/save/settings/individual'}
                                            setting={'openPeriodAfter'}
                                            hideEmptyValue={true}
                                         />
                                        <hr/>
                                        <OpenSection
                                            label={plainTranslate(this.props.settings.locale, 'Date format')}
                                            description={plainTranslate(this.props.settings.locale, "Specify custom date format. Default date format is day.month.year")}
                                            component={'select'}
                                            value ={this.props.settings.generalSettings}
                                            type={'generalSettings'}
                                            saveRoute={'/' + this.props.match.params.db + '/api/general/save/settings/individual'}
                                            setting={'dateFormat'}
                                         />

                                   </div>
                                </div>
                             </section>
                          </div>
                       </div>
                    </form>
                </div>
                );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({
   getSettings: (path, type) =>
            dispatch(fetchSettings(path, type)),
});
export default connect(mapStateToProps, mapDispatchToProps)(GeneralSettingsPage);
