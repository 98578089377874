import React, {Component} from 'react';
import {render} from "react-dom";
import Translate, {plainTranslate} from '../../common/translate';
import Message from '../../common/message';
import { connect } from 'react-redux';
import SettingsMenu from '../../sidebar/settings-menu';
import SingleCheckbox from './../singleCheckbox.jsx';
import { client} from "../../../actions/actions";
import OpenSection from './../openSection.jsx';
import Stages from './../stages.jsx';
import { fetchSettings} from "../../../actions/actions";

class StorageSettingsPage extends Component {

    constructor(props) {
    super(props);
        this.state = {
            showMessage: false,
            };
    }

    componentDidMount() {
        this.props.getSettings('/' + this.props.match.params.db + '/api/storage/settings/storage-settings', 'storageSettings');
        if (this.props.title) {
            let titleArr = this.props.title;
            titleArr.map((item, key) => titleArr[key] = plainTranslate(this.props.settings.locale, item));
            document.title = titleArr.join(' ');
        }
    }

     render() {
        return (
                <div>
                    <SettingsMenu
                       location = {this.props.location.pathname} db = {this.props.match.params.db}/>
                    <form name="settings_expense" className="cmxform form-horizontal bucket-form ng-pristine ng-valid">
                       <div className="row m-bot15">
                          <div className="col-lg-12">
                             <section className="panel">
                                <div className="panel-body">
                                   <div className="settings">
                                        <OpenSection
                                            label={plainTranslate(this.props.settings.locale, 'File api url')}
                                            description={plainTranslate(this.props.settings.locale, "Set file api upload url")}
                                            component={'input'}
                                            value ={this.props.settings.storageSettings}
                                            type={'storageSettings'}
                                            saveRoute={'/' + this.props.match.params.db + '/api/storage/save/settings/individual'}
                                            setting={'storageUrl'}
                                         />
                                         <hr/>
                                        <OpenSection
                                            label={plainTranslate(this.props.settings.locale, 'File api token')}
                                            description={plainTranslate(this.props.settings.locale, "Set token")}
                                            component={'input'}
                                            value ={this.props.settings.storageSettings}
                                            type={'storageSettings'}
                                            saveRoute={'/' + this.props.match.params.db + '/api/storage/save/settings/individual'}
                                            setting={'storageToken'}
                                         />
                                   </div>
                                </div>
                             </section>
                          </div>
                       </div>
                    </form>
                </div>
                );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({
   getSettings: (path, type) =>
            dispatch(fetchSettings(path, type)),
});
export default connect(mapStateToProps, mapDispatchToProps)(StorageSettingsPage);
