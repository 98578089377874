import React, { useState, useEffect } from 'react';
import ReactDOM from "react-dom";
import connect from "react-redux/es/connect/connect";
import {plainTranslate} from "../common/translate";
import {Link} from 'react-router-dom';
import MenuMobile from '../sidebar/menu-mobile';
import SidebarV2 from './SidebarV2';
import { withRouter } from 'react-router-dom'

const MenuV2 = (props, state) => {
  let isPublic = props.location.pathname.indexOf('/public/invoice');
  let isPublicSurvey = props.location.pathname.indexOf('/public/survey');
  if (isPublic > 0 || isPublicSurvey > 0) {
    return <></>;
  }
  if(window.innerWidth < 768){
    return <MenuMobile db={props.db}/>
  
  }else if(window.innerWidth >= 768){
    return <SidebarV2 db={props.db}/>
  }
}


const mapStateToProps = state => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MenuV2));