import React from 'react';
import {connect} from 'react-redux';
import {plainTranslate} from "../common/translate";
import {searchItem} from "../../actions/actions";
import 'antd/lib/tree/style/index.css';
import { Tree, Switch } from 'antd';
import { StarOutlined, HomeOutlined, DatabaseOutlined, TagOutlined } from '@ant-design/icons'
import client from '../../actions/client'
import moment from 'moment'

const { TreeNode, DirectoryTree } = Tree;

class ItemsTreeView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            treeData: [],
            locId: null,
            loadedKeys: null,
            itemId: null
          };
          this.onLoadData = this.onLoadData.bind(this);
          this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    }

    componentDidMount() {
        client.post('/'+this.props.match.params.db+'/api/items/tree-view/data', {
            locations : true,
            stockAvailability: this.state.stockAvailability
        })
        .then(response => response.data)
        .then(response => {
            var result = []
            if(response){
                response.map((row, key) => { 
                    row.parent = 0
                    result.push(row)
                })
            }
            this.setState({treeData: result});

        })
        client.get('/'+this.props.match.params.db+'/api/taxes/settings/get-default-vat')
        .then(response => response.data)
        .then(response => {
            this.setState({taxRate: response});

        })

        if (this.props.title) {
            let titleArr = this.props.title;
            titleArr.map((item, key) => titleArr[key] = plainTranslate(this.props.settings.locale, item));
            document.title = titleArr.join(' ');
        }
    }

    onLoadData(treeNode){
        this.setState({
          loadedKeys: [treeNode]
        });    
        let locId = this.state.locId;
        if(treeNode.dataRef.type === "location"){
            locId = treeNode.dataRef.id;
            this.setState({locId: treeNode.dataRef.id})
        }
        if(treeNode.props.dataRef.type === "item"){
            this.setState({itemId: treeNode.dataRef.id})
        }
        return client.post('/'+this.props.match.params.db+'/api/items/tree-view/data', {
            'type': treeNode.dataRef.type,
            'id': treeNode.dataRef.id,
            'locId': locId,
            'stockAvailability': this.state.stockAvailability,
            'checkPrice': false
        })
            .then(response => response.data)
            .then(response => {
                var result = []
            if(response){
                response.map((row, key) => { 
                    row.parent = treeNode.pos
                    result.push(row)
                })
            }

                treeNode.props.dataRef.childrenData = result;
                this.setState({
                  treeData: [...this.state.treeData],
                });
        });
    };

    renderTreeNodes(data){
        let taxRate = this.state.taxRate;

        return data.map((item, key) => {
            let icon = <StarOutlined/>;
            let title = item.name;
            let price = item.price ? item.price : 0;
            let qty = item.quantity ? item.quantity : 0;
            if(item.type === 'item'){
                icon = <StarOutlined/>
                title += ' : '+qty;
                title += '; '+plainTranslate(this.props.settings.locale, 'price')+': '+price;
                if(taxRate){
                    let vatPrice = parseFloat(price)+(parseFloat(price)*(taxRate/100));
                    title += ' / '+parseFloat(vatPrice).toFixed(2);
                }
            }else if(item.type === 'location'){
                icon = <HomeOutlined/>
            }
            else if(item.type === 'category'){
                icon = <DatabaseOutlined/>
            }
            else if(item.type === 'batch'){
                icon = <TagOutlined/>
                if(item.name === null){
                    title = 'Without batch';
                }
                title += ' : '+qty;
                title += '; '+plainTranslate(this.props.settings.locale, 'price')+': '+price;
                if(taxRate){
                    let vatPrice = parseFloat(price)+(parseFloat(price)*(taxRate/100));
                    title += ' / '+parseFloat(vatPrice).toFixed(2);
                }
            }
           var itemKey =  item.parent+'-'+key ;

            if(item.childrenData){
                    return (
                      <TreeNode defaultExpandAll icon={icon} title={title} key={itemKey} dataRef={item}>
                        {this.renderTreeNodes(item.childrenData)}
                      </TreeNode>
                    );
                }

          return <TreeNode defaultExpandAll icon={icon} title={title} key={itemKey} dataRef={item} {...item}/>;
        })
    };

     handleCheckboxChange(e){
            this.setState({
              loadedKeys: [],
              stockAvailability: e.target.checked,
            });
        client.post('/'+this.props.match.params.db+'/api/items/tree-view/data', {
            locations : true,
            stockAvailability: true
        })
        .then(response => response.data)
        .then(response => {
            this.setState({treeData: response});

        })
    }

    render() {
        return (
                <div>
                    <div style={{backgroundColor: "white", minHeight: 800, padding: 50}}>
                        <div className="searchBar" style={{display: 'flex', alignItems: 'baseline'}}>
                            <input type="checkbox" id="stockAvailability" name="stockAvailability" onChange={this.handleCheckboxChange} value="stockAvailability" checked={this.state.stockAvailability || false} className="stockAvailability"/>
                            <label style={{marginLeft: 5}} for="stockAvailability">{plainTranslate(this.props.settings.locale, 'Show Available Items')}</label>
                        </div>
                        <DirectoryTree 
                        defaultExpandAll
                        loadedKeys={[this.state.loadedKeys]} 
                        loadData={this.onLoadData} 
                        showIcon>{this.renderTreeNodes(this.state.treeData)}</DirectoryTree>
                    </div>
                </div>
            )
    }

}

const mapStateToProps = (state, ownProps) => ({
    settings: state.settings,
    item: state.importData
});

const mapDispatchToProps = dispatch => ({
    searchItem: (db, items, locId) => dispatch(searchItem(db, items, locId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemsTreeView);
