import React, {Component} from 'react';
import {render} from "react-dom";
import Translate, {plainTranslate} from '../../common/translate';
import Message from '../../common/message';
import { connect } from 'react-redux';
import SettingsMenu from '../../sidebar/settings-menu';
import { client} from "../../../actions/actions";
import OpenSection from './../openSection.jsx';
import SingleCheckbox from './../singleCheckbox.jsx';
import Stages from './../stages.jsx';
import { fetchSettings} from "../../../actions/actions";
import { Skeleton } from 'antd';

class InventorySettingsPage extends Component {

    constructor(props) {
    super(props);
            this.state = {
            showMessage: false,
            ready: false
            };
        this.allSettings = [
            { value: "inventoryMeasures", name: 'Inventory measures', text: 'Select whether you need measures on each item line when creating new receipts for the Inventory'},
        ];
        this.updateStages = this.updateStages.bind(this);
    }

    componentDidMount() {
        this.props.getSettings('/' + this.props.match.params.db + '/api/inventory/settings/inventory-settings', 'inventorySettings')
        .then(res => {            
            this.setState({ready: true})
        })
        
        if (this.props.title) {
            let titleArr = this.props.title;
            titleArr.map((item, key) => titleArr[key] = plainTranslate(this.props.settings.locale, item));
            document.title = titleArr.join(' ');
        }
    }
    
    updateStages() {
        this.props.getSettings('/' + this.props.match.params.db + '/api/inventory/settings/inventory-settings', 'inventorySettings');
    }

     render() {

        return (
                <div>
                    <SettingsMenu
                       location = {this.props.location.pathname} db = {this.props.match.params.db}/>
                    <form name="settings_expense" className="cmxform form-horizontal bucket-form ng-pristine ng-valid">
                       <div className="row m-bot15">
                          <div className="col-lg-12">
                             <section className="panel">
                                <div className="panel-body">
                                   <div className="settings">
                                        <OpenSection
                                            label={plainTranslate(this.props.settings.locale, 'Inventory form type')}
                                            description={plainTranslate(this.props.settings.locale, "Specify different types of documents for inventory")}
                                            link={"/" + this.props.match.params.db + "/form-types"}
                                         />
                                         <hr/>
                                         <OpenSection
                                            label={plainTranslate(this.props.settings.locale, 'Purchase Order Sequential No')}
                                            description={plainTranslate(this.props.settings.locale, "Set the format of the sequential number of purchasing receipts")}
                                            component={'input'}
                                            value ={this.props.settings.inventorySettings}
                                            type={'inventorySettings'}
                                            saveRoute={'/' + this.props.match.params.db + '/api/inventory/save/settings/individual'}
                                            setting={'purchaseOrderSeqNo'}
                                         />
                                         <hr/>
                                         <OpenSection
                                            label={plainTranslate(this.props.settings.locale, 'Release Order Sequential No')}
                                            description={plainTranslate(this.props.settings.locale, "Set the format of the sequential number of release receipts")}
                                            component={'input'}
                                            value ={this.props.settings.inventorySettings}
                                            type={'inventorySettings'}
                                            saveRoute={'/' + this.props.match.params.db + '/api/inventory/save/settings/individual'}
                                            setting={'releaseOrderSeqNo'}
                                         />
                                         <hr/>
                                         <OpenSection
                                            label={plainTranslate(this.props.settings.locale, 'Transfer Order Sequential No')}
                                            description={plainTranslate(this.props.settings.locale, "Set the format of the sequential number of transfer receipts")}
                                            component={'input'}
                                            value ={this.props.settings.inventorySettings}
                                            type={'inventorySettings'}
                                            saveRoute={'/' + this.props.match.params.db + '/api/inventory/save/settings/individual'}
                                            setting={'transferInOrderSeqNo'}
                                         />
                                         <hr/>
                                         <OpenSection
                                            label={plainTranslate(this.props.settings.locale, 'Receipt Template')}
                                            description={plainTranslate(this.props.settings.locale, "Set the default template for printing inventory receipts")}
                                            component={'select'}
                                            value ={this.props.settings.inventorySettings}
                                            type={'inventorySettings'}
                                            saveRoute={'/' + this.props.match.params.db + '/api/inventory/save/settings/individual'}
                                            setting={'inventoryTemplate'}
                                         />
                                         <hr/>
                                         <OpenSection
                                            label={plainTranslate(this.props.settings.locale, 'Receipt Sorting')}
                                            description={plainTranslate(this.props.settings.locale, "Set the way a document's lines to be sorted.")}
                                            component={'select'}
                                            value ={this.props.settings.inventorySettings}
                                            type={'inventorySettings'}
                                            saveRoute={'/' + this.props.match.params.db + '/api/inventory/save/settings/individual'}
                                            setting={'inventorySorting'}
                                         />
                                         <hr/>
                                        <div className="col-sm-12 column-centered">
                                          {this.state.ready ? <Stages
                                                saveRoute={'/' + this.props.match.params.db + '/api/stages/save/settings/data'}
                                                type={'inventorySettings'}
                                                workflow={"inventory"}
                                                configuration = {false}
                                                value={this.props.settings.inventorySettings}
                                                settings={this.props.settings}
                                                fields={this.allSettings}
                                                setting = {'stages'}
                                                updateStages={this.updateStages}
                                                roles={'roles'}
                                            />
                                            :
                                            <Skeleton active />}
                                        </div>
                                        <div className="col-sm-12 column-centered">
                                        {this.state.ready ?  <Stages
                                                saveRoute={'/' + this.props.match.params.db + '/api/stages/save/settings/data'}
                                                title={'Item requests stages'}
                                                type={'inventorySettings'}
                                                workflow={"item_request"}
                                                configuration = {false}
                                                value={this.props.settings.inventorySettings}
                                                settings={this.props.settings}
                                                setting = {'stagesItemRequests'}
                                                updateStages={this.updateStages}
                                                roles={'rolesItemRequests'}
                                                colorSetting={true}
                                            />
                                            :
                                            <Skeleton active />}
                                        </div>
                                   </div>
                                </div>
                             </section>
                          </div>
                       </div>
                    </form>
                </div>
                );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({
   getSettings: (path, type) =>
            dispatch(fetchSettings(path, type)),
});
export default connect(mapStateToProps, mapDispatchToProps)(InventorySettingsPage);
