import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {plainTranslate} from '../common/translate';
import SaveCancel from '../common/saveCancelButtons';
import {
    FORM_SAVE_VALIDATE,
    FORM_MOUNT,
    FORM_DATA_LOAD,
    FORM_EMPTY_VALID,
    FORM_SAVE_DISABLE,
    FORM_SAVE_ENABLE,
    FORM_SHOW_LOADER
} from "../../constants/actionTypes";
import {
    onLoadForm,
    onCustomerChangeLoadData,
    initialValidation,
    onSave,
    onFieldChange
} from "../../actions/actions";

import Field from './field';
import FormLoader from './form-loader';
import showAToast from '../common/showAToast';
require('./forms.css');

class Form extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id: (new Date()).getTime(),
            type: '...',
            error: false,

            formProperties: {},
            formData: {},
            formIsValid: {}
        };
        this.updateForm = this.updateForm.bind(this);
        this.save = this.save.bind(this);
        this.onBreadcrumbClick = this.onBreadcrumbClick.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onCustChange = this.onCustChange.bind(this);
        this.onChangeMainOptions = this.onChangeMainOptions.bind(this);
    }

    componentDidMount() { 
        this.updateForm();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.match && prevProps.match.params.id !== this.props.match.params.id) {
            this.updateForm();
        }
        else if (JSON.stringify(prevProps.formProperties) !== JSON.stringify(this.props.formProperties)
            || JSON.stringify(prevProps.formData) !== JSON.stringify(this.props.formData)
            || JSON.stringify(prevProps.formIsValid) !== JSON.stringify(this.props.formIsValid)) {
            let formProperties = this.state.id in this.props.formProperties ? this.props.formProperties[this.state.id] : [];
            if (!formProperties.form) {
                formProperties.form = {
                    fields: [],
                    schema: []
                };
            }
            const formData = this.state.id in this.props.formData ? this.props.formData[this.state.id] : {};
            const formIsValid = this.state.id in this.props.formIsValid ? this.props.formIsValid[this.state.id] : [];

            this.setState({
                formProperties,
                formData,
                formIsValid
            });
        }

        if(this.state !== prevState && this.state.formProperties.isEdit == true && this.state.formData.custId && this.state.type !== prevState.type){
            this.onCustChange(this.state.formData.custId);
        }
    }

    componentWillUnmount() {
        this.props.onEmptyData(this.state.id);
        this.props.onEmptyValid(this.state.id);
    }

    updateForm(){

        this.props.onLoader(this.state.id, true);
        this.props.onEmptyValid(this.state.id);
        this.setState({type: '...'});

        if (this.props.title) {
            let titleArr = this.props.title;
            titleArr.map((item, key) => titleArr[key] = plainTranslate(this.props.settings.locale, item));
            document.title = titleArr.join(' ');
        }

        let formRoute = '';
        let saveRoute = '';

        if(this.props.match.params.db){
            saveRoute = '/' + this.props.match.params.db + this.props.saveRoute;
            formRoute = '/' + this.props.match.params.db + this.props.formRoute;
        }else{
            saveRoute = this.props.saveRoute;
            formRoute = this.props.formRoute;
        }

        if (this.props.match && this.props.match.params) {
            let params = [];
            for (var prop in this.props.match.params) {
                if (this.props.isAdditionalId)
                    params.push(prop + '=' + this.props.match.params[prop]);
            }
            if (params.length > 0) {
                saveRoute += '?' + params.join('&');
            }
        }

        if (!this.props.isPopup) {
            if (this.props.match) {
                const id = this.props.match.params.id;
                formRoute = formRoute + id;
            } else {
                formRoute = formRoute;
            }
        }

        if (this.props.isNew) {
            formRoute = formRoute + '/form';
            if (saveRoute[saveRoute.length - 1] === '/') {
                saveRoute = saveRoute + this.props.match.params.id;
            }
        }
        if (this.props.location) {
            const urlParams = new URLSearchParams(this.props.location.search)
            const key = urlParams.get('refs')
            if (key !== null) {
                formRoute = formRoute  + '/' + key;
            }
        }
        if (this.props.account) {
            formRoute = formRoute + '/' + this.props.account;
        }
        if (this.props.refs && this.props.type) {
            formRoute = formRoute + '?refs=' + this.props.refs + '&type=' + this.props.type;
            saveRoute = saveRoute + '?refs=' + this.props.refs;
        } else if (this.props.refs) {
            formRoute = formRoute + '?refs=' + this.props.refs;
            saveRoute = saveRoute + '?refs=' + this.props.refs;
            if (this.props.cartSaleId) {
                formRoute = formRoute + '&cartSale=' + this.props.cartSaleId;
                saveRoute = saveRoute + '&cartSale=' + this.props.cartSaleId;
            }
        } else if (this.props.type) {
            formRoute = formRoute + '?type=' + this.props.type;
        } else if (this.props.userId) {
            formRoute = formRoute + '?refs=' + this.props.userId;
            saveRoute = saveRoute;
        } else if (this.props.cartSaleId) {
            formRoute = formRoute + '?cartSale=' + this.props.cartSaleId;
            saveRoute = saveRoute + '&cartSale=' + this.props.cartSaleId;
        }

        this.props.onMount({formRoute, saveRoute, formId: this.state.id});

        let formData = this.state.id in this.props.formData ? this.props.formData[this.state.id] : {};
        let formProperties = this.state.id in this.props.formProperties ? this.props.formProperties[this.state.id] : [];
        if (!formProperties.form) {
            formProperties.form = {
                fields: [],
                schema: []
            };
        }
        const formIsValid = this.state.id in this.props.formIsValid ? this.props.formIsValid[this.state.id] : [];

        this.props.onLoadForm(this.state.id, formRoute)
            .then((data) => {
                formProperties = this.state.id in this.props.formProperties ? this.props.formProperties[this.state.id] : [];
                this.props.initialValidation(this.state.id, formProperties.form.fields, formProperties.schema, data)
                this.setState({
                    type: formData['id'] ? 'Edit' : 'New'
                });
                this.props.onLoader(this.state.id, false);
            })
            .then(() => this.props.validateSave(this.state.id, formIsValid, formProperties.form.fields));
    }

    onCustChange(custId){
        this.props.onEmptyValid(this.state.id);

        let formRoute = '/'+this.props.match.params.db + this.props.formRoute + this.props.match.params.id+ '/form';

        formRoute = formRoute;

        //this.props.onMount({formRoute, saveRoute, formId: this.state.id});

        let formData = this.state.id in this.props.formData ? this.props.formData[this.state.id] : {};

        let formProperties = this.state.id in this.props.formProperties ? this.props.formProperties[this.state.id] : [];
        if (!formProperties.form) {
            formProperties.form = {
                fields: [],
                schema: []
            };
        }

        const formIsValid = this.state.id in this.props.formIsValid ? this.props.formIsValid[this.state.id] : [];

        this.props.onCustomerChangeLoadData(this.state.id, formRoute, custId)
            .then((data) => {
                formProperties = this.state.id in this.props.formProperties ? this.props.formProperties[this.state.id] : [];
                this.props.initialValidation(this.state.id, formProperties.form.fields, formProperties.schema, data)
            })
            .then(() => this.props.validateSave(this.state.id, formIsValid, formProperties.form.fields));
    }

    save(saveNew = false) {
        const formProperties = this.state.id in this.props.formProperties ? this.props.formProperties[this.state.id] : [];
        const formData = this.state.id in this.props.formData ? this.props.formData[this.state.id] : {};

        if (!formProperties.enableSave) return;

        this.props.onDisableSave(this.state.id);
        this.props.save(this.state.id, formProperties.saveRoute, formData)
            .then((response) => {
                this.props.onEnableSave(this.state.id);
                if(response && ('errors' in response)) {
                    showAToast(plainTranslate(this.props.settings.locale, response.errors), 'errSettings', 'error');
                }else if(response && ('message' in response)) {
                    showAToast(plainTranslate(this.props.settings.locale, response.message), 'errSettings', 'error');
                }else {

                    if(response && (this.props.updateParent)) {
                        this.props.updateParent('entity' in response ? response['entity'] : response);
                    }

                    showAToast(plainTranslate(this.props.settings.locale, 'Data saved successfully!'), 'succSettings', 'success');

                    if (response && this.props.onSuccessfulSave) {
                        this.props.onSuccessfulSave(response);
                    }

                    if (response && !this.props.popup) {
                        if (!formProperties.isEdit) {
                            if (!saveNew) {
                                this.props.history.push(response.redirectUrl);
                            }
                            else {
                                this.updateForm();
                            }
                        }
                    }
                }
            });
    }

    onBreadcrumbClick(element, target) {
        this.props.onUpdateField(this.state.id, target, element.value);
    }

    onCancel() {
        if(this.props.onCancel) {
            this.props.onCancel();
        }
    }
    
    onChangeMainOptions(fieldId, options) {
        let arr = (this.props.formProperties[this.state.id].form.fields);
        let field = arr.find(o => o.id === fieldId);
        if (field) {
            field.options = options;
        }
    }

    render() {
        const formData = this.state.id in this.props.formData ? this.props.formData[this.state.id] : {};
        const formIsValid = this.state.id in this.props.formIsValid ? this.props.formIsValid[this.state.id] : [];
        let formProperties = this.state.id in this.props.formProperties ? this.props.formProperties[this.state.id] : [];
        if (!formProperties.form) {
            formProperties.form = {
                fields: [],
                schema: []
            };
        }

        let title = formProperties.form && formProperties.form.title ? formProperties.form.title.join(' ') : '';
        title = plainTranslate(this.props.settings.locale, title);

        let returnUrl = formProperties.form ? formProperties.form.returnUrl : null;
        returnUrl = returnUrl ? returnUrl : '#';

        if (this.props.isPopup) {
            return <div>
                <div id="reactForm">
                    {
                        this.props.formProperties.form.fields.map((field, key) => {
                            return <Field field={field} key={key} fieldKey={key} isPopup={this.props.isPopup}/>
                        })
                    }

                    <div className="col-md-12 col-xs-12">
                        <div className="pull-left">
                            <SaveCancel
                                // locale={this.props.locale}
                                enableSave={this.props.formProperties.enableSave}
                                save={(saveNew) => this.save(saveNew)}
                                showSaveNew={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        }

        return <div>
        {!this.props.popup &&
            <div className="dashboard">
                <div className="moduleSubMenu backMenu">
                    <div className="col-sm-12">
                        <h3 className="pull-left">
                            <Link to={returnUrl}>
                                {!this.props.popup && <i className="ion-ios-arrow-back"></i>}</Link> {!formProperties.showLoader && title}
                        </h3>

                        {
                            'form' in formProperties && 'tabs' in formProperties.form && formProperties.form.tabs &&
                            <ul className="nav navbar-nav pull-right">
                                {
                                    formProperties.form.tabs.map((tab, key) => {
                                        return <li className="nav-item" key={key}>
                                            <Link className={tab.active ? 'active' : ''} to={tab.link}>
                                                {plainTranslate(this.props.settings.locale, tab.label)}
                                            </Link>
                                        </li>
                                    })
                                }
                            </ul>
                        }
                    </div>
                </div>
            </div>
            }
            <div id="reactForm">
                <div className="row m-bot15 cmxform form-horizontal bucket-form ng-pristine ng-valid">
                    <div className="col-lg-12">
                        <section className={formProperties.form && formProperties.form.noBackground ? '' : 'panel'}>
                            <header className="panel-heading"
                                    style={{display: formProperties.form && formProperties.form.noBackground ? 'none' : 'block'}}>{plainTranslate(this.props.settings.locale, this.state.type)}</header>

                            {
                                !formProperties.showLoader &&
                                <div className="panel-body"
                                     style={'panelBody' in formProperties.form ? {width: formProperties.form.panelBody} : {}}>

                                    {
                                        formProperties.form.breadcrumb &&
                                        <div className="status-container col-md-12 col-xs-12">
                                            <div className="status-breadcrumb">
                                                {
                                                    formProperties.form.breadcrumb.fields.map((element, key) => {
                                                        return <a
                                                            className={formProperties.form.breadcrumb.target in formData && element.value === formData[formProperties.form.breadcrumb.target] ? 'active' : ''}
                                                            href="#" key={key}
                                                            onClick={() => this.onBreadcrumbClick(element, formProperties.form.breadcrumb.target)}>
                                                <span className="breadcrumb__inner">
                                                    <span className="breadcrumb__desc">{element.label}</span>
                                                </span>
                                                        </a>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    }

                                    {
                                        formProperties.form.fields.map((field, key) => {
                                            return <Field
                                                formId={this.state.id}
                                                formData={formData}
                                                custId={this.state.formData.custId}
                                                onCustChange={this.onCustChange}
                                                onChangeMainOptions={(field, options) => this.onChangeMainOptions(field, options)}
                                                formProperties={formProperties}
                                                formIsValid={formIsValid}
                                                formRoute={'/'+ this.props.match.params.db + this.props.formRoute}
                                                field={field}
                                                key={key}
                                                fieldKey={key}/>
                                        })
                                    }

                                    <div className="col-md-12 col-xs-12">
                                        <div className="pull-left">
                                            <SaveCancel
                                                // locale={this.props.locale}
                                                enableSave={formProperties.enableSave}
                                                save={(saveNew) => this.save(saveNew)}
                                                showCancel={this.props.popup}
                                                showSaveNew={!formProperties.isEdit && !this.props.popup}
                                                cancel={this.onCancel}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                formProperties.showLoader &&
                                <FormLoader/>
                            }
                        </section>
                    </div>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = state => ({
    formProperties: state.formProperties,
    formData: state.formData,
    formIsValid: state.formIsValid,
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({
    onCustomerChangeLoadData: (formId, route, value) => dispatch(onCustomerChangeLoadData(formId, route, value)),
    onMount: (payload) => dispatch({type: FORM_MOUNT, payload}),
    onLoadForm: (formId, route) => dispatch(onLoadForm(formId, route)),
    onEmptyData: (formId) => dispatch({type: FORM_DATA_LOAD, payload: {data: {}, formId}}),
    onEmptyValid: (formId) => dispatch({type: FORM_EMPTY_VALID, payload: {formId}}),
    initialValidation: (formId, form, schema, data) => dispatch(initialValidation(formId, form, schema, data)),
    validateSave: (formId, isValid, form) => dispatch({type: FORM_SAVE_VALIDATE, payload: {isValid, form, formId}}),
    onUpdateField: (formId, key, value) => dispatch(onFieldChange(formId, key, value)),
    save: (formId, route, data) => dispatch(onSave(formId, route, data)),
    onDisableSave: (formId) => dispatch({type: FORM_SAVE_DISABLE, payload: {formId}}),
    onEnableSave: (formId) => dispatch({type: FORM_SAVE_ENABLE, payload: {formId}}),
    onLoader: (formId, value) => dispatch({
        type: FORM_SHOW_LOADER,
        payload: {formId, value}
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
