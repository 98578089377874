import React from 'react';
import {connect} from 'react-redux';
import {plainTranslate} from "../common/translate";
import {onUploadFile} from "../../actions/actions";
import './import.css';
import { Table, Radio, Statistic, Card, Row, Col } from 'antd';
import FormLoader from "../forms/form-loader";
import client from '../../actions/client';
import showAToast from '../../components/common/showAToast';
import {Prompt} from "react-router-dom";

class Import extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showUploadModal: false,
            loading: false,
            showDuplicatesTable: false,
            showImportData: false,
            importType: 'importData',
            showLoader: false
        };

        this.handleImport = this.handleImport.bind(this);
        this.showUploadModal = this.showUploadModal.bind(this);
        this.chooseFile = this.chooseFile.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onFormTypeChange = this.onFormTypeChange.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleUnload = this.handleUnload.bind(this);
        this.handleFinish = this.handleFinish.bind(this);
    }

    componentDidMount(){
        window.addEventListener('beforeunload', this.handleUnload); 
    }

    componentWillUnmount() {
        this.handleCancel();
        window.removeEventListener('beforeunload', this.handleUnload);
    }

    handleUnload(e) {
        if(this.state.showImportData){
            var message = "\o/";
            (e || window.event).returnValue = message;
            client.get(this.props.match.params.db + '/api/cancel/import/data');
            return message;
        }
    }

    showUploadModal() {
        this.setState({showUploadModal: !this.state.showUploadModal});
    }

    chooseFile() {
        document.getElementById('fileUpload').click();
    }

    uploadFile(e) {
        const files = e.target.files[0];

        this.setState({loading: true});
        this.props.onUploadFile(this.props.match.params.db, this.props.match.params.type, files).then((response) => {
            var file = response;
            this.setState({loading:false, showUploadModal: false, value:file, showDuplicatesTable: false});
        });
    }

    handleImport(testImport){
        this.setState({showLoader: true, showImportStats: false, showDuplicatesTable: false});
        client.post(this.props.match.params.db + '/api/import/data/items', {
                file: this.state.value.id,
                importType: this.state.importType,
                formType: this.state.formType,
                testImport: testImport,
            })
        .then(response => response.data)
        .then(response => {
            let showImportDataTable = true;

            if ('duplicateItems' in response) {
                showImportDataTable = false;
                showAToast(response.duplicateItems, 'baseError', 'error')
                this.setState({showDuplicatesTable: true, duplicatesData: response.data});
            }
            if ('error' in response) {
                showImportDataTable = false;
                showAToast(response.error, 'baseError', 'error')
            }
            if ('success' in response) {
                showAToast(response.success, 'baseSuccess', 'success')
                this.setState({showImportStats: true, value: null});
            }
            if ('uninsertedRows' in response) {
                this.setState({uninsertedRows: response.uninsertedRows});
            }

            this.setState({ 
                showLoader: false, 
                showImportData: showImportDataTable,
                importData: testImport ? response.importData : this.state.uninsertedRows, 
                insertedRowsCount: response.insertedRowsCount ? response.insertedRowsCount : 0, 
                totalRecords: response.totalRecords ? response.totalRecords : 0, 
                updatedRowsCount: response.updatedRowsCount ? response.updatedRowsCount : 0
            });
        });
    }

    handleCancel(){
        client.get(this.props.match.params.db + '/api/cancel/import/data');

        this.setState({
          showImportData: false,value: null
        });
    }

    onChange(e){
        this.setState({
          importType: e.target.value,
        });
    }

    onFormTypeChange(e){
        this.setState({
          formType: e.target.value,
        });
    }

    handleFinish(){
        this.setState({
          showImportData: false, value: null, showImportStats: null
        });
    }

    render() {
        const radioStyle = {
          display: 'block',
          height: '30px',
          lineHeight: '30px',
        };

        const cols = [
            {
                'key': 'itemNo',
                'dataIndex' : 'itemNo',
                'title' : plainTranslate(this.props.settings.locale, 'Item No')
            },
            {
                'key': 'lineNo',
                'dataIndex' : 'lineNo',
                'title' : plainTranslate(this.props.settings.locale, 'Lines')
            }
        ];

        const importDataCols = [
            {
                'key': 'rowStatus',
                'dataIndex' : 'rowStatus',
                'width': 100,
                'title' : plainTranslate(this.props.settings.locale, ''),
                render: (text, record) => (
                    <span className="form-input-field">
                        {record['rowStatus'] == true && 
                            <i className="fa fa-check" style={{color:'green', marginLeft: 15}}></i> 
                        }
                        {record['rowStatus'] == false && 
                            <span><i className="fa fa-times" style={{color:'red', marginLeft: 15}}></i><span className="help" title={plainTranslate(this.props.settings.locale, 'This data will not be imported or updated. Required fields are missing')} ><i className="fa fa-info-circle"></i></span></span>
                        } 
                    </span>
                )
            },
            {
                'key': 'cc',
                'dataIndex' : 'cc',
                'width': 100,
                'title' : plainTranslate(this.props.settings.locale, 'CC'),
                render: (text, record) => (
                    record['rowStatus'] == true ? <span>{record.cc}</span> : <span style={{color: 'red'}}>{record.cc}</span>
                )
            },
            {
                'key': 'code',
                'dataIndex' : 'code',
                'width': 100,
                'title' : plainTranslate(this.props.settings.locale, 'Code'),
                render: (text, record) => (
                    record['rowStatus'] == true ? <span>{record.code}</span> : <span style={{color: 'red'}}>{record.code}</span>
                )
            },
            {
                'key': 'title',
                'dataIndex' : 'title',
                'width': 200,
                'title' : plainTranslate(this.props.settings.locale, 'Name'),
                render: (text, record) => (
                    record['rowStatus'] == true ? <span>{record.title}</span> : <span style={{color: 'red'}}>{record.title}</span>
                )
            },
            {
                'key': 'description',
                'dataIndex' : 'description',
                'width': 200,
                'title' : plainTranslate(this.props.settings.locale, 'Short Description'),
                render: (text, record) => (
                    record['rowStatus'] == true ? <span>{record.description}</span> : <span style={{color: 'red'}}>{record.description}</span>
                )
            },
            {
                'key': 'category',
                'dataIndex' : 'category',
                'width': 200,
                'title' : plainTranslate(this.props.settings.locale, 'Item Category'),
                render: (text, record) => (
                    record['rowStatus'] == true ? <span>{record.category}</span> : <span style={{color: 'red'}}>{record.category}</span>
                )
            },
            {
                'key': 'parentCategory',
                'dataIndex' : 'parentCategory',
                'width': 200,
                'title' : plainTranslate(this.props.settings.locale, 'Category Parent'),
                render: (text, record) => (
                    record['rowStatus'] == true ? <span>{record.parentCategory}</span> : <span style={{color: 'red'}}>{record.parentCategory}</span>
                )
            },
            {
                'key': 'price',
                'dataIndex' : 'price',
                'width': 100,
                'title' : plainTranslate(this.props.settings.locale, 'Item Price'),
                render: (text, record) => (
                    record['rowStatus'] == true ? <span>{record.price}</span> : <span style={{color: 'red'}}>{record.price}</span>
                )
            },
            {
                'key': 'purchasePrice',
                'dataIndex' : 'purchasePrice',
                'width': 150,
                'title' : plainTranslate(this.props.settings.locale, 'Item Purchase price'),
                render: (text, record) => (
                    record['rowStatus'] == true ? <span>{record.purchasePrice}</span> : <span style={{color: 'red'}}>{record.purchasePrice}</span>
                )
            },
            {
                'key': 'currency',
                'dataIndex' : 'currency',
                'width': 150,
                'title' : plainTranslate(this.props.settings.locale, 'Item Currency'),
                render: (text, record) => (
                    record['rowStatus'] == true ? <span>{record.currency}</span> : <span style={{color: 'red'}}>{record.currency}</span>
                )
            },
            {
                'key': 'barcode',
                'dataIndex' : 'barcode',
                'width': 200,
                'title' : plainTranslate(this.props.settings.locale, 'Item Barcode'),
                render: (text, record) => (
                    record['rowStatus'] == true ? <span>{record.barcode}</span> : <span style={{color: 'red'}}>{record.barcode}</span>
                )
            },
            {
                'key': 'supplier',
                'dataIndex' : 'supplier',
                'width': 200,
                'title' : plainTranslate(this.props.settings.locale, 'Item Supplier'),
                render: (text, record) => (
                    record['rowStatus'] == true ? <span>{record.supplier}</span> : <span style={{color: 'red'}}>{record.supplier}</span>
                )
            },
            {
                'key': 'itemSupplierCode',
                'dataIndex' : 'itemSupplierCode',
                'width': 200,
                'title' : plainTranslate(this.props.settings.locale, 'Item Supplier No'),
                render: (text, record) => (
                    record['rowStatus'] == true ? <span>{record.itemSupplierCode}</span> : <span style={{color: 'red'}}>{record.itemSupplierCode}</span>
                )
            },
            {
                'key': 'countStock',
                'dataIndex' : 'countStock',
                'width': 100,
                'title' : plainTranslate(this.props.settings.locale, 'Item Type'),
                render: (text, record) => (
                    record['rowStatus'] == true ? <span>{record.countStock}</span> : <span style={{color: 'red'}}>{record.countStock}</span>
                )
            },
            {
                'key': 'form',
                'dataIndex' : 'form',
                'width': 100,
                'title' : plainTranslate(this.props.settings.locale, 'Form'),
                render: (text, record) => (
                    record['rowStatus'] == true ? <span>{record.form}</span> : <span style={{color: 'red'}}>{record.form}</span>
                )
            },
            {
                'key': 'attribute1',
                'dataIndex' : 'attribute1',
                'width': 200,
                'title' : plainTranslate(this.props.settings.locale, 'Attribute 1'),
                render: (text, record) => (
                    record['rowStatus'] == true ? <span>{record.attribute1}</span> : <span style={{color: 'red'}}>{record.attribute1}</span>
                )
            },
            {
                'key': 'attribute2',
                'dataIndex' : 'attribute2',
                'width': 200,
                'title' : plainTranslate(this.props.settings.locale, 'Attribute 2'),
                render: (text, record) => (
                    record['rowStatus'] == true ? <span>{record.attribute2}</span> : <span style={{color: 'red'}}>{record.attribute2}</span>
                )
            },
            {
                'key': 'attribute3',
                'dataIndex' : 'attribute3',
                'width': 200,
                'title' : plainTranslate(this.props.settings.locale, 'Attribute 3'),
                render: (text, record) => (
                    record['rowStatus'] == true ? <span>{record.attribute3}</span> : <span style={{color: 'red'}}>{record.attribute3}</span>
                )
            },
            {
                'key': 'attribute4',
                'dataIndex' : 'attribute4',
                'width': 200,
                'title' : plainTranslate(this.props.settings.locale, 'Attribute 4'),
                render: (text, record) => (
                    record['rowStatus'] == true ? <span>{record.attribute4}</span> : <span style={{color: 'red'}}>{record.attribute4}</span>
                )
            },
            {
                'key': 'attribute5',
                'dataIndex' : 'attribute5',
                'width': 200,
                'title' : plainTranslate(this.props.settings.locale, 'Attribute 5'),
                render: (text, record) => (
                    record['rowStatus'] == true ? <span>{record.attribute5}</span> : <span style={{color: 'red'}}>{record.attribute5}</span>
                )
            }
        ];

        let itemForms = this.props.settings.formLinks ? this.props.settings.formLinks.items : [];

        let defaultValue = itemForms[0] ? itemForms[0].label : null;

        return (
            <div>
                <Prompt
                    when={this.state.showImportData}
                    message={location =>
                      `Changes you made may not be saved.`
                    }
                />
                <div class={this.state.showImportData ? 'import-data disabled' : 'import-data'}>
                    <div className="col-md-12">
                        <div className="col-md-12" style={{marginBottom: 10}}>
                            <button type="button" className="upload-btn btn btn-success"
                                    onClick={() => this.showUploadModal()}>
                                <i className="fa fa-upload"></i>
                                {plainTranslate(this.props.settings.locale, 'Upload')}
                            </button>
                            {this.state.value &&
                                <span>{this.state.value.name}</span>
                            }
                        </div>
                        <div className="col-md-12" style={{float: 'right', display: 'grid'}}>
                            <span style={{marginBottom: 15, fontWeight: 'bold'}}>{plainTranslate(this.props.settings.locale, 'Import type')}</span>
                            <Radio.Group onChange={this.onChange} value={this.state.importType}>
                                <Radio value={'importData'}>
                                  {plainTranslate(this.props.settings.locale, 'Import new data')}
                                </Radio>
                                <Radio value={'importAndUpdate'}>
                                  {plainTranslate(this.props.settings.locale, 'Import and update')}
                                </Radio>
                            </Radio.Group>
                            <span style={{marginBottom: 15, marginTop: 15, fontWeight: 'bold'}}>{plainTranslate(this.props.settings.locale, 'Form type')}</span>
                            <Radio.Group onChange={this.onFormTypeChange} value={this.state.formType ? this.state.formType : defaultValue}>
                                {itemForms.map(form =>{
                                   return <Radio value={form.label}>
                                      {plainTranslate(this.props.settings.locale, form.label)}
                                    </Radio>
                                })}
                            </Radio.Group>
                        </div>
                    </div>
                
                    {
                        this.state.showUploadModal &&
                        <div className={`backendropss`}>
                            <div className={'modalConfirms scroll input-board ' + (this.state.dragging ? 'beige' : '')}
                                 ref={this.setWrapperRef}>
                                <div className="text-right">
                                    <div className="" onClick={this.showUploadModal}>
                                        <i className="fa fa-close" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <section className="newPanel">
                                    <div className="panel-body">
                                        <div className="row m-bot15">
                                            <div className="text-center">
                                                {this.state.loading && 'Loading...'} 
                                            </div>
                                            <button type="button" className={'upload-btn btn btn' + (this.state.loading ? ' disabled' : '-success') + ' upload-button'}
                                                    onClick={this.chooseFile}>
                                                <i className="fa fa-upload"></i>
                                                Choose
                                            </button>
                                            <input id={'fileUpload'} name="files[]" type="file" style={{visibility: 'hidden'}}
                                                   onChange={this.uploadFile} multiple/>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    }
                    <button className={"button-primary" + (this.state.value ? '' : ' disabled')} disabled={this.state.value ? '' : 'disabled'} type="button" onClick={() => this.handleImport(true)}>
                        {plainTranslate(this.props.settings.locale, 'Save')}
                    </button>
                </div>
                {this.state.showDuplicatesTable &&
                    <div className="col-md-4" style={{backgroundColor: 'white', marginLeft: 100}}>
                        <h4>Your import has duplicate rows. Please update your file.</h4>
                        <Table columns={cols} dataSource={this.state.duplicatesData} pagination={false} />
                    </div>
                }

                {!this.state.showLoader && this.state.showImportData && this.state.showImportStats &&
                    <div style={{margin: 'auto', width: '90%', outline: 'none'}}>
                        <Row gutter={8} style={{marginBottom: 20}}>
                            <Col span={4}>
                                <Card>
                                  <Statistic
                                    title={plainTranslate(this.props.settings.locale, "Total records")}
                                    value={this.state.totalRecords}
                                    precision={0}
                                    valueStyle={{ color: '#3f8600' }}
                                  />
                                </Card>
                            </Col>
                            <Col span={4}>
                                <Card>
                                  <Statistic
                                    title={plainTranslate(this.props.settings.locale, "Inserted records")}
                                    value={this.state.insertedRowsCount}
                                    precision={0}
                                    valueStyle={{ color: '#3f8600' }}
                                  />
                                </Card>
                            </Col>
                            <Col span={4}>
                                <Card>
                                  <Statistic
                                    title={plainTranslate(this.props.settings.locale, "Updated records")}
                                    value={this.state.updatedRowsCount}
                                    precision={0}
                                    valueStyle={{ color: '#3f8600' }}
                                  />
                                </Card>
                            </Col>
                            <Col span={4}>
                                <Card>
                                  <Statistic
                                    title={plainTranslate(this.props.settings.locale, "Uninserted records")}
                                    value={this.state.uninsertedRows.length}
                                    precision={0}
                                    valueStyle={{ color: '#cf1322' }}
                                  />
                                </Card>
                            </Col>
                        </Row>
                    </div>
                }

                {!this.state.showLoader && this.state.showImportData &&
                    <div style={{backgroundColor: 'white', margin: 'auto', width: '90%', outline: 'none'}}>
                        <Table columns={importDataCols} dataSource={this.state.importData} pagination={{pageSize: 50, showSizeChanger:false}} scroll={{ x: 600, y: 500 }} />
                        {!this.state.showImportStats &&
                           <span> <button className="button-primary" type="button" onClick={() => this.handleImport(false)}>
                                {plainTranslate(this.props.settings.locale, 'Import')}
                            </button>
                            <button className="button-primary" type="button" onClick={this.handleCancel}>
                                {plainTranslate(this.props.settings.locale, 'Cancel')}
                            </button></span>
                        }
                        {this.state.showImportStats &&
                            <button className="button-primary" type="button" onClick={this.handleFinish}>
                                {plainTranslate(this.props.settings.locale, 'OK')}
                            </button>
                        }
                    </div>
                }
                {
                    this.state.showLoader &&
                    <FormLoader/>
                }
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({
    onUploadFile: (db, id, file) => dispatch(onUploadFile(db, id, file))
});

export default connect(mapStateToProps, mapDispatchToProps)(Import);
