import React from 'react';
import {connect} from 'react-redux';
import {plainTranslate} from "../common/translate";
import {searchItem} from "../../actions/actions";
import SaveCancel from '../common/saveCancelButtons';
import { withRouter } from 'react-router-dom'
import client from '../../actions/client'

class ImportLines extends React.Component { 

    constructor(props) {
        super(props);

        this.state = {
            lines:[],
            errors: [],
            enableSave:true,
            finishImport: false
        };

        this.save = this.save.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleImport = this.handleImport.bind(this);
    }

    componentDidMount() {
       
    }

    save(){
        var data = this.state.value;
        var rows = data.split("\n");

        var table = '<table /><tr id="headers">';
        var items = [];
        var error = null;
        var index = 0;
        var tableHeaders = rows[0].split("\t");

        for(var i = 0; i < tableHeaders.length; i++){
            if(this.props.formData.hasOwnProperty('priority')){
                table+='<th>'+'<select style="min-width: 10px!important; width: 100%" class="form-control" id="headerOption'+i+'"><option value=""></option><option value="itemCode">'+plainTranslate(this.props.settings.locale, "Item")+'</option><option value="quantity">'+plainTranslate(this.props.settings.locale, "Minimum Quantity")+'</option><option value="barcode">'+plainTranslate(this.props.settings.locale, "Barcode")+'</option><option value="price">'+plainTranslate(this.props.settings.locale, "Price")+'</option></select>'+'</th>'; 
            }else if(this.props.options){
                table+='<th><select style="min-width: 10px!important; width: 100%" class="form-control" id="headerOption'+i+'"><option value=""></option>';

                if(this.props.options.includes("Item")){
                    table+= '<option value="itemCode">'+plainTranslate(this.props.settings.locale, "Item")+'</option>';
                }
                if(this.props.options.includes("Quantity")){
                    table+= '<option value="quantity">'+plainTranslate(this.props.settings.locale, "Quantity")+'</option>';
                }
                if(this.props.options.includes("Description")){
                    table+= '<option value="description">'+plainTranslate(this.props.settings.locale, "Description")+'</option>';
                }
                if(this.props.options.includes("Measure")){
                    table+= '<option value="measure">'+plainTranslate(this.props.settings.locale, "Measure")+'</option>';
                }
                if(this.props.options.includes("Measure2")){
                    table+= '<option value="measure2">'+plainTranslate(this.props.settings.locale, "Measure2")+'</option>';
                }
                if(this.props.options.includes("Serial")){
                    table+= '<option value="serial">'+plainTranslate(this.props.settings.locale, "Serial")+'</option>';
                }
                if(this.props.options.includes("Barcode")){
                    table+= '<option value="barcode">'+plainTranslate(this.props.settings.locale, "Barcode")+'</option>';
                }
                if(this.props.options.includes("Price")){
                    table+= '<option value="price">'+plainTranslate(this.props.settings.locale, "Price")+'</option>';
                }
                if(this.props.options.includes("Value Unit")){
                    table+= '<option value="valueUnit">'+plainTranslate(this.props.settings.locale, "Value Unit")+'</option>';
                }
                table += '</select></th>';
            }
            else{
                table+='<th>'+'<select style="min-width: 10px!important; width: 100%" class="form-control" id="headerOption'+i+'"><option value=""></option><option value="itemCode">'+plainTranslate(this.props.settings.locale, "Item")+'</option><option value="quantity">'+plainTranslate(this.props.settings.locale, "Quantity")+'</option><option value="description">'+plainTranslate(this.props.settings.locale, "Description")+'</option><option value="measure">'+plainTranslate(this.props.settings.locale, "Measure")+'</option><option value="measure2">'+plainTranslate(this.props.settings.locale, "Measure2")+'</option><option value="serial">'+plainTranslate(this.props.settings.locale, "Serial")+'<option value="barcode">'+plainTranslate(this.props.settings.locale, "Barcode")+'</option><option value="price">'+plainTranslate(this.props.settings.locale, "Price")+'</option></select>'+'</th>';
            }
            
        }

        table+='</tr>';
        for(var value in rows) {
            var cells = rows[value].split("\t");
            var row = '<tr id="row'+index+'" />';

            //var emptyRow = true;
            if(cells.length == tableHeaders.length){
                for(var x in cells) {
                    row+='<td style="width: 50px!important; height: 40px; text-align: center; padding:5px">'+cells[x]+'</td>';
                }
            }
/*                var code = cells[0];
                items.push({code});*/

            table+=row;
            index++;
        }
        let count = this.state.items ? this.state.items.length : 0;
        for (var i = 0; i < count; i++) {
            this.props.remove(i);
        } 
/*        this.props.searchItem('/' + this.props.match.params.db + '/api/items/item/items-search', items, this.props.formData.locId).then(() => {
            this.setState({items: this.props.item.item.data},() =>{
                this.handleImport();
            } 
            );
          
        });*/

        let previewTable = document.getElementById("preview-table");
        previewTable.innerHTML=table;
        this.setState({ showTable: true, enableSave: error ? false : true});
        

    }

    onChange(e) {
        this.setState({ value: e.target.value, enableSave: false});
    }

    handleImport(){
        this.setState({ enableSave: false});
        var data = this.state.value;
        var rows = data.split("\n");
        let index = 0;
        let rowIndex = 0;
        let itemsArray = [];
        var items = [];
        var selectedHeaders = [];
        let i = 0;  

        var tableHeaders = rows[0].split("\t");
        for(var y in tableHeaders){
            var id = 'headerOption'+y;
            var a = document.getElementById(id);
            selectedHeaders.push(a.value);  
        }
        let hasQty = false;
        for(var value in rows) {
            if(rows[value].length > 0){
                var cells = rows[value].split("\t");
                var code = cells[selectedHeaders.indexOf('itemCode')];
                var qty = cells[selectedHeaders.indexOf('quantity')];
                var description = cells[selectedHeaders.indexOf('description')];
                var measure = cells[selectedHeaders.indexOf('measure')];
                var measure2 = cells[selectedHeaders.indexOf('measure2')];
                var serial = cells[selectedHeaders.indexOf('serial')];
                var barcode = cells[selectedHeaders.indexOf('barcode')];
                var price = cells[selectedHeaders.indexOf('price')];
                var valueUnit = cells[selectedHeaders.indexOf('valueUnit')];
                var docType = this.props.formData.documentType;
                items.push({code, qty, description, measure, measure2, serial, barcode, price, docType, valueUnit});

                if(qty){
                    hasQty = true;
                }
            }

        }

        this.props.searchItem('/' + this.props.match.params.db + '/api/items/item/items-search', items, this.props.formData.documentType == 3 ? this.props.formData.locIdFrom : this.props.formData.locId,this.props.formData.documentDate, this.props.formData.custIdAdditionalValue ? this.props.formData.custIdAdditionalValue.id : null).then(() => {

            this.setState({items: this.props.item.item.data}, () =>{
                let newLines;
                let action = this.props;

                this.state.items.map(item => {

                    this.props.fields.map(field =>{
                        if(field.id === 'lines'){
                            field.fields.map(value =>{
                                if(value.id === "itId" && value.type === "select2"){
                                    let newOption={value:item.value,label:item.itName}
                                    value.options.push(newOption);
                                }
                            })
                        }
                    })
/*                    if (this.props.quantityUrl && selectedHeaders.indexOf('price') === -1) {
                        client.post(this.props.quantityUrl, {
                            'qty': item.quantity ? item.quantity : 1,
                            'itId': item.value,
                            'locId' : this.props.formData.documentType == 3 ? this.props.formData.locIdFrom : this.props.formData.locId,
                            'item': item,
                            'stockAvailability': this.state.stockAvailability,
                            'documentDate' : this.props.formData.documentDate ? this.props.formData.documentDate : null,
                            'custId': this.props.formData.custIdAdditionalValue ? this.props.formData.custIdAdditionalValue.id : null,
                            'accId': this.props.formData.accId ? this.props.formData.accId : null,
                            'channel': this.props.formData.channel ? this.props.formData.channel : null
                        })
                        .then(response => response.data)
                        .then(response => {
                            newLines = Array(response.length).fill(item);
                            response.forEach(function (arrayItem, i) {
                                var itemIn = JSON.parse(JSON.stringify(item));   
                                itemIn.measId2 = arrayItem.measId2;
                                itemIn.quantity2 = arrayItem.qty2;
                                itemIn.quantity = arrayItem.qty;
                                itemIn.minQuantity = arrayItem.qty;
                                if(item.batchId){
                                    itemIn.batch = item.batchId;
                                }else{
                                    itemIn.batch = '';
                                }
                                itemIn.batchName = item.batchLabel;
                                if (arrayItem.hasOwnProperty("price")) {
                                    itemIn.unitPriceOriginal = arrayItem.price;
                                }
                                itemIn.discountRate = arrayItem.discountRate;
                                if(arrayItem.hasOwnProperty('currId')){
                                    itemIn.currId = arrayItem.currId;
                                }
                                action.onAddItemRow(itemIn);
                            });
                        });
                    } else {*/
                      
                        item.quantity = item.quantity ? item.quantity : 1;
                        item.minQty = hasQty ? item.quantity : 0;
                        if(item.batchId){
                            item.batch = item.batchId;
                            item.batchId = item.batchId;
                            item.batchLabel = item.batchLabel;
                        }
                        else{
                             item.batch = '';
                            item.batchId = '';
                            item.batchLabel = '';
                        }
                        action.onAddItemRow(item, false);
                    //}
                })
            });

            let line = 0;

            items.map((item,key) =>{
                let lastChar = null;
                if(item.code){
                    lastChar = item.code.substr(item.code.length - 1);
                    if(/^\s*$/.test(lastChar)){
                        item.code = item.code.slice(0, -1);
                    }
                }else if(item.barcode){
                    lastChar = item.barcode.substr(item.barcode.length - 1);
                    if(/^\s*$/.test(lastChar)){
                        item.barcode = item.barcode.slice(0, -1);
                    }
                }
                
                let itemExists = this.state.items.filter(obj=>obj.label===item.code || item.barcode);
                if(itemExists.length == 0){
                    var node = document.createElement("td");
                    node.id = 'line'+line
                    node.style.cssText = 'color:red; text-align:center';
                    node.style.width = '50px';
                    var textnode = document.createTextNode("Not imported");  
                    node.appendChild(textnode);

                    let resultColumn = document.getElementById('line'+line);
                    if(!resultColumn){
                        var id = "row"+line;
                        document.getElementById(id).appendChild(node);
                    }else{
                        resultColumn.innerText="Not imported";
                        resultColumn.style.color="red";
                        resultColumn.style.width="50px!important";
                    }
                }else{
                    var node = document.createElement("td");
                    node.id = 'line'+line
                    node.style.cssText = 'color:green; text-align:center';
                    node.style.width = '50px';
                    var textnode = document.createTextNode("Imported");  
                    node.appendChild(textnode);
                    let resultColumn = document.getElementById('line'+line);
                    if(!resultColumn){
                        var id = "row"+line;
                        document.getElementById(id).appendChild(node);
                    }else{
                        resultColumn.innerText="Imported";
                        resultColumn.style.width="50px!important";
                        resultColumn.style.color="green";
                    }
                }
                line++;

                if(key = items.length){
                    this.setState({finishImport: true, enableSave: true});
                }
            })
        })
        
    }

    render() {
        
        
        return <div>
                    <textarea className="form-control" onChange={this.onChange} placeholder="Paste your data here">
                    </textarea>
                    <SaveCancel
                        // locale={this.props.locale}
                        enableSave={true}
                        save={this.save}
                        showCancel={true}
                        cancel={this.props.close}
                    />
                    <div className="import-preview">
                        <div id="preview-table">
                            
                        </div>
                        {this.state.showTable &&
                            <div>
                                <button disabled = {this.state.enableSave ? '' : 'disabled'} type="button" className={"button-primary " + (this.state.enableSave ? '' : 'disabled')} id="import" onClick={this.handleImport}>Import</button>
                                <button style={{float: 'right'}} disabled = {this.state.finishImport ? '' : 'disabled'} type="button" className={"button-primary " + (this.state.finishImport ? '' : 'disabled')} onClick={this.props.close}>OK</button>
                            </div>
                        }
                    </div>
                </div>
    }
}

const mapStateToProps = (state, ownProps) => ({
    settings: state.settings,
    item: state.importData
});

const mapDispatchToProps = dispatch => ({
   searchItem: (db, items, locId, docDate, cust) => dispatch(searchItem(db, items, locId, docDate, cust)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ImportLines));
