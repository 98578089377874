import React from 'react';
import {connect} from 'react-redux';
import {plainTranslate} from "../../common/translate";

class TranslationsComponent extends React.Component {

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.addNewTrans = this.addNewTrans.bind(this);
        this.removeTrans = this.removeTrans.bind(this);
    }

    onChange(e, type, key) {
        this.props.change('translations['+key+']['+type+']', e.target.value);
    }
    
    addNewTrans() {
        let translations = this.props.formData.translations;
        let newTrans = {lang: 'en', trans: '', id: ''};
        translations.push(newTrans);
        this.setState({
            translations
        });
    }
    
    removeTrans(key) {
        let translations = this.props.formData.translations;
        delete translations[key];
        this.setState({
            translations
        });
    }

    render() {
        return <React.Fragment>
            {this.props.formData.translations && this.props.formData.translations.length > 0 && this.props.formData.translations.map((translation, key) =>
                        <React.Fragment key={key}>
                            <select className="form-control form-contacts" key={key + 'type'} value={translation.lang} onChange={(e) => this.onChange(e, 'lang', key)}>
                            {this.props.field.languages.length > 0 && this.props.field.languages.map((lang, key) =>
                                <option key={key} value={lang.value}>{plainTranslate(this.props.locale, lang.label)}</option>
                            )}    
                            </select>
                            <input className="form-control" style={{color: 'black'}} key={key + 'value'} value={translation.trans} onChange={(e) => this.onChange(e, 'trans', key)}/>
                            <i className="fa fa-trash contact-icon" style={{marginRight: '-20px'}} aria-hidden="true" onClick = {() => this.removeTrans(key)} title={plainTranslate(this.props.locale, 'Remove translation')}></i>
                            <br/>
                        </React.Fragment>
                        )}
                <a className="button-outline grey " href='#' onClick = {this.addNewTrans}>
                    {plainTranslate(this.props.locale, 'Add new translation')}
                </a>
        </React.Fragment>
    }
}

const mapStateToProps = state => ({
    });

const mapDispatchToProps = dispatch => ({
    });

export default connect(mapStateToProps, mapDispatchToProps)(TranslationsComponent);
