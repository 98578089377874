import React, { Component } from 'react';
import Translate, { plainTranslate } from '../../common/translate';
import { connect } from 'react-redux';

class Attributes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            attributes: [],
            showButton: true
        };
        this.addNewRow = this.addNewRow.bind(this);
        this.removeRow = this.removeRow.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        let values = this.props.input.value ? JSON.parse(this.props.input.value) : [];
        this.setState({
            values
        });
    }

    addNewRow() {
        let values = this.props.input.value ? JSON.parse(this.props.input.value) : [];
        let newItem = { name: '', 'value': '' };
        values.push(newItem);
        this.setState({
            values
        });
    }

    removeRow(key) {
        let values = this.state.values;
        values.splice(key, 1);

        this.setState({
            values
        });
    }

    onChange(e, type, key) {
        let values = this.state.values;
        values[key][type] = e.target.value;

        this.props.input.onChange(values);
    }

    render() {
        return (
            <div>
                <div className='col-md-8 col-xs-12 no-gutter-m no-gutter'>
                    <span className="form-input-field tableCollection">
                        <table>
                            <thead style={{ border: "none" }}>
                                <tr>
                                    <td className="text-left">
                                        {plainTranslate(this.props.settings.locale, this.props.field.fields[0])}
                                    </td>
                                    <td className="text-left">
                                        {plainTranslate(this.props.settings.locale, this.props.field.fields[1])}
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.values && this.state.values.length > 0 && this.state.values.map((attribute, key) =>
                                    <tr key={key}>
                                        <td>
                                            <select className="form-control form-stage" value={attribute.name} onChange={(e) => this.onChange(e, 'name', key)}>
                                                <option value=''></option>
                                                {this.props.field && this.props.field.options && this.props.field.options.map(option => (
                                                    <option value={option.value}>{plainTranslate(this.props.settings.locale, option.label)}</option>
                                                ))
                                                }
                                            </select>
                                        </td>
                                        <td>
                                            <input className="form-control form-stage" defaultValue={attribute.value} onChange={(e) => this.onChange(e, 'value', key)} />
                                        </td>
                                        <td className={'right'}>
                                            <span onClick={() => this.removeRow(key)}>
                                                <i className='ion-android-close' title={plainTranslate(this.props.settings.locale, 'Remove row')}></i></span>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                            <tfoot>
                                {this.state.showButton &&
                                    <tr className="text-center">
                                        <th colSpan="3">
                                            <a className="button-outline grey text-uppercase" onClick={this.addNewRow}>
                                                {plainTranslate(this.props.settings.locale, 'New row')}
                                            </a>
                                        </th>
                                    </tr>
                                }
                            </tfoot>
                        </table>
                    </span>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Attributes);
