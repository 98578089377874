import React, {Component} from 'react';
import {render} from "react-dom";
import Translate, {plainTranslate} from '../../common/translate';
import Message from '../../common/message';
import { connect } from 'react-redux';
import SettingsMenu from '../../sidebar/settings-menu';
import OpenSection from './../openSection.jsx';
import SingleCheckbox from './../singleCheckbox.jsx';
import Stages from './../stages.jsx';
import client from '../../../actions/client';
import { fetchSettings} from "../../../actions/actions";

class AccountSettingsPage extends Component {

    constructor(props) {
    super(props);
            this.state = {
            showMessage: false,
            };
        this.allSettings = [
            { value: "inventoryMeasures", name: 'Inventory measures', text: 'Select whether you need measures on each item line when creating new receipts for the Inventory'},
        ];
        this.updateStages = this.updateStages.bind(this);
    }

    componentDidMount() {
        this.props.getSettings('/' + this.props.match.params.db + '/api/account/settings/account-settings', 'accountSettings');
        if (this.props.title) {
            let titleArr = this.props.title;
            titleArr.map((item, key) => titleArr[key] = plainTranslate(this.props.settings.locale, item));
            document.title = titleArr.join(' ');
        }
    }
    
    updateStages() {
        this.props.getSettings('/' + this.props.match.params.db + '/api/account/settings/account-settings', 'accountSettings');
    }

     render() {
        return (
                <div>
                    <SettingsMenu
                       location = {this.props.location.pathname}
                       db = {this.props.match.params.db} />
                    <form name="settings_expense" className="cmxform form-horizontal bucket-form ng-pristine ng-valid">
                       <div className="row m-bot15">
                          <div className="col-lg-12">
                             <section className="panel">
                                <div className="panel-body">
                                   <div className="settings">
                                        <OpenSection
                                            label={plainTranslate(this.props.settings.locale, 'Lists')}
                                            description={plainTranslate(this.props.settings.locale, "Create, edit and delete account lists from here. You can select different list types and choose from the available fields")}
                                            link={"/" + this.props.match.params.db + "/settings/accounts/lists"}
                                         />
                                        <hr/>
                                        <SingleCheckbox
                                            value={this.props.settings.accountSettings}
                                            label={plainTranslate(this.props.settings.locale, 'Consent')}
                                            description={plainTranslate(this.props.settings.locale, 'This option enables you to record when, how and what type of consent you have received.')}
                                            settings={this.props.settings}
                                            type={'accountSettings'}
                                            saveRoute={'/' + this.props.match.params.db + '/api/account/save/settings/individual'}
                                            setting = {'dataConsent'}
                                        />
                                        <hr/>
                                        <OpenSection
                                            label={plainTranslate(this.props.settings.locale,'Data Retention')}
                                            description={plainTranslate(this.props.settings.locale,"You have the ability to set the time-limit for storing data by Composity software. The system will notify you for any account that is for deletion and add it in table Data retention exspired in module Accounts.")}
                                            component={'selectWithCheckbox'}
                                            value ={this.props.settings.accountSettings}
                                            type={'accountSettings'}
                                            saveRoute={'/' + this.props.match.params.db + '/api/account/save/settings/individual'}
                                            setting={'dataRetention'}
                                         />
                                         <hr/>
                                          <OpenSection
                                            label={plainTranslate(this.props.settings.locale,'Account Profile Statistics')}
                                            description={plainTranslate(this.props.settings.locale,"You can add up to three statistic values to account profile")}
                                            component={'accountProfileStatistics'}
                                            value ={this.props.settings.accountSettings}
                                            type={'accountSettings'}
                                            saveRoute={'/' + this.props.match.params.db + '/api/account/save/settings/individual'}
                                            setting={'accountStatistics'}
                                         />
                                        <hr/>
                                         <OpenSection
                                            label={plainTranslate(this.props.settings.locale,'Account Name Pattern')}
                                            description={plainTranslate(this.props.settings.locale,"You can choose which fields to participate in the formation of the account name")}
                                            component={'accountNamePattern'}
                                            value ={this.props.settings.accountSettings}
                                            type={'accountSettings'}
                                            saveRoute={'/' + this.props.match.params.db + '/api/account/save/settings/individual'}
                                            setting={'setupAccountName'}
                                         />
                                   </div>
                                </div>
                             </section>
                          </div>
                       </div>
                    </form>
                </div>
                );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({
   getSettings: (path, type) =>
            dispatch(fetchSettings(path, type)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AccountSettingsPage);
