import React from 'react';
import { connect } from 'react-redux';

class CalculationFiled extends React.Component {

    constructor(props) {
        super(props);

        this.calculateOperation = this.calculateOperation.bind(this);
        this.addZeroes = this.addZeroes.bind(this);
    }

    calculateOperation(operation, item) {
        if (operation.type === 'sumList') {
            let sum = 0;
            if (operation.list in this.props.formData) {
                this.props.formData[operation.list].map(item => {
                    if ('operation' in operation) {
                        sum += this.calculateOperation(operation.operation, item);
                    }
                });
            }

            if ('decimalPoint' in operation) {
                let multiplier = "e+" + operation.decimalPoint;
                let multiplier2 = "e-" + operation.decimalPoint;

                return this.addZeroes(parseFloat(Math.sign(sum) * (Math.round(Math.abs(sum) + multiplier) + multiplier2)), operation.decimalPoint);
            }
            return sum;
        }
        if (operation.type === 'multiply') {
            let field1 = operation.field1;
            let field2 = operation.field2;

            if (field1.type === 'additionalValue') field1 = field1.populateFrom in item && field1.name in item[field1.populateFrom] ? item[field1.populateFrom][field1.name] ? item[field1.populateFrom][field1.name] : 0 : 0;
            else if (field1.type === 'formValue') field1 = field1.name in item ? item[field1.name] : 0;
            if (field2.type === 'additionalValue') field2 = field2.populateFrom in item && field2.name in item[field2.populateFrom] ? item[field2.populateFrom][field2.name] ? item[field2.populateFrom][field2.name] : 0 : 0;
            else if (field2.type === 'formValue') field2 = field2.name in item ? item[field2.name] : 0;

            if ('pre' in operation.field1) {
                operation.field1.pre.forEach(op => {
                    if (op.type === 'add')
                        field1 += op.value;
                    if (op.type === 'subtract')
                        field1 -= op.value;
                    if (op.type === 'multiply')
                        field1 *= op.value;
                    if (op.type === 'divide')
                        field1 /= op.value;
                });
            }

            if ('pre' in operation.field2) {
                operation.field2.pre.forEach(op => {
                    if (op.type === 'add')
                        field2 += op.value;
                    if (op.type === 'subtract')
                        field2 -= op.value;
                    if (op.type === 'multiply')
                        field2 *= op.value;
                    if (op.type === 'divide')
                        field2 /= op.value;
                });
            }

            if ('decimalPoint' in operation) {
                let multiplier = "e+" + operation.decimalPoint;
                let multiplier2 = "e-" + operation.decimalPoint;

                return this.addZeroes(parseFloat(Math.sign(field1 * field2) * (Math.round(Math.abs(field1 * field2) + multiplier) + multiplier2)), operation.decimalPoint);
            }
 
            return field1 * field2;
        }
    }

    addZeroes(num, decimal) {
        // Convert input string to a number and store as a variable.
        //var value = parseFloat(num);      
        // Split the input string into two arrays containing integers/decimals
        var res = num.toString().split(".");
        // If there is no decimal point or only one decimal place found.
        if (res.length == 1 || res[1].length < 3) {
            // Set the number to two decimal places
            num = num.toFixed(decimal);
        }
        // Return updated or original number.
        return num;
    }

    render() {
        return <div>
            <span className="form-input-field" style={{ position: 'relative' }}>
                <input
                    className={"form-control "}
                    type="text"
                    style={{ minWidth: this.props.field.width ? this.props.field.width : 'unset', textAlign: "right" }}
                    disabled={this.props.field.disabled}
                    placeholder={this.props.field.placeholder}
                    value={'operation' in this.props.field ? this.calculateOperation(this.props.field.operation, this.props.formData) : '0.00'} />
            </span>
        </div>
    }
}

const mapStateToProps = state => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(CalculationFiled);
