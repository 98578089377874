import React, {Component} from 'react';
import {render} from "react-dom";
import Translate, {plainTranslate} from './translate';
import Message from './message';
import {connect} from 'react-redux';
import {onAccountFetchData, onFieldSave} from "../../actions/actions";
import Select from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import client from '../../actions/client'
import showAToast from './showAToast';

class SelectField extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: [],
        };

       
        this.changeValue = this.changeValue.bind(this);
    }

    componentDidMount() {
        this.setState({
            value: this.props.value,
            options: this.props.options
        });
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.value) !== JSON.stringify(this.props.value) || JSON.stringify(prevProps.options) !== JSON.stringify(this.props.options)) {
            this.setState({
                value: this.props.value
            });
        }
    }
    changeValue(value) {
   
        this.props.change(this.props.id, value);
        this.setState({value});
    }

    loadOptions = (inputValue, callback) => {       
        client
        .get(this.props.url+'?q='+inputValue)
        .then(response => {
            this.setState({
                options: response.data
            })
            callback(response.data)         
        })
        .catch(err => {
            showAToast('Error!', 'baseError', 'error')
        })
    }

    render() {
       
         return (
                   <div className="inlineHover input-select2">
                          {this.props.url ?
                                <AsyncSelect
                                cacheOptions
                                isMulti={this.props.isMulti}
                                value={this.state.value}
                                onChange={this.changeValue}
                                defaultOptions={this.state.options}
                                isDisabled={this.props.isDisabled}
                                isClearable={true}
                                loadOptions={this.loadOptions}
                            />
                            :
                            <Select
                            isMulti={this.props.isMulti}
                            value={this.state.value}
                            onChange={this.changeValue}
                            options={this.props.options}
                            isDisabled={this.props.isDisabled}
                            isClearable={true}
                        />
                           }
                </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});


export default connect(mapStateToProps, {})(SelectField);
