import React, {Component} from 'react';
import {connect} from 'react-redux';
import Translate, {plainTranslate} from '../../../common/translate';
import Popup from "../../../common/popup";
import SimpleForm from "../../../forms/form";
import SlideModal from '../../../common/SlideModal';
import MessageContent from './message';
import { withRouter } from 'react-router-dom';
import client from '../../../../actions/client';
import showAToast from '../../../common/showAToast';
import GeneralInfoForm from './generalInfoForm'
import { onAccountFetchData, onFieldSave, onUploadImage } from '../../../../actions/actions';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import Form from "../../../redux-forms/form";
import moment from 'moment';
import { Button, Tooltip, Card, Col, Row, Menu, Dropdown, Upload, Drawer } from 'antd';
import { SnippetsOutlined, StopOutlined } from '@ant-design/icons';
import EditablePrint from '../../../custom-pages/editablePrint';
import { Link } from 'react-router-dom';

class GeneralInfo extends Component {

    constructor(props) {
        super(props);

        this.state = {
            groups: [],
            editCompanyName: false,
            isNotification: false,
            isNew: false,
            deletePopup: false,
            emailPopup: false,
            value: '',
            openEdit: false,
            loading: false,
            templateId: null
        };

        this.updateRoute = '/' + this.props.match.params.db + '/api/accounts/update-field';
        this.openEditField = this.openEditField.bind(this);
        this.onArchiveAccount = this.onArchiveAccount.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.handleDeleteAccount = this.handleDeleteAccount.bind(this);
        this.showNewPopup = this.showNewPopup.bind(this);
        this.onDoNotDisturb = this.onDoNotDisturb.bind(this);
    }

    componentDidMount() {
       this.setState({
            groups: this.props.account.groupsOptions ? this.props.account.groupsOptions : [],
            popup: false,
            newPopup: false
        });
    }

    componentDidUpdate(prevProps){
        if (prevProps.value !== this.props.value) {
            this.setState({
                groups: this.props.account.groupsOptions ? this.props.account.groupsOptions : []
            });
        }

        //this.props.account.groupsOptions = this.state.groups;
    }

    openEditField(editField) {
       var trueinlinetext;
       var edit;

        this.setState({
            [editField]: trueinlinetext - edit
        });
    }

    onArchiveAccount(accountId, status) {
        this.props.onFieldSave(this.updateRoute, 'status', status, {'accountId':accountId})
        .then(() => {
            if (status==1) {
                    showAToast("Success! You have restored the Account!", 'baseSuccess', 'success');
            } else {
                    showAToast("Success! You have archived the Account!", 'baseSuccess', 'success');
                }
            });
    }

    onDoNotDisturb(accountId, value) {
        this.props.onFieldSave(this.updateRoute, 'doNotDisturb', value, {'accountId':accountId})
        .then(() => {
                showAToast("Success! You have changed the Account!", 'baseSuccess', 'success');
            });
    }

    changeValue(data) {
        let groups = this.state.groups;
        let find = groups.find((group, key) => {
                if (group.id === data.id) {
                    groups[key] = data;
                    return true;
                }
        });

        if (!find) {
            let newLine = [];
            newLine = { value: data.id ,label: data.name};
            groups.push(newLine);
          }
        this.setState({
            newPopup: false,
            groups: groups,
        });
    }

    showNewPopup() {
        return (
                <SlideModal onClose={() => this.setState({newPopup: false})} title={plainTranslate(this.props.settings.locale, 'Group')}>
                    <section className="newPanel">
                        <div className="panel-body">
                            <div className="row m-bot15">
                                <SimpleForm
                                    formRoute={this.props.match.params.db + '/api/groups/'}
                                    saveRoute={this.props.match.params.db + '/api/groups'}
                                    updateParent={(data) => this.changeValue(data)}
                                    popup={true}
                                    isNew= {true}
                                    onCancel={() => this.setState({newPopup: false})}
                                    match={{params: {id: 0}}}
                                />
                            </div>
                        </div>
                    </section>
                </SlideModal>
                );
    }

    closePopup() {
        this.setState({
            isNotification: false
        });
    }
    closeEmailPopup = () => {
        this.setState({
            emailPopup: false
        });
    }

    handleDeleteAccount(id) {
            client
            .post('/' + this.props.match.params.db + '/api/accounts/delete-list', {'body': id})
            .then(response => response.data)
            .then((response) => {
                if ('errors' in response) {
                    showAToast(response.errors, 'baseError', 'error');
                }
                else {
                    showAToast('Success! You have deleted the Account!', 'baseSuccess', 'success');
                }
            })
            .then(this.props.onFieldSave(this.updateRoute, 'deleted', true, {accountId: id}))

        this.setState({
            deletePopup: false,
        });
    } 

    openEditInfoHandle = () => {
        const { openEditInfo } = this.state;
        this.setState({
            openEditInfo: !openEditInfo,
        });
    }

    onImageUpload = (e) => {
        const formData = new FormData();
        formData.append('file', e.file);
        client.post('/' + this.props.match.params.db + '/api/account/'+ this.props.account.id +'/image/upload', formData)
            .then(response => response.data)
            .then(response => {
                if(response.success){
                    showAToast(response.success, 'baseSuccess', 'success');
                    this.props.onAccountFetchData(this.props.match.params.db, this.props.match.params.id);
                }                    
            })
            .catch(err => {
                console.log(err)
                if(err.response && err.response.data && err.response.data.message){
                    showAToast(err.response.data.message, 'baseError', 'error')
                }else{
                    showAToast('Error!', 'baseError', 'error')
                }                   
            })

    }

    removeImage = (e) => {
        client.post('/' + this.props.match.params.db + '/api/account/'+ this.props.account.id +'/delete/image').then(response =>{
            this.props.onAccountFetchData(this.props.match.params.db, this.props.match.params.id);
            showAToast(response.data, 'baseSuccess', 'success');
        });
    }

    render() {
        let editTemplatesOptions = null;
        if ((this.props.editTemplates && this.props.editTemplates.length) || (this.props.account.accTemplates && this.props.account.accTemplates.length)) {
            editTemplatesOptions = (
                <Menu>
                    {this.props.editTemplates.map((option, i) => {
                            return <Menu.Item key={i}>
                                <a onClick={() => this.setState({ showEditTemplate: true, templateId: option.value })}>
                                    <Translate locale={this.props.settings.locale} value={option.label} />
                                </a>
                            </Menu.Item>
                    })}
                    {this.props.account.accTemplates.map((option, i) => {
                            return <Menu.Item key={i}>
                                <a onClick={() => this.setState({ showTemplate: true, templateId: option.value })}>
                                    <Translate locale={this.props.settings.locale} value={option.label} />
                                </a>
                            </Menu.Item>
                    })}
                </Menu>
            );
        }
        const uploadButton = (
        <div>
            {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
        );
        
        var menu = <>
        <div className="col-lg-2 col-md-2 col-xs-2" style={{marginLeft: '-85px'}}>
            {editTemplatesOptions &&
                <Tooltip placement="top" title={plainTranslate(this.props.settings.locale, 'Templates')}>
                    <Button type="default" ghost style={{color: '#6f6f6f', border: '1px solid #6f6f6f99', background: '#f8f8f89c', margin: '0 1px'}}>
                        <Dropdown overlay={editTemplatesOptions}>
                            <Link to="#" data-toggle="dropdown" title="Template">
                                <SnippetsOutlined />
                            </Link>
                        </Dropdown>
                    </Button>
                </Tooltip> 
            }
        </div>
        <div className="col-lg-2 col-md-2 col-xs-2" id="action-menu">
        <i className="ion-android-more-vertical" title={plainTranslate(this.props.settings.locale, "More actions")}></i>
        <div className="account-action">
            <ul>
            <li>
                <a href='#' onClick={this.openEditInfoHandle}>
                    {plainTranslate(this.props.settings.locale, 'Edit')}
                </a>
            </li>
            {this.props.account.status == 2 ?
                <li>
                    <a href='#' onClick={() => this.onArchiveAccount(this.props.account.id, 1)}>
                        {plainTranslate(this.props.settings.locale, 'Restore')}
                    </a>
                </li>
                :
                <li>
                    <a href='#' onClick={() => this.onArchiveAccount(this.props.account.id, 2)}>
                        {plainTranslate(this.props.settings.locale, 'Archive')}
                    </a>
                </li>
                }
                {this.props.account.doNotDisturb == 1 ?
                <li>
                    <a href='#' onClick={() => this.onDoNotDisturb(this.props.account.id, 0)}>
                        {plainTranslate(this.props.settings.locale, 'Allow Notifications')}
                    </a>
                </li>
                :
                <li>
                    <a href='#' onClick={() => this.onDoNotDisturb(this.props.account.id, 1)}>
                        {plainTranslate(this.props.settings.locale, 'Do not disturb')}
                    </a>
                </li>
                }
                <li>
                    <a href='#' onClick={() => this.setState({isNotification: true, isNew: 'undefined'})}>
                        {plainTranslate(this.props.settings.locale, 'Add notification')}
                    </a>
                </li>
                <li className="separate"></li>
                <li>
                    <a href="#" className="text-red" onClick={() => this.setState({deletePopup: true})}
                       data-target="#deleteModal"
                       data-toggle='modal'>{plainTranslate(this.props.settings.locale, 'Delete')}</a>
                </li>
            </ul>
        </div>
    </div></>;

        return (
          <div>
            <div className="account-profile-information-general">
            <Card extra={menu} 
            size="small"
            style={{marginTop: 10}}
            title={this.props.account.listFields.find(field => field=='no') ? <span style={{fontSize: 19}}>{this.props.account.doNotDisturb ? <Tooltip placement="bottom" title='Do not disturb'><StopOutlined className="dnd-icon" /></Tooltip> : ''}  {this.props.account.no}</span> : this.props.account.doNotDisturb ? <Tooltip placement="bottom" title='Do not disturb'><StopOutlined className="dnd-icon"/></Tooltip> : ''}>
                 <Row>
                 <Col span={24} className="no-gutter">               
                    {this.props.account.deleted &&
                        <div className="col-lg-10 col-md-10 col-xs-10 no-gutter">
                            <h5 className="col-md-12 col-xs-12 infoMessage text-red">
                                <i className="fa fa-ban" aria-hidden="true"></i> 
                                {plainTranslate(this.props.settings.locale, "Account is deleted!")}</h5>
                        </div>
                    }
                    {this.props.account.status == 2 &&
                        <div className="col-lg-10 col-md-10 col-xs-10 no-gutter">
                            <h5 className="col-md-12 col-xs-12 infoMessage text-red">
                                <i className="fa fa-archive" aria-hidden="true"></i> 
                                {plainTranslate(this.props.settings.locale, "Account is archived!")}</h5>
                        </div>
                    }               
                </Col>               
                </Row>
                        {(this.props.account.listType == 1) &&
                        <Row>
                           {(this.props.account.listFields.find(field => field=='companyName') ? true:false) &&
                                    <Col span={19}><h2>{this.props.account.companyName}</h2></Col> }
                             {(this.props.account.listFields.find(field => field=='accountImage') ? true:false) &&
                            <Col span={5}>   <Upload
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader"
                                //showUploadList={{showRemoveIcon: true}}
                                fileList={this.props.account.images}
                                onRemove={this.removeImage}
                                customRequest={this.onImageUpload}
                              >
                                {this.props.account.images.length == 1 ? null : uploadButton}
                              </Upload></Col> }
                            {(this.props.account.listFields.find(field => field=='title') ? true:false)  && this.props.account.title &&
                                <Col span={8} style={{fontSize: 24}}>{this.props.account.title}</Col>}
                                <Col span={16} style={{fontSize: 24}}>
                            {(this.props.account.listFields.find(field => field=='firstName') ? true:false)  &&
                                 <span>{this.props.account.firstName}  </span>}
                            {(this.props.account.listFields.find(field => field=='middleName') ? true:false)  &&
                                  <span>{this.props.account.middleName} </span>} 
                            {(this.props.account.listFields.find(field => field=='lastName') ? true:false)  &&
                                  <span>{this.props.account.lastName}</span>} 
                               </Col>                    
                        </Row>}
                        {(this.props.account.listType == 2) &&
                            <Row style={{fontSize: 24}}>
                                <Col span={19}>
                                    {(this.props.account.listFields.find(field => field == 'title') ? true : false) && this.props.account.title &&
                                        <span>{this.props.account.title}</span>
                                    }
                                </Col>
                                {(this.props.account.listFields.find(field => field == 'accountImage') ? true : false) &&
                                    <Col span={5}>   <Upload
                                        name="avatar"
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        //showUploadList={{showRemoveIcon: true}}
                                        fileList={this.props.account.images}
                                        onRemove={this.removeImage}
                                        customRequest={this.onImageUpload}
                                    >
                                        {this.props.account.images.length == 1 ? null : uploadButton}
                                    </Upload></Col>}
                                    <Col span={16} style={{fontSize: 24}}>
                                    {(this.props.account.listFields.find(field => field=='firstName') ? true:false)  &&
                                        <span>{this.props.account.firstName}  </span>}
                                    {(this.props.account.listFields.find(field => field=='middleName') ? true:false)  &&
                                        <span>{this.props.account.middleName} </span>} 
                                    {(this.props.account.listFields.find(field => field=='lastName') ? true:false)  &&
                                        <span>{this.props.account.lastName}</span>} 
                                    </Col>  
                            </Row>}                   
                {(this.props.account.listFields.find(field => field=='position') ? true:false) &&
                   <Row style={{fontSize: 24}}>
                       <Col span={8}>{this.props.account.position}</Col>
                        <Col span={16}> {this.props.account.position && <>/&#160;</>}{this.props.account.companyName}</Col>
                    </Row>
                }
                {
                    (this.props.account.listFields.find(field => field=='name') ? true:false) &&
                    <div className="line col-lg-12 col-md-12 col-xs-12 no-gutter">
                        <div className="col-lg-3 col-md-3 col-xs-4 title no-gutter-r">
                            <span>{plainTranslate(this.props.settings.locale, 'Account name')}:</span></div>
                        <div className="col-lg-9 col-md-9 col-xs-8 content">
                            <div className="notes">
                            <p>{this.props.account.name}</p>
                            </div>
                        </div>
                    </div>
                }
                {(this.props.account.listFields.find(field => field == 'nationalId') ? true : false) &&
                    <div className="line col-lg-12 col-md-12 col-xs-12 no-gutter">
                        <div className="col-lg-3 col-md-3 col-xs-4 title no-gutter-r">
                            <span>{plainTranslate(this.props.settings.locale, 'National Id')}:</span></div>
                        <div className="col-lg-9 col-md-9 col-xs-8 content">
                            <div className="notes">
                                <p>{this.props.account.nationalId}</p>
                            </div>
                        </div>
                    </div>
                } 
                {
                    (this.props.account.listFields.find(field => field=='email') ? true:false) && this.props.account.email &&
                    <div className="line col-lg-12 col-xs-12 col-md-12 no-gutter">
                        <div className='col-lg-9 col-xs-8 col-md-9 no-gutter inlineHover'>
                            <h4>
                            <div className="icon-before-input pull-left">
                                <i className="fa fa-envelope-o" aria-hidden="true"></i>
                            </div>                          
                            <p style={{color: 'black'}}>{this.props.account.email}</p>
                            </h4>
                          </div>
                            <div className='col-lg-3 col-xs-4 col-md-3 m-top10 no-gutter'>
                                <div className="callIcons pull-right">
                                    {this.props.account.emailHasCreate && 
                                        <span className="circle" onClick={() => this.setState({emailPopup: true})}>
                                            <i className="fa fa-paper-plane" aria-hidden="true"></i>
                                        </span>
                                    }
                                    {this.state.emailPopup === true &&
                                      <SlideModal onClose={() => this.setState({emailPopup: false})} title={plainTranslate(this.props.settings.locale, 'New Email')}>
                                        <section className="newPanel">
                                          <div className="panel-body">
                                            <div className="row m-bot15">
                                                {this.props.emailSettings && this.props.emailSettings.length>0 && this.props.account.email &&
                                                   <Form
                                                   formRoute={this.props.match.params.db + '/api/message-email/' }
                                                   saveRoute={this.props.match.params.db + '/api/message-email/new'}
                                                   popup={true}
                                                   onSuccessfulSave={() => this.setState({emailPopup: false})}
                                                   onCancel={() => this.setState({emailPopup: false})}
                                                   refs={this.props.account.email ? this.props.account.email : ' '}
                                                   form={'initializeFromState'} 
                                                   match={{params: {id: 0}}}
                                                   isNew={true}
                                               />                                                   
                                                }
                                                {
                                                    this.props.emailSettings && this.props.emailSettings.length==0 &&
                                                    <div><p>{plainTranslate(this.props.settings.locale,'Send email directly from an Account profile with the Email service integration. Configure Email integration-related settings on the Composity platform from Account -> Email.')}
                                                    </p>
                                              {this.props.settings.user.roles.includes('Admin') &&  <a className="rectangular_button green"
                                                   href={'/'+this.props.match.params.db + '/settings/integrations'}>
                                                   <Translate locale={this.props.settings.locale} value="Setup now"/></a>}</div>
                                                }
                                            </div>
                                          </div>
                                        </section>
                                      </SlideModal>
                                    }
                        </div>
                            </div>
                        </div>
                }
                {
                    (this.props.account.listFields.find(field => field=='phone') ? true:false) && this.props.account.phone &&
                    <div className="line col-lg-12 col-md-12 col-xs-12 no-gutter">
                        <div className='col-lg-9 col-xs-8 col-md-9 no-gutter inlineHover'>
                            <h4>
                            <div className="icon-before-input pull-left">
                                <i className="fa fa-phone" aria-hidden="true"></i>
                            </div>
                            <p style={{color: 'black'}}>{this.props.account.phone}</p>
                            </h4>
                        </div>
                        <div className='col-lg-3 col-xs-4 col-md-3 m-top10 no-gutter'>
                            <div className="callIcons pull-right">
                                    {this.props.account.phone &&
                                        <span className="circle">
                                            <a href={"tel:" + this.props.account.phone}>
                                                <i className="fa fa-volume-control-phone" aria-hidden="true"></i>

                                            </a>
                                          </span>}
                                    {this.props.account.skype &&
                                            <a href={"skype:" + this.props.account.skype + "?call"}>
                                                <i className="fa fa-skype" aria-hidden="true"></i>
                                                {plainTranslate(this.props.settings.locale, 'Skype')}
                                            </a>}
                                <span className="circle" onClick={() => this.setState({messagePopup: true})}>
                                    <i className="fa fa-commenting" aria-hidden="true"></i>
                                </span>
                                {this.state.messagePopup === true &&
                                  <SlideModal onClose={() => this.setState({messagePopup: false})} title={plainTranslate(this.props.settings.locale, 'New Message')}>
                                    <section className="newPanel">
                                      <div className="panel-body">
                                        <div className="row m-bot15">
                                            {this.props.smsSettings && this.props.smsSettings.length>0 &&
                                                <MessageContent account={this.props.account} templates={this.props.smsTemplates} smsSettingOptions={this.props.smsSettings} onCancel={() => this.setState({messagePopup: false})} type="sms"/>
                                            }
                                            {
                                                this.props.smsSettings && this.props.smsSettings.length==0 &&
                                                <div><p>{plainTranslate(this.props.settings.locale,'Send messages directly from an Account profile with the Mobica integration. Mobica integration-related settings on the Composity platform from Account -> SMS.')}</p>
                                                <a className="rectangular_button green"
                                                   href={'/'+this.props.match.params.db + '/profile/settings/sms'}>
                                                   <Translate locale={this.props.settings.locale} value="Setup now"/></a></div>
                                            }
                                        </div>
                                      </div>
                                    </section>
                                  </SlideModal>
                                }
                    </div>
                        </div>
                    </div>
                }       
                 {(this.props.listFields.find(field => field=='website') ? true : false) && this.props.account.website &&
                    <div className="line col-lg-12 col-md-12 col-xs-12 no-gutter inlineHover">
                        <h4>
                            <div className="icon-before-input pull-left">
                                <i className="fa fa-laptop" aria-hidden="true"></i>
                            </div> 
                            <p>{this.props.account.website}</p>
                        </h4>
                        </div>}   
                   {
                    (this.props.account.listFields.find(field => field == 'user') ? true : false) &&
                    this.props.account.user && 
                    this.props.account.user.label &&
                    <div className="line col-lg-12 col-md-12 col-xs-12 no-gutter h-40">
                        <div className="col-lg-2 col-md-4 col-xs-4 no-gutter-r title">
                            <span>{plainTranslate(this.props.settings.locale, 'User')}:</span>
                        </div>
                        <div className="col-lg-10 col-md-8 col-xs-8 content no-gutter-l">
                                <div className="notes">
                                   <p>{this.props.account.user.label}</p>
                                </div>

                        </div>
                    </div>
                }       
                 {
                    (this.props.account.listFields.find(field => field=='location') ? true:false) && 
                    this.props.account.location && 
                    this.props.account.location.label && 
                    <div className="line col-lg-12 col-md-12 col-xs-12 no-gutter h-40">
                        <div className="col-lg-3 col-md-3 col-xs-4 title no-gutter-r">
                            <span>{plainTranslate(this.props.settings.locale, 'Location')}:</span></div>
                        <div className="col-lg-9 col-md-9 col-xs-8 content no-gutter-l">
                            <div className="notes">
                                <p>{this.props.account.location.label}</p>
                            </div>
                        </div>
                    </div>
                }
                {
                    (this.props.account.listFields.find(field => field=='department') ? true:false) && this.props.account.department &&
                    <div className="line col-lg-12 col-md-12 col-xs-12 no-gutter h-40">
                        <div className="col-lg-3 col-md-3 col-xs-4 title no-gutter-r">
                            <span>{plainTranslate(this.props.settings.locale, 'Department')}:</span></div>
                        <div className="col-lg-9 col-md-9 col-xs-8 content no-gutter-l">
                            <div className="notes">
                            <p>{this.props.account.department}</p>
                            </div>
                        </div>
                    </div>
                }  
             {this.props.account.accountDate && <div className="line col-lg-12 col-md-12 col-xs-12 no-gutter h-40">
                    <div className="col-lg-3 col-md-3 col-xs-4 title no-gutter-r">
                        <span>{plainTranslate(this.props.settings.locale, 'Account date')}:</span>
                    </div>
                    <div className="col-lg-9 col-md-9 col-xs-8 content no-gutter-l">
                        <div className="notes">
                            <p>{this.props.account.accountDate ? moment((this.props.account.accountDate).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : ''}</p>
                        </div>
                       
                    </div>
                </div>}
                {
                    (this.props.account.listFields.find(field => field=='documentRemark') ? true:false) && this.props.account.documentRemark && 
                    <div className="line col-lg-12 col-md-12 col-xs-12 no-gutter h-40">
                        <div className="col-lg-3 col-md-3 col-xs-4 title no-gutter-r">
                            <span>{plainTranslate(this.props.settings.locale, 'Document Remark')}:</span></div>
                        <div className="col-lg-9 col-md-9 col-xs-8 content no-gutter-l">
                            <div className="notes">
                            <p>{this.props.account.documentRemark}</p>
                            </div>
                        </div>
                    </div>
                }  
            </Card>                
                </div>
                {
                 this.state.isNotification &&
                <SlideModal onClose={() => this.setState({isNotification: false})} title={plainTranslate(this.props.settings.locale, 'Add notification')}>
                    <section className="newPanel">
                        <div className="panel-body">
                            <div className="row m-bot15">
                                     <SimpleForm
                                        formRoute={this.props.match.params.db + '/api/notification/form/' + this.state.isNew}
                                        saveRoute={this.props.match.params.db + '/api/notifications/new/' + this.props.account.id}
                                        popup={true}
                                        updateParent={() => this.closePopup()}
                                        onCancel={() => this.setState({isNotification: false})}
                                        match={{params: {id: 0}}}
                                    />
                            </div>
                        </div>
                    </section>
                </SlideModal>
                }
                {
                    this.state.newPopup && this.showNewPopup()

                }
                { this.state.deletePopup &&
                <Popup locale={this.props.settings.locale}
                       title={plainTranslate(this.props.settings.locale, 'Delete account')}
                       onClose={() => this.setState({deletePopup: false})}>
                    <section className="newPanel">
                        <div className="panel-body">
                            <div className="row m-bot15">
                                <div className="text-center">

                                    <h3>
                                        <Translate locale={this.props.settings.locale} value="Are you sure you want to delete this Account?"/>
                                    </h3>
                                    <button className="button-outlines green text-center"
                                            onClick={() => this.setState({deletePopup: false})}>
                                        <Translate locale={this.props.settings.locale} value="No"/>
                                    </button>
                                    <button className="button-primary-edited text-center btn-ok"
                                            onClick={() => this.handleDeleteAccount(this.props.account.id)}>
                                        <Translate locale={this.props.settings.locale} value="Yes"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>
                </Popup>
                }
                {
                 this.state.openEditInfo &&
                <SlideModal onClose={this.openEditInfoHandle}
                title={plainTranslate(this.props.settings.locale, 'Edit general info')}>
                    <GeneralInfoForm 
                    account={this.props.account} 
                    db={this.props.match.params.db} 
                    settings={this.props.settings}
                    onAccountFetchData={this.props.onAccountFetchData}
                    openEditInfoHandle={this.openEditInfoHandle}
                    listFields={this.props.listFields}
                    gridPopup={this.props.gridPopup}
                    updateGrid={this.props.updateGrid}/>
                </SlideModal>
                }
                <Drawer className="grid-popup-form" title="Download document" placement="right" visible={this.state.showEditTemplate} width={'90%'} onClose={() => this.setState({ showEditTemplate : false, templateId: null})}>
                    {(this.state.templateId && this.state.showEditTemplate) && <EditablePrint {...this.props} type={"account"} db={this.props.match.params.db} templateId={this.state.templateId} docId={this.props.match.params.id}/>}
                </Drawer> 
                <Drawer className="grid-popup-form" title="Download document" placement="right" visible={this.state.showTemplate} width={'90%'} onClose={() => this.setState({ showTemplate : false, templateId: null})}>
                    <div>
                        {(this.state.templateId && this.state.showTemplate) &&
                            <embed src={'/' + this.props.match.params.db + '/account/' + this.props.match.params.id + '/template/'+ this.state.templateId + '/export.pdf'} type="application/pdf"   height="775px" width="100%"/>
                        }
                    </div>
                </Drawer> 
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});


export default connect(mapStateToProps, {onAccountFetchData, onFieldSave, onUploadImage})(withRouter(GeneralInfo));
