import React from 'react';
import {Field as FormField, FieldArray, reduxForm } from 'redux-form';
import LineField from './lineField';
import Field from '../field';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import SaveCancel from '../../common/saveCancelButtons';
import {plainTranslate} from "../../common/translate";
import client from '../../../actions/client'

class renderMobileLines extends React.Component {

    constructor(props) {
        super(props);

        //this.onChange = this.onChange.bind(this);
        this.onNewOptions = this.onNewOptions.bind(this);
    }

    onDatalistChange(e, field, key) {
        this.props.change(this.props.fieldId+'['+key+']['+field.id+']', e.target.value);
        if (field['connectedField']) {
            let addVal = {};
            let jsonData = [];
            if (field.connectedField.dependency) {
                field.connectedField.dependency.map(field => {
                    if (this.props.mainFormData[field]) {
                        addVal[field] = this.props.mainFormData[field];
                    } else {
                        addVal[field] = this.props.mainFormData.lines[key][field];
                    }
                })
            }
                addVal['body'] = e.target.value;
                jsonData.push(JSON.stringify(addVal));

            client.post(field.connectedField.optionsUrl, addVal)
                    .then(response => response.data)
                    .then(response => {
                        this.props.change(this.props.fieldId+'['+key+'].'+field.connectedField.field, response ? response : null)
                    })
        }
    }

    onNewOptions(line, fieldId, options, addValue) {
        let lines = this.props.mainFormData.lines;
        if (lines) {
            if (!(line in lines))
                return;

            if (options) {
                lines[line][fieldId + 'options'] = options;
            }
            if (addValue) {
                options.push(addValue);
                lines[line][fieldId] = addValue.value;
                lines[line][fieldId + 'AdditionalValue'] = addValue;
            }
            this.props.change(this.props.fieldId, lines);
        }
    }

    onChangeOptions(fieldId, options, isLine = false) {
        let arr = (this.props.columns)
        let obj = arr.find(o => o.id === fieldId);
        if (obj && !isLine) {
            obj.options = options;
        }

        if (obj && obj.options && isLine) {
            if (typeof obj.options == 'undefined') {
                obj.options = options;
            } else {
                let add_options = [];
                for (let a = 0; a < obj.options.length; a++) {
                    if (obj.options[a].selected) {
                        add_options.push(obj.options[a]);
                    }

                }
                obj.options = add_options;
                if(options){
                    for (let i = 0; i < options.length; i++) {
                        obj.options.push(options[i]);
                    }
                }
            }
        }
    }


    render() {
        let key = null;

        if(this.props.editLine !== undefined && this.props.editLine !== null){
            key = this.props.editLine;
        }else{
            key = this.props.lines.length-1;
        }

        return <React.Fragment>
            {this.props.isNew &&
                this.props.columns.map((column, colKey) => {

                    let line = this.props.formData.lines[key];;
                    let fieldValue = line[column.id];
                    if ('populateFrom' in column) {
                        if (column.populateFrom === 'mainFormValue' && this.props.mainFormData) {
                            fieldValue = column.id in this.props.mainFormData ? this.props.mainFormData[column.id] : column.defaultValue ? column.defaultValue : 0;
                        }
                        else {
                            fieldValue = line[column.id] && !(column.populateFrom in line) ? line[column.id] : column.populateFrom in line ? line[column.populateFrom][column.id] : null;
                        }
                    }
                    else if ('operation' in column) {
                        fieldValue = this.props.calculateOperation(column.operation, line);
                        this.props.change(this.props.fieldId+'['+key+'].'+column.id, fieldValue);
                    }

                    if (column.initialValue !== undefined && !fieldValue) {
                        fieldValue = column.initialValue;
                    }

                    return <div className={column.type=='number' ? "text-right " : "text-left "} 
                    className={column.hide ? 'hidden ' : ''} 
                    style={{display: 'block'}}>

                       <FormField
                            name={this.props.fieldId+'['+key+'].'+column.id}
                            field={column}
                            locale={this.props.settings.locale}
                            component={Field}
                            type={column.type}
                            search={this.props.search}
                            width={column.width}
                            placeholder={column.placeholder}
                            onDatalistChange = {(e, field) => this.onDatalistChange(e, field, key)}
                            onNewOptions={(options, addValue) => this.onNewOptions(key, column.id, options, addValue)}
                            onChangeOptions={(field, options, isLine) => this.onChangeOptions(field, options, isLine)}
                            change={this.props.change}
                        />
                        </div>

                })}

            {this.props.save &&
                <div className="col-md-12 col-xs-12">
                <div className="pull-left">
                    <SaveCancel
                        enableSave={this.props.formData.lines[key].itId ? true : this.props.formData.lines[key].itemDescription ? true : this.props.formData.lines[key].description ? true : this.props.formData.lines[key].price ? true : this.props.formData.lines[key].amountOriginal ? true : false}
                        save={(e)=>this.props.save(e)}
                        showCancel={false}
                        showSaveNew={true}
                    />
                </div>
            </div>
            }

            {!this.props.isNew &&
                this.props.columns.map((column, colKey) => {
                    let line = this.props.lines;
                    let key = this.props.lineNo;
                    let fieldValue = line[column.id];
                    if ('populateFrom' in column && key !== this.props.lines.length - 1) {
                        if (column.populateFrom === 'mainFormValue' && this.props.mainFormData) {
                            fieldValue = column.id in this.props.mainFormData ? this.props.mainFormData[column.id] : column.defaultValue ? column.defaultValue : 0;
                        }
                        else {
                            fieldValue = line[column.id] && !(column.populateFrom in line) ? line[column.id] : column.populateFrom in line ? line[column.populateFrom][column.id] : null;
                        }
                    }
                    else if ('operation' in column) {
                   
                        fieldValue = this.props.calculateOperation(column.operation, line);
                       
                        this.props.change(this.props.fieldId+'['+key+'].'+column.id, fieldValue);
                    }

                    if (column.initialValue !== undefined && !fieldValue && key !== this.props.lines.length - 1) {
                        fieldValue = column.initialValue;
                    }
                    if(line['itId'] || line['price'] || line['documentId']){
                        if(column.id !== 'taxId' && column.id !== 'taxRate' && column.id !== 'availableQuantity' && column.id !== 'stockAvailability' && column.id !== 'stockAvailability2')
                        return <React.Fragment>
                            {colKey === 0 && <tr>
                                    <td className={'text-center'} style={{border: "1px solid #d3d3d3"}}>
                                    <span style={{display: "block"}} onClick={() => this.props.remove(key)}>
                                    <i className='ion-android-close' title="Delete row"></i></span>
                                </td>
                            </tr>
                            }
                            <tr onClick={() => this.props.edit(key)} className={column.hide ? 'hidden ' : ''} style={{display: 'block'}}>
                                    <td className={column.hide ? 'hidden ' : '' + " mobileTable-td mobileTable-label"}>{plainTranslate(this.props.settings.locale, column.label)}</td>
                                    <td className={column.hide ? 'hidden ' : '' + " mobileTable-td"}>
                                        {column.id === 'itId' ? line['itIdAdditionalValue'] ? line['itIdAdditionalValue'].label : line.itName : column.id === 'batch' ? line['batchAdditionalValue'] ? line['batchAdditionalValue'].label : line.batchName  : column.id === 'measId' ? line.measName : column.id === 'currId' ? line['currIdAdditionalValue'] ? line['currIdAdditionalValue'].label : line.currName : column.id === 'measId2' ? line['measId2AdditionalValue'] ? line['measId2AdditionalValue'].label : line.meas2Name : fieldValue}</td>
                           </tr>
                        </React.Fragment>
                    }
                })
            }
        </React.Fragment>

    }
}
export default renderMobileLines;
