import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Translate, {plainTranslate} from '../../../common/translate';
import CommentsByUser from './commentsByUser';

class CommentsByDate extends React.Component {

    componentDidMount() {
    }

    render() {
        return  <div>
            <div className="col-md-12 col-xs-12 content-container">
                <h4>{this.props.date ? this.props.date.toLocaleDateString('en-EN', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                }) : ''}</h4>
            </div>
            {
                this.props.list.map(function (comments, i) {
                    return (
                        <CommentsByUser list={comments} key={i}/>
                    );
                }.bind(this))
            }
        </div>
    }
}

const mapStateToProps = state => ({
    settings: state.settings
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(CommentsByDate);
