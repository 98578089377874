import React, {Component} from 'react';
import {connect} from 'react-redux';
import Translate,{plainTranslate} from '../common/translate';
import {Link} from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import {onDocumentProfileFetchData} from '../../actions/actions';
import FormLoader from '../forms/form-loader';

class MessageProfile extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        
        this.props.onDocumentProfileFetchData('/' + this.props.match.params.db + '/api/get/message/' + this.props.match.params.id);
    }

    render() {
        if (this.props.messageInfo.loading) {
            return <FormLoader/>
        }

        let message = this.props.messageInfo.invoiceProfileInfo ? this.props.messageInfo.invoiceProfileInfo.message : {};

        return (
            <div className="email-profile-message">
            <div className="col-md-12 col-lg-6 email-profile-message" style={{fontSize: 16}}>
                <div className="email-profile-message" style={{backgroundColor: 'white', padding: '20px 40px',marginBottom: 20}}>
                    {message.messageFrom && <p><b>{plainTranslate(this.props.settings.locale, 'From')}</b>: {message.messageFrom}</p>}
                    {message.messageTo && <p><b>{plainTranslate(this.props.settings.locale, 'To')}</b>: {message.messageTo}</p>}
                    {message.messageDate && <p><b>{plainTranslate(this.props.settings.locale, 'Date')}</b>: {message.messageDate}</p>}
                    {message.messageSubject && <p><b>{plainTranslate(this.props.settings.locale, 'Subject')}</b>: {message.messageSubject}</p>}
                    {message.messageCC && <p><b>{plainTranslate(this.props.settings.locale, 'Message cc')}</b>: {message.messageCC}</p>}
                    {message.messageBcc && <p><b>{plainTranslate(this.props.settings.locale, 'Message bcc')}</b>: {message.messageBcc}</p>}
                </div>
            </div>
            <div className="col-md-12 col-lg-6 email-profile-message" style={{fontSize: 16}}>
                {message.messageBody &&
                    <div style={{backgroundColor: 'white', padding: '20px 40px', height: '100%', overflow: 'auto'}}>
                        <p><b>{plainTranslate(this.props.settings.locale, 'Description')}:</b></p> 
                        <div dangerouslySetInnerHTML={{ __html: message.messageBody }}></div><
                    /div>
                }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
    messageInfo: state.profiles
});
const mapDispatchToProps = dispatch => ({
    onDocumentProfileFetchData: (db, id) => dispatch(onDocumentProfileFetchData(db, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MessageProfile));
