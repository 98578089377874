import React, { Component } from 'react';
import { plainTranslate } from '../../common/translate';
import { connect } from 'react-redux';
import { Upload, Select, Button } from 'antd';
import 'antd/lib/modal/style/index.css';
import { FormOutlined, DeleteOutlined, UploadOutlined, LoadingOutlined} from '@ant-design/icons';
import SlideModal from '../../common/SlideModal';
import client from '../../../actions/client';

const { Option } = Select;

class SectionContents extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            availableFields: [],
            fileList: [],
            loading: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.showModal = this.showModal.bind(this);
        this.handleOk = this.handleOk.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSectionChange = this.handleSectionChange.bind(this);
        this.addItemToSection = this.addItemToSection.bind(this);
        this.addItemToSubSection = this.addItemToSubSection.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleDocumentsChange = this.handleDocumentsChange.bind(this);
        this.uploadImages = this.uploadImages.bind(this);
    }

    componentDidMount() {
        this.update();
    }

    componentDidUpdate(prevProps) {
        if (this.props.input.name.includes("[")) {
            let ind = (this.props.input.name).indexOf('[');
            let line = (this.props.input.name).substring(0, ind);
            let numb = (this.props.input.name).match(/^(\D*)(\d+)/)[2];

            if (prevProps.formData && prevProps.formData[line][numb][this.props.field.id] !== this.props.formData[line][numb][this.props.field.id]) {
                let fileList = [];
                if (this.props.formData[line][numb][this.props.field.id] && this.props.formData[line][numb][this.props.field.id].length > 0) {
                    for (let i = 0; i < this.props.formData[line][numb][this.props.field.id].length; i++) {
                        if (this.props.formData[line][numb][this.props.field.id][i]) {
                            fileList.push(this.props.formData[line][numb][this.props.field.id][i]);
                            this.props.formData[line][numb][this.props.field.id][i]['uid'] = this.props.formData[line][numb][this.props.field.id][i]['id'];
                            this.props.formData[line][numb][this.props.field.id][i]['name'] = this.props.formData[line][numb][this.props.field.id][i].name ? this.props.formData[line][numb][this.props.field.id][i].name : this.props.formData[line][numb][this.props.field.id][i].originalName
                        }
                    }
                }
                this.setState({
                    fileList: fileList
                });
            }
        } else {
            if (prevProps.formData && prevProps.formData[this.props.field.id] !== this.props.formData[this.props.field.id]) {
                let fileList = [];
                if (this.props.formData[this.props.field.id] && this.props.formData[this.props.field.id].length > 0) {
                    for (let i = 0; i < this.props.formData[this.props.field.id].length; i++) {
                        if (this.props.formData[this.props.field.id][i]) {
                            fileList.push(this.props.formData[this.props.field.id][i]);
                            this.props.formData[this.props.field.id][i]['uid'] = this.props.formData[this.props.field.id][i]['id'];
                            this.props.formData[this.props.field.id][i]['name'] = this.props.formData[this.props.field.id][i].name ? this.props.formData[this.props.field.id][i].name : this.props.formData[this.props.field.id][i].originalName
                        }
                    }
                }
                this.setState({
                    fileList: fileList
                });
            }
        }
    }

    handleDocumentsChange({ fileList }) {
        this.setState({
            fileList: [...fileList],
        });
    }

    handleDocumentsDelete(file) {
        let images = this.state.images;
        let updatedImages = [];
        for (let i = 0; i < images.length; i++) {
            if (images[i].name !== file.name) {
                updatedImages.push(images[i]);
            }
        }

        this.setState({ images: updatedImages });
        this.props.change(this.props.input.name, updatedImages);
        //this.props.onDeleteImage(file.id, this.props.meta.form, this.props.field.id);
    }

    uploadImages(options, type, subType = null, ind = null, subSubType = null, subInd = null) {
        const { onSuccess, onError, file, onProgress } = options;
        const formData = new FormData();
        formData.append('file', file);
        this.setState({loading: true});

        return client
            .post('/' + this.props.settings.db + '/api/settings/image/upload', formData)
            .then(response => response.data)
            .then(response => {
                this.setState({loading: false});
                this.handleChange(response.url, type, subType, ind, subSubType, subInd)
            })
            .catch(err => {
                console.log(err)
                if (err.response && err.response.data && err.response.data.message) {
                    console.log(err.response.data)
                } else {
                    console.log(err)
                }
            })

    };

    chooseSection(type) {
        let availableFields;
        switch (type) {
            case 'SectionAboutEntry':
                availableFields = [{ 'id': 'title', 'type': 'text' }, { 'id': 'image', 'type': 'text' }, { 'id': 'imageAltText', 'type': 'text' }];
                break;
            case 'SectionAboutIntro':
                availableFields = [{ 'id': 'description', 'type': 'text' }];
                break;
            case 'SectionHtml':
                availableFields = [{ 'id': 'link', 'type': 'text' }];
                break;
            case 'SectionAboutItems':
                availableFields = [{ 'id': 'rows', 'type': 'array', 'fields': [{ 'id': 'title', 'type': 'text' }, { 'id': 'description', 'type': 'text' }, { 'id': 'image', 'type': 'text' }] }];
                break;
            case 'SectionAboutDna':
                availableFields = [{ 'id': 'title', 'type': 'text' }, { 'id': 'image', 'type': 'text' }, { 'id': 'imageAltText', 'type': 'text' }, { 'id': 'rows', 'type': 'array', 'fields': [{ 'id': 'title', 'type': 'text' }, { 'id': 'description', 'type': 'text' }] }];
                break;
            case 'SectionAboutTeam':
                availableFields = [{ 'id': 'title', 'type': 'text' }, { 'id': 'rows', 'type': 'array', 'fields': [{ 'id': 'image', 'type': 'text' }, { 'id': 'imageAltText', 'type': 'text' }, { 'id': 'name', 'type': 'text' }, { 'id': 'position', 'type': 'text' }, { 'id': 'entry', 'type': 'text' }, { 'id': 'aboutMe', 'type': 'text' }, { 'id': 'workExperiances', 'type': 'array', 'fields': [{ 'id': 'text', 'type': 'text' }] }, { 'id': 'coachingExperiances', 'type': 'array', 'fields': [{ 'id': 'text', 'type': 'text' }] }, { 'id': 'otherAboutMe', 'type': 'text' }, { 'id': 'author', 'type': 'text' }, { 'id': 'corporation', 'type': 'text' }] }];
                break;
            case 'SectionAccents':
                availableFields = [{ 'id': 'image', 'type': 'text' }, { 'id': 'imageAltText', 'type': 'text' }, { 'id': 'accents', 'type': 'array', 'fields': [{ 'id': 'title', 'type': 'text' }, { 'id': 'description', 'type': 'text' }, { 'id': 'image', 'type': 'text' }] }];
                break;
            case 'SectionActivity':
                availableFields = [{ 'id': 'description', 'type': 'text' }, { 'id': 'rows', 'type': 'array', 'fields': [{ 'id': 'link', 'type': 'text' }, { 'id': 'class', 'type': 'text' }, { 'id': 'text', 'type': 'text' }] }];
                break;
            case 'SectionAdvantages':
                availableFields = [{ 'id': 'title', 'type': 'text' }, { 'id': 'subtitle', 'type': 'text' }, { 'id': 'images', 'type': 'array', 'fields': [{ 'id': 'link', 'type': 'text' }, { 'id': 'class', 'type': 'text' }, { 'id': 'imageAltText', 'type': 'text' }] }];
                break;
            case 'SectionAlternativesCompareReplacement':
                availableFields = [{ 'id': 'title', 'type': 'text' }, { 'id': 'description', 'type': 'text' }];
                break;
            case 'SectionAlternativesCompareValues':
                availableFields = [{ 'id': 'leftImage', 'type': 'text' }, { 'id': 'imageAltTextLeft', 'type': 'text' }, { 'id': 'rightName', 'type': 'text' }, { 'id': 'rightImage', 'type': 'text' }, { 'id': 'imageAltTextRight', 'type': 'text' }, { 'id': 'leftName', 'type': 'text' }, { 'id': 'values', 'type': 'array', 'fields': [{ 'id': 'title', 'type': 'text' }, { 'id': 'leftValue', 'type': 'text' }, { 'id': 'leftAddDesc', 'type': 'text' }, { 'id': 'rightValue', 'type': 'text' }, { 'id': 'rightAddDesc', 'type': 'text' }] }];
                break;
            case 'SectionAlternativesHero':
                availableFields = [{ 'id': 'title', 'type': 'text' }, { 'id': 'subtitle', 'type': 'text' }];
                break;
            case 'SectionBenefits':
                availableFields = [{ 'id': 'rows', 'type': 'array', 'fields': [{ 'id': 'title', 'type': 'text' }, { 'id': 'text', 'type': 'text' }, { 'id': 'image', 'type': 'text' }, { 'id': 'imageAltText', 'type': 'text' }, { 'id': 'buttonLink', 'type': 'text' }, { 'id': 'buttonText', 'type': 'text' }] }];
                break;
            case 'SectionBenefitsList':
                availableFields = [{ 'id': 'rows', 'type': 'array', 'fields': [{ 'id': 'title', 'type': 'text' }, { 'id': 'subtitle', 'type': 'text' }, { 'id': 'image', 'type': 'text' }, { 'id': 'imageAltText', 'type': 'text' }] }];
                break;
            case 'SectionCommonScreen':
                availableFields = [{ 'id': 'rows', 'type': 'array', 'fields': [{ 'id': 'title', 'type': 'text' }, { 'id': 'text', 'type': 'text' }, { 'id': 'image', 'type': 'text' }, { 'id': 'imageAltText', 'type': 'text' }] }];
                break;
            case 'SectionCustomForm':
                availableFields = [{ "id": "title", "type": "text" }, { "id": "subtitle", "type": "text" }, { "id": "image", "type": "text" }, { 'id': 'imageAltText', 'type': 'text' }, { "id": "formTitle", "type": "text" }, { "id": "successLink", "type": "text" }, { "id": "successMessage", "type": "text" }, { "id": "formSlug", "type": "text" }];
                break;
            case 'SectionContent':
                availableFields = [];
                break;
            case 'SectionCta':
                availableFields = [{ 'id': 'layout', 'type': 'text' }, { 'id': 'title', 'type': 'text' }, { 'id': 'description', 'type': 'text' }, { 'id': 'buttons', 'type': 'array', 'fields': [{ 'id': 'text', 'type': 'text' }, { 'id': 'link', 'type': 'text' }, { 'id': 'class', 'type': 'text' }] }];
                break;
            case 'SectionFAQs':
                availableFields = [{ "id": "title", "type": "text" }, { 'id': 'faq', 'type': 'array', 'fields': [{ 'id': 'question', 'type': 'text' }, { 'id': 'answer', 'type': 'textarea' }, { 'id': 'image', 'type': 'text' }, { 'id': 'imageAltText', 'type': 'text' }] }];
                break;
            case 'SectionFeatures':
                availableFields = [{ 'id': 'image', 'type': 'text' }, { 'id': 'accents', 'type': 'array', 'fields': [{ 'id': 'title', 'type': 'text' }, { 'id': 'description', 'type': 'text' }, { 'id': 'image', 'type': 'text' }, { 'id': 'imageAltText', 'type': 'text' }] }];
                break;
            case 'SectionTopFeatures':
                availableFields = [{ 'id': 'description', 'type': 'text' }, { 'id': 'title', 'type': 'text' }, { 'id': 'texts', 'type': 'array', 'fields': [{ 'id': 'text', 'type': 'text' }] }];
                break;
            case 'SectionHero':
                availableFields = [{ 'id': 'title', 'type': 'text' }, { 'id': 'subtitle', 'type': 'text' }, { 'id': 'layout', 'type': 'text' }, { 'id': 'images', 'type': 'array', 'fields': [{ 'id': 'link', 'type': 'text' }, { 'id': 'class', 'type': 'text' }, { 'id': 'imageAltText', 'type': 'text' }] }];
                break;
            case 'SectionHeroCenter':
                availableFields = [
                    { 'id': 'description', 'type': 'text' },
                    { 'id': 'title', 'type': 'text' },
                    { 'id': 'hashtag', 'type': 'text' },
                    { 'id': 'buttonDescription', 'type': 'text' },
                    { 'id': 'buttons', 'type': 'array', 'fields': [{ 'id': 'text', 'type': 'text' }, { 'id': 'link', 'type': 'text' }, { 'id': 'class', 'type': 'text' }] },
                    { 'id': 'image', 'type': 'text' },
                    { 'id': 'imageClass', 'type': 'text' },
                    { 'id': 'imageAltText', 'type': 'text' }
                ];
                break;
            case 'SectionHeroRight':
                availableFields = [
                    { 'id': 'title', 'type': 'text' },
                    { 'id': 'subtitle', 'type': 'text' },
                    { 'id': 'layout', 'type': 'text' },
                    { 'id': 'buttonDescription', 'type': 'text' },
                    { 'id': 'buttons', 'type': 'array', 'fields': [{ 'id': 'text', 'type': 'text' }, { 'id': 'link', 'type': 'text' }, { 'id': 'class', 'type': 'text' }] },
                    { 'id': 'images', 'type': 'array', 'fields': [{ 'id': 'link', 'type': 'text' }, { 'id': 'class', 'type': 'text' }, { 'id': 'imageAltText', 'type': 'text' }] }];
                break;
            case 'SectionImplementation':
                availableFields = [{ 'id': 'title', 'type': 'text' }, { 'id': 'rows', 'type': 'array', 'fields': [{ 'id': 'title', 'type': 'text' }, { 'id': 'description', 'type': 'text' }, { 'id': 'image', 'type': 'text' }] }, { 'id': 'checkboxes', 'type': 'array', 'fields': [{ 'id': 'title', 'type': 'text' }] }];
                break;
            case 'SectionIndexHero':
                availableFields = [{ 'id': 'title', 'type': 'text' }, { 'id': 'subtitle', 'type': 'text' }, { 'id': 'image', 'type': 'text' }, { 'id': 'imageAltText', 'type': 'text' }, { 'id': 'CTA', 'type': 'array', 'fields': [{ 'id': 'type', 'type': 'text' }, { 'id': 'form', 'type': 'text' }, { 'id': 'class', 'type': 'text' }, { 'id': 'title', 'type': 'text' }, { 'id': 'additionalText', 'type': 'text' }, { 'id': 'url', 'type': 'text' }, { 'id': 'downloadLink', 'type': 'text' }] }];
                break;
            case 'SectionIndustries':
                availableFields = [{ 'id': 'title', 'type': 'text' }, { 'id': 'left', 'type': 'array', 'fields': [{ 'id': 'title', 'type': 'text' }, { 'id': 'image', 'type': 'text' }, { 'id': 'imageAltText', 'type': 'text' }] }, { 'id': 'rigth', 'type': 'array', 'fields': [{ 'id': 'title', 'type': 'text' }, { 'id': 'image', 'type': 'text' }] }];
                break;
            case 'SectionInventoryManagement':
                availableFields = [{ 'id': 'title', 'type': 'text' }, { 'id': 'subtitle', 'type': 'text' }, { 'id': 'tabs', 'type': 'array', 'fields': [{ 'id': 'title', 'type': 'text' }, { 'id': 'subtitle', 'type': 'text' }, { 'id': 'image', 'type': 'text' }, { 'id': 'imageAltText', 'type': 'text' }, { 'id': 'items', 'type': 'array', 'fields': [{ 'id': 'title', 'type': 'text' }, { 'id': 'description', 'type': 'text' }] }] }];
                break;
            case 'SectionModules':
                availableFields = [{ 'id': 'title', 'type': 'text' }, { 'id': 'modules', 'type': 'array', 'fields': [{ 'id': 'link', 'type': 'text' }, { 'id': 'title', 'type': 'text' }, { 'id': 'image', 'type': 'text' }, { 'id': 'imageAltText', 'type': 'text' }] }];
                break;
            case 'SectionPlansInclusion':
                availableFields = [{ 'id': 'title', 'type': 'text' }, { 'id': 'modules', 'type': 'array', 'fields': [{ 'id': 'text', 'type': 'text' }, { 'id': 'image', 'type': 'text' }, { 'id': 'imageAltText', 'type': 'text' }] }];
                break;
            case 'SectionPrices':
                availableFields = [{ 'id': 'title', 'type': 'text' }, { 'id': 'subtitle', 'type': 'text' }, { 'id': 'annuallyText', 'type': 'text' }, { 'id': 'monthlyText', 'type': 'text' }, {
                    'id': 'plans', 'type': 'array', 'fields': [
                        { 'id': 'title', 'type': 'text' },
                        { 'id': 'planDescription', 'type': 'text' },
                        { 'id': 'planDescriptionMonthly', 'type': 'text' },
                        { 'id': 'mostUsed', 'type': 'text' },
                        { 'id': 'planPrice', 'type': 'text' },
                        { 'id': 'users', 'type': 'text' },
                        { 'id': 'records', 'type': 'text' },
                        { 'id': 'accounts', 'type': 'text' },
                        { 'id': 'modules', 'type': 'array', 'fields': [{ 'id': 'text', 'type': 'text' }] },
                        { 'id': 'storage', 'type': 'text' },
                        { 'id': 'sla', 'type': 'text' },
                        { 'id': 'support', 'type': 'text' },
                        { 'id': 'link', 'type': 'text' },
                        { 'id': 'linkText', 'type': 'text' },
                        { 'id': 'buttonClass', 'type': 'text' },
                        { 'id': 'addTexts', 'type': 'array', 'fields': [{ 'id': 'text', 'type': 'text' }] },
                    ]
                }];
                break;
            case 'SectionProducts':
                availableFields = [{ 'id': 'title', 'type': 'text' }, { 'id': 'products', 'type': 'array', 'fields': [{ 'id': 'title', 'type': 'text' }, { 'id': 'image', 'type': 'text' }, { 'id': 'imageAltText', 'type': 'text' }, { 'id': 'slug', 'type': 'text' }, { 'id': 'class', 'type': 'array', 'fields': [{ 'id': 'title', 'type': 'text' }, { 'id': 'link', 'type': 'text' }] }] }];
                break;
            case 'SectionResults':
                availableFields = [{ 'id': 'title', 'type': 'text' }, { 'id': 'rows', 'type': 'array', 'fields': [{ 'id': 'title', 'type': 'text' }, { 'id': 'image', 'type': 'text' }, { 'id': 'imageAltText', 'type': 'text' }, { 'id': 'description', 'type': 'text' }] }];
                break;
            case 'SectionSolutionsList':
                availableFields = [{ 'id': 'description', 'type': 'text' }, { 'id': 'alternatives', 'type': 'array', 'fields': [{ 'id': 'slug', 'type': 'text' }, { 'id': 'title', 'type': 'text' }] }];
                break;
            case 'SectionStartToday':
                availableFields = [{ 'id': 'description', 'type': 'text' }, { 'id': 'rows', 'type': 'array', 'fields': [{ 'id': 'link', 'type': 'text' }, { 'id': 'class', 'type': 'text' }, { 'id': 'text', 'type': 'text' }] }];
                break;
            case 'SectionStats':
                availableFields = [{ 'id': 'description', 'type': 'text' }, { 'id': 'rows', 'type': 'array', 'fields': [{ 'id': 'link', 'type': 'text' }, { 'id': 'class', 'type': 'text' }, { 'id': 'text', 'type': 'text' }] }];
                break;
            case 'SectionStory':
                availableFields = [{ 'id': 'title', 'type': 'text' }, { 'id': 'description', 'type': 'text' }, { 'id': 'buttonLink', 'type': 'text' }, { 'id': 'buttonText', 'type': 'text' }, { 'id': 'image', 'type': 'text' }, { 'id': 'imageAltText', 'type': 'text' }, { 'id': 'name', 'type': 'text' }, { 'id': 'position', 'type': 'text' }];
                break;
            case 'SectionSubHero':
                availableFields = [{ 'id': 'rows', 'type': 'array', 'fields': [{ 'id': 'title', 'type': 'text' }, { 'id': 'description', 'type': 'text' }] }];
                break;
            case 'SectionTitle':
                availableFields = [{ 'id': 'description', 'type': 'text' }, { 'id': 'rows', 'type': 'array', 'fields': [{ 'id': 'link', 'type': 'text' }, { 'id': 'class', 'type': 'text' }, { 'id': 'text', 'type': 'text' }] }];
                break;
            case 'SectionVerificationNumbers':
                availableFields = [{ 'id': 'numbers', 'type': 'array', 'fields': [{ 'id': 'title', 'type': 'text' }, { 'id': 'count', 'type': 'text' }] }];
                break;
            case 'SectionDocuments':
                availableFields = [{ 'id': 'rows', 'type': 'array', 'fields': [{ 'id': 'Title', 'type': 'text' }, { 'id': 'documents', 'type': 'array', 'fields': [{ 'id': 'slug', 'type': 'image' }, { 'id': 'title', 'type': 'text' }] }] }];
                break;
            default:
                availableFields = [];
        }

        return availableFields;
    }

    handleSectionChange(value) {
        let section = this.state.value;
        section.id = value;
        let availableFields = this.chooseSection(value);
        this.setState({
            availableFields: availableFields,
            value: section,
        });
    }

    addItemToSection(item, key) {
        let value = this.state.value;
        let newItem = {};
        this.state.availableFields[key]['fields'].map(function (key, index) {
            if (key.type == 'text') {
                newItem[key.id] = '';
            } else {
                newItem[key.id] = [];
            }
        });
        if (value.hasOwnProperty(item.id)) {
            value[item.id].push(newItem);
        } else {
            value[item.id] = [];
            value[item.id].push(newItem);
        }

        this.setState({
            value: value,
        });
    }

    addItemToSubSection(item, key, itemKey, subItem, subKey) {
        let value = this.state.value;
        let newItem = {};

        this.state.availableFields[key]['fields'][subKey]['fields'].map(function (field, index) {
            if (field.type == 'text') {
                newItem[field.id] = '';
            }
        });

        if (value.hasOwnProperty(item.id) && value[item.id][itemKey].hasOwnProperty(subItem.id)) {
            value[item.id][itemKey][subItem.id].push(newItem);
        } else {
            value[item.id][itemKey][subItem.id] = [];
            value[item.id][itemKey][subItem.id].push(newItem);
        }

        this.setState({
            value: value,
        });
    }

    update() {
        let availableFields = this.chooseSection(this.props.item.id);
        this.setState({
            value: this.props.item ? this.props.item : [],
            availableFields: availableFields
        });
    }

    handleChange(e, type, subType = null, ind = null, subSubType = null, subInd = null) {
        let value = this.state.value;
        let val = e;
        if (e.hasOwnProperty('target')) {
            val = e.target.value;
            //val = val.replace(/["'}{\\\/]/g, "");
        }
        if (subSubType && subInd !== null) {
            value[type][ind][subType][subInd][subSubType] = val;
        } else if (ind !== null && subType && subInd == null && subSubType == null) {
            value[type][ind][subType] = val;
        } else {
            value[type.id] = val;
        }

        this.setState({ value: value });
    }

    handleDelete(type, ind = null, subType = null, subInd = null) {
        let value = this.state.value;
        if (subInd !== null) {
            value[type][ind][subType].splice(subInd, 1);
        } else if (ind !== null && subInd == null) {
            value[type].splice(ind, 1);
        }

        this.setState({ value: value });
    }

    showModal() {
        this.setState({
            visible: true,
        });
    }

    handleOk() {
        this.props.change();
        this.setState({
            visible: false,
        });
    }

    handleCancel() {
        this.setState({
            visible: false,
        });
    };

    componentDidUpdate(prevProps) {
        if (prevProps.item != this.props.item) {
            this.setState({
                value: this.props.item
            })
        }
    }

    render() {
        const { previewVisible, previewImage, fileList } = this.state;
        const uploadButton = (
            <Button>
                <UploadOutlined /> {plainTranslate(this.props.locale, 'Upload')}
            </Button>
        );
        return (
            <div>
                {this.state.value &&
                    <div className="row">
                        <div className="accordion col-md-12 m-bot15">
                            <div className="accordion-group">
                                <div className="accordion-heading area collapsible col-md-12 no-gutter">
                                    <div className="col-md-9 col-xs-6">
                                        <strong>{this.state.value.id}</strong>
                                    </div>
                                    <div className="col-md-3 col-xs-6">
                                        <span style={{ marginRight: 10 }} onClick={this.showModal}><FormOutlined title={plainTranslate(this.props.locale, 'Edit menu item')} /> {plainTranslate(this.props.locale, 'Edit')}</span>
                                        <span onClick={() => this.props.remove(this.state.value)}><DeleteOutlined title={plainTranslate(this.props.locale, 'Remove menu item')} /> {plainTranslate(this.props.locale, 'Delete')}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.visible &&
                            <SlideModal
                                title={plainTranslate(this.props.locale, 'Edit item')}
                                onClose={this.handleCancel}
                            >
                                <section className="newPanel" >
                                    <div className="panel-body" id="section" style={{ position: 'relative' }}>
                                        <div className="row m-bot15 form-group" >
                                            <label className="text-left">{plainTranslate(this.props.locale, 'Type section')}</label>
                                            <Select getPopupContainer={() => document.getElementById('section')} value={this.state.value.id} style={{ maxWidth: '100%', minWidth: "100%", marginBottom: '10px' }} onChange={(value) => this.handleSectionChange(value)}>
                                                <Option value='SectionAboutEntry'>SectionAboutEntry</Option>
                                                <Option value='SectionAboutIntro'>SectionAboutIntro</Option>
                                                <Option value='SectionHtml'>SectionHtml</Option>
                                                <Option value='SectionAboutItems'>SectionAboutItems</Option>
                                                <Option value='SectionAboutDna'>SectionAboutDna</Option>
                                                <Option value='SectionAboutTeam'>SectionAboutTeam</Option>
                                                <Option value='SectionAboutTeamProfile'>SectionAboutTeamProfile</Option>
                                                <Option value='SectionAccents'>SectionAccents</Option>
                                                <Option value='SectionActivity'>SectionActivity</Option>
                                                <Option value='SectionAdvantages'>SectionAdvantages</Option>
                                                <Option value='SectionAlternativesCompareReplacement'>SectionAlternativesCompareReplacement</Option>
                                                <Option value='SectionAlternativesCompareValues'>SectionAlternativesCompareValues</Option>
                                                <Option value='SectionAlternativesHero'>SectionAlternativesHero</Option>
                                                <Option value='SectionBenefits'>SectionBenefits</Option>
                                                <Option value='SectionBenefitsList'>SectionBenefitsList</Option>
                                                <Option value='SectionCommonScreen'>SectionCommonScreen</Option>
                                                <Option value='SectionCustomForm'>SectionCustomForm</Option>
                                                <Option value='SectionContent'>SectionContent</Option>
                                                <Option value='SectionCta'>SectionCta</Option>
                                                <Option value='SectionDocuments'>SectionDocuments</Option>
                                                <Option value='SectionFAQs'>SectionFAQs</Option>
                                                <Option value='SectionFeatures'>SectionFeatures</Option>
                                                <Option value='SectionTopFeatures'>SectionTopFeatures</Option>
                                                <Option value='SectionHero'>SectionHero</Option>
                                                <Option value='SectionHeroCenter'>SectionHeroCenter</Option>
                                                <Option value='SectionHeroRight'>SectionHeroRight</Option>
                                                <Option value='SectionImplementation'>SectionImplementation</Option>
                                                <Option value='SectionIndexHero'>SectionIndexHero</Option>
                                                <Option value='SectionIndustries'>SectionIndustries</Option>
                                                <Option value='SectionInventoryManagement'>SectionInventoryManagement</Option>
                                                <Option value='SectionModules'>SectionModules</Option>
                                                <Option value='SectionPlansInclusion'>SectionPlansInclusion</Option>
                                                <Option value='SectionPrices'>SectionPrices</Option>
                                                <Option value='SectionProducts'>SectionProducts</Option>
                                                <Option value='SectionResults'>SectionResults</Option>
                                                <Option value='SectionSolutionsList'>SectionSolutionsList</Option>
                                                <Option value='SectionStartToday'>SectionStartToday</Option>
                                                <Option value='SectionStory'>SectionStory</Option>
                                                <Option value='SectionSubHero'>SectionSubHero</Option>
                                                <Option value='SectionStats'>SectionStats</Option>
                                                <Option value='SectionTitle'>SectionTitle</Option>
                                                <Option value='SectionVerificationNumbers'>SectionVerificationNumbers</Option>
                                            </Select>
                                            {this.state.availableFields.length ? this.state.availableFields.map((item, key) => {
                                                return <><div className='col-md-12' key={key}>
                                                    <label className="text-left">{plainTranslate(this.props.locale, item.id)}</label>
                                                    {item.type == 'text' &&
                                                        <input type="text" className="form-control" onChange={(e) => this.handleChange(e, item)}
                                                            value={this.state.value[item.id] ? this.state.value[item.id] : null}
                                                        />
                                                    }
                                                    {item.type == 'textarea' &&
                                                        <textarea
                                                            value={this.state.value[item.id] ? this.state.value[item.id] : null}
                                                            onChange={(val) => this.handleChange(val, item)}
                                                        />
                                                    }
                                                    {item.type == 'array' && this.state.value[item.id] && this.state.value[item.id].length && this.state.value[item.id].map((field, ind) => {
                                                        return <div key={ind} className='col-md-12' style={{ paddingLeft: '10px' }}>
                                                            <a className="text-red text-bold" onClick={() => this.handleDelete(item.id, ind)}>{plainTranslate(this.props.locale, 'Delete element')} {ind + 1} - {plainTranslate(this.props.locale, item.id)}</a>
                                                            {this.state.availableFields[key]['fields'].length && this.state.availableFields[key]['fields'].map((sectionRow, rowKey) => {
                                                                return <><div className='col-md-12' key={rowKey}>
                                                                    <label className="text-left">{plainTranslate(this.props.locale, sectionRow['id'])}</label>
                                                                    {sectionRow['type'] == 'text' &&
                                                                        <input type="text" className="form-control" 
                                                                            onChange={(e) => this.handleChange(e, item.id, sectionRow['id'], ind)}
                                                                            value={this.state.value[item.id][ind][sectionRow['id']]}
                                                                        />
                                                                    }
                                                                    {sectionRow['type'] == 'textarea' &&
                                                                        <textarea
                                                                            onChange={(e) => this.handleChange(e, item.id, sectionRow['id'], ind)}
                                                                            value={this.state.value[item.id][ind][sectionRow['id']]
                                                                        }
                                                                        />
                                                                    }
                                                                    {sectionRow['type'] == 'array' && this.state.value[item.id][ind][sectionRow['id']] && this.state.value[item.id][ind][sectionRow['id']].length ? this.state.value[item.id][ind][sectionRow['id']].map((field1, ind1) => {
                                                                        return <div className='col-md-12' key={ind1} style={{ paddingLeft: '10px' }}>
                                                                            <a className="text-red text-bold" onClick={() => this.handleDelete(item.id, ind, sectionRow['id'], ind1)}>{plainTranslate(this.props.locale, 'Delete element')} {ind1 + 1} - {plainTranslate(this.props.locale, sectionRow['id'])}</a>
                                                                            {sectionRow['fields'] && sectionRow['fields'].length && sectionRow['fields'].map((sectionRow1, rowKey1) => {
                                                                                return <div key={rowKey1}>
                                                                                    {sectionRow1['type'] !== 'image' && <label className="text-left">{plainTranslate(this.props.locale, sectionRow['fields'][rowKey1]['id'])}</label>}
                                                                                    {sectionRow1['type'] == 'text' &&
                                                                                        <input key={rowKey1} type="text" className="form-control" 
                                                                                            onChange={(e) => this.handleChange(e, item.id, sectionRow.id, ind, sectionRow1.id, ind1)}
                                                                                            value={this.state.value[item.id] &&
                                                                                                this.state.value[item.id][ind] &&
                                                                                                this.state.value[item.id][ind][sectionRow.id] &&
                                                                                                this.state.value[item.id][ind][sectionRow.id][ind1][sectionRow1.id]
                                                                                            }
                                                                                        />
                                                                                    } 
                                                                                    {sectionRow1['type'] == 'image' &&
                                                                                        <>
                                                                                            <Upload
                                                                                                multiple
                                                                                                customRequest={(files) => this.uploadImages(files, item.id, sectionRow.id, ind, sectionRow1.id, ind1)}
                                                                                                onRemove={this.handleDocumentsDelete}
                                                                                                defaultFileList={fileList}
                                                                                                fileList={fileList}
                                                                                                showUploadList={false}
                                                                                                onChange={this.handleDocumentsChange}
                                                                                            >
                                                                                                {fileList.length >= 8 ? null : uploadButton}
                                                                                            </Upload>
                                                                                        {this.state.value[item.id][ind] && this.state.value[item.id][ind]['documents'] && this.state.value[item.id][ind]['documents'][ind1][sectionRow1.id] ?
                                                                                            <p style={{ display: "inline-block" }}><strong>{this.state.value[item.id][ind]['documents'][ind1][sectionRow1.id]}</strong></p>
                                                                                            : this.state.loading ?
                                                                                                <LoadingOutlined /> : ''
                                                                                        }
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                            })
                                                                            }
                                                                        </div>
                                                                    }) : ''
                                                                    }
                                                                </div>
                                                                    {sectionRow['type'] == 'array' &&
                                                                        <div className='col-md-12'><a className="button-primary" onClick={() => this.addItemToSubSection(item, key, ind, sectionRow, rowKey)}>{plainTranslate(this.props.locale, 'Add subitem to ')}{sectionRow['id']}</a></div>
                                                                    }
                                                                </>
                                                            })
                                                            }
                                                        </div>
                                                    })
                                                    }
                                                </div>
                                                    {item.type == 'array' &&
                                                        <div className='col-md-12'><a className="button-outline grey" onClick={() => this.addItemToSection(item, key)}>{plainTranslate(this.props.locale, 'Add item to ')}{item.id}</a></div>
                                                    }
                                                </>
                                            })
                                                : <p>No fields added for this section</p>
                                            }
                                            <div style={{ float: 'right' }}>
                                                <a className="button-outline grey" onClick={this.handleCancel}>{plainTranslate(this.props.locale, 'Cancel')}</a>
                                                <a className="button-outline green" onClick={this.handleOk}>{plainTranslate(this.props.locale, 'Save')}</a>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </SlideModal>
                        }
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(SectionContents);
