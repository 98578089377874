import React from 'react';
import {Field as FormField, FieldArray, reduxForm } from 'redux-form';
import LineField from './lineField';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import client from '../../../actions/client'

class renderLines extends React.Component {

    constructor(props) {
        super(props);
        
        //this.onChange = this.onChange.bind(this);
        this.onNewOptions = this.onNewOptions.bind(this);
    }
    
    onDatalistChange(e, field, key) {
        this.props.change(this.props.fieldId+'['+key+']['+field.id+']', e.target.value);
        if (field['connectedField']) {
            let addVal = {};
            let jsonData = [];
            if (field.connectedField.dependency) {
                field.connectedField.dependency.map(field => {
                    if (this.props.mainFormData[field]) {
                        addVal[field] = this.props.mainFormData[field];
                    } else {
                        addVal[field] = this.props.mainFormData.lines[key][field];
                    }
                })
            }
                addVal['body'] = e.target.value;
                jsonData.push(JSON.stringify(addVal));

            client.post(field.connectedField.optionsUrl, addVal)
                    .then(response => response.data)
                    .then(response => {
                        this.props.change(this.props.fieldId+'['+key+'].'+field.connectedField.field, response ? response : null)
                    })
        }
    }
    
    onNewOptions(line, fieldId, options, addValue) {
        let lines = this.props.mainFormData.lines;
        if (lines) {
            if (!(line in lines))
                return;

            if (options) {
                lines[line][fieldId + 'options'] = options;
            }
            if (addValue) {
                lines[line][fieldId] = addValue.value;
                lines[line][fieldId + 'AdditionalValue'] = addValue;
            }
            this.props.change(this.props.fieldId, lines);
        }
    }
    
//    onChangeOptions(fieldId, options) {
//        let arr = (this.props.columns)
//        let obj = arr.find(o => o.id === fieldId);
//        if (obj) {
//            obj.options = options;
//        }
//    }
    
    onChangeOptions(fieldId, options, isLine = false) {
        let arr = (this.props.columns)
        let obj = arr.find(o => o.id === fieldId);
        if (obj && !isLine) {
            obj.options = options;
        }

        if (obj && obj.options && isLine) {
            if (typeof obj.options == 'undefined') {
                obj.options = options;
            } else {
                let add_options = [];
                if (options) {
                    for (let a = 0; a < obj.options.length; a++) {
                        if (obj.options[a].selected) {
                            add_options.push(obj.options[a]);
                        }
                    }
                }
                obj.options = add_options;
                if (options) {
                    for (let i = 0; i < options.length; i++) {
                        obj.options.push(options[i]);
                    }
                }
            }
        }
    }
    

    render() {
         let indexes = null;
         let isDuplicate = [];

        let hasBatch = this.props.columns.find(o => o.id === 'batch');
        if(!hasBatch){
            hasBatch = this.props.columns.find(o => o.id === 'batchName');
        }

        return <React.Fragment> 
            {this.props.lines.map((line, key) => (
              <Draggable key={key} draggableId={key + 1} index={key}>
                {(provided, snapshot) => (
                    indexes = null,
                    indexes = this.props.lines.map((o, i) => o.itId === line['itId'] ? i : '').filter(String),
                    isDuplicate.push(indexes.length > 1 ? true : false),

                    <tr key={key} ref={provided.innerRef} className={this.props.highlightRows && isDuplicate[key] === true ? 'isDuplicate' : ''}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}>
                        {!this.props.formData.hasOwnProperty('priority') &&
                            <td className={'borderNone left ' + (this.props.isPopup ? ' popup' : '')}>
                            {
                                key !== this.props.lines.length && !this.props.oneLine &&
                                <span>
                                <i className='fa fa-arrows' title="Reorder rows"></i></span>
                            }
                            </td>
                        }
                        <td className={'borderNone left'}>
                        {!this.props.oneLine &&
                            
                                <span onClick={() => this.props.remove(key)}>
                                    <i className='ion-android-close' title="Delete row"></i></span>
                            
                        }</td>
                        {this.props.copyLine &&
                            <td className={'left'}>
                                <span onClick={() => this.props.showCopyLine(key)}>
                                    <i className='fa fa-clone' title="Copy row"></i>
                                </span>
                                
                            </td>
                        }
                        <td className={'rowNumber'}>{key + 1}</td>                                
                        {
                            this.props.columns.map((column, colKey) => {
                                let highlightField = null;
                                if(column.id == "quantity"){
                                    let qty = line.stockAvailability
                                    if(hasBatch && hasBatch.hide === false && line.availableQuantity !== null){
                                        qty = line.availableQuantity;
                                    }

                                    if(this.props.highlightAvailability && Number(line.quantity) > Number(qty)){
                                        highlightField = 'highlightField';
                                    }
                                }
                                let fieldValue = line[column.id];
                                if ('populateFrom' in column && key !== this.props.lines.length - 1) {
                                    if (column.populateFrom === 'mainFormValue' && this.props.mainFormData) {
                                        fieldValue = column.id in this.props.mainFormData ? this.props.mainFormData[column.id] : column.defaultValue ? column.defaultValue : 0;
                                    }
                                    else {
                                        fieldValue = line[column.id] && !(column.populateFrom in line) ? line[column.id] : column.populateFrom in line ? line[column.populateFrom][column.id] : null;
                                    }
                                }
                                else if ('operation' in column) {
                                    fieldValue = this.props.calculateOperation(column.operation, line);
                                    this.props.change(this.props.fieldId+'['+key+'].'+column.id, fieldValue);
                                }

                                if (column.initialValue !== undefined && !fieldValue && key !== this.props.lines.length - 1) {
                                    fieldValue = column.initialValue;
                                }

                                if('highlightField' in column){
                                    if(line[column.highlightField] !== fieldValue && line[column.highlightField] != 0){
                                        highlightField = 'highlightField';
                                    }
                                }

                                   return <FormField 
                                        name={this.props.fieldId+'['+key+'].'+column.id}
                                        field={column}
                                        fields={this.props.columns}
                                        component={LineField}
                                        type={column.type}
                                        search={this.props.search}
                                        width={column.width}
                                        placeholder={column.placeholder}
                                        formData={this.props.formData}
                                        onDatalistChange = {(e, field) => this.onDatalistChange(e, field, key)}
                                        onNewOptions={(options, addValue) => this.onNewOptions(key, column.id, options, addValue)}
                                        onChangeOptions={(field, options, isLine) => this.onChangeOptions(field, options, isLine)}
                                        change={this.props.change}
                                        isDuplicate={isDuplicate[key]}
                                        highlightField={highlightField}
                                    />
                        })
                     }
                    {this.props.splitRow &&
                        <td className={'right'}>
                            <span onClick={() => this.props.split(key, this.props.makeZeroFields)}>
                                <i className='fa fa-clone' title="Split row"></i></span>
                        </td>
                    }
                    </tr>
                    )}
               </Draggable> 
        ))}  
    </React.Fragment>
    }
}
export default renderLines;
