import React, { useEffect, useState, useRef } from "react";
import Translate, { plainTranslate } from "../../../common/translate";
import { Card, Form, Input, Row, Col, Button } from "antd";
import client from "../../../../actions/client";
import SelectField from "../../../common/SelectField";
import showAToast from "../../../common/showAToast";
import { DatePicker } from "antd";
import moment from "moment";
import SimpleForm from "../../../redux-forms/form";

const GeneralInfoForm = (props) => {
  const ref = useRef(null);
  const [account, setAccount] = useState(props.account);
  const [loading, setLoading] = useState(false);
  const [warning, setWarn] = useState([]);

  useEffect(() => {
    if (props.account) {
      setAccount(props.account);
    }
  }, [account]);

  const onSuccessfulSave = () => {
    props.onAccountFetchData(props.db, props.account.id, false);
    props.openEditInfoHandle();
    if(props.gridPopup){
      props.updateGrid();
    }
  };

  return (
    <span>
      <SimpleForm
        formRoute={props.db + "/api/account/general-form/"}
        saveRoute={"/" + props.db + "/api/accounts/edit/" + props.account.id}
        popup={true}
        onCancel={props.openEditInfoHandle}
        match={{ params: { id: props.account.id } }}
        onSuccessfulSave={onSuccessfulSave}
        popup={true}
        isAdditionalId={true}
        form={"initializeFromState2"}
      />
    </span>
  );
};

export default GeneralInfoForm;
