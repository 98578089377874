import React from 'react';
import { connect } from 'react-redux';
import SlideModal from '../common/SlideModal';
import SimpleForm from "../forms/form";
//import { ACCOUNT_ADD_NEW_FILE } from "../../constants/actionTypes";
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import Translate, { plainTranslate } from '../common/translate';
import { Table, Dropdown, Upload, Menu, Select, Row, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import client from '../../actions/client';
import { getFolderOptions, getDocuments, uploadFiles, createFolder, loadUpload } from '../../actions/documentsActions';
//import { onTabsLoad } from '../../../../actions/actions';
import showAToast from '../common/showAToast';

const { Option } = Select;

class Documents extends React.Component {
    constructor() {
        super();
        this.state = {
            isDir: false,
            isNew: false,
            isFile: false,
            fileList: [],
            folder: 'all',
            pageFiles: 1,
            pageFolders: 1,
            uploadModal: false, 
            uploadLoading: false
        }

    }

    componentDidMount() {
        if (localStorage.storageToken && localStorage.storageUrl) {
            this.props.getDocuments('all', this.state.pageFiles, this.state.pageFolders);
        }
    }

    updateDir = (data) => {  
        this.props.createFolder(this.props.match.params.db, data['name']).then((response) => {
            if (response && response.error) {
                showAToast(response.error, 'baseError', 'error')
            } else {
                let result = {
                    'id': data['id'],
                    'type': 'fa fa-folder-open-o',
                    'date': moment(new Date()).format('DD MMM Y'),
                    'name': data['name'],
                    'nameURL': data['url'],
                    'delete': '"fa fa-trash-o"',
                    'description': response.id,
                    'deleteURL': '/' + this.props.match.params.db + '/api/directories/' + data['id'] + '/delete', 'table': "documentTable", 'secureId': data['id'],
                };
                client
                    .post(`/${this.props.match.params.db}/api/directories/${data['id']}`, { body: { description: response.id } })
                    .then(() => {
                        //this.props.onAddNewFile(result);
                        //this.updateTable();
                        this.props.onUploadFile();
                        this.setState({ isDir: false });
                    })
            }
        });
    }

    updateFile = (data) => {
        let result = {
            'id': data['id'],
            'directory': data['dir'],
            'directoryUrl': data['dirUrl'],
            'type': "fa fa-file-o",
            'date': moment(new Date()).format('DD MMM Y'),
            'name': data['name'],
            'nameURL': data['url'],
            'delete': '"fa fa-trash-o"',
            'deleteURL': '/' + this.props.match.params.db + '/api/files/' + data['id'] + '/delete', 'table': "documentTable", 'secureId': data['id'],
        };
        //this.props.onAddNewFile(result);
        //this.updateTable();
        this.props.onUploadFile();
        this.setState({ isFile: false });
    }

/*    updateTable = () => {
        this.props.onTabsLoad('/' + this.props.match.params.db + '/api/accounts/tabs/' + this.props.accountId, 1, false);
    }
*/
    handleUpload = () => {
        this.setState({uploadLoading: true});
        if (this.state.fileList) {
            this.props.loadUpload(true)
            var body = new FormData();
            this.state.fileList.map(file => (
                body.append('files[]', file.originFileObj)
            ))
            if (this.state.folder && this.state.folder !== 'all') {
                body.append('folder', this.state.folder)
            }
            body.append('tenant', this.props.match.params.db)
            this.props.uploadFiles(body).then((files) => {
                for (let i = 0; i < files.length; i++) {
                    let data = {
                        body: {
                            directoryAdditionalValue: this.state.folder,
                            directory: this.state.folder,
                            name: files[i].name,
                            url: files[i].full,
                            description: files[i].id
                        }
                    };
                    client
                        .post(`/${this.props.match.params.db}/api/files?projectId=${this.props.projectId}`, data)
                        .then((response) => {
                            this.setState({uploadLoading: false});
                            data.body['id'] = response.data.entity.id;
                            data.body['name'] = response.data.entity.name;
                            data.body['nameUrl'] = response.data.entity.url;
                            this.updateFile(data.body);
                            this.setState({ uploadModal: false });
                        })
                }
            });

            setTimeout(() => {
                this.setState({
                    fileList: []
                })
            }, 3000)
        }
    }

    handlePreview = (file) => {
        if (!file.url && !file.preview) {
            file.preview = URL.createObjectURL(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
        });
    }

    handleChange = ({ fileList }) => {
        this.setState({ fileList });
    }

    handleFolderChange = (value) => {
        this.setState({ folder: value });
    }

    dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
          onSuccess("ok");
        }, 0);
      };

    render() {
        const { fileList } = this.state;
        const uploadButton = (
            <div>
                <i className="fa fa-file-o" aria-hidden="true"></i>
                <div style={{ marginTop: 8 }}>{plainTranslate(this.props.settings.locale, 'New file')}</div>
            </div>
        );
        let menuOptions = [];
        menuOptions = (
            <Menu>
                <Menu.Item key='folder'>
                    <a to="#" onClick={() => this.setState({ isDir: true, isNew: 'undefined' })}>
                        <Translate locale={this.props.settings.locale} value="New folder" />
                    </a>
                </Menu.Item>
                <Menu.Item key='file'>
                    <a to="#" onClick={() => this.setState({ isFile: true, isNew: 'undefined' })}>
                        <Translate locale={this.props.settings.locale} value="Save image link" />
                    </a>
                </Menu.Item>
                <Menu.Item key='upload'>
                    <a to="#" onClick={() => this.setState({ uploadModal: true })}>
                        <Translate locale={this.props.settings.locale} value="Upload image" />
                    </a>
                </Menu.Item>
            </Menu>
        );
        return (
            <div>
                {
                    this.props.dataDocuments &&
                    <React.Fragment>
                        <div className="btn-round btn-blue">
                            <Dropdown overlay={menuOptions} placement="bottomCenter" >
                                <PlusOutlined />
                            </Dropdown>
                        </div>
                        <Table
                            rowKey={record => record.id}
                            columns={this.props.columnsDocuments}
                            dataSource={this.props.dataDocuments}
                            pagination={false}
                            rowClassName={"account-profile-tabTable"}
                            size="small"
                            loading={this.props.loading}
                        />
                    </React.Fragment>
                }
                {this.state.uploadModal &&
                    <SlideModal onClose={() => this.setState({ uploadModal: false })} title={plainTranslate(this.props.settings.locale, 'File upload')}>
                        <div id="reactForm">
                            <div class="row m-bot15 cmxform form-horizontal bucket-form ng-pristine ng-valid">
                                <div class="col-lg-12">
                                    <section class="panel">
                                    <div class="panel-body">
                                        <label style={{ display: 'block' }}><Translate locale={this.props.settings.locale} value="Directory" /></label>
                                        <Select className="account-profile-file-upload" style={{ width: '575.75px', marginBottom: '30px' }} onChange={this.handleFolderChange}>
                                            {this.props.documents && this.props.documents.documents && this.props.documents.documents.folders && this.props.documents.documents.folders.map((folder, key) => (
                                                <Option value={folder.id}>{folder.name}</Option>
                                            ))}
                                        </Select>
                                        <Row>
                                            <Upload
                                                listType="picture-card"
                                                fileList={fileList}
                                                onPreview={this.handlePreview}
                                                onChange={this.handleChange}
                                                multiple={true}
                                                style={{ display: "flex" }}
                                                customRequest={this.dummyRequest}
                                                accept="application/pdf, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv, text/plain, application/vnd.oasis.opendocument.presentation, application/vnd.oasis.opendocument.spreadsheet, application/vnd.oasis.opendocument.text,  image/png, image/jpeg, image/svg+xml, image/gif, image/vnd.microsoft.icon, application/json, text/html, text/css"
                                            >
                                                {fileList.length >= 8 ? null : uploadButton}
                                            </Upload>
                                            <Button
                                                type="button"
                                                className="button-outline grey pull-left"
                                                onClick={this.handleUpload}
                                                loading={this.state.uploadLoading}
                                                disabled={fileList.length === 0}
                                                style = {{marginLeft: '0'}}
                                            >
                                                {this.state.uploadLoading ? <Translate locale={this.props.settings.locale} value="Uploading" /> : <Translate locale={this.props.settings.locale} value="Start Upload" />}
                                            </Button>
                                        </Row>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </SlideModal>
                }
                {
                    this.state.isFile &&
                    <SlideModal onClose={() => this.setState({ isFile: false, isNew: false })} title={plainTranslate(this.props.settings.locale, 'Save file link')}>
                        <section className="newPanel">
                            <SimpleForm
                                formRoute={this.props.match.params.db + '/api/files/form'}
                                saveRoute={this.state.isNew !== 'undefined' ? this.props.match.params.db + '/api/files/edit' : this.props.match.params.db + '/api/files?projectId=' + this.props.projectId}
                                updateParent={(data) => this.updateFile(data)}
                                match={{ params: { id: 0 } }}
                                onCancel={() => this.setState({ isFile: false, isNew: false })}
                                popup={true}
                                isNew={true}
                            />
                        </section>
                    </SlideModal>
                }
                {
                    this.state.isDir &&
                    <SlideModal onClose={() => this.setState({ isDir: false, isNew: false })} title={plainTranslate(this.props.settings.locale, 'Folder')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <SimpleForm
                                        formRoute={'/' + this.props.match.params.db + '/api/directories/form'}
                                        saveRoute={this.state.isNew !== 'undefined' ? '/' + this.props.match.params.db + '/api/directories/edit' : '/' + this.props.match.params.db + '/api/directories?projectId=' + this.props.projectId}
                                        updateParent={(data) => this.updateDir(data)}
                                        match={{ params: { id: 0 } }}
                                        onCancel={() => this.setState({ isDir: false, isNew: false })}
                                        popup={true}
                                        isNew={true}
                                    />
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }
            </div>
        );
    }
}


const mapStateToProps = state => ({
    settings: state.settings,
    documents: state.documents
});
const mapDispatchToProps = dispatch => ({
    //onAddNewFile: (data) => dispatch({ type: ACCOUNT_ADD_NEW_FILE, payload: { dataFile: data } }),
    getFolderOptions: () => dispatch(getFolderOptions()),
    getDocuments: (type, pageFiles, pageFolders, id) => dispatch(getDocuments(type, pageFiles, pageFolders, id)),
    uploadFiles: (form) => dispatch(uploadFiles(form)),
    createFolder: (db, name, parentId) => dispatch(createFolder(db, name, parentId)),
    loadUpload: () => dispatch(loadUpload()),
    //onTabsLoad: (route, page, requiredTotal) => dispatch(onTabsLoad(route, page, requiredTotal))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Documents));
