import React from 'react';
import {connect} from 'react-redux';
import Translate, {plainTranslate} from '../../common/translate';

class InsertTemplate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            
        };
        
        this.invoice = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta http-equiv="Content-Type" content="charset=utf-8" />
    <link rel="stylesheet" href="style.css">
    {% if not styles %}
        <link rel="stylesheet" href="https://api1.composity.cloud/styles.css">
    {% endif %}

    <style>
        /* Custom Styles */
        {{ styles|raw }}

        .text-primary {
            /* color: #5391f3; */
            }
        .bg-primary {
            /* background-color: #5391f3; */
        }
        .border-primary {
            /* border-color: #5391f3; */
        }
    </style>
</head>
<header page="1" paper="A4" paper-orientation="portrait" paper-margin="2cm"></header>
<body>
    <div class="container">
        <!-- HEADER start -->
        <table class="document-top">
            <!-- LOGO start -->
            <tr>
                <td colspan="2" class="logo text-right">
                    <img src="https://composity.com/images/main_logo.png" alt="logo" />
                </td>
            </tr>
            <!-- LOGO end -->

            <!-- DOCUMEN HEADER start -->
            <tr class="document-header">
                <!-- DOCUMENT TITLE start -->
                <td class="document-title">
                    <h1>
                        {% if invoice.type == 'invoice' %}
                            {{ 'Invoice'|trans }}
                        {% elseif invoice.type == 'credit note' %}
                            {{ 'Credit Note'|trans }}
                        {% elseif invoice.type == 'debit note' %}
                            {{ 'Debit Note'|trans }}
                        {% else %}
                            {{ 'Undefined'|trans }}
                        {% endif %}
                    </h1>

                    <p class="text-muted">
                        {% if invoice.status == 'issued' %}{{ 'Original'|trans }}
                        {% elseif invoice.status == 'draft' %}{{'Draft'|trans }}
                        {% else %}<span style="color:red;">{{ 'Cancelled'|trans }}</span>
                        {% endif %}
                    </p>
                </td>
                <!-- DOCUMENT TITLE end -->

                <!-- DOCUMENT DETAILS start -->
                <td class="document-details">
                    <table>
                        <tr>
                            <td class="narrow">
                                <p class="label text-primary">{{'#'|trans}}</p>
                                <p class=" mb-10">{{ invoice.no }}</p>
                            </td>

                            <td class="narrow">
                                <p class="label text-primary">{{'Date'|trans}}</p>
                                <p class=" mb-10">
                                    {{ invoice.date|date("d.m.Y") }}
                                <!--
                                    {% set day = invoice.date|date('d') %}
                                    {% set month = invoice.date|date('F') %}
                                    {% set year = invoice.date|date('Y') %}
                                    {{ '%s %s %s'|format(day, month|trans, year) }}
                                -->
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td class="narrow">
                            {% if invoice.incoterm.code %}
                                <p class="label text-primary">{{'Incoterms'|trans}}</p>
                                <p>{{ invoice.incoterm.code }}</p>
                            {% else %}
                                <p class="label text-primary">{{'Amount due'|trans}}</p>
                                <p>{{ ((invoice.amountSum/invoice.currencyRate)|number_format(2, '.', '') + (invoice.Discount/invoice.currencyRate)|number_format(2, '.', '') + (invoice.vat/invoice.currencyRate)|number_format(2, '.', ''))|number_format(2, '.', ' ') }} {{ invoice.currency.code }}</p>
                            {% endif %}
                            </td>

                            <td class="narrow">
                                <p class="label text-primary">{{'Due date'|trans}}</p>
                                <p>
                                    {% if invoice.dueDate %}
                                        {{ invoice.dueDate|date("d.m.Y") }}
                                    {% else %}
                                        {{ invoice.date|date("d.m.Y") }}
                                    {% endif %}
                                </p>
                            </td>
                        </tr>
                    </table>
                </td>
                <!-- DOCUMENT DETAILS end -->
            </tr>
            <!-- DOCUMEN HEADER end -->

            <tr>
                <td colspan="2">
                    <!-- -->
                    <div style="height: 60px;"></div>
                </td>
            </tr>

            <!-- SUBHEADER start -->
            <tr class="document-subheader">
                <!-- COMPANY DATA start -->
                <td class="company-data">
                    <p class="label text-primary">{{ 'Customer'|trans }}</p>
                    <h2>{{ financialInformation.name }}</h2>
                    <p>
                        <!--<span class="text-muted">{{ 'Address'|trans }}: </span> -->
                        {{ financialInformation.address1 }}
                    </p>

                    <p>
                        {{ financialInformation.country }},
                        {{ financialInformation.city }}
                    </p>

                    {% if financialInformation.uic %}
                    <p class="nowrap">
                        <span class="text-muted">{{ 'Company ID'|trans }}: </span>
                        {{ financialInformation.uic }}
                    </p>
                    {% endif %}

                    {% if financialInformation.vat%}
                    <p class="nowrap">
                        <span class="text-muted">{{ 'VAT No'|trans }}: </span>
                        {{ financialInformation.vat }}
                    </p>
                    {% endif %}

                    {% if financialInformation.responsiblePerson %}
                    <p class="nowrap">
                        <span class="text-muted">{{ 'Responsible person'|trans }}: </span>
                        {{ financialInformation.responsiblePerson }}
                    </p>
                    {% endif %}
                </td>
                <!-- COMPANY DATA end -->

                <!-- PARTNER DATA start -->
                <td class="company-data">
                    <p class="label text-primary">{{ 'Supplier'|trans }}</p>
                    <h2>{{ vendorFinancialInformation.name }}</h2>
                    <p>
                        <!--<span class="text-muted">{{ 'Address'|trans }}: </span> -->
                        {{ vendorFinancialInformation.address1 }}
                    </p>

                    <p>
                        {{ vendorFinancialInformation.country }},
                        {{ vendorFinancialInformation.city }}
                    </p>

                    <p class="nowrap">
                        <span class="text-muted">{{ 'Company ID'|trans }}: </span>
                        {{ vendorFinancialInformation.uic }}
                    </p>

                    {% if vendorFinancialInformation.vat %}
                    <p class="nowrap">
                        <span class="text-muted">{{ 'VAT No'|trans }}: </span>
                        {{ vendorFinancialInformation.vat }}
                    </p>
                    {% endif %}

                    <p class="nowrap">
                        <span class="text-muted">{{ 'Responsible person'|trans }}: </span>
                        {{ vendorFinancialInformation.responsiblePerson }}
                    </p>
                </td>
                <!-- PARTNER DATA end -->
            </tr>
            <!-- SUBHEADER end -->
        </table>


        <!-- DOCUMENT DETAILS start -->
        <div class="document-lines">
            <div class="divider bg-primary mb-20"></div>

            <table>
                <thead>
                    <tr>
                      <th class="text-primary text-center"></th>
                      <th class="text-primary text-left">{{'Code' | trans }}</th>
                      <th class="text-primary text-left">{{'Title' | trans }}</th>
                      <th class="text-primary narrow text-right">{{'Qty' | trans }}</th>
                      <th class="text-primary narrow text-right">{{'Unit Price' | trans }}</th>
                      <!--
                        <th class="text-primary narrow text-right">{{'Discount' | trans }}</th>
                        <th class="text-primary narrow text-right">{{'Amount' | trans }}</th>
                        <th class="text-primary narrow text-right">{{'Tax Amount' | trans }}</th>
                      -->
                      <th class="text-primary narrow text-right">{{'Total Amount' | trans }}</th>
                    </tr>
                </thead>

                <tbody>
                    {% for line in invoice.lines %}
                    {%if not line.deleted%}
                    {%set no=no+1%}
                    <tr {% if loop.index%2 %}class="odd"{% endif %}>
                        <td class="narrow text-center">{{ no }}</td>
                        <td class="narrow text-left">{{ line.item.no }}</td>
                        <td class="text-desc text-left">
                            {% if line.description %}
                                {{ line.description | trans}}
                            {% else %}
                                {{ line.item.title | trans}}
                            {% endif %}
                            <p class="comment text-muted">
                                {{ line.comment }}
                            </p>
                        </td>
                        <td class="narrow text-right">{{ line.quantity }} {{line.measure.name | trans}}</td>
                        <td class="narrow text-right">{{ line.price|number_format(2, '.', '') }} {{line.currency.code | trans}}</td>
                        <td class="narrow text-right">{{ (line.baseAmount/line.currencyRate)|number_format(2, '.', '') }} {{line.currency.code | trans}} </td>
                        <!--
                        <td class="narrow text-right">{{ line.discountPercent|number_format(2, '.', '') }}</td>

                        <td class="narrow text-right">{{ line.amount|number_format(2, '.', '') }}</td>
                        <td class="narrow text-right">{{ line.vat|number_format(2, '.', '') }}</td>
                        <td class="narrow text-right">
                            {{ line.quantity*line.price|number_format(2, '.', '') }} {{line.currency.code | trans}}
                        </td>
                        -->

                    </tr>
                    {%endif%}
                    {% endfor %}
                </tbody>
            </table>
        </div>
        <!-- DOCUMENT DETAILS end -->

        <!-- DOCUMENT SUMMARY start -->
        <table class="document-summary-holder">
            <tr>
                <td style="width: 50%;">
                    <!-- SPACER: DO NOT DELETE -->
                </td>

                <td>
                    <table class="document-summary">
                        <!-- {{ 'Terms and conditions'|trans }} -->
                        <tr>
                            <td>{{ 'Tax Base'|trans }}:</td>
                            <td class="text-right">{{ (invoice.amountSum/invoice.currencyRate)|number_format(2, '.', ' ') }} {{invoice.currency.code }}</td>
                        </tr>

                        {% if invoice.Discount %}
                        <tr>
                            <td>{{ 'Discount Amount'|trans }}:</td>
                            <td class="text-right">{{ (invoice.Discount/invoice.currencyRate)|number_format(2, '.', ' ') }} {{invoice.currency.code }}</td>
                        </tr>
                        {% endif %}

                        <tr>
                            <td>{{ 'Tax'|trans }} {{ invoice.vatPercent|number_format(0, '.', ' ') }}% :</td>
                            <td class="text-right">{{ (invoice.vat/invoice.currencyRate)|number_format(2, '.', ' ') }} {{invoice.currency.code }}</td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <div class="divider bg-primary"></div>
                            </td>
                        </tr>

                        <tr class="text-bold">
                            <td>{{ 'Total'|trans }}:</td>
                            <td class="text-right">
                                {{ ((invoice.amountSum/invoice.currencyRate)|number_format(2, '.', '') + (invoice.Discount/invoice.currencyRate)|number_format(2, '.', '') + (invoice.vat/invoice.currencyRate)|number_format(2, '.', ''))|number_format(2, '.', ' ') }} {{invoice.currency.code }}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" class="inwords text-muted text-right">
                              {% if language == "en" %}
                                ( {{ widget_numbers_in_words_en(((invoice.amountSum/invoice.currencyRate)|number_format(2, '.', '') + (invoice.Discount/invoice.currencyRate)|number_format(2, '.', '') + (invoice.vat/invoice.currencyRate)|number_format(2, '.', ''))|number_format(2, '.', '')) }} )
                              {% elseif language == "bg" %}
                                  ( {{ widget_numbers_in_words(((invoice.amountSum/invoice.currencyRate)|number_format(2, '.', '') + (invoice.Discount/invoice.currencyRate)|number_format(2, '.', '') + (invoice.vat/invoice.currencyRate)|number_format(2, '.', ''))|number_format(2, '.', '')) }} )
                              {% endif %}
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
        <!-- DOCUMENT SUMMARY end -->

        <!-- DOCUMENT FOOTER start -->
        <table class="document-footer">
            <tr>
                <td class="col-left">
                    <p class="text-muted">{{ 'Payment Method'|trans }}:</p>
                    <p>
                    {% if invoice.paymentMethod.name %}
                        {{ invoice.paymentMethod.parent.name |trans  }} › {{ invoice.paymentMethod.name |trans }}
                    {% elseif invoice.paymentMethod.parent.name %}
                        {{ invoice.paymentMethod.parent.name |trans  }}
                    {% endif %}
                    <br />
                    {% if invoice.paymentMethod.name %}
                        {{  invoice.paymentMethod.description|raw}}
                    {% endif %}
                    </p>

                    {% if additionalData['custom_field'] %}
                    <div class="mt-20">
                    <p class="text-muted">{{'Terms'|trans}}</p>
                    <p>{{additionalData['custom_field']}}</p>
                    </div>
                    {% endif %}
                </td>

                <td class="col-right">
                    <p>
                        <b class="text-muted">{{'Billing clerk'|trans}}:</b> {{ invoice.createdBy.names|trans|default('-') }}
                    </p>
                    <p>
                        <b class="text-muted">{{'Taxation date'|trans}}:</b> {{ invoice.date|date("d.m.Y") }}
                    </p>
                </td>
            </tr>

            {% if invoice.comment %}
            <tr>
                <td colspan="2">
                    <p class="text-muted mt-20">{{'Notes'|trans}}</p>
                    <p>{{ invoice.comment }}</p>
                </td>
            {% endif %}
            </tr>
        </table>
        <!-- DOCUMENT FOOTER end -->

        <!-- COMPOSITY FOOTER start -->
        <div class="composity-footer mt-20" >
            <p class="text-right">{{ 'Composity Business Software'|trans }}</p>
        </div>
        <!-- COMPOSITY FOOTER end -->
    </div>
</body>
</html>
`;
    this.receipt = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta http-equiv="Content-Type" content="charset=utf-8" />
    <link rel="stylesheet" href="style.css">
    {% if not styles %}
      <link rel="stylesheet" href="https://api1.composity.cloud/styles.css">
    {% endif %}

    <style>
        /* Custom Styles */
        {{ styles|raw }}

        .text-primary {
            /* color: #5391f3; */
            }
        .bg-primary {
            /* background-color: #5391f3; */
        }
        .border-primary {
            /* border-color: #5391f3; */
        }
    </style>
</head>
<header page="1" paper="A4" paper-orientation="portrait" paper-margin="2cm"></header>
<body>
    <div class="container">
        <!-- HEADER start -->
        <table class="document-top">
            <!-- LOGO start -->
            <tr>
                <td colspan="2" class="logo text-right">
                    <img src="https://composity.com/images/main_logo.png" alt="logo" />
                </td>
            </tr>
            <!-- LOGO end -->

            <!-- DOCUMEN HEADER start -->
            <tr class="document-header">
                <!-- DOCUMENT TITLE start -->
                <td class="document-title">
                    <h1>
                        {% if receipt.form.type  == 'purchase' %}
                            {{ 'Purchase receipt'|trans }}
                        {% elseif receipt.form.type  == 'release' %}
                            {{ 'Release receipt'|trans }}
                        {% elseif receipt.form.type  == 'transfer' %}
                          {{ 'Transfer order'|trans }}
                        {% elseif receipt.form.type == 'revision part' %}
                            {{ 'Revision order part'|trans }}
                        {% elseif receipt.form.type == 'revision full' %}
                            {{ 'Revision order full'|trans }}
                        {% endif %}
                    </h1>

                    <p class="text-muted">
                        {{ 'Stage'|trans }}: {{ receipt.stage.name |trans }}
                    </p>
                </td>
                <!-- DOCUMENT TITLE end -->

                <!-- DOCUMENT DETAILS start -->
                <td class="document-details">
                    <table>
                        <tr>
                            <td class="narrow">
                                <p class="label text-primary">{{'#'|trans}}</p>
                                <p class=" mb-10">{{ receipt.documentNo }}</p>
                            </td>

                            <td class="narrow">
                                <p class="label text-primary">{{'Date'|trans}}</p>
                                <p class=" mb-10">
                                    {{ receipt.date|date("d.m.Y") }}
                                </p>
                            </td>
                        </tr>

                        {% if sale.documentNo %}
                        <tr>
                            <td class="narrow">
                                <p class="label text-primary">{{'From sale #'|trans}}</p>
                                <p class=" mb-10">{{ sale.documentNo }}</p>
                            </td>

                            <td class="narrow">
                                <p class="label text-primary">{{'Sale date'|trans}}</p>
                                <p class=" mb-10">
                                    {{ sale.documentDate|date("d.m.Y") }}
                                </p>
                            </td>
                        </tr>
                        {% endif %}
                    </table>
                </td>
                <!-- DOCUMENT DETAILS end -->
            </tr>
            <!-- DOCUMEN HEADER end -->

            <tr>
                <td colspan="2">
                    <!-- -->
                    <div style="height: 60px;"></div>
                </td>
            </tr>

            <!-- SUBHEADER start -->
            <tr class="document-subheader">
                <!-- COMPANY DATA start -->
                <td class="company-data">
                    <p class="label text-primary">
                        {% if receipt.form.type  == 'purchase' %}
                            {{ 'Supplier'|trans }}
                        {% elseif receipt.form.type  == 'release' %}
                            {{ 'Client'|trans }}
                        {% elseif receipt.form.type  == 'transfer' %}
                            {{ 'Location From'|trans }}
                        {% elseif receipt.form.type  == 'revision full' or  receipt.form.type  == 'revision part' %}
                            {{ 'Location'|trans }}
                        {% endif %}
                    </p>

                    <!-- purchase or release start -->
                    {% if receipt.form.type  == 'purchase' or  receipt.form.type  == 'release' %}
                        <h2>{{ financialInformation.name }}</h2>
                        <p>
                            {{ financialInformation.address1 }}
                        </p>

                        <p>
                            {{ financialInformation.country }},
                            {{ financialInformation.city }}
                        </p>

                        {% if financialInformation.uic %}
                        <p class="nowrap">
                            <span class="text-muted">{{ 'Company ID'|trans }}: </span>
                            {{ financialInformation.uic }}
                        </p>
                        {% endif %}

                        {% if financialInformation.vat%}
                        <p class="nowrap">
                            <span class="text-muted">{{ 'VAT No'|trans }}: </span>
                            {{ financialInformation.vat }}
                        </p>
                        {% endif %}

                        {% if financialInformation.responsiblePerson %}
                        <p class="nowrap">
                            <span class="text-muted">{{ 'Responsible person'|trans }}: </span>
                            {{ financialInformation.responsiblePerson }}
                        </p>
                        {% endif %}

                    {% endif %}
                    <!-- purchase or release end -->

                    <!-- transfer start -->
                    {% if receipt.form.type  == 'transfer' %}
                        <h2>{{ receipt.parent.location.name }}</h2>
                    {% endif %}
                    <!-- transfer end -->

                    <!-- transfer start -->
                    {% if receipt.form.type  == 'revision full' or  receipt.form.type  == 'revision part' %}
                        <h2>{{ receipt.location.name }}</h2>

                    {% endif %}
                    <!-- transfer end -->
                </td>
                <!-- COMPANY DATA end -->

                <!-- PARTNER DATA start -->
                <td class="company-data">
                    <p class="label text-primary">
                        {% if receipt.form.type  == 'purchase' %}
                            {{ 'Client'|trans }}
                        {% elseif receipt.form.type  == 'release' %}
                            {{ 'Supplier'|trans }}
                        {% elseif receipt.form.type  == 'transfer' %}
                            {{ 'Location To'|trans }}
                        {% elseif receipt.form.type  == 'revision full' or  receipt.form.type  == 'revision part' %}
                            ..
                        {% endif %}
                    </p>

                    <!-- purchase or release start -->
                    {% if receipt.form.type  == 'purchase' or  receipt.form.type  == 'release' %}
                        <h2>{{ vendorFinancialInformation.name }}</h2>
                        <p>
                            {{ vendorFinancialInformation.address1 }}
                        </p>

                        <p>
                            {{ vendorFinancialInformation.country }},
                            {{ vendorFinancialInformation.city }}
                        </p>

                        <p class="nowrap">
                            <span class="text-muted">{{ 'Company ID'|trans }}: </span>
                            {{ vendorFinancialInformation.uic }}
                        </p>

                        {% if vendorFinancialInformation.vat %}
                        <p class="nowrap">
                            <span class="text-muted">{{ 'VAT No'|trans }}: </span>
                            {{ vendorFinancialInformation.vat }}
                        </p>
                        {% endif %}

                        <p class="nowrap">
                            <span class="text-muted">{{ 'Responsible person'|trans }}: </span>
                            {{ vendorFinancialInformation.responsiblePerson }}
                        </p>

                    {% endif %}
                    <!-- purchase or release end -->

                    <!-- transfer start -->
                    {% if receipt.form.type  == 'transfer' %}
                        <h2>{{ receipt.location.name }}</h2>
                    {% endif %}
                    <!-- transfer end -->

                    <!-- transfer start: EMPTY for now!!!! --
                    {% if receipt.form.type  == 'revision full' or  receipt.form.type  == 'revision part' %}
                    {% endif %}
                    -- transfer end -->
                </td>
                <!-- PARTNER DATA end -->
            </tr>
            <!-- SUBHEADER end -->
        </table>


        <!-- DOCUMENT DETAILS start -->
        <div class="document-lines">
            <div class="divider bg-primary mb-20"></div>

            <!-- PURCHASE or RELEASE start -->
            {% if receipt.form.type  == 'purchase' or  receipt.form.type  == 'release' %}
            <table>
                <thead>
                    <tr>
                      <th class="text-primary text-center">{{'#' |trans}}</th>
                      <th class="text-primary text-left">{{'Code' | trans }}</th>
                      <th class="text-primary text-left">{{'Name' | trans }}</th>
                      <th class="text-primary narrow text-right">{{'Qty' | trans }}</th>
                      <th class="text-primary narrow text-right">{{'Unit Price' | trans }}</th>
                      <th class="text-primary narrow text-right">{{ 'Currency'|trans }}</th>
                      <th class="text-primary narrow text-right">{{ 'Currency rate'|trans }}</th>
                      <th class="text-primary narrow text-right">{{'Amount' | trans }}</th>
                    </tr>
                </thead>

                <tbody>
                    {% for line in receipt.lines %}
                    {%if not line.deleted%}
                    {%set no=no+1%}
                    <tr {% if loop.index%2 %}class="odd"{% endif %}>
                        <td class="narrow text-center">{{ no }}</td>
                        <td class="narrow text-left">{{ line.item.no }}</td>
                        <td class="text-desc text-left">{{ line.itemDescription |trans  }}</td>
                        <td class="narrow text-right">{{ line.itemQuantity|abs|number_format(0, '.', '') }}</td>
                        <td class="narrow text-right">{{ (line.unitPriceOriginal)|number_format(2, '.', '') }}</td>
                        <td class="narrow text-right">{{ line.currencyOriginal.code }}</td>
                        <td class="narrow text-right">{{ line.currencyRate|number_format(5, '.', '') }}</td>
                        <td class="narrow text-right">
                            {{ ((line.unitPrice)*(line.itemQuantity))|abs|number_format(2, '.', '') }}
                        </td>
                    </tr>
                    {%endif%}
                    {% endfor %}
                </tbody>
            </table>
            {% endif %}
            <!-- PURCHASE or RELEASE end -->

            <!-- TRANSFER or REVISION start -->
            {% if receipt.form.type  == 'transfer' or receipt.form.type  == 'revision full' or  receipt.form.type  == 'revision part' %}
            <table>
                <thead>
                    <tr>
                      <th class="text-primary text-center">{{'#' |trans}}</th>
                      <th class="text-primary text-left">{{ 'Items'|trans }}</th>
                      <th class="text-primary text-left">{{ 'Description'|trans }}</th>
                      <th class="text-primary narrow text-right">{{ 'Qty'|trans }}</th>
                    </tr>
                </thead>

                <tbody>
                    {% for line in receipt.lines %}
                        {%if not line.deleted%}
                            {%set no=no+1%}
                            <tr {% if loop.index%2 %}class="odd"{% endif %}>
                                <td class="narrow text-center">{{ no }}</td>
                                <td class="narrow text-left">{{ line.item.no }}</td>
                                <td class="text-desc text-left">{{ line.itemDescription |trans }}</td>
                                <td class="narrow text-right">{{ line.itemQuantity|abs|number_format(0, '.', '') }}</td>
                            </tr>
                        {%endif%}
                    {% endfor %}
                </tbody>
            </table>
            {% endif %}
            <!-- TRANSFER or REVISION end -->
        </div>
        <!-- DOCUMENT DETAILS end -->

        <!-- DOCUMENT SUMMARY start -->
        {% if receipt.form.type  == 'purchase' or  receipt.form.type  == 'release' %}
        <table class="document-summary-holder">
            <tr>
                <td style="width: 50%;">
                    <!-- SPACER: DO NOT DELETE -->
                </td>

                <td>
                    <table class="document-summary">
                        <tr>
                            <td>{{ 'Tax Base'|trans }}:</td>
                            <td class="text-right">{{ receipt.amountBeforeTax|abs|number_format(2, '.', ' ') }} {{defaultCurrency.code}}</td>
                        </tr>

                        <tr>
                            <td>{{ 'Tax'|trans }}:</td>
                            <td class="text-right">{{ receipt.amountTax|abs|number_format(2, '.', ' ') }} {{defaultCurrency.code}}</td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <div class="divider bg-primary"></div>
                            </td>
                        </tr>

                        <tr class="text-bold">
                            <td>{{ 'Total'|trans }}:</td>
                            <td class="text-right">
                                {{ (receipt.amountBeforeTax|abs|number_format(2, '.', '') + receipt.amountTax|abs|number_format(2, '.', ''))|number_format(2, '.', ' ') }} {{defaultCurrency.code}}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" class="inwords text-muted text-right">
                              {% if language == "en" %}
                                ( {{ widget_numbers_in_words_en((receipt.amountBeforeTax|abs|number_format(2, '.', '') + receipt.amountTax|abs|number_format(2, '.', ''))) }} )
                              {% elseif language == "bg" %}
                                ( {{ widget_numbers_in_words((receipt.amountBeforeTax|abs|number_format(2, '.', '') + receipt.amountTax|abs|number_format(2, '.', ''))) }} )
                              {% endif %}
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
        {% endif %}
        <!-- DOCUMENT SUMMARY end -->

        <!-- DOCUMENT FOOTER start -->
        <table class="document-footer">
            <tr>
                <td class="col-left">
                    {% if receipt.form.type  == 'release' %}
                        <p><b class="text-muted">{{'Recipient'|trans}}:</b> {{ financialInformation.name }}</p>
                        <p><b class="text-muted">{{'Signature'|trans}}:</b> ..................................</p>

                    {% elseif receipt.form.type  == 'purchase' %}
                        {% if receipt.documentRefNo %}
                            <p><b class="text-muted">{{'Document ref. No'|trans}}:</b> {{ receipt.documentRefNo }}</p>
                        {% endif %}

                        {% if receipt.documentRefDate %}
                            <p class="text-muted">{{'Docmument ref. date'|trans}}:</p>
                            <p>{{ receipt.documentRefDate|date("d.m.Y") }}</p>
                        {% endif %}
                    {% endif %}
                </td>

                <td class="col-right">
                    <p>
                        <b class="text-muted">{{ 'Issued by'|trans }}:</b> {{ receipt.createdBy.names|default('-') }}
                    </p>
                    <p>
                        <b class="text-muted">{{ 'Date'|trans }}:</b> {{ receipt.date|date("d.m.Y") }}
                    </p>
                </td>
            </tr>

            {% if receipt.comment %}
            <tr>
                <td colspan="2">
                    <p class="text-muted mt-20">{{'Notes'|trans}}</p>
                    <p>{{ receipt.comment }}</p>
                </td>
            {% endif %}
            </tr>
        </table>
        <!-- DOCUMENT FOOTER end -->

        <!-- COMPOSITY FOOTER start -->
        <div class="composity-footer mt-20" >
            <p class="text-right">{{ 'Composity Business Software'|trans }}</p>
        </div>
        <!-- COMPOSITY FOOTER end -->
    </div>
</body>
</html>`;
    this.purchase =`<!DOCTYPE HTML>
<html>
    <head>
        <meta http-equiv="Content-Type" content="charset=utf-8" />
        <link rel="stylesheet" href="{{ asset('bundles/uiweb/css/ionicons.css') }}" media="screen" type="text/css">        
        <style>
            * {font-family: "Times New Roman";font-size:17px}
            .invoice-title{width:48%;padding:0}
            .invoice-title > h1{font-size:32px; text-transform:uppercase; color:#000; margin:0;font-weight:400;line-height:34px;padding:0px}
            .invoice-title > p{font-weight:400; margin:10px 0; font-size:21px; text-transform:uppercase; color:#ccc}
            .invoice{width:48%;font-size:28px;letter-spacing:1px;font-weight:400;text-align:left}
            .first{margin:0px}                
            .first > table tr > td, .last > table tr > td{color:#666; line-height:25px;background:#f5f5f5; padding:0 10px}
            #details{border:1px solid #ddd;border-bottom:0px}
            #details > thead > tr{background:#dedede}
            #details > thead > tr > th{text-align: left; font-weight:400; line-height:25px; border-style:solid;border-width:0 1px 0 0px; border-color:#fff; padding:0 10px; font-size:14px}
            #details > tbody > tr > td{padding:0 10px; font-weight:400; line-height:25px; border-style:solid;border-width:0 1px 1px 0; border-color:#ddd #fff #ddd #fff}
            #details > tbody > tr > td.red, #total > tbody > tr > td.red{color:#ed1b2f}
            #details > tbody > tr > td.price, #total > tbody > tr > td.price{text-align:right}
            #total{background:#f5f5f5; border:0px}
            #total > tbody > tr > td{padding:0 10px; font-weight:400; line-height:25px; border:0}
            .footer > span{font-style:normal;font-size:17px;line-height:25px;margin:0 5px;color:#444}
            .footer > span > strong{font-size:19px}
            .footer{text-align:right}
        </style>
    </head>
    <body style="width:1024px; margin:0 auto;" >
        <table width="100%" border="0">
            <thead>
                <tr>
                    <th align="left" width="45%">
                    </th>
                    <th align="right" width="45%">   
                        <img src="{{ share_image(company.logo, null, null, db) }}" height="120px"/>
                    </th>
                </tr> 
            </thead>
        </table>
        <table width="100%" border="0">
            <thead>
                <tr>
                    <th class="invoice-title" align="left">
                        <h1 style="">{{ 'Purchase #'|trans }}</h1>
                    </th>
                    <th class="invoice" style="">
                        {{ purchase.documentNo }} <br>
                        {{ 'Date'|trans }}: 
                        {% set day = purchase.date|date('d') %}
                        {% set month = purchase.date|date('F') %}
                        {% set year = purchase.date|date('Y') %}
                        {{ '%s %s %s'|format(day, month|trans, year) }}
                    </th>
                </tr>
            </thead>
        </table>
        <div class="body">
            <table width="100%" cellspacing="0" cellpadding="0" border="0">
                <tbody>
                    <tr>
                        <td colspan="2">
                            <table width="100%" cellspacing="0" cellpadding="0" id="info">
                                <thead>
                                    <tr>
                                        <th width="40%" align="center" class="first"></th>
                                        <th width="40%" align="center" class="last"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="last">
                                        <td class="first" style="">
                                            <table style="padding:5px 2.5% 15px 0" width="97.5%" cellspacing="0" cellpadding="0">
                                                <tr>
                                                    <td style="background: #f5f5f5;border-bottom:2px solid #fff;" valign="middle" width="50"><span style="font-size: 20px;padding:5px 50px 5px 5px;background:#f5f5f5; line-height:35px;">{{ 'Customer'|trans }}</span></td>
                                                    <td style="background: #fff;" valign="middle"></td>
                                                    <td style="background: #fff;" valign="middle"></td>
                                                </tr>
                                                <tr>
                                                    <td colspan="3" valign="middle"><strong style="color:#000; line-height:31px;font-size:28px;height:65px;">{{ financialInformation.name }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle">{{ 'Address'|trans }}:</td>
                                                    <td colspan="2" valign="middle"><strong>{{ financialInformation.city }}</strong></td>
                                                </tr>   
                                                <tr>
                                                    <td valign="middle"></td>
                                                    <td colspan="2" valign="top" style="height:65px;"><strong>{{ financialInformation.address1 }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle">{{ 'Company ID'|trans }}:</td>
                                                    <td colspan="2" valign="middle"><strong>{{ financialInformation.uic }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle">{{ 'VAT No'|trans }}:</td>
                                                    <td colspan="2" valign="middle"><strong>{{ financialInformation.vat }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td style="padding-bottom:25px" valign="middle">{{ 'Responsible person'|trans }}:</td>
                                                    <td colspan="2" style="padding-bottom:25px; line-height:31px; font-size:24px; height:65px;" valign="middle"><strong>{{ financialInformation.responsiblePerson }}</strong></td>
                                                </tr>
                                            </table>
                                        </td>
                                        <td class="last">
                                            <table style="padding:5px 2.5% 15px 0" width="97.5%" cellspacing="0" cellpadding="0">
                                                <tr>
                                                    <td style="background: #f5f5f5;border-bottom:2px solid #fff;" valign="middle" ><span style="font-size: 20px;padding:5px 50px 5px 5px;background:#f5f5f5; line-height:35px;">{{ 'Supplier'|trans }}</span></td>
                                                    <td style="background: #fff;" valign="middle"></td>
                                                    <td style="background: #fff;" valign="middle"></td>
                                                </tr>
                                                <tr>
                                                    <td colspan="3" valign="middle"><strong style="color:#000; line-height:31px;font-size:28px;height:65px">{{ vendorFinancialInformation.name }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle">{{ 'Address'|trans }}:</td>
                                                    <td colspan="2" style="width:100%;" valign="middle"><strong>{{ vendorFinancialInformation.city }}</strong></td>
                                                </tr>   
                                                <tr>
                                                    <td valign="middle"></td>
                                                    <td colspan="2" valign="top" style="height:65px;"><strong>{{ vendorFinancialInformation.address1 }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle">{{ 'Company ID'|trans }}:</td>
                                                    <td colspan="2" valign="middle"><strong>{{ vendorFinancialInformation.uic }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle">{{ 'VAT No'|trans }}:</td>
                                                    <td colspan="2" valign="middle"><strong>{{ vendorFinancialInformation.vat }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td style="padding-bottom:25px" valign="middle">{{ 'Responsible person'|trans }}:</td>
                                                    <td colspan="2" style="padding-bottom:25px; line-height:31px; font-size:24px; height:65px;" valign="middle"><strong>{{ vendorFinancialInformation.responsiblePerson }}</strong></td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
            <br>
            <br>
            <br>
            <table width="100%" cellspacing="0" cellpadding="0" id="details">
                <thead>
                    <tr>
                        <th width="5%" style="border-width:0 1px 0 0;">No</th>
                        <th width="50%" style="">{{ 'Description'|trans }}</th>
                        <th width="5%">{{ 'Qty'|trans }}</th>
                        <th width="10%">{{ 'Unit price'|trans }}</th>
                        <th width="10%">{{ 'Amount'|trans }}</th>
                        <th width="10%">{{ 'VAT'|trans }}</th>
                        <th width="10%" style="border-width:0 0 0 0px;">{{ 'Total'|trans }}</th>
                    </tr>
                </thead>
                <tbody>
                    {% for line in purchase.lines %}
                        <tr {% if loop.index%2 %} class="odd"{% endif %} style="background:#f5f5f5;">
                            <td class="red price">{{ loop.index }}</td>
                            <td>{{ line.description }}</td>
                            <td class="red price">{{ line.quantity|number_format }}</td>
                            <td class="red price">{{ line.unitPrice|number_format(2, '.', '') }}</td>
                            <td class="red price">{{ line.baseAmount|number_format(2, '.', '') }}</td>
                            <td class="red price">{{ line.vatAmount|number_format(2, '.', '') }}</td>
                            <td class="red price">{{ line.totalAmount|number_format(2, '.', '') }}</td>
                        </tr>
                    {% endfor %}
                </tbody>
            </table>
            <br>
            <br>
            <table  width="100%" cellspacing="0" cellpadding="0" id="total" style="border:0">
                <tbody>
                    <tr>
                        <td width="60%" style="background:#fff;border:0"> </td>
                        <td width="20%" style="">{{ 'Tax Base'|trans }}:</td>
                        <td width="20%" align="right" class="price red">{{ ((purchase.amountSubtotal))|number_format(2, '.', ' ') }}</td>
                    </tr>
                    <tr>
                        <td width="60%" style="background:#fff;border:0"></td>
                        <td style="">{{ 'VAT'|trans }}:</td>
                        <td align="right" class="price red">{{ purchase.vat|number_format(2, '.', ' ') }} </td>
                    </tr>
                    <tr>
                        <td style="background:#fff;border:0"></td>
                        <td style="background:#eee"><strong>{{ 'Total'|trans }}:</strong></td>
                        <td align="right" style="background:#eee" class="price red"><strong>{{ ((purchase.amountTotal))|number_format(2, '.', ' ') }}</strong></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <br><br>
        <div class="footer">
        </div>
    </body>
</html>`;
    this.doc = `<p>Some text here</p>`;
    this.sales = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta http-equiv="Content-Type" content="charset=utf-8" />
    <link rel="stylesheet" href="style.css">
    {% if not styles %}
        <link rel="stylesheet" href="https://api1.composity.cloud/styles.css">
    {% endif %}

    <style>
        /* Custom Styles */
        {{ styles|raw }}

        .text-primary {
            /* color: #5391f3; */
            }
        .bg-primary {
            /* background-color: #5391f3; */
        }
        .border-primary {
            /* border-color: #5391f3; */
        }
    </style>
</head>
<header page="1" paper="A4" paper-orientation="portrait" paper-margin="2cm"></header>
<body>
    <div class="container">
        <!-- HEADER start -->
        <table class="document-top">
            <!-- LOGO start -->
            <tr>
                <td colspan="2" class="logo text-right">
                    <img src="https://composity.com/images/main_logo.png" alt="logo" />
                </td>
            </tr>
            <!-- LOGO end -->

            <!-- DOCUMEN HEADER start -->
            <tr class="document-header">
                <!-- DOCUMENT TITLE start -->
                <td class="document-title">
                    <h1>{{ 'Sale'|trans }}</h1>
                </td>
                <!-- DOCUMENT TITLE end -->

                <!-- DOCUMENT DETAILS start -->
                <td class="document-details">
                    <table>
                        <tr>
                            <td class="narrow">
                                <p class="label text-primary">{{'#'|trans}}</p>
                                <p class=" mb-10">{{ sales.documentNo }}</p>
                            </td>

                            <td class="narrow">
                                <p class="label text-primary">{{'Date'|trans}}</p>
                                <p class=" mb-10">
                                    {{ sales.documentDate|date("d.m.Y") }}
                                <!--
                                    {% set day = sales.date|date('d') %}
                                    {% set month = sales.date|date('F') %}
                                    {% set year = sales.date|date('Y') %}
                                    {{ '%s %s %s'|format(day, month|trans, year) }}
                                -->
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td class="narrow">
                            {% if sales.incoterm.code %}
                                <p class="label text-primary">{{'Incoterms'|trans}}</p>
                                <p>{{ sales.incoterm.code }}</p>
                            {% else %}
                                <p class="label text-primary">{{'Amount due'|trans}}</p>
                                <p> {{ ((sales.amount/sales.currencyRate)|number_format(2, '.', '') + (sales.Discount/sales.currencyRate)|number_format(2, '.', '') + (sales.amountTax/sales.currencyRate)|number_format(2, '.', ''))|number_format(2, '.', ' ') }} {{ sales.currency.code }}</p>
                            {% endif %}
                            </td>

                            <td class="narrow">
                                <p class="label text-primary">{{'Due date'|trans}}</p>
                                <p>
                                    {% if sales.dueDate %}
                                        {{ sales.dueDate|date("d.m.Y") }}
                                    {% else %}
                                        {{ sales.documentDate|date("d.m.Y") }}
                                    {% endif %}
                                </p>
                            </td>
                        </tr>
                    </table>
                </td>
                <!-- DOCUMENT DETAILS end -->
            </tr>
            <!-- DOCUMEN HEADER end -->

            <tr>
                <td colspan="2">
                    <!-- -->
                    <div style="height: 60px;"></div>
                </td>
            </tr>

            <!-- SUBHEADER start -->
            <tr class="document-subheader">
                <!-- COMPANY DATA start -->
                <td class="company-data">
                    <p class="label text-primary">{{ 'Customer'|trans }}</p>
                    <h2>{{ financialInformation.name }}</h2>
                    <p>
                        <!--<span class="text-muted">{{ 'Address'|trans }}: </span> -->
                        {{ financialInformation.address1 }}
                    </p>

                    <p>
                        {{ financialInformation.country }},
                        {{ financialInformation.city }}
                    </p>

                    {% if financialInformation.uic %}
                    <p class="nowrap">
                        <span class="text-muted">{{ 'Company ID'|trans }}: </span>
                        {{ financialInformation.uic }}
                    </p>
                    {% endif %}

                    {% if financialInformation.vat%}
                    <p class="nowrap">
                        <span class="text-muted">{{ 'VAT No'|trans }}: </span>
                        {{ financialInformation.vat }}
                    </p>
                    {% endif %}

                    {% if financialInformation.responsiblePerson %}
                    <p class="nowrap">
                        <span class="text-muted">{{ 'Responsible person'|trans }}: </span>
                        {{ financialInformation.responsiblePerson }}
                    </p>
                    {% endif %}
                </td>
                <!-- COMPANY DATA end -->

                <!-- PARTNER DATA start -->
                <td class="company-data">
                    <p class="label text-primary">{{ 'Supplier'|trans }}</p>
                    <h2>{{ vendorFinancialInformation.name }}</h2>
                    <p>
                        <!--<span class="text-muted">{{ 'Address'|trans }}: </span> -->
                        {{ vendorFinancialInformation.address1 }}
                    </p>

                    <p>
                        {{ vendorFinancialInformation.country }},
                        {{ vendorFinancialInformation.city }}
                    </p>

                    <p class="nowrap">
                        <span class="text-muted">{{ 'Company ID'|trans }}: </span>
                        {{ vendorFinancialInformation.uic }}
                    </p>

                    {% if vendorFinancialInformation.vat %}
                    <p class="nowrap">
                        <span class="text-muted">{{ 'VAT No'|trans }}: </span>
                        {{ vendorFinancialInformation.vat }}
                    </p>
                    {% endif %}

                    <p class="nowrap">
                        <span class="text-muted">{{ 'Responsible person'|trans }}: </span>
                        {{ vendorFinancialInformation.responsiblePerson }}
                    </p>
                </td>
                <!-- PARTNER DATA end -->
            </tr>
            <!-- SUBHEADER end -->
        </table>


        <!-- DOCUMENT DETAILS start -->
        <div class="document-lines">
            <div class="divider bg-primary mb-20"></div>

            <table>
                <thead>
                    <tr>
                        <th class="text-primary text-center"></th>
                        <th class="text-primary text-left">{{'Code' | trans }}</th>
                        <th class="text-primary text-left">{{'Title' | trans }}</th>
                        <!-- <th class="text-primary narrow text-right">{{ 'Batch'|trans }}</th> -->
                        <th class="text-primary narrow text-right">{{'Qty' | trans }}</th>
                        <th class="text-primary narrow text-right">{{'Unit Price Original' | trans }}</th>
                        <th class="text-primary narrow text-right">{{'Unit Price' | trans }}</th>
                        <!-- <th class="text-primary narrow text-right">{{'Discount' | trans }}</th> -->
                        <th class="text-primary narrow text-right">{{'Amount' | trans }}</th>
                        <!--
                        <th class="text-primary narrow text-right">{{'Tax Amount' | trans }}</th>
                        <th class="text-primary narrow text-right">{{'Total Amount' | trans }}</th>
                        -->
                    </tr>
                </thead>

                <tbody>
                    {% for line in sales.lines %}
                    {%if not line.deleted%}
                    {%set no=no+1%}
                    <tr {% if loop.index%2 %}class="odd"{% endif %}>
                        <td class="narrow text-center">{{ no }}</td>
                        <td class="narrow text-left">{{ line.item.no }}</td>
                        <td class="text-desc text-left">
                            {% if line.itemDescription %}
                                {{ line.itemDescription | trans }}
                            {% else %}
                                {{ line.item.title }}
                            {% endif %}
                            <p class="comment text-muted">
                                {{ line.comment }}
                            </p>
                        </td>
                        <!-- <td class="narrow text-right">{{ line.batch.name }}</td> -->
                        <td class="narrow text-right">{{ line.quantity }} {{line.measure.name | trans}}</td>
                        <td class="narrow text-right">{{ line.unitPriceOriginal|number_format(2, '.', '') }} {{line.currency.code | trans}}</td>
                        <td class="narrow text-right">{{ line.unitPrice|number_format(2, '.', '') }} {{defaultCurrency.code | trans}}</td>
                        <!-- <td class="narrow text-right">{{ line.discountRate|number_format(2, '.', '') }}</td>                     -->
                        <td class="narrow text-right">{{ line.amountBaseOriginal|number_format(2, '.', '') }} {{line.currency.code | trans}}</td>
                        <!--
                        <td class="narrow text-right">{{ line.amountTax|number_format(2, '.', '') }}</td>
                        <td class="narrow text-right">
                            {{ line.amountTotal|number_format(2, '.', '') }} {{line.currency.code | trans}}
                        </td>
                        -->
                    </tr>
                    {% endif %}
                    {% endfor %}
                </tbody>
            </table>
        </div>
        <!-- DOCUMENT DETAILS end -->

        <!-- DOCUMENT SUMMARY start -->
        <!-- DOCUMENT SUMMARY start -->
        <table class="document-summary-holder">
            <tr>
                <td style="width: 50%;">
                    <!-- SPACER: DO NOT DELETE -->
                </td>

                <td>
                    <table class="document-summary">
                        <!-- {{ 'Terms and conditions'|trans }} -->

                        <tr>
                            <td>{{ 'Tax Base'|trans }}:</td>
                            <td class="text-right">{{ (sales.amount/sales.currencyRate)|number_format(2, '.', ' ') }} {{ sales.currency.code }}</td>
                        </tr>

                        {% if sales.Discount %}
                        <tr>
                            <td>{{ 'Discount Amount'|trans }}:</td>
                            <td class="text-right">{{ (sales.Discount/sales.currencyRate)|number_format(2, '.', ' ') }} {{ sales.currency.code }}</td>
                        </tr>
                        {% endif %}

                        <tr>
                            <td>{{ 'Tax'|trans }} {{ sales.taxRate|number_format(0, '.', ' ') }}% :</td>
                            <td class="text-right">{{ (sales.amountTax/sales.currencyRate)|number_format(2, '.', ' ') }} {{ sales.currency.code }}</td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <div class="divider bg-primary"></div>
                            </td>
                        </tr>

                        <tr class="text-bold">
                            <td>{{ 'Total'|trans }}:</td>
                            <td class="text-right">
                                {{ ((sales.amount/sales.currencyRate)|number_format(2, '.', '') + (sales.Discount/sales.currencyRate)|number_format(2, '.', '') + (sales.amountTax/sales.currencyRate)|number_format(2, '.', ''))|number_format(2, '.', ' ') }} {{ sales.currency.code }}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" class="inwords text-muted text-right">
                            {% if language == "en" %}
                              ({{ widget_numbers_in_words_en(((sales.amount/sales.currencyRate)|number_format(2, '.', '') + (sales.Discount/sales.currencyRate)|number_format(2, '.', '') + (sales.amountTax/sales.currencyRate)|number_format(2, '.', ''))|number_format(2, '.', '')) }})                             </td>
                            {% elseif language == "bg" %}
                              ({{ widget_numbers_in_words(((sales.amount/sales.currencyRate)|number_format(2, '.', '') + (sales.Discount/sales.currencyRate)|number_format(2, '.', '') + (sales.amountTax/sales.currencyRate)|number_format(2, '.', ''))|number_format(2, '.', '')) }})                             </td>
                            {% endif %}
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
        <!-- DOCUMENT SUMMARY end -->

        <!-- DOCUMENT FOOTER start -->
        <table class="document-footer">
            <tr>
                <td class="col-left">
                    <p class="text-muted">{{ 'Payment Method'|trans }}:</p>
                    <p>
                    {% if sales.paymentMethod.name %}
                        {{ sales.paymentMethod.parent.name |trans }} › {{ sales.paymentMethod.name |trans }}
                    {% elseif sales.paymentMethod.parent.name %}
                        {{ sales.paymentMethod.parent.name |trans  }}
                    {% endif %}
                    <br />
                    {% if sales.paymentMethod.name %}
                        {{  sales.paymentMethod.description|raw}}
                    {% endif %}
                    </p>

                    {% if additionalData['custom_field'] %}
                    <div class=" mt-20">
                        <p class="text-muted">{{'Terms'|trans}}</p>
                        <p>{{additionalData['custom_field']}}</p>
                    </div>
                    {% endif %}
                </td>

                <td class="col-right">
                    <p>
                        <b class="text-muted">{{ 'Created by'|trans }}:</b> {{ sales.createdBy.names|default('-') }}
                    </p>
                    <p>
                        <b class="text-muted">{{ 'Created date'|trans }}:</b> {{ sales.created|date("d.m.Y") }}
                    </p>
                </td>
            </tr>

            {% if sales.comment %}
            <tr>
                <td colspan="2">
                    <p class="text-muted mt-20">{{'Notes'|trans}}</p>
                    <p>{{ sales.comment }}</p>
                </td>
            {% endif %}
            </tr>
        </table>
        <!-- DOCUMENT FOOTER end -->

        <!-- COMPOSITY FOOTER start -->
        <div class="composity-footer mt-20" >
            <p class="text-right">{{ 'Composity Business Software'|trans }}</p>
        </div>
        <!-- COMPOSITY FOOTER end -->
    </div>
</body>
</html>`;
    this.payment = `<!DOCTYPE HTML>
<html>
    <head>
        <meta http-equiv="Content-Type" content="charset=utf-8" />
        <style>

            * {
                font-family: "Times New Roman";
                font-size:17px;
            }
            .first{
                margin:0px;
            }
            .first > table tr > td,
            .last > table tr > td{
                color:#666; line-height:25px;background:#f5f5f5; padding:0 10px;
            }
            .column {display:inline-block;width: 50%;}
            .column-title {float: left;}
            .row{display: block}
            #details{
                border:1px solid #ddd;border-bottom:0px;
            }
            #details > thead > tr{
                background:#dedede;
            }
            #details > thead > tr > th{
                text-align: left; font-weight:400; line-height:25px; border-style:solid;
                border-width:0 1px 0 0px; border-color:#fff; padding:0 10px; font-size:14px;
            }
            #details > tbody > tr > td{
                padding:0 10px; font-weight:400; line-height:25px; border-style:solid;
                border-width:0 1px 1px 0; border-color:#ddd #fff #ddd #fff;
            }
            #details > tbody > tr > td.red,
            #total > tbody > tr > td.red{
                color:#ed1b2f;
            }
            #details > tbody > tr > td.price,
            #total > tbody > tr > td.price{
                text-align:right;
            }
            #total{
                border:1px solid #ddd; background:#f5f5f5; border-bottom:0px;
            }
            #total > tbody > tr > td{
                padding:0 10px; font-weight:400; line-height:25px; border-style:solid;
                border-width:0 1px 1px 0; border-color:#ddd
            }
            .footer > span{
                font-style:normal;
                font-size:17px;
                line-height:25px;
                margin:0 5px;
                color:#444;
            }
            .footer > span > strong{
                font-size:19px;
            }
            .footer{
                text-align:left;
                position: absolute;
                bottom:25px;
            }

        </style>
    </head>
    <body style="width:1024px; margin:0 auto;" >
        {% if payment.form.type  == 'payment transfer'%}
        <div>
          <div  class="row">
                    <div class="column" align="left">
                      <h1 style="font-size:24px; text-transform:uppercase; color:#000; margin:0;font-weight:400;line-height:34px;padding:0px;">
                        {{ 'Transfer order'|trans }}
                      </h1>
                      <p style="">{{ 'Stage'|trans }}: {{ payment.stage.name |trans }}</p>
                    </div>
                    <div class="column" align="left">
                        <h1 style="font-size:24px; text-transform:uppercase; color:#000; margin:0;font-weight:400;line-height:34px;padding:0px">{{'#' |trans}} {{ payment.documentNo }}</h1>
                        <p style="font-weight:400; margin:10px 0; font-size:16px; text-transform:uppercase; color:#515151">{{ 'Date'|trans }} {% set day = payment.date|date('d') %}
                              {% set month = payment.date|date('F') %}
                              {% set year = payment.date|date('Y') %}
                              {{ '%s %s %s'|format(day, month|trans, year) }}</p>
                    </div>
            </div>
        </div>
        <div class="body">
            <table  width="100%" cellspacing="0" cellpadding="0" border="0">
                <tbody>
                    <tr>
                        <td colspan="2">
                            <table border="0" width="100%" cellspacing="0" cellpadding="0" id="info">
                                  <thead>
                                        <tr>
                                            <th width="40%" align="center" class="first"></th>
                                            <th width="40%" align="center" class="last"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="last">
                                          <td class="first" >
                                              <table border="0" style="padding:35px 0 15px 0;" width="100%" cellspacing="0" cellpadding="0">
                                                  <tr>
                                                      <td style="background: #fff;" valign="middle"><span style="border-bottom:1px solid #fff;padding:5px 50px 5px 5px;background:#f5f5f5; line-height:35px;">{{ 'From'|trans }}</span></td>
                                                      <td style="background: #fff;" valign="middle"></td>
                                                      <td style="background: #fff;" valign="middle"></td>
                                                  </tr>
                                                  <tr>
                                                      <td colspan="3" valign="middle"><strong style="color:#000; line-height:31px;font-size:28px;height:65px">{{ payment.paymentMethod.name }}</strong></td>
                                                  </tr>
                                              </table>
                                            </td>
                                            <td class="last">
                                              <table border="0" style="padding:35px 0 15px 0; padding-left: 20px;" width="100%" cellspacing="0" cellpadding="0">
                                                  <tr>
                                                      <td style="background: #fff;" valign="middle"><span style="border-bottom:1px solid #fff;padding:5px 50px 5px 5px;background:#f5f5f5; line-height:35px;">{{ 'To'|trans }}</span></td>
                                                      <td style="background: #fff;" valign="middle"></td>
                                                      <td style="background: #fff;" valign="middle"></td>
                                                  </tr>
                                                  <tr>
                                                      <td colspan="3" valign="middle"><strong style="color:#000; line-height:31px;font-size:28px;height:65px">{{ payment.parent.paymentMethod.name }}</strong></td>
                                                  </tr>
                                              </table>
                                            </td>
                                          </tr>
                                      </tbody>
                                </table>
                            </td>
                    </tr>
              </tbody>
            </table>
            <br><br>
                        <table border="0px" width="100%" cellspacing="0" cellpadding="0" id="details"  style="background:#f5f5f5;">
                            <thead>
                                <tr>
                                    <th width="30%">{{ 'Comment'|trans }}</th>
                                    <th width="20%">{{ 'Amount'|trans }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {% for line in payment.parent.lines %}
                                    <tr{% if loop.index%2 %} class="odd"{% endif %} style="background:#f5f5f5;">
                                        <td>{{ line.comment }}</td>
                                        <td>{{ line.amount|number_format(0, '.', '') }}</td>
                                    </tr>
                                {% endfor %}
                            </tbody>
                        </table>
                        <br><br>
                        <table style="background:#f5f5f5;" width="100%" cellspacing="0" cellpadding="0" border="0" class="more-info">
                                            <tbody>
                                                <tr>
                                                    <td class="last">
                                                        <table border="0" width="50%" style="padding:10px; color:#666; font-size:16px;">
                                                          <tr><td width="100" valign="middle"><strong>{{ 'Issued by'|trans }}:</strong></td></tr>
                                                          <tr><td width="100" valign="middle">{{ payment.createdBy.names|default('-') }}</td></tr>
                                                          <tr><td width="100" valign="middle"><strong>{{ 'Date'|trans }}:</strong></td></tr>
                                                          <tr><td width="100" valign="middle"> {{ payment.date|date("d/m/Y") }}</td></tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                      </table>
              </div>
              {% elseif payment.form.type  == 'payment revision' %}

                        <div>
                          <div  class="row">
                                    <div class="column" align="left">
                                      <h1 style="font-size:24px; text-transform:uppercase; color:#000; margin:0;font-weight:400;line-height:34px;padding:0px;">
                                          {{ 'Revision order'|trans }}:
                                      </h1>
                                      <p>{{ 'Payment Method'|trans }}: {{ payment.paymentMethod.name }}</p>
                                      <p style="">{{ 'Stage'|trans }}: {{ payment.stage.name |trans }}</p>
                                    </div>
                                    <div class="column" align="left">
                                        <h1 style="font-size:24px; text-transform:uppercase; color:#000; margin:0;font-weight:400;line-height:34px;padding:0px">{{'#' |trans}} {{ payment.documentNo }}</h1>
                                        <p style="font-weight:400; margin:10px 0; font-size:16px; text-transform:uppercase; color:#515151">{{ 'Date'|trans }} {% set day = payment.date|date('d') %}
                                              {% set month = payment.date|date('F') %}
                                              {% set year = payment.date|date('Y') %}
                                              {{ '%s %s %s'|format(day, month|trans, year) }}</p>
                                    </div>
                            </div>
                        </div>
                        <br><br>
                    <div class="body">
                        <table border="0px" width="100%" cellspacing="0" cellpadding="0" id="details"  style="background:#f5f5f5;">
                              <thead>
                                  <tr>
                                      <th width="30%">{{ 'Comment'|trans }}</th>
                                      <th width="20%">{{ 'Amount'|trans }}</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  {% for line in payment.lines %}
                                  <tr {% if loop.index%2 %} class="odd"{% endif %} style="background:#f5f5f5;">
                                      <td>{{ line.comment }}</td>
                                      <td>{{ line.amount|number_format(0, '.', '') }}</td>
                                  </tr>
                                  {% endfor %}
                              </tbody>
                          </table>
                          <br><br>
                          <table style="background:#f5f5f5;" width="100%" cellspacing="0" cellpadding="0" border="0" class="more-info">
                                              <tbody>
                                                  <tr>
                                                      <td class="last">
                                                          <table border="0" width="50%" style="padding:10px; color:#666; font-size:16px;">
                                                            <tr><td width="100" valign="middle"><strong>{{ 'Issued by'|trans }}:</strong></td></tr>
                                                            <tr><td width="100" valign="middle">{{ payment.createdBy.names|default('-') }}</td></tr>
                                                            <tr><td width="100" valign="middle"><strong>{{ 'Date'|trans }}:</strong></td></tr>
                                                            <tr><td width="100" valign="middle"> {{ payment.date|date("d/m/Y") }}</td></tr>
                                                          </table>
                                                      </td>
                                                  </tr>
                                              </tbody>
                        </table>
                    </div>
              {% else %}

                      <div>
                          <div  class="row">
                              <div class="column" align="left">
                                  <h1 style="font-size:24px; text-transform:uppercase; color:#000; margin:0;font-weight:400;line-height:34px;padding:0px;">
                                    {{ 'Payment'|trans }}:
                                  </h1>
                                  <p style="font-weight:400; margin:10px 0; font-size:16px; text-transform:uppercase; color:#515151">{{ payment.paymentMethod.name }}</p>
                                  <br>
                                  <p style="">{{ 'Stage'|trans }}: {{ payment.stage.name |trans }}</p>
                              </div>
                              <div class="column" align="left">
                                  <h1 style="font-size:24px; text-transform:uppercase; color:#000; margin:0;font-weight:400;line-height:34px;padding:0px">{{'#' |trans}} {{ payment.documentNo }}</h1>
                                  <p style="font-weight:400; margin:10px 0; font-size:16px; text-transform:uppercase; color:#515151">{{ 'Date'|trans }} {% set day = payment.date|date('d') %}
                                  {% set month = payment.date|date('F') %}
                                  {% set year = payment.date|date('Y') %}
                                  {{ '%s %s %s'|format(day, month|trans, year) }}</p>
                             </div>
                        </div>
                    </div>
                    <div class="body">
                          <table width="100%" cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                    <tr>
                                      <td colspan="2">
                                          <table width="95%" cellspacing="0" cellpadding="0" id="info">
                                              <thead>
                                                  <tr>
                                                    <th width="40%" align="center" class="first"></th>
                                                    <th width="40%" align="center" class="last"></th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                  <tr class="last">
                                                      <td class="first" >
                                                        <table style="padding:5px 5% 15px 0" width="95%" cellspacing="0" cellpadding="0">
                                                           <tr>
                                                             <td style="background: #fff;" valign="middle"><span style="border-bottom:1px solid #fff;padding:5px 50px 5px 5px;background:#f5f5f5; line-height:35px;">
                                                                {% if payment.amount < 0 %}
                                                                {{ 'Supplier'|trans }}
                                                                {% elseif payment.amount  >= 0 %}
                                                                {{ 'Client'|trans }}
                                                                {% endif %}
                                                            </span></td>
                                                              <td style="background: #fff;" valign="middle"></td>
                                                              <td style="background: #fff;" valign="middle"></td>
                                                            </tr>
                                                            <tr>
                                                              <td colspan="3" valign="middle"><strong style="color:#000; line-height:31px;font-size:28px;height:65px;">{{ financialInformation.name }}</strong></td>
                                                            </tr>
                                                             <tr>
                                                                <td valign="middle">{{ 'Address'|trans }}:</td>
                                                                <td colspan="2" valign="middle"><strong>{{ financialInformation.city }}</strong></td>
                                                             </tr>
                                                             <tr>
                                                                <td valign="middle"></td>
                                                                <td colspan="2" valign="top" style="height:65px;"><strong>{{ financialInformation.address1 }}</strong></td>
                                                            </tr>
                                                            <tr>
                                                                <td valign="middle">{{ 'Company Id'|trans }}</td>
                                                                <td colspan="2" valign="middle"><strong>{{ financialInformation.uic }}</strong></td>
                                                            </tr>
                                                            <tr>
                                                                <td valign="middle">{{ 'Company VAT' |trans }}:</td>
                                                                <td colspan="2" valign="middle"><strong>{{ financialInformation.vat }}</strong></td>
                                                            </tr>
                                                            <tr>
                                                                <td style="padding-bottom:25px" valign="middle">{{ 'Responsible Person'|trans }}:</td>
                                                                <td colspan="2" style="padding-bottom:25px; line-height:31px; font-size:24px;" valign="middle"><strong>{{ financialInformation.responsiblePerson }}</strong></td>
                                                            </tr>
                                                         </table>
                                                        </td>
                                                        <td class="last">
                                                          <table style="padding:5px 5% 15px 0;" width="95%" cellspacing="0" cellpadding="0">
                                                                <tr>
                                                                  <td style="background: #fff;" valign="middle"><span style="border-bottom:1px solid #fff;padding:5px 50px 5px 5px;background:#f5f5f5; line-height:35px;">
                                                                    {% if payment.amount < 0 %}
                                                                    {{ 'Client'|trans }}
                                                                    {% elseif payment.amount  >= 0 %}
                                                                    {{ 'Supplier'|trans }}
                                                                    {% endif %}
                                                                  </span></td>
                                                                  <td style="background: #fff;" valign="middle"></td>
                                                                  <td style="background: #fff;" valign="middle"></td>
                                                                </tr>
                                                                <tr>
                                                                  <td colspan="3" valign="middle"><strong style="color:#000; line-height:31px;font-size:28px;height:65px">{{ vendorFinancialInformation.name }}</strong></td>
                                                                </tr>
                                                                <tr>
                                                                  <td valign="middle">{{ 'Address'|trans }}:</td>
                                                                  <td colspan="2" style="width:100%;" valign="middle"><strong>{{ vendorFinancialInformation.city }}</strong></td>
                                                                </tr>
                                                                <tr>
                                                                  <td valign="middle"></td>
                                                                  <td colspan="2" valign="top" style="height:65px;"><strong>{{ vendorFinancialInformation.address1 }}</strong></td>
                                                                </tr>
                                                                <tr>
                                                                  <td valign="middle">{{ 'Company Id'|trans }}:</td>
                                                                  <td colspan="2" valign="middle"><strong>{{ vendorFinancialInformation.uic }}</strong></td>
                                                                </tr>
                                                                <tr>
                                                                  <td valign="middle">{{ 'Company VAT' |trans }}:</td>
                                                                  <td colspan="2" valign="middle"><strong>{{ vendorFinancialInformation.vat }}</strong></td>
                                                                </tr>
                                                                <tr>
                                                                  <td style="padding-bottom:25px" valign="middle">{{ 'Responsible Person'|trans }}:</td>
                                                                  <td colspan="2" style="padding-bottom:25px; line-height:31px; font-size:24px;" valign="middle"><strong>{{ vendorFinancialInformation.responsiblePerson }}</strong></td>
                                                                </tr>
                                                            </table>
                                                          </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br><br>
                                <table border="0px" width="100%" cellspacing="0" cellpadding="0" id="details">
                                    <thead>
                                        <tr>
                                            <th width="10%" style="border-width:0 1px 0 0;">{{ '#'|trans }}</th>
                                            <th width="30%" style="border-width:0 0 0 0px;text-align:left;">{{ 'Document' |trans}}</th>
                                            <th width="30%" style="border-width:0 0 0 0px;text-align:left;">{{ 'Comment' |trans}}</th>
                                            <th width="30%" style="border-width:0 0 0 0px;text-align:right;">{{ 'Amount'|trans }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {%set no=0%}
                                        {% for line in payment.lines %}
                                            {%if not line.deleted%}
                                                {%set no=no+1%}
                                                <tr{% if loop.index%2 %} class="odd"{% endif %} style="background:#f5f5f5;">
                                                    <td class="price">{{ no }}</td>
                                                    <td style="text-align:left;">
                                                        {% if line.invoice %}
                                                            {{ 'Invoice'|trans }} {{ line.invoice.documentNo }}
                                                        {% elseif line.sale%}
                                                            {{ 'Sale'|trans }} {{ line.sale.documentNo }}
                                                        {% elseif line.expense %}
                                                            {{ 'Expense'|trans }} {{ line.expense.documentNo }}
                                                        {% endif %}
                                                    </td>
                                                    <td style="text-align:left;">{{ line.comment }}</td>
                                                    <td style="text-align:right;">{{ line.amount|number_format(2, '.', ' ') }}</td>
                                                </tr>
                                            {%endif%}
                                        {% endfor %}
                                    </tbody>
                                </table>
                                <br><br>
                                <table  width="100%" cellspacing="0" cellpadding="0" id="total">
                                    <tbody>
                                        <tr>
                                            <td width="20%" style="">{{'Amount' | trans}}:</td>
                                            <td width="20%" align="right">{{ payment.amount|number_format(2, '.', ' ') }} {{payment.currency.name}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br><br>
                                <table style="background:#f5f5f5;" width="100%" cellspacing="0" cellpadding="0" border="0" class="more-info">
                                                    <tbody>
                                                        <tr>
                                                            <td class="last">
                                                                <table border="0" width="50%" style="padding:10px; color:#666; font-size:16px;">
                                                                  <tr><td width="100" valign="middle"><strong>{{ 'Issued by'|trans }}:</strong></td></tr>
                                                                  <tr><td width="100" valign="middle">{{ payment.createdBy.names|default('-') }}</td></tr>
                                                                  <tr><td width="100" valign="middle"><strong>{{ 'Date'|trans }}:</strong></td></tr>
                                                                  <tr><td width="100" valign="middle"> {{ payment.date|date("d/m/Y") }}</td></tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                              </table>
                      <br><br>
                      {% endif %}
              </div>
       </body>
  </html>`;

    this.report = `<!DOCTYPE HTML>
<html>
    <head>
        <meta http-equiv="Content-Type" content="charset=utf-8" />
    </head>
    <table>
        <tr>
            {%for header in headers%}
                <th>
                    {{header}}
                </th>
            {%endfor%}
        </tr>
        {%for row in rows%}
            <tr>
                {%for value in row%}
                    <td>
                        {{value}}
                    </td>
                {%endfor%}
            </tr>
        {%endfor%}
    </table>
</html>`;
this.deal = `<!DOCTYPE HTML>
<html>
    <head>
        <meta http-equiv="Content-Type" content="charset=utf-8" />
        <link rel="stylesheet" href="{{ asset('bundles/uiweb/css/ionicons.css') }}" media="screen" type="text/css">
        <style>
            * {font-family: "Times New Roman";font-size:17px}
            .first{margin:0px}
            .first > table tr > td, .last > table tr > td{color:#666; line-height:25px;background:#f5f5f5; padding:0 10px}
            .column {display:inline-block;width: 50%;}
            .column-title {float: left;}
            .row{display: block}
            #details{border:1px solid #ddd;border-bottom:0px}
            #details > thead > tr{background:#dedede}
            #details > thead > tr > th{text-align: left; font-weight:400; line-height:25px; border-style:solid;border-width:0 1px 0 0px; border-color:#fff; padding:0 10px; font-size:14px}
            #details > tbody > tr > td{padding:0 10px; font-weight:400; line-height:25px; border-style:solid;border-width:0 1px 1px 0; border-color:#ddd #fff #ddd #fff}
            #details > tbody > tr > td.red, #total > tbody > tr > td.red{color:#ed1b2f}
            #details > tbody > tr > td.price, #total > tbody > tr > td.price{text-align:right}
            #total{border:1px solid #ddd; background:#f5f5f5; border-bottom:0px}
            #total > tbody > tr > td{padding:0 10px; font-weight:400; line-height:25px; border-style:solid;border-width:0 1px 1px 0; border-color:#ddd}
            .footer {
                    position: absolute;
                    bottom:-20;
                    right:0;
            }

        </style>
    </head>
    <header page="1" paper="A4" paper-orientation="portrait" paper-margin="2cm"></header>
    <body style="width:90%; margin:0 auto;" >
        <div width="100%" border="0">
            <thead>
                <tr>
                    <th align="left" width="45%">
                    </th>
                    <th align="right" width="45%">
                    </th>
                </tr>
            </thead>
        </div>
        <div>
          <div  class="row">
                    <div class="column" align="left">
                        <h1 style="font-size:24px; text-transform:uppercase; color:#000; margin:0;font-weight:400;line-height:34px;padding:0px">{{'Deal' | trans}}</h1>

                    </div>
                    <div class="column" align="left">
                        <h1 style="font-size:24px; text-transform:uppercase; color:#000; margin:0;font-weight:400;line-height:34px;padding:0px">{{'#' |trans}} {{ deal.documentNo }}</h1>
                        <p style="font-weight:400; margin:10px 0; font-size:16px; text-transform:uppercase; color:#515151">{{ 'Date'|trans }} {% set day = deal.documentDate|date('d') %}
                            {% set month = deal.documentDate|date('F') %}
                            {% set year = deal.documentDate|date('Y') %}
                            {{ '%s %s %s'|format(day, month|trans, year) }}</p>
                    </div>
            </div>
        </div>
        <div class="body">
            <table width="100%" cellspacing="0" cellpadding="0" border="0" >
                <tbody>
                    <tr>
                        <td colspan="2">
                            <table border="0" width="100%" cellspacing="0" cellpadding="0" id="info" >
                                <thead>
                                    <tr>
                                        <th width="45%" align="center" class="first"></th>
                                        <th width="45%" align="center" class="last"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="last">
                                        <td class="first" >
                                            <table border="0" style="padding:35px 0 15px 0; padding-right: 10px;" width="100%" cellspacing="0" cellpadding="0">
                                                <tr>
                                                    <td valign="middle"><span style="padding:5px 50px 5px 5px;background:#f5f5f5; line-height:35px;">{{ 'Customer'|trans }}</span></td>
                                                    <td style="background: #fff;" valign="middle"></td>
                                                    <td style="background: #fff;" valign="middle"></td>
                                                </tr>
                                                <tr>
                                                    <td colspan="3" valign="middle"><strong style="color:#000; line-height:31px;font-size:24px;height:65px">{{ financialInformation.name }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle">{{ 'Address'|trans }}:</td>
                                                    <td colspan="2" style="width:100%;" valign="middle"><strong>{{ financialInformation.country }}, {{ financialInformation.city }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle"></td>
                                                    <td colspan="2" valign="top" style="height:65px;"><strong>{{ financialInformation.address1 }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle">{{ 'Company ID'|trans }}:</td>
                                                    <td colspan="2" valign="middle"><strong>{{ financialInformation.uic }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle">{{ 'VAT No'|trans }}:</td>
                                                    <td colspan="2" valign="middle"><strong>{{ financialInformation.vat }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td style="padding-bottom:25px" valign="middle">{{ 'Responsible person'|trans }}:</td>
                                                    <td colspan="2" style="padding-bottom:25px; line-height:31px; font-size:24px;" valign="middle"><strong>{{ financialInformation.responsiblePerson }}</strong></td>
                                                </tr>
                                            </table>
                                        </td>
                                        <td class="last">
                                            <table style=" border-top: 0; border-left: 3px solid white; padding:35px 0 15px 0; padding-left: 10px;" width="100%" cellspacing="0" cellpadding="0">
                                                <tr>
                                                    <td style="border-bottom:1px solid #fff;" valign="middle"><span style="padding:5px 50px 5px 5px;background:#f5f5f5; line-height:35px;">{{ 'Supplier'|trans }}</span></td>
                                                    <td style="background: #fff;" valign="middle"></td>
                                                    <td style="background: #fff;" valign="middle"></td>
                                                </tr>
                                                <tr>
                                                    <td colspan="3" valign="middle"><strong style="color:#000; line-height:31px;font-size:24px;height:65px">{{ vendorFinancialInformation.name }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle">{{ 'Address'|trans }}:</td>
                                                    <td colspan="2" style="width:100%;" valign="middle"><strong>{{ vendorFinancialInformation.country }}, {{ vendorFinancialInformation.city }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle"></td>
                                                    <td colspan="2" valign="top" style="height:65px;"><strong>{{ vendorFinancialInformation.address1 }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle">{{ 'Company ID'|trans }}:</td>
                                                    <td colspan="2" valign="middle"><strong>{{ vendorFinancialInformation.uic }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle">{{ 'VAT No'|trans }}:</td>
                                                    <td colspan="2" valign="middle"><strong>{{ vendorFinancialInformation.vat }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td style="padding-bottom:25px" valign="middle">{{ 'Responsible person'|trans }}:</td>
                                                    <td colspan="2" style="padding-bottom:25px; line-height:31px; font-size:24px;" valign="middle"><strong>{{ vendorFinancialInformation.responsiblePerson }}</strong></td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
            <br>
            <table border="0" width="100%" cellspacing="0" cellpadding="0" id="details">
                <thead>
                    <tr>
                        <th width="5%" style="border-width:0 1px 0 0;">№</th>
                        <th width="10%">{{'Code' | trans }}</th>
                        <th width="25%">{{'Title' | trans }}</th>
                        <th width="8%">{{'Qty' | trans }}</th>
                        <th width="10%" style="text-align:right;">{{'Unit Price' | trans }}</th>
                        <th width="10%" style="text-align:right;">{{'Discount' | trans }}</th>
                        <th width="10%" style="text-align:right;">{{'Amount' | trans }}</th>
                        <th width="10%" style="text-align:right;">{{'Tax Amount' | trans }}</th>
                        <th width="10%" style="border-width:0 0 0 0px;text-align:right;">{{'Total Amount' | trans }}</th>
                    </tr>
                </thead>
                <tbody>
                    {% for line in deal.lines %}
                    {%if not line.deleted%}
                    {%set no=no+1%}
                        <tr{% if loop.index%2 %} class="odd"{% endif %} style="background:#f5f5f5;">
                            <td class="price">{{ no }}</td>
                            <td>{{ line.item.no }}</td>
                            <td>  {% if line.item.title %}
                                {{ line.item.title }}
                                {% else %}
                                {{ line.itemDescription }}
                                {% endif %}</td>
                            <td style="text-align:right;">{{ line.quantity }} {{line.measure.name | trans}}</td>
                            <td class="price">{{ line.unitPriceOriginal|number_format(2, '.', '') }} {{line.currency.code | trans}}</td>
                            <td class="price">{{ line.discountRate|number_format(2, '.', '') }}</td>
                            <td class="price">{{ line.amountBase|number_format(2, '.', '') }}</td>
                            <td class="price">{{ line.amountTax|number_format(2, '.', '') }}</td>
                            <td class="price">{{ line.amountTotal|number_format(2, '.', '') }}</td>
                        </tr>
                    {%endif%}
                    {% endfor %}
                </tbody>
            </table>
            <br><br>
            <table border="0" width="100%" cellspacing="0" cellpadding="0" id="total">
                <tbody>
                    <tr>
                        <td width="60%" style=""><strong>{{ 'Terms and conditions'|trans }}</strong></td>
                        <td width="20%" style="">{{ 'Tax Base'|trans }}:</td>
                        <td width="20%" align="right" class="price">{{ ((deal.amount))|number_format(2, '.', ' ') }} </td>
                    </tr>
                    {% if deal.Discount %}
                    <tr>
                        <td style="background:#fff;"></td>
                        <td width="20%" style="">{{ 'Discount Amount'|trans }}:</td>
                        <td width="20%" align="right" class="price">{{ ((deal.Discount))|number_format(2, '.', ' ') }}</td>
                    </tr>
                    {% endif %}
                    <tr>
                        <td width="60%" style="background:#fff"></td>
                        <td style="">{{ 'Tax'|trans }} {{ deal.taxRate|number_format(0, '.', ' ') }}% :</td>
                        <td align="right" class="price">{{ deal.amountTax|number_format(2, '.', ' ') }}</td>
                    </tr>
                    <tr>
                        <td style="background:#fff; border-left:1px solid #eee;">{{ deal.comment }}</td>
                        <td style="background:#eee"><strong>{{ 'Total'|trans }}:</strong></td>
                        <td align="right" style="background:#eee" class="price"><strong>{{ ((deal.amountTotal))|number_format(2, '.', ' ') }}</strong></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <br><br>
            <table style="background:#f5f5f5;" width="100%" cellspacing="0" cellpadding="0" border="0" class="more-info">
                <tbody>
                    <tr><td><br></td></tr>
                    <tr>
                        <td colspan="2">
                            <table border="0" width="100%" cellspacing="0" cellpadding="0">
                                <tbody>
                                    <tr>
                                        <td>
                                            <table width="100%" style="padding:10px; color:#666; font-size:16px;">
                                                <tr><td style="font-size:21px;line-height:25px; color:#000; font-weight:300; text-transform:uppercase;" width="100" valign="middle"><b>{{ 'Payment Method'|trans }}:</b></td></tr>
                                                <tr><td width="100" valign="middle">{% if deal.paymentMethod.name %}
                                                    {{ deal.paymentMethod.parent.name  }} › {{ deal.paymentMethod.name }}
                                                {% elseif deal.paymentMethod.parent.name %}
                                                    {{ deal.paymentMethod.parent.name  }}
                                                                        {% endif %}</td></tr>
                                                <tr><td width="100" valign="middle">{% if deal.paymentMethod.name %}{{  deal.paymentMethod.description|raw}} {% endif %}</td></tr>

                                            </table>
                                        </td>
                                        <td>
                                            <table border="0" width="100%" style="padding:10px; color:#666; font-size:16px;">
                                                <tr><td width="100" valign="middle"><b>{{ 'Created by'|trans }}:</b></td></tr>
                                                <tr><td width="100" valign="middle">{{ deal.createdBy.names|default('-') }}</td></tr>
                                                <tr><td width="100" valign="middle"><b>{{ 'Created date'|trans }}:</b></td></tr>
                                                <tr><td width="100" valign="middle">
                                                {% set day = deal.created|date('d') %}
                                                {% set month = deal.created|date('F') %}
                                                {% set year = deal.created|date('Y') %}
                                                {{ '%s %s %s'|format(day, month|trans, year) }}</td></tr>
                                                {% if deal.dueDate %}
                                                <tr><td width="100" valign="middle"><b>{{ 'Due date'|trans }}:</b></td></tr>
                                                <tr><td width="100" valign="middle">
                                                    {% set day = deal.dueDate|date('d') %}
                                                    {% set month = deal.dueDate|date('F') %}
                                                    {% set year = deal.dueDate|date('Y') %}
                                                    {{ '%s %s %s'|format(day, month|trans, year) }}</td></tr>
                                                {% endif %}
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        <br><br>
            <div class="footer" style="border-top: 1px solid; width: 100%;">
             <p style="text-align: right;">{{ 'Created by Composity.'|trans }}</p>
           </div>
    </body>
</html>`;

    this.production = `<!DOCTYPE HTML>
<html>
    <head>
        <meta http-equiv="Content-Type" content="charset=utf-8" />
        <link rel="stylesheet" href="{{ asset('bundles/uiweb/css/ionicons.css') }}" media="screen" type="text/css">
        <style>
            * {font-family: "Times New Roman";font-size:17px}
            .first{margin:0px}
            .first > table tr > td, .last > table tr > td{color:#666; line-height:25px;background:#f5f5f5; padding:0 10px}
            .column {display:inline-block;width: 50%;}
            .column-title {float: left;}
            .row{display: block}
            #details{border:1px solid #ddd;border-bottom:0px}
            #details > thead > tr{background:#dedede}
            #details > thead > tr > th{text-align: left; font-weight:400; line-height:25px; border-style:solid;border-width:0 1px 0 0px; border-color:#fff; padding:0 10px; font-size:14px}
            #details > tbody > tr > td{padding:0 10px; font-weight:400; line-height:25px; border-style:solid;border-width:0 1px 1px 0; border-color:#ddd #fff #ddd #fff}
            #details > tbody > tr > td.red, #total > tbody > tr > td.red{color:#ed1b2f}
            #details > tbody > tr > td.price, #total > tbody > tr > td.price{text-align:right}
            #total{border:1px solid #ddd; background:#f5f5f5; border-bottom:0px}
            #total > tbody > tr > td{padding:0 10px; font-weight:400; line-height:25px; border-style:solid;border-width:0 1px 1px 0; border-color:#ddd}
            .footer {
                    position: absolute;
                    bottom:-20;
                    right:0;
            }

        </style>
    </head>
    <header page="1" paper="A4" paper-orientation="portrait" paper-margin="2cm"></header>
    <body style="width:90%; margin:0 auto;" >
        <div width="100%" border="0">
            <thead>
                <tr>
                    <th align="left" width="45%">
                    </th>
                    <th align="right" width="45%">
                    </th>
                </tr>
            </thead>
        </div>
        <div>
          <div  class="row">
                    <div class="column" align="left">
                        <h1 style="font-size:24px; text-transform:uppercase; color:#000; margin:0;font-weight:400;line-height:34px;padding:0px">{{'Sale' | trans}}</h1>

                    </div>
                    <div class="column" align="left">
                        <h1 style="font-size:24px; text-transform:uppercase; color:#000; margin:0;font-weight:400;line-height:34px;padding:0px">{{'#' |trans}} {{ sales.documentNo }}</h1>
                        <p style="font-weight:400; margin:10px 0; font-size:16px; text-transform:uppercase; color:#515151">{{ 'Date'|trans }} {% set day = sales.documentDate|date('d') %}
                            {% set month = sales.documentDate|date('F') %}
                            {% set year = sales.documentDate|date('Y') %}
                            {{ '%s %s %s'|format(day, month|trans, year) }}</p>
                    </div>
            </div>
        </div>
        <div class="body">
            <table width="100%" cellspacing="0" cellpadding="0" border="0" >
                <tbody>
                    <tr>
                        <td colspan="2">
                            <table border="0" width="100%" cellspacing="0" cellpadding="0" id="info" >
                                <thead>
                                    <tr>
                                        <th width="45%" align="center" class="first"></th>
                                        <th width="45%" align="center" class="last"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="last">
                                        <td class="first" >
                                            <table border="0" style="padding:35px 0 15px 0; padding-right: 10px;" width="100%" cellspacing="0" cellpadding="0">
                                                <tr>
                                                    <td valign="middle"><span style="padding:5px 50px 5px 5px;background:#f5f5f5; line-height:35px;">{{ 'Customer'|trans }}</span></td>
                                                    <td style="background: #fff;" valign="middle"></td>
                                                    <td style="background: #fff;" valign="middle"></td>
                                                </tr>
                                                <tr>
                                                    <td colspan="3" valign="middle"><strong style="color:#000; line-height:31px;font-size:24px;height:65px">{{ financialInformation.name }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle">{{ 'Address'|trans }}:</td>
                                                    <td colspan="2" style="width:100%;" valign="middle"><strong>{{ financialInformation.country }}, {{ financialInformation.city }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle"></td>
                                                    <td colspan="2" valign="top" style="height:65px;"><strong>{{ financialInformation.address1 }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle">{{ 'Company ID'|trans }}:</td>
                                                    <td colspan="2" valign="middle"><strong>{{ financialInformation.uic }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle">{{ 'VAT No'|trans }}:</td>
                                                    <td colspan="2" valign="middle"><strong>{{ financialInformation.vat }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td style="padding-bottom:25px" valign="middle">{{ 'Responsible person'|trans }}:</td>
                                                    <td colspan="2" style="padding-bottom:25px; line-height:31px; font-size:24px;" valign="middle"><strong>{{ financialInformation.responsiblePerson }}</strong></td>
                                                </tr>
                                            </table>
                                        </td>
                                        <td class="last">
                                            <table style=" border-top: 0; border-left: 3px solid white; padding:35px 0 15px 0; padding-left: 10px;" width="100%" cellspacing="0" cellpadding="0">
                                                <tr>
                                                    <td style="border-bottom:1px solid #fff;" valign="middle"><span style="padding:5px 50px 5px 5px;background:#f5f5f5; line-height:35px;">{{ 'Supplier'|trans }}</span></td>
                                                    <td style="background: #fff;" valign="middle"></td>
                                                    <td style="background: #fff;" valign="middle"></td>
                                                </tr>
                                                <tr>
                                                    <td colspan="3" valign="middle"><strong style="color:#000; line-height:31px;font-size:24px;height:65px">{{ vendorFinancialInformation.name }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle">{{ 'Address'|trans }}:</td>
                                                    <td colspan="2" style="width:100%;" valign="middle"><strong>{{ vendorFinancialInformation.country }}, {{ vendorFinancialInformation.city }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle"></td>
                                                    <td colspan="2" valign="top" style="height:65px;"><strong>{{ vendorFinancialInformation.address1 }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle">{{ 'Company ID'|trans }}:</td>
                                                    <td colspan="2" valign="middle"><strong>{{ vendorFinancialInformation.uic }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="middle">{{ 'VAT No'|trans }}:</td>
                                                    <td colspan="2" valign="middle"><strong>{{ vendorFinancialInformation.vat }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td style="padding-bottom:25px" valign="middle">{{ 'Responsible person'|trans }}:</td>
                                                    <td colspan="2" style="padding-bottom:25px; line-height:31px; font-size:24px;" valign="middle"><strong>{{ vendorFinancialInformation.responsiblePerson }}</strong></td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
            <br>
            <table border="0" width="100%" cellspacing="0" cellpadding="0" id="details">
                <thead>
                    <tr>
                        <th width="5%" style="border-width:0 1px 0 0;">№</th>
                        <th width="10%">{{'Code' | trans }}</th>
                        <th width="25%">{{'Title' | trans }}</th>
                        <th width="8%">{{'Qty' | trans }}</th>
                        <th width="10%" style="text-align:right;">{{'Unit Price' | trans }}</th>
                        <th width="10%" style="text-align:right;">{{'Discount' | trans }}</th>
                        <th width="10%" style="text-align:right;">{{'Amount' | trans }}</th>
                        <th width="10%" style="text-align:right;">{{'Tax Amount' | trans }}</th>
                        <th width="10%" style="border-width:0 0 0 0px;text-align:right;">{{'Total Amount' | trans }}</th>
                    </tr>
                </thead>
                <tbody>
                    {% for line in sales.lines %}
                    {%if not line.deleted%}
                    {%set no=no+1%}
                        <tr{% if loop.index%2 %} class="odd"{% endif %} style="background:#f5f5f5;">
                            <td class="price">{{ no }}</td>
                            <td>{{ line.item.no }}</td>
                            <td>  {% if line.item.title %}
                                {{ line.item.title }}
                                {% else %}
                                {{ line.itemDescription }}
                                {% endif %}</td>
                            <td style="text-align:right;">{{ line.quantity }} {{line.measure.name | trans}}</td>
                            <td class="price">{{ line.unitPriceOriginal|number_format(2, '.', '') }} {{line.currency.code | trans}}</td>
                            <td class="price">{{ line.discountRate|number_format(2, '.', '') }}</td>
                            <td class="price">{{ line.amountBase|number_format(2, '.', '') }}</td>
                            <td class="price">{{ line.amountTax|number_format(2, '.', '') }}</td>
                            <td class="price">{{ line.amountTotal|number_format(2, '.', '') }}</td>
                        </tr>
                    {%endif%}
                    {% endfor %}
                </tbody>
            </table>
            <br><br>
            <table border="0" width="100%" cellspacing="0" cellpadding="0" id="total">
                <tbody>
                    <tr>
                        <td width="60%" style=""><strong>{{ 'Terms and conditions'|trans }}</strong></td>
                        <td width="20%" style="">{{ 'Tax Base'|trans }}:</td>
                        <td width="20%" align="right" class="price">{{ ((sales.amount))|number_format(2, '.', ' ') }} </td>
                    </tr>
                    {% if sales.Discount %}
                    <tr>
                        <td style="background:#fff;"></td>
                        <td width="20%" style="">{{ 'Discount Amount'|trans }}:</td>
                        <td width="20%" align="right" class="price">{{ ((sales.Discount))|number_format(2, '.', ' ') }}</td>
                    </tr>
                    {% endif %}
                    <tr>
                        <td width="60%" style="background:#fff"></td>
                        <td style="">{{ 'Tax'|trans }} {{ sales.taxRate|number_format(0, '.', ' ') }}% :</td>
                        <td align="right" class="price">{{ sales.amountTax|number_format(2, '.', ' ') }}</td>
                    </tr>
                    <tr>
                        <td style="background:#fff; border-left:1px solid #eee;">{{ sales.comment }}</td>
                        <td style="background:#eee"><strong>{{ 'Total'|trans }}:</strong></td>
                        <td align="right" style="background:#eee" class="price"><strong>{{ ((sales.amountTotal))|number_format(2, '.', ' ') }}</strong></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <br><br>
            <table style="background:#f5f5f5;" width="100%" cellspacing="0" cellpadding="0" border="0" class="more-info">
                <tbody>
                    <tr><td><br></td></tr>
                    <tr>
                        <td colspan="2">
                            <table border="0" width="100%" cellspacing="0" cellpadding="0">
                                <tbody>
                                    <tr>
                                        <td>
                                            <table width="100%" style="padding:10px; color:#666; font-size:16px;">
                                                <tr><td style="font-size:21px;line-height:25px; color:#000; font-weight:300; text-transform:uppercase;" width="100" valign="middle"><b>{{ 'Payment Method'|trans }}:</b></td></tr>
                                                <tr><td width="100" valign="middle">{% if sales.paymentMethod.name %}
                                                    {{ sales.paymentMethod.parent.name  }} › {{ sales.paymentMethod.name }}
                                                {% elseif sales.paymentMethod.parent.name %}
                                                    {{ sales.paymentMethod.parent.name  }}
                                                                        {% endif %}</td></tr>
                                                <tr><td width="100" valign="middle">{% if sales.paymentMethod.name %}{{  sales.paymentMethod.description|raw}} {% endif %}</td></tr>

                                            </table>
                                        </td>
                                        <td>
                                            <table border="0" width="100%" style="padding:10px; color:#666; font-size:16px;">
                                                <tr><td width="100" valign="middle"><b>{{ 'Created by'|trans }}:</b></td></tr>
                                                <tr><td width="100" valign="middle">{{ sales.createdBy.names|default('-') }}</td></tr>
                                                <tr><td width="100" valign="middle"><b>{{ 'Created date'|trans }}:</b></td></tr>
                                                <tr><td width="100" valign="middle">
                                                {% set day = sales.created|date('d') %}
                                                {% set month = sales.created|date('F') %}
                                                {% set year = sales.created|date('Y') %}
                                                {{ '%s %s %s'|format(day, month|trans, year) }}</td></tr>
                                                {% if sales.dueDate %}
                                                <tr><td width="100" valign="middle"><b>{{ 'Due date'|trans }}:</b></td></tr>
                                                <tr><td width="100" valign="middle">
                                                    {% set day = sales.dueDate|date('d') %}
                                                    {% set month = sales.dueDate|date('F') %}
                                                    {% set year = sales.dueDate|date('Y') %}
                                                    {{ '%s %s %s'|format(day, month|trans, year) }}</td></tr>
                                                {% endif %}
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        <br><br>
            <div class="footer" style="border-top: 1px solid; width: 100%;">
             <p style="text-align: right;">{{ 'Created by Composity.'|trans }}</p>
           </div>
    </body>
</html>`;

    this.expense = `<!DOCTYPE HTML>
    <html>
        <head>
            <meta http-equiv="Content-Type" content="charset=utf-8" />
            <link rel="stylesheet" href="{{ asset('bundles/uiweb/css/ionicons.css') }}" media="screen" type="text/css">
            <style>
                * {font-family: "Times New Roman";font-size:17px}
                .first{margin:0px}
                .first > table tr > td, .last > table tr > td{color:#666; line-height:25px;background:#f5f5f5; padding:0 10px}
                .column {display:inline-block;width: 50%;}
                .column-title {float: left;}
                .row{display: block}
                #details{border:1px solid #ddd;border-bottom:0px}
                #details > thead > tr{background:#dedede}
                #details > thead > tr > th{text-align: left; font-weight:400; line-height:25px; border-style:solid;border-width:0 1px 0 0px; border-color:#fff; padding:0 10px; font-size:14px}
                #details > tbody > tr > td{padding:0 10px; font-weight:400; line-height:25px; border-style:solid;border-width:0 1px 1px 0; border-color:#ddd #fff #ddd #fff}
                #details > tbody > tr > td.red, #total > tbody > tr > td.red{color:#ed1b2f}
                #details > tbody > tr > td.price, #total > tbody > tr > td.price{text-align:right}
                #total{border:1px solid #ddd; background:#f5f5f5; border-bottom:0px}
                #total > tbody > tr > td{padding:0 10px; font-weight:400; line-height:25px; border-style:solid;border-width:0 1px 1px 0; border-color:#ddd}
                .footer {
                        position: absolute;
                        bottom:-20;
                        right:0;
                }
    
            </style>
        </head>
        <header page="1" paper="A4" paper-orientation="portrait" paper-margin="2cm"></header>
        <body style="width:90%; margin:0 auto;" >
            <div width="100%" border="0">
                <thead>
                    <tr>
                        <th align="left" width="45%">
                        </th>
                        <th align="right" width="45%">
                        </th>
                    </tr>
                </thead>
            </div>
            <div>
              <div  class="row">
                        <div class="column" align="left">
                            <h1 style="font-size:24px; text-transform:uppercase; color:#000; margin:0;font-weight:400;line-height:34px;padding:0px">{{'Expense' | trans}}</h1>
    
                        </div>
                        <div class="column" align="left">
                            <h1 style="font-size:24px; text-transform:uppercase; color:#000; margin:0;font-weight:400;line-height:34px;padding:0px">{{'#' |trans}} {{ expense.documentNo }}</h1>
                            <p style="font-weight:400; margin:10px 0; font-size:16px; text-transform:uppercase; color:#515151">{{ 'Date'|trans }} {% set day = expense.documentDate|date('d') %}
                                {% set month = expense.documentDate|date('F') %}
                                {% set year = expense.documentDate|date('Y') %}
                                {{ '%s %s %s'|format(day, month|trans, year) }}</p>
                        </div>
                </div>
            </div>
            <div class="body">
                <table width="100%" cellspacing="0" cellpadding="0" border="0" >
                    <tbody>
                        <tr>
                            <td colspan="2">
                                <table border="0" width="100%" cellspacing="0" cellpadding="0" id="info" >
                                    <thead>
                                        <tr>
                                            <th width="45%" align="center" class="first"></th>
                                            <th width="45%" align="center" class="last"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="last">
                                            <td class="first" >
                                                <table border="0" style="padding:35px 0 15px 0; padding-right: 10px;" width="100%" cellspacing="0" cellpadding="0">
                                                    <tr>
                                                        <td valign="middle"><span style="padding:5px 50px 5px 5px;background:#f5f5f5; line-height:35px;">{{ 'Customer'|trans }}</span></td>
                                                        <td style="background: #fff;" valign="middle"></td>
                                                        <td style="background: #fff;" valign="middle"></td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="3" valign="middle"><strong style="color:#000; line-height:31px;font-size:24px;height:65px">{{ financialInformation.name }}</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td valign="middle">{{ 'Address'|trans }}:</td>
                                                        <td colspan="2" style="width:100%;" valign="middle"><strong>{{ financialInformation.country }}, {{ financialInformation.city }}</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td valign="middle"></td>
                                                        <td colspan="2" valign="top" style="height:65px;"><strong>{{ financialInformation.address1 }}</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td valign="middle">{{ 'Company ID'|trans }}:</td>
                                                        <td colspan="2" valign="middle"><strong>{{ financialInformation.uic }}</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td valign="middle">{{ 'VAT No'|trans }}:</td>
                                                        <td colspan="2" valign="middle"><strong>{{ financialInformation.vat }}</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding-bottom:25px" valign="middle">{{ 'Responsible person'|trans }}:</td>
                                                        <td colspan="2" style="padding-bottom:25px; line-height:31px; font-size:24px;" valign="middle"><strong>{{ financialInformation.responsiblePerson }}</strong></td>
                                                    </tr>
                                                </table>
                                            </td>
                                            <td class="last">
                                                <table style=" border-top: 0; border-left: 3px solid white; padding:35px 0 15px 0; padding-left: 10px;" width="100%" cellspacing="0" cellpadding="0">
                                                    <tr>
                                                        <td style="border-bottom:1px solid #fff;" valign="middle"><span style="padding:5px 50px 5px 5px;background:#f5f5f5; line-height:35px;">{{ 'Supplier'|trans }}</span></td>
                                                        <td style="background: #fff;" valign="middle"></td>
                                                        <td style="background: #fff;" valign="middle"></td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="3" valign="middle"><strong style="color:#000; line-height:31px;font-size:24px;height:65px">{{ vendorFinancialInformation.name }}</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td valign="middle">{{ 'Address'|trans }}:</td>
                                                        <td colspan="2" style="width:100%;" valign="middle"><strong>{{ vendorFinancialInformation.country }}, {{ vendorFinancialInformation.city }}</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td valign="middle"></td>
                                                        <td colspan="2" valign="top" style="height:65px;"><strong>{{ vendorFinancialInformation.address1 }}</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td valign="middle">{{ 'Company ID'|trans }}:</td>
                                                        <td colspan="2" valign="middle"><strong>{{ vendorFinancialInformation.uic }}</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td valign="middle">{{ 'VAT No'|trans }}:</td>
                                                        <td colspan="2" valign="middle"><strong>{{ vendorFinancialInformation.vat }}</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding-bottom:25px" valign="middle">{{ 'Responsible person'|trans }}:</td>
                                                        <td colspan="2" style="padding-bottom:25px; line-height:31px; font-size:24px;" valign="middle"><strong>{{ vendorFinancialInformation.responsiblePerson }}</strong></td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br>
                <table border="0" width="100%" cellspacing="0" cellpadding="0" id="details">
                    <thead>
                        <tr>
                            <th width="8%" style="border-width:0 1px 0 0;">{{'No' | trans }}</th>
                            <th width="12%">{{'Code' | trans }}</th>
                            <th width="40%">{{'Title' | trans }}</th>
                            <th width="10%">{{'Qty' | trans }}</th>
                            <th width="15%" style="text-align:right;">{{'Unit Price' | trans }}</th>
                            <th width="15%" style="border-width:0 0 0 0px;text-align:right;">{{'Total Amount' | trans }}</th>
                        </tr>
                    </thead>
                    <tbody>
                      {% for line in expense.lines %}
                      {%if not line.deleted%}
                      {%set no=no+1%}
                            <tr {% if loop.index%2 %} class="odd"{% endif %} style="background:#f5f5f5;">
                                <td class="price">{{ no }}</td>
                                <td>{{ line.item.no }}</td>
                                <td>  {% if line.item.title %}
                                    {{ line.item.title }}
                                    {% else %}
                                    {{ line.itemDescription }}
                                    {% endif %}</td>
                                <td style="text-align:right;">{{ line.quantity }} {{line.measure.name | trans}}</td>
                                <td class="price">{{ line.unitPrice|number_format(2, '.', '') }} {{line.currency.code | trans}}</td>
    
                                <td class="price">{{ line.amount|number_format(2, '.', '') }}</td>
                            </tr>
                        {%endif%}
                        {% endfor %}
                    </tbody>
                </table>
                <br><br>
                <table border="0" width="100%" cellspacing="0" cellpadding="0" id="total">
                    <tbody>
                        <tr>
                            <td width="60%" style=""  border="0 px" ></td>
                            <td width="20%" style="">{{ 'Tax Base'|trans }}:</td>
                            <td width="20%" align="right" class="price">{{ ((expense.baseAmount))|number_format(2, '.', ' ') }} </td>
                        </tr>
    
                        <tr>
                            <td width="60%" style=""  border="0" ></td>
                            <td width="20%" style=""></td>
                            <td width="20%" align="right" class="price"></td>
    
                        </tr>
    
                        <tr>
                            <td width="60%" style=""  border="0 px" ></td>
                            <td width="20%" style="">{{ 'Tax'|trans }} {{ expense.tax|number_format(0, '.', ' ') }}% :</td>
                            <td align="right" class="price">{{ expense.taxAmount|number_format(2, '.', ' ') }}</td>
                        </tr>
                        <tr>
                            <td width="60%" style="" border="0 px"></td>
                            <td width="20%" style="background:#eee"><strong>{{ 'Total'|trans }}:</strong></td>
                            <td width="20%" align="right" style="background:#eee" class="price"><strong>{{ ((expense.amount))|number_format(2, '.', ' ') }}</strong></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br><br>
                <table style="background:#f5f5f5;" width="100%" cellspacing="0" cellpadding="0" border="0" class="more-info">
                    <tbody>
                        <tr><td><br></td></tr>
                        <tr>
                            <td colspan="2">
                                <table border="0" width="100%" cellspacing="0" cellpadding="0">
                                    <tbody>
                                        <tr>
                                            <td  width="60%" ></td>
                                            <td  width="40%" >
                                                <table border="0" width="100%" style="padding:10px; color:#666; font-size:16px;">
                                                    <tr><td width="100%" valign="middle"><b>{{ 'Created by'|trans }}:</b></td></tr>
                                                    <tr><td width="100%" valign="middle">{{ expense.createdBy.names|default('-') }}</td></tr>
                                                    <tr><td width="100%" valign="middle"><b>{{ 'Created date'|trans }}:</b></td></tr>
                                                    <tr><td width="100%" valign="middle">
                                                    {% set day = expense.created|date('d') %}
                                                    {% set month = expense.created|date('F') %}
                                                    {% set year = expense.created|date('Y') %}
                                                    {{ '%s %s %s'|format(day, month|trans, year) }}</td></tr>
                                                    {% if expense.dueDate %}
                                                    <tr><td width="100%" valign="middle"><b>{{ 'Due date'|trans }}:</b></td></tr>
                                                    <tr><td width="100%" valign="middle">
                                                        {% set day = expense.dueDate|date('d') %}
                                                        {% set month = expense.dueDate|date('F') %}
                                                        {% set year = expense.dueDate|date('Y') %}
                                                        {{ '%s %s %s'|format(day, month|trans, year) }}</td></tr>
                                                    {% endif %}
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br><br>
                    <div class="footer" style="border-top: 1px solid; width: 100%;">
                     <p style="text-align: right;">{{ 'Created by Composity.'|trans }}</p>
                   </div>
        </body>
    </html>`;
    this.account = `<!DOCTYPE HTML>
    <html>
        <head>
            
        </head>
        <header page="1" paper="A4" paper-orientation="portrait" paper-margin="2cm"></header>
        <body style="width:90%; margin:0 auto;" >
            Some text here
        </body>
    </html>`


        this.onClick = this.onClick.bind(this);
    }
    
    onClick() {
        let type = this.props.formData.form.initializeFromState2.values.type;
        let template = '';
        if (type=='template_document'){
            template = this.doc;
        } else if (type=='template_invoice') {
            template = this.invoice;
        } else if (type=='template_sales') {
            template = this.sales;
        } else if (type=='template_inventory') {
            template = this.receipt;
        } else if (type=='template_payment') {
            template = this.payment;
        } else if (type=='template_purchase') {
            template = this.purchase;
        } else if (type=='template_report'){
            template = this.report;
        } else if (type=='template_deal'){
            template = this.deal;
        } else if (type=='template_production'){
            template = this.production;
        } else if (type=='template_expense'){
            template = this.expense;
        } else if (type == 'template_account') {
            template = this.account;
        } else {
            template = `<p>Please, select template type first</p>`;
        }
        
        //this.props.formData.form.initializeFromState2.values.body = template;
        
        this.props.change('body', template);
    }

    render() {
        return (
            <div>
                <input id="templates-add" 
                title="CAUTION: this button will remove all your changes and add a sample template in the body area below!" 
                type="button" 
                value="</>"
                onClick={this.onClick}/>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    formData: state
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(InsertTemplate);
