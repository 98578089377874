import React, {Component} from 'react';
import Translate, {plainTranslate} from '../common/translate';
import {connect} from 'react-redux';
import {onDocumentProfileFetchData} from '../../actions/actions';
import {Link} from 'react-router-dom';
import FormLoader from '../forms/form-loader';
import GeneralInformation from './general-information';
import Select from 'react-select';
import OrdersInfo from './orders-info';
import {config} from '../../config';
import LoggedChanges from './logged-changes';
import { ExportOutlined } from '@ant-design/icons';

class DealProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            template: false,
            active: 'template',
        };
        this.handleTemplateChange = this.handleTemplateChange.bind(this);
        this.update = this.update.bind(this)
    }

    componentDidMount() {
        this.props.onDocumentProfileFetchData('/' + this.props.match.params.db + '/api/deal/' + this.props.match.params.id + '/profile-info').then(() => {
            this.setState({template: this.props.salesProfileInformation.invoiceProfileInfo && this.props.salesProfileInformation.invoiceProfileInfo.templates.length ? this.props.salesProfileInformation.invoiceProfileInfo.templates[0].value : null })
        });
        if (this.props.title) {
            let titleArr = this.props.title;
            titleArr.map((item, key) => titleArr[key] = plainTranslate(this.props.settings.locale, item));
            document.title = titleArr.join(' ');
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.update();
        }
    }

    update() {
        this.props.onDocumentProfileFetchData('/' + this.props.match.params.db + '/api/deal/' + this.props.match.params.id + '/profile-info');
    }
    
    handleTemplateChange(e){
        this.setState({template : e.value});
    }

    render() {
        let docNo = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.docNo : '';
        let currencySign = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.currency : null;
        let stages = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.stages : '';
        let deal = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.deal : '';
        let tableData = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.dealLines : '';
        let templates = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.templates : [];
        let users = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.users : [];
        let sales = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.sales : [];
        let dealSalePercentage = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.dealSalePercentage : 0;
        let roles = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.roles : {};
        let changes = this.props.salesProfileInformation.invoiceProfileInfo ? this.props.salesProfileInformation.invoiceProfileInfo.changes : [];

        if (this.props.salesProfileInformation.loading && !this.props.salesProfileInformation.invoiceProfileInfo) {
            return <FormLoader/>
        }

        return (
            <div className="invoice-profile">
                <div className="col-lg-12 col-md-12 col-xs-12 account-profile">
                    <div className="col-lg-12 col-md-12 col-xs-12" style={{display:"flex",alignItems: "center"}}>
                        {!this.props.gridPopup &&
                            <div className="col-lg-12 col-md-12 col-xs-12 profile-breadcrumb">
                                <Link to={"/" + this.props.settings.db + "/deals-v2"}>
                                    <i className="ion-ios-arrow-back"></i>{plainTranslate(this.props.settings.locale, 'Deals')}
                                </Link>
                            </div>
                        }
                    </div>
                    <div className="col-lg-4 col-md-4 col-xs-12 no-gutter">
                        <div className="col-lg-12 col-md-12 col-xs-12 account-profile-information">
                            <GeneralInformation 
                                document={deal}
                                docNo = {docNo}
                                stages={stages}
                                currency={currencySign}
                                update={this.update}
                                asyncUrl={'/' + this.props.settings.db + '/api/users'}
                                changeStageUrl = {'/' + this.props.settings.db + '/api/deals/'}
                                users={users}
                            />
                            <hr className="col-lg-12 col-md-12 col-xs-12 no-gutter"/>
                            <div className="account-profile-other-information">
                                <OrdersInfo orders={sales} />
                                <div class="single-chart">
                                    <h4 className="text-center">{plainTranslate(this.props.settings.locale, 'Sold')}</h4>
                                    <svg viewBox="0 0 36 36" class="circular-chart orange">
                                        <path class="circle-bg"
                                            d="M18 2.0845
                                                a 15.9155 15.9155 0 0 1 0 31.831
                                                a 15.9155 15.9155 0 0 1 0 -31.831"
                                        />
                                        <path class="circle"
                                            stroke-dasharray={`${dealSalePercentage}, 100`}
                                            d="M18 2.0845
                                                a 15.9155 15.9155 0 0 1 0 31.831
                                                a 15.9155 15.9155 0 0 1 0 -31.831"
                                        />
                                        <text x="18" y="20.35" class="percentage">{dealSalePercentage}%</text>
                                    </svg>
                                </div>
                                <LoggedChanges data={changes} title={'Logged changes'} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-8 col-xs-12 no-gutter-m no-gutter-r">
                        <div className="col-lg-12 col-md-12 col-xs-12 actions">
                            <div className="pull-right">
                                {roles && roles.hasView === true && 
                                    this.props.gridPopup && <a style={{ color: "#4eb0e2" }} href={"/" + this.props.settings.db + '/deal/' + this.props.match.params.id + '/profile'} target="_blank">{plainTranslate(this.props.settings.locale, 'Open in new tab')}<ExportOutlined style={{ fontSize: 15, marginLeft: 5 }} /></a>
                                }
                                {roles && roles.hasEdit === true && 
                                    <span>
                                        <Link to={"/" + this.props.settings.db + "/deals/"+this.props.match.params.id+'/edit'}>
                                            {plainTranslate(this.props.settings.locale, 'Edit')}<i className="fa fa-pencil-square-o"></i>
                                        </Link>
                                    </span>
                                }
                                <span>
                                    <a href={'/' + this.props.settings.db + '/deals/'+this.props.match.params.id+'/template/'+this.state.template+'/export.pdf'} target="_blank">
                                        {plainTranslate(this.props.settings.locale, 'Download')}<i className="fa fa-download"></i>
                                    </a>
                                </span>
                                </div>
                        </div>
                        <div className="col-md-12 col-xs-12">
                        {
                                    this.state.template ? 
                                        <div className="col-lg-12 col-md-12 col-xs-12 no-gutter">
                                            <div className="row pull-right template-select">
                                                 <Select
                                                    onChange={this.handleTemplateChange}
                                                    onBlurResetsInput={false}
                                                    onCloseResetsInput={false}
                                                    menuPlacement={"auto"}
                                                    options={templates}
                                                    value={templates.find(o => o.value === this.state.template)}
                                                />
                                            </div>
                                            <div className="row">
                                               <embed src={'/' + this.props.settings.db + '/deals/'+this.props.match.params.id+'/template/'+this.state.template+'/export.pdf?token='+localStorage.jwtToken} type="application/pdf"   height="775px" width="100%"/>
                                            </div>
                                        </div>
                                    
                                    :
                            <div className="col-lg-12 col-md-12 col-xs-12 actions">
                              <span>
                                <Link to={"/" + this.props.settings.db + "/administration/templates/documents"}>
                                    {plainTranslate(this.props.settings.locale, 'Create deal template')}
                                </Link>
                              </span>       
                            </div>
                            }
                    </div>   
                    </div>
                </div>                 
            </div>

        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
    salesProfileInformation: state.profiles
});
const mapDispatchToProps = dispatch => ({
    onDocumentProfileFetchData: (db, id) => dispatch(onDocumentProfileFetchData(db, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DealProfile);
