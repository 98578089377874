import React, { useEffect, useState, useRef } from 'react';
import Translate, { plainTranslate } from "../../../common/translate";
import { Card, Form, Input, Row, Col, Button } from 'antd';
import client from '../../../../actions/client'
import showAToast from '../../../common/showAToast'
import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Editor } from '@tinymce/tinymce-react';
import Popup from "../../../common/popup";
import { onAccountFetchData, onFieldSave } from '../../../../actions/actions';
import {connect} from 'react-redux';

const Notes = props => {
    const ref = useRef(null);
    const [description, setDescription] = useState(props.description);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);


    useEffect(() => {      
        if ( props.description) {
            setDescription( props.description);
        }
        
    }, []);

    const onFinish = (values) => {  
       setLoading(true)  
       props.onFieldSave(props.updateRoute, 'description', description, props.additionalData)
       .then(res => {
        setLoading(false)  
        onAccountFetchData(props.db, props.accountId, false)
        openEdit()
       })
        
    }

   const  onChange = (value, editor) => { 
       setDescription(value)
       
    }

   const imageUpload = (blobInfo, success, failure, progress) => {    
    var formData = new FormData();
      formData.append('file', blobInfo.blob(), blobInfo.filename());
      client
      .post('/' + props.db + '/api/settings/image/upload/tinymce', formData)
      .then(res => {       
        success(res.data.location)
      })
      .catch(err => {
        failure('HTTP Error: ' + err.response.code);
      })      
  }

    const openEdit = () => {        
        setOpen(!open);
    }



    return (
            <Card title={plainTranslate(props.locale, 'Note')}
              size="small"
              extra={description ? <EditOutlined onClick={openEdit}/> : <PlusCircleOutlined onClick={openEdit}/>}
              style={{marginTop: 10}}>
                {props.description ? 
                  <div  dangerouslySetInnerHTML={{ __html: props.description }} ></div>
                 :
                    <div className="div-inline m-left15">
                        <span className="text-italic" style={{color: '#a0a0a0'}}>
                            {plainTranslate(props.locale, 'No notes added.')}</span>
                    </div>
                }
                  
              {open && <Popup
              locale={props.locale}
              onClose={openEdit}>
            <section className="newPanel">
               <div className="panel-body">
               <Form
                ref={ref}
                name="description"                                
                onFinish={onFinish}
                initialValues={description}            
                layout="vertical" className="account-profile-popup-form">
                   
                    <Form.Item
                        label={plainTranslate(props.locale, 'Description')}>
                    <Editor 
                        apiKey='9e6m6n8f3yaairxxedx81e03olfhzeht5oubgv38egohvg5z'
                        value={description}
                        content={description}
                        initialValue={props.description ? props.description : null}
                        init={{
                        plugins: 'link image imagetools code lists advlist media emoticons table preview print searchreplace hr wordcount',
                        link_assume_external_targets: true,
                        file_picker_types: 'file image media',
                        relative_urls: false,
                        images_upload_handler: imageUpload,
                        toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code | bullist numlist outdent indent | undo redo | image code | media',
                        height : "200",
                        forced_root_block : "", 
                        force_br_newlines : true,
                        force_p_newlines : false
                        }}
                        onEditorChange={onChange}
                        />
                    </Form.Item> 
                   <Row justify="start" gutter={[16, 16]}>
                   <Col span={12} className="gutter-row">
                  <Button 
                  style={{  
                    paddingLeft: 45,
                    paddingBottom: 35}}
                   loading={loading}
                   className="button-primary"  
                   htmlType="submit">
                   {plainTranslate(props.locale, 'Save')}</Button>
                   </Col>
                   </Row>
               </Form>
</div>
</section>    
                  </Popup>}
           </Card>
    );
}

const mapStateToProps = state => ({    
});
const mapDispatchToProps = dispatch => ({
    onFieldSave: (route, id, value, additionalData) => dispatch(onFieldSave(route, id, value, additionalData)),
    onAccountFetchData: (db, id) => dispatch(onAccountFetchData(db, id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Notes);