import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {plainTranslate} from "../common/translate";

export default class NewMenuContent extends Component {

    render() {
        const settings = this.props.settings;
        
        return (
                <div className="row m-bot15" >
                    {settings.bundles.dealbundle && settings.plan.deals &&
                        <div className="col-sm-12">
                            <h4>{plainTranslate(settings.locale, 'Deals')}</h4>
                            {settings.formLinks.deals.map((link) => {
                                return <div className="col-sm-12 modal-body-item">
                                    <Link to={link.link } onClick={this.props.close}><span>{plainTranslate(settings.locale, link.label)}</span>
                                        <i className="ion-plus-round"></i>
                                    </Link>
                                </div>
                                })    
                            }
                        </div>
                    } 
                    {settings.bundles.salesbundle && settings.plan.sales &&
                        <div className="col-sm-12">
                            <h4>{plainTranslate(settings.locale, 'Sales order')}</h4>
                            {settings.formLinks.sales.map((link) => {
                                return <div className="col-sm-12 modal-body-item">
                                    <Link to={link.link } onClick={this.props.close}><span>{plainTranslate(settings.locale, link.label)}</span>
                                        <i className="ion-plus-round"></i>
                                    </Link>
                                </div>
                                })    
                            }
                        </div>
                    } 
                    {settings.bundles.paymentbundle && settings.plan.payments &&
                        <div className="col-sm-12">
                            <h4>{plainTranslate(settings.locale, 'Payment')}</h4>
                            {settings.formLinks.payments.map((link) => {
                                return <div className="col-sm-12 modal-body-item">
                                    <Link to={link.link} onClick={this.props.close}><span>{plainTranslate(settings.locale, link.label)}</span>
                                        <i className="ion-plus-round"></i>
                                    </Link>
                                </div>
                                })    
                            }
                        </div>
                    }
                    {settings.bundles.inventorybundle && settings.plan.inventory &&
                        <div className="col-sm-12">
                            <h4>{plainTranslate(settings.locale, 'Inventory receipt')}</h4>
                            {settings.formLinks.inventory.map((link) => {
                                return <div className="col-sm-12 modal-body-item">
                                    <Link to={link.link} onClick={this.props.close}><span>{plainTranslate(settings.locale, link.label)}</span>
                                        <i className="ion-plus-round"></i>
                                    </Link>
                                </div>
                                })    
                            }
                        </div>
                    }
                    {settings.bundles.inventorybundle && settings.plan.inventory &&
                        <div className="col-sm-12">
                            <h4>{plainTranslate(settings.locale, 'Items')}</h4>
                            {settings.formLinks.items.map((link) => {
                                return <div className="col-sm-12 modal-body-item">
                                    <Link to={link.link} onClick={this.props.close}><span>{plainTranslate(settings.locale, link.label)}</span>
                                        <i className="ion-plus-round"></i>
                                    </Link>
                                </div>
                                })    
                            }
                        </div>
                    }
                    {settings.plan.project &&
                        <div className="col-sm-12">
                            <h4>{plainTranslate(settings.locale, 'Projects and tasks')}</h4>
                            {settings.formLinks.projects.map((link) => {
                                return <div className="col-sm-12 modal-body-item">
                                    <Link to={link.link } onClick={this.props.close}><span>{plainTranslate(settings.locale, "Project")}: {plainTranslate(settings.locale, link.label)}</span>
                                        <i className="ion-plus-round"></i>
                                    </Link>
                                </div>
                                })    
                            }
                            {settings.formLinks.tasks.map((link) => {
                                return <div className="col-sm-12 modal-body-item">
                                    <Link to={link.link } onClick={this.props.close}><span>{plainTranslate(settings.locale, "Task")}: {plainTranslate(settings.locale, link.label)}</span>
                                        <i className="ion-plus-round"></i>
                                    </Link>
                                </div>
                                })    
                            }
                        </div>
                    }
                    <div className="col-sm-12">
                        <h4>{plainTranslate(settings.locale, 'Account')}</h4>
                        {settings.lists && settings.lists.map((list, key) => {
                            if(list.name){
                               return <div className="col-sm-12 modal-body-item">
                                    <Link to={'/' + settings.db + '/account/list/' + list.id + '/new'} onClick={this.props.close}>
                                            <span>{plainTranslate(settings.locale, list.name)}</span>
                                            <i className="ion-plus-round"></i>
                                    </Link>
                                </div>
                            }
                         })}
                    </div>
                    {settings.bundles.purchasebundle && (settings.plan.itemRequests || settings.plan.purchasing) &&
                        <div className="col-sm-12">
                            <h4>{plainTranslate(settings.locale, 'Item request')}</h4>
                            <div className="col-sm-12 modal-body-item">
                                <Link to={'/' + settings.db + '/item-requests/new'} onClick={this.props.close}>
                                        <span>{plainTranslate(settings.locale, 'Item request')}</span>
                                        <i className="ion-plus-round"></i>
                                </Link>
                            </div>
                        </div>
                    }
                </div>
    )}
}

