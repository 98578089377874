import React, { Component } from 'react';
import Translate, { plainTranslate } from '../common/translate';
import { connect } from 'react-redux';
import { onDocumentProfileFetchData, onFieldSave } from '../../actions/actions';
import { Link } from 'react-router-dom';
import FormLoader from '../forms/form-loader';
import './invoiceProfile.css';
import { Table, Dropdown, Menu, Button, Progress, Select, Upload } from 'antd';
import { ExportOutlined, LinkOutlined } from '@ant-design/icons';
import InlineRteEdit from './profile-rte';
import SlideModal from '../common/SlideModal';
import SimpleForm from "../redux-forms/form";
import Popup from '../common/popup';
import client from '../../actions/client';
import showAToast from '../common/showAToast';
import FileSaver from 'file-saver';
import axiosFileApi from '../../actions/axiosFileApi'
import 'antd/dist/antd.css';
import moment from 'moment';

const { Option } = Select;

class TaskProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeStage: null,
            active: 'description',
            isEdit: false,
            logTimeForm: false,
            addComment: false,
            openCopyTask: false,
            subTaskLogTime: false,
            editTaskId: null,
            loading: false
        };
        this.onBreadcrumbClick = this.onBreadcrumbClick.bind(this);
        //this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleOpenRte = this.handleOpenRte.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.closeLogTimePopup = this.closeLogTimePopup.bind(this);
        this.showAddComment = this.showAddComment.bind(this);
        this.closeAddComment = this.closeAddComment.bind(this);
        this.subTaskLogTime = this.subTaskLogTime.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.fetchUserList = this.fetchUserList.bind(this);
        this.watchTask = this.watchTask.bind(this);
    }

    componentDidMount() {
        let db;
        let id;
        if (this.props.popup) {
            db = this.props.db;
            id = this.props.id;
        } else {
            db = this.props.match.params.db;
            id = this.props.match.params.id;
        }

        this.setState({ loading: true });
        if (id) {
            this.props.onDocumentProfileFetchData('/' + db + '/api/task/' + id + '/profile-info').then(response => this.setState({ loading: false }));
            this.updateRoute = '/' + db + '/api/task/' + id + '/update-field';
        }
        
        if (this.props.title) {
            let titleArr = this.props.title;
            titleArr.map((item, key) => titleArr[key] = plainTranslate(this.props.settings.locale, item));
            document.title = titleArr.join(' ');
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.popup) {
            if (prevProps.id !== this.props.id) {
                this.setState({ active: 'description' });
                this.setState({ loading: true });
                if (this.props.id) {
                    this.props.onDocumentProfileFetchData('/' + this.props.db + '/api/task/' + this.props.id + '/profile-info').then(response => this.setState({ loading: false }));
                    this.updateRoute = '/' + this.props.db + '/api/task/' + this.props.id + '/update-field';
                }  
            }
        } else {
            if (prevProps.match.params.id !== this.props.match.params.id) {
                this.setState({ active: 'description' });
                this.setState({ loading: true });
                this.props.onDocumentProfileFetchData('/' + this.props.match.params.db + '/api/task/' + this.props.match.params.id + '/profile-info').then(response => this.setState({ loading: false }));
                this.updateRoute = '/' + this.props.match.params.db + '/api/task/' + this.props.match.params.id + '/update-field';
            }
        }
        if (prevProps.taskProfileInformation.invoiceProfileInfo && prevProps.taskProfileInformation.invoiceProfileInfo.task && (prevProps.taskProfileInformation.invoiceProfileInfo.task.stageId !== this.props.taskProfileInformation.invoiceProfileInfo.task.stageId)) {
            this.setState({ activeStage: this.props.taskProfileInformation.invoiceProfileInfo.task.stageId });
        }
    }

    onBreadcrumbClick(stage) {
        this.setState({ activeStage: stage }, function () {
            this.props.onFieldSave(this.updateRoute, 'stage', stage, null).then(() => {
                showAToast(plainTranslate(this.props.settings.locale, 'Data saved successfully!'), 'succSettings', 'success');
                if (this.props.update) {
                    this.props.update();
                }
            });
        });

    }

    onDownload = (file) => {
        console.log(this.props)
        var name = file.name

        axiosFileApi
            .get('/api/user/documents/files/' + file.uid + '/download', { responseType: 'blob' })
            .then(res => {
                var blob = new Blob([res.data], { type: res.headers['content-type' + ";charset=utf-8"] });
                var downloadUrl = URL.createObjectURL(blob);
                var a = document.createElement("a");
                a.href = downloadUrl;
                a.download = name;
                document.body.appendChild(a);
                a.click();
            })
            .catch(err => {
                //  toast.error('Download error!', {autoClose: 10000})
            });

        //   FileSaver.saveAs(file.url);
    }

    changeTab(tab) {
        this.setState({ active: tab, isEdit: false });
    }

/*    setWrapperRef(node) {
        this.wrapperRef = node;
        document.addEventListener('mousedown', this.handleClickOutside);
    }*/

/*    handleClickOutside(event) {
        if (this.state.isEdit === false && (event.target.className === 'profile-description-rte' || (event.target.parentElement && event.target.parentElement.className === 'profile-description-rte'))) {
            //this.setState({ isEdit: true });
        }
    }*/

    handleClose(val) {
        let db;
        let id;
        if (this.props.popup) {
            db = this.props.db;
            id = this.props.id;
        } else {
            db = this.props.match.params.db;
            id = this.props.match.params.id;
        }
        if (val === true) {
            this.props.onDocumentProfileFetchData('/' + db + '/api/task/' + id + '/profile-info');
        }

        this.setState({ isEdit: false });
    }

    handleOpenRte(event) {
        this.setState({ isEdit: true });
    }

    closeLogTimePopup() {
        let db;
        let id;
        if (this.props.popup) {
            db = this.props.db;
            id = this.props.id;
        } else {
            db = this.props.match.params.db;
            id = this.props.match.params.id;
        }
        this.props.onDocumentProfileFetchData('/' + db + '/api/task/' + id + '/profile-info');
        this.setState({ logTimeForm: false, subTaskLogTime: false });
    }

    showAddComment() {
        this.setState({ addComment: true })
    }

    closeAddComment() {
        let db;
        let id;
        if (this.props.popup) {
            db = this.props.db;
            id = this.props.id;
        } else {
            db = this.props.match.params.db;
            id = this.props.match.params.id;
        }
        this.props.onDocumentProfileFetchData('/' + db + '/api/task/' + id + '/profile-info');
        this.setState({ addComment: false });
    }

    onSaveTask(response) {
        let db;
        let id;
        if (this.props.popup) {
            db = this.props.db;
            id = this.props.id;
        } else {
            db = this.props.match.params.db;
            id = this.props.match.params.id;
        }
        this.props.onDocumentProfileFetchData('/' + db + '/api/task/' + id + '/profile-info');
        this.setState({ isOpenTask: false, openCopyTask: false, editTask: false, editTaskId: null });
    }

    handleChangeField = (e, task, value, type) => {
        if (e) {
            e.preventDefault();
        }
        let db;
        let id;
        if (this.props.popup) {
            db = this.props.db;
            id = this.props.id;
        } else {
            db = this.props.match.params.db;
            id = this.props.match.params.id;
        }

        client
            .post('/' + db + '/api/task/' + task.id + '/update-field', {
                id: type,
                value: value
            })
            .then(response => response.data)
            .then(response => {
                if ('errors' in response) {
                    showAToast(plainTranslate(this.props.settings.locale, response.errors), 'errSettings', 'error');
                } else {
                    if (this.props.update) {
                        this.props.update();
                    }
                    this.props.onDocumentProfileFetchData('/' + db + '/api/task/' + id + '/profile-info');
                    showAToast(plainTranslate(this.props.settings.locale, response.success), 'succSettings', 'success');
                    if(this.props.gridPopup){
                        this.props.updateGridData();
                    }
                }
            });
    }

    copyTask(id) {
        this.setState({ openCopyTask: true, copyTaskId: id });
    }

    subTaskLogTime(id) {
        this.setState({ subTaskLogTime: true, taskId: id });
    }

    handleDelete(file) {
        let task = this.props.taskProfileInformation.invoiceProfileInfo ? this.props.taskProfileInformation.invoiceProfileInfo.task : {};
        let images = task.images;
        let updatedImages = [];
        for (let i = 0; i < images.length; i++) {
            if (images[i].id !== file.uid) {
                updatedImages.push(images[i]);
            }
        }
        this.handleChangeField(null, task, updatedImages, 'images');
    }

    fetchUserList(val) {
        if(val){
            clearTimeout(this.inputTimer);
            this.inputTimer = setTimeout(() => {
                this.setState({selectLoading: true});
                client.get(this.props.match.params.db + "/api/find/users/search?q="+val).then(response => {
                    this.setState({users: response.data});
                    this.setState({selectLoading: false});
                });   
            }, 1000);   
        } 
    }

    fetchAccountList(val) {
        if(val){
            clearTimeout(this.inputTimer);
            this.inputTimer = setTimeout(() => {
                this.setState({selectLoading: true});
                client.get(this.props.match.params.db + "/api/billing-data-accounts-filter?q="+val).then(response => {
                    this.setState({accounts: response.data});
                    this.setState({selectLoading: false});
                });   
            }, 1000);   
        } 
    }

    fetchProjectList(val) {
        if(val){
            clearTimeout(this.inputTimer);
            this.inputTimer = setTimeout(() => {
                this.setState({selectLoading: true});
                client.get(this.props.match.params.db + "/api/select/proiject-search?q="+val).then(response => {
                    this.setState({projects: response.data});
                    this.setState({selectLoading: false});
                });   
            }, 1000);   
        } 
    }

    watchTask(){
        let db;
        let id;
        if (this.props.popup) {
            db = this.props.db;
            id = this.props.id;
        } else {
            db = this.props.match.params.db;
            id = this.props.match.params.id;
        }

        client.get(db + "/api/task/" + id + "/watch")
        .then(response => response.data)
        .then(response => {
            if ('errors' in response) {
                showAToast(plainTranslate(this.props.settings.locale, response.errors), 'errSettings', 'error');
            } else {
                showAToast(plainTranslate(this.props.settings.locale, response.success), 'succSettings', 'success');
                this.props.onDocumentProfileFetchData('/' + db + '/api/task/' + id + '/profile-info');
            }
        });
    }

    render() {
        let task = this.props.taskProfileInformation.invoiceProfileInfo ? this.props.taskProfileInformation.invoiceProfileInfo.task : {};
        let stages = this.props.taskProfileInformation.invoiceProfileInfo ? this.props.taskProfileInformation.invoiceProfileInfo.stages : [];
        let taskForms = this.props.taskProfileInformation.invoiceProfileInfo ? this.props.taskProfileInformation.invoiceProfileInfo.taskForms : [];
        let users = this.props.taskProfileInformation.invoiceProfileInfo ? this.props.taskProfileInformation.invoiceProfileInfo.users : [];
        let boards = this.props.taskProfileInformation.invoiceProfileInfo ? this.props.taskProfileInformation.invoiceProfileInfo.boards : [];
        let accounts = this.props.taskProfileInformation.invoiceProfileInfo ? this.props.taskProfileInformation.invoiceProfileInfo.accounts : [];
        let priorityOptions = this.props.taskProfileInformation.invoiceProfileInfo ? this.props.taskProfileInformation.invoiceProfileInfo.priorityOptions : [];
        let projects = this.props.taskProfileInformation.invoiceProfileInfo ? this.props.taskProfileInformation.invoiceProfileInfo.projects : [];

        let subTasks = this.props.taskProfileInformation.invoiceProfileInfo ? this.props.taskProfileInformation.invoiceProfileInfo.subTasks : [];
        let commentsCount = this.props.taskProfileInformation.invoiceProfileInfo ? this.props.taskProfileInformation.invoiceProfileInfo.commentsCount : 0;
        let tasksCount = this.props.taskProfileInformation.invoiceProfileInfo ? this.props.taskProfileInformation.invoiceProfileInfo.tasksCount : 0;
        let db;
        let id;
        if (this.props.popup) {
            db = this.props.db;
            id = this.props.id;
        } else {
            db = this.props.match.params.db;
            id = this.props.match.params.id;
        }

        const style = {
            margin: '0 5px',
        };
        let fileList = [];
        if (task && task.images) {
            task.images.map(file => {
                fileList.push({
                    uid: file.uid,
                    name: file.name,
                    status: "done",
                    url: file.url
                })
            })
        }


        const props = {
            fileList: fileList,
            onDownload: this.onDownload,
            showUploadList: {
                showDownloadIcon: true
            }
        };

        const subTasksCols = [
            {
                key: 'actions',
                dataIndex: 'actions',
                title: plainTranslate(this.props.settings.locale, 'Actions'),
                render: (text, record) => {
                    return (
                        !record.type ? <span className="table-actions">
                            <Link to={'/' + db + '/task/' + record.id + '/profile'} title={plainTranslate(this.props.settings.locale, 'Profile')}><i style={style} className="fa fa-eye"></i></Link>
                            <i title={plainTranslate(this.props.settings.locale, 'Edit')} style={{ margin: '0 5px', cursor: "pointer" }} onClick={() => this.setState({ editTask: true, editTaskId: record.id })} className="fa fa-pencil-square-o"></i>
                            <i title={plainTranslate(this.props.settings.locale, 'Copy task')} style={{ margin: '0 5px', cursor: "pointer" }} onClick={() => this.copyTask(record.id)} className="fa fa-clone"></i>
                            <i title={plainTranslate(this.props.settings.locale, 'Log time')} style={{ margin: '0 5px', cursor: "pointer" }} onClick={() => this.subTaskLogTime(record.id)} className="ion-android-time"></i>
                        </span> : <span></span>
                    )
                },
                width: 150,
            },
            {
                'dataIndex': 'name',
                'title': plainTranslate(this.props.settings.locale, 'Name'),
                'width': 100,
                render: (text, record) => {
                    return (<span className={record.stageStatus === 'Closed' ? 'text-line-through' : ''} onClick={() => this.setState({ showTaskHint: true, taskHint: record })}>{record.name}</span>)
                }
            },
            {
                'dataIndex': 'status',
                'title': plainTranslate(this.props.settings.locale, 'Status'),
                'width': 200,
                render: (text, record) => {
                    const StagesOptions = (
                        <Menu>
                            {stages.map((option, i) => (
                                <Menu.Item key={i}>
                                    <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e, record, option.id, "stage")}>
                                        {plainTranslate(this.props.settings.locale, option.label)}
                                    </a>
                                </Menu.Item>
                            ))
                            }
                        </Menu>
                    );
                    return (!record.type ? <span>
                        <Dropdown overlay={StagesOptions}>
                            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                <i style={style} className="fa fa-flag-o"></i>
                            </a>
                        </Dropdown>
                        <span style={{ whiteSpace: "nowrap" }} className={record.stageStatus} style={record.color ? { background: record.color, border: '1px solid #364a5a', color: "#000" } : {}}>{plainTranslate(this.props.settings.locale, text)}</span>
                    </span> : <span></span>)
                }
            },
            {
                'dataIndex': 'dueDate',
                'title': plainTranslate(this.props.settings.locale, 'Due Date'),
                'width': 100,
            },
            {
                'dataIndex': 'user',
                'title': plainTranslate(this.props.settings.locale, 'User'),
                'width': 50,
                render: (text, record) => (
                    !record.type ? <Select
                        onChange={(val) => this.handleChangeField(null, record, val, 'user')}
                        defaultValue={record.user} style={{ width: 120 }}>
                        {
                            users.map(option => (
                                <Option value={option.id}>{option.label}</Option>
                            ))
                        }
                    </Select> : <span></span>
                ),
            },
            {
                'dataIndex': 'completion',
                'title': plainTranslate(this.props.settings.locale, 'Completion'),
                'width': 100,
                render: (text, record) => {
                    const completionOptions = (
                        <Menu>
                            <Menu.Item key={1}>
                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e, record, 10, 'completion')}>
                                    10
                                </a>
                            </Menu.Item>
                            <Menu.Item key={2}>
                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e, record, 20, 'completion')}>
                                    20
                                </a>
                            </Menu.Item>
                            <Menu.Item key={3}>
                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e, record, 30, 'completion')}>
                                    30
                                </a>
                            </Menu.Item>
                            <Menu.Item key={4}>
                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e, record, 40, 'completion')}>
                                    40
                                </a>
                            </Menu.Item>
                            <Menu.Item key={5}>
                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e, record, 50, 'completion')}>
                                    50
                                </a>
                            </Menu.Item>
                            <Menu.Item key={6}>
                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e, record, 60, 'completion')}>
                                    60
                                </a>
                            </Menu.Item>
                            <Menu.Item key={7}>
                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e, record, 70, 'completion')}>
                                    70
                                </a>
                            </Menu.Item>
                            <Menu.Item key={8}>
                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e, record, 80, 'completion')}>
                                    80
                                </a>
                            </Menu.Item>
                            <Menu.Item key={9}>
                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e, record, 90, 'completion')}>
                                    90
                                </a>
                            </Menu.Item>
                            <Menu.Item key={10}>
                                <a target="_blank" rel="noopener noreferrer" href='#' onClick={(e) => this.handleChangeField(e, record, 100, 'completion')}>
                                    100
                                </a>
                            </Menu.Item>
                        </Menu>
                    );
                    return (!record.type ? <span>
                        <Dropdown overlay={completionOptions}>
                            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                <i style={style} className="fa fa-line-chart"></i>
                            </a>
                        </Dropdown>
                        <Progress style={{ width: "70%", marginLeft: "10px" }} percent={record.completion} />
                    </span> : <span></span>)
                }
            },
        ];

        let activities = this.props.taskProfileInformation.invoiceProfileInfo ? this.props.taskProfileInformation.invoiceProfileInfo.taskActivities : [];

        const activitiesCols = [
            {
                'dataIndex': 'date',
                'title': plainTranslate(this.props.settings.locale, 'Date'),
                'width': 100,
                render: (text, record) => (
                    <div>{text ? moment((text).replaceAll('.', '/'), 'DD/MM/YYYY H:m').format(this.props.settings.dateFormat+ ' H:m') : ''}
                    </div>
                ),
            },
            {
                'dataIndex': 'action',
                'title': plainTranslate(this.props.settings.locale, 'Action'),
                'width': 100,
            },
            {
                'dataIndex': 'loggedTime',
                'title': plainTranslate(this.props.settings.locale, 'Logged time'),
                'width': 100,
            },
            {
                'dataIndex': 'user',
                'title': plainTranslate(this.props.settings.locale, 'User'),
                'width': 100,
            },
        ];

        let comments = this.props.taskProfileInformation.invoiceProfileInfo ? this.props.taskProfileInformation.invoiceProfileInfo.taskComments : [];

        const commentsCols = [
            {
                'dataIndex': 'date',
                'title': plainTranslate(this.props.settings.locale, 'Date'),
                'width': 100,
                render: (text, record) => (
                    <div>{text ? moment((text).replaceAll('.', '/'), 'DD/MM/YYYY H:m').format(this.props.settings.dateFormat + ' H:m') : ''}
                    </div>
                ),
            },
            {
                'dataIndex': 'action',
                'title': plainTranslate(this.props.settings.locale, 'Action'),
                'width': 100,
            },
            {
                'dataIndex': 'description',
                'title': plainTranslate(this.props.settings.locale, 'Description'),
                'width': 100,
                render: (text, record) => (
                    <div dangerouslySetInnerHTML={{ __html: record.description }}>
                    </div>
                ),
            },
            {
                'dataIndex': 'user',
                'title': plainTranslate(this.props.settings.locale, 'User'),
                'width': 100,
            },
        ];

        if ((this.props.taskProfileInformation.loading && this.state.addComment === null) || this.state.loading) {
            return <FormLoader />
        }

        let activeStage = task ? task.stageId : '';

        if (this.state.activeStage) {
            activeStage = this.state.activeStage;
        }

        let menuOptions = [];
        if (taskForms && taskForms.length) {
            menuOptions = (
                <Menu>
                    {taskForms.map((item, i) => {
                        return <Menu.Item key={i}>
                            <a onClick={() => this.setState({ isOpenTask: true, taskForm: item.form, taskProject: task.projectId })}>
                                <Translate locale={this.props.settings.locale} value={item.label} />
                            </a>
                        </Menu.Item>
                    })}
                </Menu>
            );
        }

        return (
            <div className="invoice-profile item-profile">
                {
                    stages && stages.length > 0 &&
                    <div className="status-container col-md-12 col-xs-12">
                        <div className="status-breadcrumb">
                            {
                                stages.map((stage, key) => {
                                    return <a
                                        className={activeStage == stage.id ? 'active' : ''}
                                        href="#" key={key}
                                        onClick={() => this.onBreadcrumbClick(stage.id)}>
                                        <span className="breadcrumb__inner">
                                            <span className="breadcrumb__desc">{stage.label}</span>
                                        </span>
                                    </a>
                                })
                            }
                        </div>
                    </div>
                }
                {task &&
                    <div className="col-lg-12 col-md-12 col-xs-12 account-profile">
                        <div className="col-lg-12 col-md-12 col-xs-12 profile-breadcrumb">
                        {!this.props.gridPopup &&
                            <Link to={"/" + db + "/tasks-v2"}>
                                <i className="ion-ios-arrow-back"></i>{plainTranslate(this.props.settings.locale, 'Tasks')}
                            </Link>
                        }
                            <div className="pull-right">
                                {this.props.gridPopup && <a style={{ marginRight: 20 }} href={"/" + db + '/task/' + id + '/profile'} target="_blank">{plainTranslate(this.props.settings.locale, 'Open in new tab')}<ExportOutlined style={{ fontSize: 15, marginLeft: 5 }} /></a>}
                                <span onClick={() => this.setState({ editTask: true })}><a href="#">{plainTranslate(this.props.settings.locale, 'Edit')}<i style={{ marginLeft: 5 }} className="fa fa-pencil-square-o"></i></a></span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-xs-12 no-gutter">
                            <div className="col-lg-12 col-md-12 col-xs-12 account-profile-information">
                                <div className="account-profile-information-general">
                                    <div className="line col-md-12 col-xs-12 no-gutter">
                                        <div className="col-md-12 col-xs-12" style={{ paddingTop: "25px" }}>
                                            <h3>{task.name}</h3>
                                        </div>
                                    </div>
                                    <div className="line col-md-12 col-xs-12 no-gutter">
                                        <div className="col-lg-8 col-md-8 col-xs-8" style={{ marginTop: 30, fontSize: 14, paddingLeft: 7 }}>
                                            <span style={{ cursor: 'pointer' }} onClick={() => this.watchTask()}>
                                                {plainTranslate(this.props.settings.locale, (task.isWatching ? 'Unwatch' : 'Watch'))}
                                                <i style={{verticalAlign: "middle",marginLeft: 5}} className="ion-eye"></i></span>
                                        </div>
                                    </div>
                                    <div className="line col-md-12 col-xs-12 no-gutter">
                                        <div className="col-lg-8 col-md-8 col-xs-8" style={{ marginTop: 30, fontSize: 14, paddingLeft: 7 }}>
                                            <span style={{ cursor: 'pointer' }}
                                                onClick={() => this.setState({ logTimeForm: true })}>
                                                {plainTranslate(this.props.settings.locale, 'Log time')}
                                                <i className="ion-android-time"></i></span>
                                        </div>
                                    </div>
                                    {task.parent &&
                                        <div className="line col-md-12 col-xs-12 no-gutter">
                                            <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(this.props.settings.locale, 'Parent task')}:</span></div>
                                            <div className="col-lg-8 col-md-8 col-xs-8 content">
                                                <div className="created">
                                                    {task &&
                                                        <Link to={'/' + db + '/task/' + task.parentId + '/profile'} className="link">{task.parent} </Link>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="line col-md-12 col-xs-12 no-gutter">
                                        <div className="col-lg-4 col-md-4 col-xs-4 title"><span>
                                            {plainTranslate(this.props.settings.locale, 'Project')}:</span></div>
                                            <div className="col-lg-8 col-md-8 col-xs-8 content">
                                                <div className="created">
                                                {task && 
                                                    <Select 
                                                    className="small-select"
                                                    size='small' 
                                                    filterOption={false}
                                                    showSearch={true}
                                                    loading={this.state.selectLoading}
                                                    onSearch={(value) => this.fetchProjectList(value)}
                                                    onChange={(val) => this.handleChangeField(null, task, val, 'project')}
                                                    value={task.project} 
                                                    style={{ width: 200, height: 25 }}
                                                    onDropdownVisibleChange={(e) => this.setState({projects: null})}
                                                    >
                                                        <Option value={null} style={{height: 25}}></Option>                                                        
                                                        {
                                                            (this.state.projects ? this.state.projects : projects).map(option =>(
                                                                <Option  value={option.id}>{option.label}</Option>
                                                            ))
                                                        }
                                                    </Select>
                                                }
                                                <Link to={'/' + db + '/project/' + task.projectId + '/profile'} className="link"><LinkOutlined /></Link>
                                             </div>
                                        </div>   
                                    </div>
                                    <div className="line col-md-12 col-xs-12 no-gutter">
                                        <div className="col-lg-4 col-md-4 col-xs-4 title"><span>
                                            {plainTranslate(this.props.settings.locale, 'Account')}:</span></div>
                                        <div className="col-lg-8 col-md-8 col-xs-8 content">
                                            <div className="created">
                                                {task &&
                                                    <Select 
                                                    className="small-select"
                                                    size='small' 
                                                    filterOption={false}
                                                    showSearch={true}
                                                    loading={this.state.selectLoading}
                                                    onSearch={(value) => this.fetchAccountList(value)}
                                                    onChange={(val) => this.handleChangeField(null, task, val, 'account')}
                                                    value={task.account} 
                                                    style={{ width: 200, height: 25 }}
                                                    onDropdownVisibleChange={(e) => this.setState({accounts: null})}
                                                    >
                                                        <Option value={null} style={{height: 25}}></Option>                                                        
                                                        {
                                                            (this.state.accounts ? this.state.accounts : accounts).map(option =>(
                                                                <Option  value={option.id}>{option.label}</Option>
                                                            ))
                                                        }
                                                    </Select>
                                                }
                                                <Link to={'/' + db + '/account/' + task.accountId + '/profile'} className="link"><LinkOutlined /> </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="line col-md-12 col-xs-12 no-gutter">
                                        <div className="col-lg-4 col-md-4 col-xs-4 title"><span>
                                            {plainTranslate(this.props.settings.locale, 'User')}:</span></div>
                                        <div className="col-lg-8 col-md-8 col-xs-8 content">
                                            <div className="created">
                                               {task && 
                                                    <Select 
                                                    className="small-select"
                                                    size='small' 
                                                    filterOption={false}
                                                    showSearch={true}
                                                    loading={this.state.selectLoading}
                                                    onSearch={(value) => this.fetchUserList(value)}
                                                    onChange={(val) => this.handleChangeField(null, task, val, 'user')}
                                                    value={task.user} 
                                                    style={{ width: 200, height: 25 }}
                                                    onDropdownVisibleChange={(e) => this.setState({users: null})}
                                                    >
                                                        <Option value={null} style={{height: 25}}></Option>                                                        
                                                        {
                                                            (this.state.users ? this.state.users : users).map(option =>(
                                                                <Option  value={option.id}>{option.label}</Option>
                                                            ))
                                                        }
                                                    </Select>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="line col-md-12 col-xs-12 no-gutter">
                                        <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(this.props.settings.locale, 'Board')}:</span></div>
                                        <div className="col-lg-8 col-md-8 col-xs-8 content">
                                            <div className="created">
                                                {task &&
                                                    <Select
                                                        onChange={(val) => this.handleChangeField(null, task, val, 'board')}
                                                        size='small'
                                                        className="small-select"
                                                        value={task.board}
                                                        style={{ width: 90, height: 25 }}>  
                                                            <Option value={null} style={{height: 25}}></Option>
                                                            {boards.map(option => (
                                                                <Option value={option.id}>{option.label}</Option>
                                                            ))}
                                                    </Select>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="line col-md-12 col-xs-12 no-gutter">
                                        <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(this.props.settings.locale, 'Priority')}:</span></div>
                                        <div className="col-lg-8 col-md-8 col-xs-8 content">
                                            <div className="created">
                                                {task &&
                                                    <Select
                                                        onChange={(val) => this.handleChangeField(null, task, val, 'priority')}
                                                        size='small'
                                                        className="small-select"
                                                        value={task.priority}
                                                        style={{ width: 120, height: 25 }}>  
                                                            {priorityOptions.map(option => (
                                                                <Option value={option.value}>{option.label}</Option>
                                                            ))}
                                                    </Select>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="line col-md-12 col-xs-12 no-gutter">
                                        <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(this.props.settings.locale, 'Completion')}:</span></div>
                                        <div className="col-lg-8 col-md-8 col-xs-8 content">
                                            <div className="created">
                                                {task &&
                                                    <Select
                                                        onChange={(val) => this.handleChangeField(null, task, val, 'completion')}
                                                        size='small'
                                                        className="small-select"
                                                        value={task.completion + '%'}
                                                        style={{ width: 90, height: 25 }}>  
                                                            {[0,10,20,30,40,50,60,70,80,90,100].map((item, i) => (
                                                                <Option value={item}>{item}%</Option>
                                                            ))}
                                                    </Select>
                                                }
                                                <Progress style={{ width: "50%", marginLeft: "10px" }} percent={task.completion} showInfo={false}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="line col-md-12 col-xs-12 no-gutter">
                                        <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(this.props.settings.locale, 'Start Date')}:</span></div>
                                        <div className="col-lg-8 col-md-8 col-xs-8 content">
                                            <div className="created">
                                                {task ? task.dateStart ? moment((task.dateStart).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : '' : ''}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="line col-md-12 col-xs-12 no-gutter">
                                        <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(this.props.settings.locale, 'End Date')}:</span></div>
                                        <div className="col-lg-8 col-md-8 col-xs-8 content">
                                            <div className="created">
                                                {task ? task.dateEnd ? moment((task.dateEnd).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : '' : ''}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="line col-md-12 col-xs-12 no-gutter">
                                        <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(this.props.settings.locale, 'Due Date')}:</span></div>
                                        <div className="col-lg-8 col-md-8 col-xs-8 content">
                                            <div className="created">
                                                {task ? task.dateDue ? moment((task.dateDue).replaceAll('.', '/'), 'DD/MM/YYYY').format(this.props.settings.dateFormat) : '' : ''}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="line col-md-12 col-xs-12 no-gutter">
                                        <div className="col-lg-4 col-md-4 col-xs-4 title"><span>{plainTranslate(this.props.settings.locale, 'Total logged time')}:</span></div>
                                        <div className="col-lg-8 col-md-8 col-xs-8 content">
                                            <div className="created">
                                                {task ? task.totalLoggedTime : ''}
                                            </div>
                                        </div>
                                    </div>
                                    {task.additionalFields &&
                                        task.additionalFields.map(task => {
                                            return <div className="line col-md-12 col-xs-12 no-gutter">
                                                <div className="col-lg-4 col-md-4 col-xs-4 title"><span style={{ textTransform: "capitalize" }}>{plainTranslate(this.props.settings.locale, Object.keys(task))}:</span></div>
                                                <div className="col-lg-8 col-md-8 col-xs-8 content">
                                                    {Array.isArray(Object.values(task)[0] && Object.values(task)[0].length) ?
                                                        <><img src={(Object.values(task)[0][0]['url'])} style={{ width: '250px' }} /></>
                                                        :
                                                        <div className="created" style={{ textTransform: "capitalize" }}>
                                                            {Object.values(task)}
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        })
                                    }

                                    {
                                        this.state.changeStagePopup &&
                                        <Popup locale={this.props.settings.locale}
                                            title={plainTranslate(this.props.settings.locale, 'Change stage')}
                                            onClose={() => this.setState({ changeStagePopup: false })}>
                                            <section className="newPanel">
                                                <div className="panel-body">
                                                    <div className="row m-bot15">
                                                        <div className="text-center">

                                                            <h3>
                                                                <Translate locale={this.props.settings.locale} value="Are you sure?" />
                                                            </h3>
                                                            <button className="button-outlines green text-center"
                                                                onClick={() => this.setState({ changeStagePopup: false })}>
                                                                <Translate locale={this.props.settings.locale} value="No" />
                                                            </button>
                                                            <button className="button-primary-edited text-center btn-ok"
                                                                onClick={() => this.handleChange(this.state.changeStagePopup)}>
                                                                <Translate locale={this.props.settings.locale} value="Yes" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </Popup>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-8 col-xs-12 no-gutter-m no-gutter-r">
                            <div className="col-lg-12 col-md-12 col-xs-12 account-profile-tabs">
                                <div className="nav nav-tabs" id="tabBtn">
                                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 basic no-gutter">
                                        <button className={this.state.active == 'description' ? 'btn-tabs active' : 'btn-tabs'} type="button" onClick={() => this.changeTab('description')}>
                                            {plainTranslate(this.props.settings.locale, 'Description')}
                                        </button>
                                        {task && !task.parent &&
                                            <button className={this.state.active == 'subTasks' ? 'btn-tabs active' : 'btn-tabs'} type="button" onClick={() => this.changeTab('subTasks')}>
                                                {plainTranslate(this.props.settings.locale, 'Subtasks') + " (" + tasksCount + ")"}
                                            </button>
                                        }
                                        <button className={this.state.active == 'activities' ? 'btn-tabs active' : 'btn-tabs'} type="button" onClick={() => this.changeTab('activities')}>
                                            {plainTranslate(this.props.settings.locale, 'Activities')}
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-12 col-xs-12 no-gutter">
                                    {
                                        this.state.active === 'description' &&
                                        <React.Fragment>
                                            <InlineRteEdit id={'description'}
                                                value={task ? task.description : ''}
                                                updateRoute={this.updateRoute}
                                                ref={this.setWrapperRef}
                                                isEdit={this.state.isEdit}
                                                handleClose={this.handleClose}
                                                handleOpen={this.handleOpenRte}
                                                taskId={task.id}
                                            />
                                            {task && task.images && task.images.length > 0 &&
                                                <span className="projectsAndTasksImagePreview">
                                                    <h4>Attachments</h4>

                                                    <Upload
                                                        fileList={fileList}
                                                        listType="picture"
                                                        className="upload-list-inline"
                                                        onRemove={this.handleDelete}
                                                        onDownload={this.onDownload}
                                                        showUploadList={{
                                                            showDownloadIcon: true,
                                                            showPreviewIcon: false
                                                        }}
                                                    ></Upload>
                                                </span>
                                            }
                                        </React.Fragment>

                                    }
                                    {
                                        this.state.active === 'subTasks' &&
                                        <div className="col-lg-12 col-md-12 col-xs-12 no-gutter itemHistory-table">
                                            {taskForms.length > 0 ?
                                                <Dropdown overlay={menuOptions} className="btn-round btn-blue">
                                                    <Link to="#" data-toggle="dropdown" title="New Task">
                                                        <i className="ion-android-add"></i>
                                                    </Link>
                                                </Dropdown>
                                                :
                                                <a href='javascript:void(0)'
                                                    onClick={() => this.setState({ popup: true })}
                                                    className="btn-round btn-blue">
                                                    <i className="ion-android-add"></i>
                                                </a>
                                            }
                                            <Table className="projectsAndTasksTable" rowKey={record => record.id} columns={subTasksCols} dataSource={subTasks} />
                                        </div>
                                    }
                                    {
                                        this.state.active === 'activities' &&
                                        <div className="col-lg-12 col-md-12 col-xs-12 no-gutter itemHistory-table">
                                            <Table rowKey={record => record.id} columns={activitiesCols} dataSource={activities} />
                                        </div>
                                    }
                                </div>
                            </div>
                            <h4>Comments:</h4>
                            <div className="col-lg-12 col-md-12 col-xs-12 no-gutter itemHistory-table" style={{marginBottom: 30}}>
                                <Table rowKey={record => record.id} columns={commentsCols} dataSource={comments} />
                                {
                                    !this.state.addComment &&
                                    <Button style={{ margin: 15, paddingBottom: 35 }} onClick={this.showAddComment}>
                                        <i style={{ fontSize: "20px", position: "relative", top: "3px", left: "-6px" }}
                                            className="ion-ios-chatbubble-outline"></i>
                                        {plainTranslate(this.props.settings.locale, 'Add comment')}
                                    </Button>
                                }
                                {
                                    this.state.addComment &&
                                    <React.Fragment>
                                        <InlineRteEdit id={'comment'}
                                            value={''}
                                            updateRoute={'/' + db + '/api/task/' + id + '/comment/new'}
                                            ref={this.setWrapperRef}
                                            isEdit={true}
                                            handleClose={this.closeAddComment}
                                        />
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </div>
                }
                {
                    this.state.logTimeForm &&
                    <SlideModal onClose={() => this.setState({ logTimeForm: false })} title={plainTranslate(this.props.settings.locale, 'Log time')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <SimpleForm
                                        saveRoute={db + '/api/log-time'}
                                        formRoute={db + '/api/log-time/'}
                                        match={{ params: { id: 0 } }}
                                        onSuccessfulSave={this.closeLogTimePopup}
                                        isNew={true}
                                        popup={true}
                                        onCancel={() => this.setState({ logTimeForm: false })}
                                        type={'task'}
                                        refs={id}
                                        form={'initializeFromState'}
                                    />
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }
                {
                    this.state.isOpenTask &&
                    <SlideModal onClose={() => this.setState({ isOpenTask: false })} title={plainTranslate(this.props.settings.locale, 'Task')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <SimpleForm
                                        formRoute={db + '/api/tasks/'}
                                        saveRoute={db + '/api/tasks'}
                                        match={{ params: { id: 0 } }}
                                        onSuccessfulSave={(response) => this.onSaveTask(response)}
                                        onCancel={() => this.setState({ isOpenTask: false })}
                                        isNew={true}
                                        popup={true}
                                        parent={id}
                                        type={this.state.taskForm}
                                        refs={this.state.taskProject ? this.state.taskProject : null}
                                        form={'initializeFromState'}
                                    />
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }
                {
                    this.state.subTaskLogTime &&
                    <SlideModal onClose={() => this.setState({ subTaskLogTime: false })} title={plainTranslate(this.props.settings.locale, 'Log time')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <SimpleForm
                                        saveRoute={db + '/api/log-time'}
                                        formRoute={db + '/api/log-time/'}
                                        match={{ params: { id: 0 } }}
                                        onSuccessfulSave={this.closeLogTimePopup}
                                        isNew={true}
                                        popup={true}
                                        onCancel={() => this.setState({ subTaskLogTime: false })}
                                        type={'task'}
                                        refs={this.state.taskId}
                                        parent={id}
                                        form={'initializeFromState'}
                                    />
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }
                {
                    this.state.openCopyTask &&
                    <SlideModal onClose={() => this.setState({ openCopyTask: false })} title={plainTranslate(this.props.settings.locale, 'Task')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <SimpleForm
                                        formRoute={db + '/api/tasks/'}
                                        saveRoute={db + '/api/tasks'}
                                        match={{ params: { id: 0 } }}
                                        onSuccessfulSave={(response) => this.onSaveTask(response)}
                                        onCancel={() => this.setState({ openCopyTask: false })}
                                        isNew={true}
                                        popup={true}
                                        copy={this.state.copyTaskId}
                                        form={'initializeFromState'}
                                    />
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }
                {
                    this.state.editTask &&
                    <SlideModal onClose={() => this.setState({ editTask: false, editTaskId: null })} title={plainTranslate(this.props.settings.locale, 'Task')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <SimpleForm
                                        formRoute={db + '/api/tasks/'}
                                        saveRoute={db + '/api/tasks/'}
                                        match={{ params: { id: this.state.editTaskId ? this.state.editTaskId : id } }}
                                        onSuccessfulSave={(response) => this.onSaveTask(response)}
                                        onCancel={() => this.setState({ editTask: false, editTaskId: null })}
                                        isNew={true}
                                        popup={true}
                                        form={'initializeFromState'}
                                    />
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }
                {this.state.showTaskHint &&
                    <Popup locale={this.props.settings.locale}
                        title={this.state.taskHint.name}
                        onClose={() => this.setState({ showTaskHint: false, taskHint: null })}
                        className="popupReports">
                        <div dangerouslySetInnerHTML={{ __html: this.state.taskHint.description }}>
                        </div>
                    </Popup>
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
    taskProfileInformation: state.profiles
});
const mapDispatchToProps = dispatch => ({
    onDocumentProfileFetchData: (url) => dispatch(onDocumentProfileFetchData(url)),
    onFieldSave: (route, id, value, additionalData) => dispatch(onFieldSave(route, id, value, additionalData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskProfile);