import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Translate, {plainTranslate} from '../../../common/translate';
import {addComment} from '../../../../actions/actions';
import CommentsByDate from './commentsByDate';
import { COMMENTS_WIDGET_UNLOADED } from "../../../../constants/actionTypes";
import { withRouter } from 'react-router-dom'

class Comments extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            text: ''
        };

        this.addComment = this.addComment.bind(this);
        this.handleNewCommentChange = this.handleNewCommentChange.bind(this);
    }

    addComment(e) {
        e.preventDefault();
        this.setState({
            text: ''
        });
        this.props.addComment(this.props.accountId, this.props.match.params.db, this.state.text);
    }

    handleNewCommentChange(e) {
        this.setState({
            text: e.target.value
        });
    }

    render() {
        return  <div id="comments">
            <div className="col-md-12">
                <form>
                    <input className="form-control"
                           placeholder={plainTranslate(this.props.settings.locale, "type a message here")} type="text"
                           value={this.state.text}
                           onChange={this.handleNewCommentChange}
                           onKeyPress={event => {
                               if (event.key === 'Enter') {
                                   this.addComment(event)
                               }
                           }}
                    />
                    <button className="form-control post-button" type="button" onClick={this.addComment}>
                    <i className="fa fa-paper-plane-o" aria-hidden="true"></i>
                    </button>
                    <div className="clearfix"></div>
                </form>
            </div>
            {
                (this.props.comments || []).map(function (comment, i) {
                    return (
                        <CommentsByDate key={i} date={comment.date} list={comment.list}/>
                    )
                }.bind(this))
            }
        </div>;
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});

const mapDispatchToProps = dispatch => ({
    onUnload: () =>
        dispatch({type: COMMENTS_WIDGET_UNLOADED}),
    addComment: (userId, db, text) =>
        dispatch(addComment(userId, db, text))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Comments));
