import React, { Component } from 'react';
import { connect } from 'react-redux';
import { plainTranslate } from '../common/translate';
import { Link } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import client from '../../actions/client'
import FormLoader from '../forms/form-loader';

class EditablePrint extends Component {

    constructor(props) {
        super(props);
        this.state = {          
        }
        this.getEditableTemplate = this.getEditableTemplate.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        if (this.props.title) {
            let titleArr = this.props.title;
            titleArr.map((item, key) => titleArr[key] = plainTranslate(this.props.settings.locale, item));
            document.title = titleArr.join(' ');
        }
        this.getEditableTemplate();
    }

    componentDidUpdate(prevProps){
        if(this.props.value !== prevProps.value || this.props.templateId !== prevProps.templateId){
            this.getEditableTemplate();
        }
    }

    getEditableTemplate() {
        let url = '';
        const urlParams = new URLSearchParams(this.props.location.search);
        let key = urlParams.get('type');
        this.setState({loading: true});

        if(!key){
            key = this.props.type;
        }

        let templateId = this.props.match.params.templateId ? this.props.match.params.templateId : this.props.templateId;
        let docId = this.props.match.params.docId ? this.props.match.params.docId : this.props.docId;
        let db = this.props.match.params.db ? this.props.match.params.db : this.props.db;

        if (key == 'sale') {
            url = '/' + db + '/api/administration/templates/document/' + templateId + '/print_sales/' + docId;
        }
        if (key == 'inventory') {
            url = '/' + db + '/api/administration/templates/document/' + templateId + '/print_ims/' + docId;
        }
        if (key == 'invoice') {
            url = '/' + db + '/api/administration/templates/document/' + templateId + '/print/' + docId;
        }
        if (key == 'payment') {
            url = '/' + db + '/api/administration/templates/document/' + templateId + '/print_pay/' + docId;
        }
        if (key == 'deal') {
            url = '/' + db + '/api/administration/templates/document/' + templateId + '/print_deal/' + docId;
        }
        if (key == 'expense') {
            url = '/' + db + '/api/administration/templates/document/' + templateId + '/print_expense/' + docId;
        }
        if (key == 'account') {
            url = '/' + db + '/api/administration/templates/document/' + templateId + '/print_account/' + docId;
        }

        client
        .get(url)
        .then(response => response.data)
        .then(response => {
            if (response) {
                this.setState({value: response.twig, loading: false});
            }
        });
    }



    imageUpload = (blobInfo, success, failure, progress) => {
        console.log(blobInfo.filename())
        var formData = new FormData();
          formData.append('file', blobInfo.blob(), blobInfo.filename());
          client
          .post('/' + this.props.db + '/api/settings/image/upload/tinymce', formData)
          .then(res => {
            success(res.data.location)
          })
          .catch(err => {
            failure('HTTP Error: ' + err.response.code);
          })      
      }

    onChange(value) {
        this.setState({value: this.props.value ? this.props.value : ''});
    }


    render() {
        return <div>
            {!this.state.loading &&
                <Editor apiKey='9e6m6n8f3yaairxxedx81e03olfhzeht5oubgv38egohvg5z'
                    value={this.state.value ? this.state.value : null}
                    init={{
                        plugins: 'link image imagetools code lists advlist media emoticons fullpage table preview print searchreplace hr wordcount print preview',
                        file_picker_types: 'file image media',
                        // images_upload_url: config.API_URL+'/' + this.props.match.params.db + '/api/settings/image/upload/tinymce',
                        images_upload_handler: this.imageUpload,
                        relative_urls: false,
                        toolbar: 'print | preview | undo redo | bold italic | alignleft aligncenter alignright | code | bullist numlist outdent indent | undo redo | image code | media',
                        height: "800",
                        extended_valid_elements: "style,link[href|rel]",
                        custom_elements: "style,link,~link",
                        valid_children: '+body[style]',
                        inline_styles: true,
                        width: "100%",
                        visual: false
                    }}
                    onChange={this.onChange}
                />}
            {this.state.loading &&
                <FormLoader />
            }
        </div>
    }
}

const mapStateToProps = (state) => ({
    settings: state.settings,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(EditablePrint);
