import React, {Component} from 'react';
import {render} from "react-dom";
import Translate, {plainTranslate} from '../../common/translate';
import Message from '../../common/message';
import { connect } from 'react-redux';
import SettingsMenu from './../../sidebar/settings-menu';
import OpenSection from './../openSection.jsx';
import { client} from "../../../actions/actions";
import SingleCheckbox from './../singleCheckbox.jsx';
import Stages from './../stages.jsx';
import { fetchSettings} from "../../../actions/actions";
import { Skeleton } from 'antd';

class Settings extends Component {

    constructor(props) {
        super(props);  
        this.state = {
            showMessage: false,
            ready: false
        }; 
        this.allSettings = [
            { value: "", name: '', text: ''},
        ];
        
        this.updateStages = this.updateStages.bind(this);
    }
    
    componentDidMount() {
        this.props.getSettings('/' + this.props.match.params.db + '/api/expense/settings/expense-settings', 'expenseSettings')
        .then(res => {            
            this.setState({ready: true})
        })
         if (this.props.title) {
            let titleArr = this.props.title;
            titleArr.map((item, key) => titleArr[key] = plainTranslate(this.props.settings.locale, item));
            document.title = titleArr.join(' ');
        }
        
    } 
    
    updateStages() {
        this.props.getSettings('/' + this.props.match.params.db + '/api/expense/settings/expense-settings', 'expenseSettings');
    }
    
    render() {

        return (
                <div>
                    <SettingsMenu 
                       location = {this.props.location.pathname} db = {this.props.match.params.db}/>
                    <form name="settings_expense" className="cmxform form-horizontal bucket-form ng-pristine ng-valid">
                       <div className="row m-bot15">
                          <div className="col-lg-12">
                             <section className="panel">
                                <div className="panel-body">
                                   <div className="settings">
                                        <OpenSection
                                            label={plainTranslate(this.props.settings.locale, 'Expense form type')}
                                            description={plainTranslate(this.props.settings.locale, "Specify different types of documents for expenses")}
                                            link={"/" + this.props.match.params.db + "/form-types"}
                                         />
                                         <hr/>
                                        <OpenSection
                                            label={plainTranslate(this.props.settings.locale,'Expense Template')}
                                            description={plainTranslate(this.props.settings.locale,'Set the default template for printing expenses')}
                                            component={'select'}
                                            value ={this.props.settings.expenseSettings}
                                            type={'expenseSettings'}
                                            saveRoute={'/' + this.props.match.params.db + '/api/expense/save/settings/individual'}
                                            setting={'expenseTemplate'}
                                         />
                                        <hr/>
                                         <OpenSection 
                                            label={plainTranslate(this.props.settings.locale, 'Expense categories')}
                                            description={plainTranslate(this.props.settings.locale, "Set default categories to organize and keep track of the company's spending.")}
                                            link={"/" + this.props.match.params.db + "/expense-categories"}    
                                         />  
                                         <hr/>
                                        <div className="col-sm-12 column-centered">
                                            {this.state.ready ?
                                            <Stages
                                                saveRoute={'/' + this.props.match.params.db + '/api/stages/save/settings/data'}
                                                type={'expenseSettings'}
                                                workflow={"expense"}
                                                configuration = {false}
                                                value={this.props.settings.expenseSettings}
                                                settings={this.props.settings}
                                                fields={this.allSettings}
                                                setting = {'stages'}
                                                roles={'roles'}
                                                updateStages={this.updateStages}
                                            />
                                            :
                                            <Skeleton active />}
                                        </div>
                                   </div> 
                                </div>
                             </section>
                          </div>
                       </div>    
                    </form>        
                </div>
                );
    }
}

const mapStateToProps = state => ({
        settings: state.settings,
    });

const mapDispatchToProps = dispatch => ({
   getSettings: (path, type) =>
            dispatch(fetchSettings(path, type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);


