import React, {Component} from 'react';
import {render} from "react-dom";
import Translate, {plainTranslate} from '../common/translate';
import Message from '../common/message';
import {connect} from 'react-redux';
import {onAccountFetchData, onFieldSave} from "../../actions/actions";
import Select from 'react-select';
import AsyncSelect from 'react-select/lib/Async';
import client from '../../actions/client'
import showAToast from '../common/showAToast';

class FiltersSelect extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: [],
            isEdit: false,
            isChanged: false,
            options: []
        };

        this.openEditField = this.openEditField.bind(this);
        this.closeEditField = this.closeEditField.bind(this);
        this.changeValue = this.changeValue.bind(this);
        this.saveField = this.saveField.bind(this);
        this.closeUnchangedField = this.closeUnchangedField.bind(this);
    }

    componentDidMount() {
        this.setState({
            value: this.props.value,
            options: this.props.options
        });
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.value) !== JSON.stringify(this.props.value) || JSON.stringify(prevProps.options) !== JSON.stringify(this.props.options)) {
            this.setState({
                value: this.props.value
            });
        }
    }

    openEditField() {
        this.setState({
            isEdit: !this.state.isEdit
        });
    }

    closeEditField() {
        this.setState({
            value: this.props.value
        });

        this.openEditField();
    }

    saveField() {
        this.props.onFieldSave(this.props.updateRoute, this.props.id, this.state.value, this.props.additionalData);
        this.openEditField();
    }

    changeValue(value) {
        var result = []
        value.map(row =>{
            result.push(row.value)
        })
        this.props.change(result, this.props.id, value);
        this.setState({value, isChanged: true,});
    }

    closeUnchangedField() {
        if (!this.state.isChanged) {
            this.openEditField();
            this.setState({isChanged: false});
        }
    }

    loadOptions = (inputValue, callback) => {       
        client
        .get(this.props.url+'?q='+inputValue)
        .then(response => {
            this.setState({
                options: response.data
            })
            callback(response.data)         
        })
        .catch(err => {
            showAToast('Error!', 'baseError', 'error')
        })
    }

    render() {
         return (
            <React.Fragment>
                <div className="inlineHover input-select2">
                    <React.Fragment>
                        <div className="col-lg-10 col-md-10 col-xs-10 no-gutter">
                        <div className="col-lg-12 col-md-12 col-xs-12 no-gutter" onMouseLeave={this.closeUnchangedField} onClick={()=>this.setState({isChanged:true})}>
                           {this.props.url ?
                                <AsyncSelect
                                cacheOptions
                                isMulti={this.props.isMulti}
                                value={this.state.value}
                                onChange={this.changeValue}
                                defaultOptions={this.state.options}
                                isDisabled={this.props.isDisabled}
                                isClearable={true}
                                loadOptions={this.loadOptions}
                                onKeyPress={event => {
                                    if (event.key === 'Enter') {
                                        this.saveField()
                                    }
                                }}
                            />
                            :
                            <Select
                            isMulti={this.props.isMulti}
                            value={this.state.value}
                            onChange={this.changeValue}
                            options={this.props.options}
                            isDisabled={this.props.isDisabled}
                            isClearable={true}
                            onKeyPress={event => {
                                if (event.key === 'Enter') {
                                    this.saveField()
                                }
                            }}
                        />
                           }
                            </div>
                        </div>
                    </React.Fragment>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({
    onFieldSave: (route, id, value, additionalData) => dispatch(onFieldSave(route, id, value, additionalData))
});

export default connect(mapStateToProps, mapDispatchToProps)(FiltersSelect);
