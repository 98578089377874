import React, {Component} from 'react';
import {render} from "react-dom";
import Translate, {plainTranslate} from '../../common/translate';
import Message from '../../common/message';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
import MenuContent from './menuContent';
import { Modal, Button  } from 'antd';
import { RightOutlined, FormOutlined, PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons'
import 'antd/lib/icon/style/index.css';
import 'antd/lib/modal/style/index.css';
import 'antd/lib/button/style/index.css';
import AsyncSelect from 'react-select/lib/Async';
import { withRouter } from 'react-router-dom'
import client from '../../../actions/client'

class MenuContents extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showContent: false,
            visible: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.addNewSubItem = this.addNewSubItem.bind(this);
        this.showContent = this.showContent.bind(this);
        this.showModal = this.showModal.bind(this);
        this.handleOk = this.handleOk.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.remove = this.remove.bind(this);
        this.handleCategoryChange = this.handleCategoryChange.bind(this);
        this.loadOptions = this.loadOptions.bind(this);
    }

    componentDidMount() {
        this.update();
    }

    update() {
        this.setState({
            value: this.props.item ? this.props.item : []
        });
    }

    addNewSubItem() {
        let menu = this.state.value ? this.state.value.subcategories : [];
        if (menu) {
            let newItem = {'id': (menu.length + 1), 'name': 'New subelement to ' + this.props.item.name, 'level': (this.props.level * 1) + 1};
            menu.push(newItem);
        } else {
            menu = this.state.value;
            let newItem = {'id': 1, 'name': 'New subelement to ' + this.props.item.name, 'level': (this.props.level * 1) + 1};
            menu['subcategories'] = [];
            menu['subcategories'].push(newItem);
        }
        this.setState({
            menu
        });
    }

    handleChange(e, type) {
        let value = this.state.value;
        value[type] = e.target.value;
        this.setState({value:value});
        this.props.change();
    }

    handleCategoryChange(val) {
        let value = this.state.value;
        value['category'] = val;
        value['bannerLink'] = val.image;
        value['image'] = val.imageTitle;
        value['url'] = val.url;
        if (value['name'].includes('element')) {
            value['name'] = val.name;
        }
        this.setState({value:value});
        this.props.change();
    }

    showContent() {
        this.setState({showContent: !this.state.showContent});
    }

    showModal() {
      this.setState({
        visible: true,
      });
    }

    handleOk() {
      this.setState({
        visible: false,
      });
    }

    handleCancel() {
      this.setState({
        visible: false,
      });
    };

    componentDidUpdate(prevProps) {
        if (prevProps.item != this.props.item) {
            this.setState({
                value: this.props.item
            })
        }
    }

    removeFromTree(tree, itemToDelete) {
        let index;
        if (tree.hasOwnProperty('subcategories')) {
            tree.subcategories.find((val, key) => {
                if (val === itemToDelete) {
                    index = key;
                    if (index > -1) {
                        tree.subcategories.splice(index, 1);
                    }
                    return true;
                } else {
                    this.removeFromTree(val, itemToDelete);
                }
            });
        }
    }

    remove(itemToDelete) {
        let tree = this.state.value;
        let index;
        if (tree.hasOwnProperty('subcategories')) {
            tree.subcategories.find((val, key) => {
                if (val === itemToDelete) {
                    index = key;
                    if (index > -1) {
                        tree.subcategories.splice(index, 1);
                    }
                    return true;
                } else {
                    this.removeFromTree(val, itemToDelete);
                }
            });
        }

        this.setState({value: tree});
        this.props.change();
    }
    
    loadOptions(inputValue, callback) {  
        client.get('/' + this.props.match.params.db + '/api/search-categories?q=' + inputValue)
                    .then(response => response.data)
                    .then(response => {
                        let options = [];
                        if (response) {
                            options = response.map(option => {
                                return {
                                    ...option,
                                    'value': option.value,
                                    'label': option.label
                                };
                            });
                        }
                        this.setState({options: options});
                        callback(options ? options : this.state.options);
                    });

    }

    render() {
        return (
                <div>
                {this.state.value &&
                    <div className="row">
                        <div className="accordion col-md-12 m-bot15">
                          <div className="accordion-group">
                            <div className="accordion-heading area collapsible col-md-12 no-gutter">
                              <div className="col-md-9 col-xs-6" onClick={this.showContent}>
                                {this.state.value && this.state.value.subcategories && this.state.value.subcategories.length > 0 &&
                                   <span style={{marginRight: 10}}>
                                     <RightOutlined />
                                   </span>
                                 }
                                <strong>{this.state.value.name}</strong>
                               </div>
                               <div className="col-md-3 col-xs-6">
                                 <span style={{marginRight: 10}} onClick={this.showModal}><FormOutlined title = {plainTranslate(this.props.locale, 'Edit menu item')}/> Edit</span>
                                 <span style={{marginRight: 10}}  onClick = {this.addNewSubItem}><PlusCircleOutlined  title = {plainTranslate(this.props.locale, 'Add new subelement')}/> Add new</span>
                                 <span onClick = {() => this.props.remove(this.state.value)}><DeleteOutlined title = {plainTranslate(this.props.locale, 'Remove menu item')}/> Delete</span>
                             </div>
                            </div>
                            {this.state.showContent &&
                            <div className="content">
                              {this.state.value && this.state.value.subcategories && this.state.value.subcategories.length > 0 && this.state.value.subcategories.map((child, key) =>
                                  <MenuContent item={child} key={key} change={this.props.change} remove={this.remove} level={(this.props.level * 1) + 1} options={this.props.options} db={this.props.match.params.db}/>
                              )}
                            </div>
                            }
                        </div>
                      </div>
                      <Modal
                        title = {plainTranslate(this.props.locale, 'Edit menu item')}
                        visible={this.state.visible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                      >
                      <div>
                        <div className="form-group">
                            <label className="text-left">{plainTranslate(this.props.locale, 'Label')}</label>
                            <input type="text" className="form-control" onChange={(e) => this.handleChange(e, 'name')}
                                   value = {this.state.value.name}
                                   />
                        </div>
                        <div className="form-group">
                            <label className="text-left">{plainTranslate(this.props.locale, 'Category')}</label>
                            <AsyncSelect
                                    isClearable
                                    cacheOptions
                                    isSearchable
                                    onChange={this.handleCategoryChange}
                                    onBlurResetsInput={false}
                                    onBlur={event => event.preventDefault()}
                                    onCloseResetsInput={false}
                                    menuPlacement={"auto"}
                                    loadOptions={this.loadOptions}
                                    defaultOptions={this.props.options}
                                    value = {this.state.value.category}
                                />
                        </div>
                        <div className="form-group">
                            <label className="text-left">{plainTranslate(this.props.locale, 'Url')}</label>
                            <input type="text" className="form-control" onChange={(e) => this.handleChange(e, 'url')}
                                   value = {this.state.value.url}
                                   />
                        </div>
                        <div className="form-group">
                            <label className="text-left">{plainTranslate(this.props.locale, 'Image alt tag')}</label>
                            <input type="text" className="form-control" onChange={(e) => this.handleChange(e, 'imageTitle')}
                                   value = {this.state.value.imageTitle}
                                   />
                        </div>
                        <div className="form-group">
                            <label className="text-left">{plainTranslate(this.props.locale, 'Image slug')}</label>
                            <input type="text" className="form-control" onChange={(e) => this.handleChange(e, 'image')}
                                   value = {this.state.value.image}
                                   />
                        </div>
                        <div className="form-group">
                            <label className="text-left">{plainTranslate(this.props.locale, 'Banner')}</label>
                            <input type="text" className="form-control" onChange={(e) => this.handleChange(e, 'banner')}
                                   value = {this.state.value.banner}
                                   />
                        </div>
                        <div className="form-group">
                            <label className="text-left">{plainTranslate(this.props.locale, 'Banner slug')}</label>
                            <input type="text" className="form-control" onChange={(e) => this.handleChange(e, 'bannerLink')}
                                   value = {this.state.value.bannerLink}
                                   />
                        </div>
                      </div>
                      </Modal>
                    </div>
                  }
                </div>
        );
    }
}

const mapStateToProps = state => ({
        settings: state.settings,
    });

const mapDispatchToProps = dispatch => ({

    });

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MenuContents));
