import React from 'react';
import { connect } from 'react-redux';
import { Select } from 'antd';
import 'antd/lib/select/style/index.css';
import { plainTranslate } from "../../common/translate";
import SlideModal from '../../common/SlideModal';
import SimpleForm from "../../redux-forms/form";
import { withRouter } from 'react-router-dom'
import client from '../../../actions/client'

const { Option } = Select;

class UpdatedSelect extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            options: [],
            batchForm: false
        }
        this.handleClick = this.handleClick.bind(this);
        this.addItem = this.addItem.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    componentDidMount() {
        this.setState({ options: this.props.field.options });
    }

    handleClick(url, dependency, lineDependency) {
        if (url) {
            let addVal = {};
            lineDependency.map(field => {
                let numb = (this.props.input.name).match(/^(\D*)(\d+)/)[2];
                if ('lines' in this.props.formData.form.initializeFromState2.values) {
                    addVal[field] = this.props.formData.form.initializeFromState2.values.lines[numb][field];
                }
            })
            dependency.map(field => {
                addVal[field] = this.props.formData.form.initializeFromState2.values[field];
            })

            let fields = this.props.field.selfUpdate.fields;
            client.post(url, addVal)
                .then(response => response.data)
                .then(response => {
                    fields.map(field => {
                        if (response[field]) {
                            let options = response[field];
                            options.push({ 'value': null, 'label': 'Without batch' });
                            this.setState({ options: options });
                        }
                    })
                    if ('item' in response) {
                        this.setState({ item: response['item'] })
                    } else {
                        this.setState({ item: false })
                    }
                })
        }
    }

    handleChange(val, name, action, fieldProps) {
        action(name, val);
        if ('changedFields' in fieldProps) {
            let addVal = {};
            fieldProps.changedFields.lineDependency.map(field => {
                let numb = (name).match(/^(\D*)(\d+)/)[2];
                addVal[field] = this.props.formData.form.initializeFromState2.values.lines[numb][field];
            })
            fieldProps.changedFields.dependency.map(field => {
                addVal[field] = this.props.formData.form.initializeFromState2.values[field];
            })
            addVal['body'] = val;
            let fields = this.props.field.changedFields.fields;
            client.post(fieldProps.changedFields.url, addVal)
                .then(response => response.data)
                .then(response => {
                    fields.map(field => {
                        let ind = (name).indexOf('.' + this.props.field.id);
                        let line = (name).substring(0, ind);
                        action(line + field, response[field]);
                    })
                })
        }
        if ('setLabel' in fieldProps) {
            let exist = this.state.options.find(o => o.value === val);
            if (exist) {
                let ind = (name).indexOf('.' + this.props.field.id);
                        let line = (name).substring(0, ind);
                        action(line + fieldProps.setLabel, exist.label);
            }
        }
    }

    addItem(response) {
        this.state.options.push({ value: response.entity.id, label: response.entity.name });
        this.props.change(this.props.input.name, response.entity.id);
        this.setState({ batchForm: false });
    }

    handleClose() {
        this.props.change(this.props.input.name, this.props.input.value);
        this.setState({ batchForm: false });
    }

    onSave(data) {
        this.setState({ batchForm: false });
        this.props.input.onChange(data.entity.id);
    }
     openBatch = (e) => {
         e.preventDefault()
        this.setState({ batchForm: true })
     }

    render() {
        return (
            <span className="form-input-field">
                <Select
                    size={this.props.field.size ? this.props.field.size : "default"}
                    disabled={this.props.field.isDisabled ? this.props.field.isDisabled : false}
                    value={this.props.input.value}
                    style={{ minWidth: this.props.field.width ? this.props.field.width : '120px', maxWidth: this.props.field.width ? this.props.field.width : '120px', width: '100%' }}
                    notFoundContent={plainTranslate(this.props.settings.locale, 'No options')}
                    onChange={(value) => this.handleChange(value, this.props.input.name, this.props.change, this.props.field)}
                    onMouseEnter={() => this.handleClick(this.props.field.selfUpdate ? this.props.field.selfUpdate.url : null, this.props.field.selfUpdate ? this.props.field.selfUpdate.dependency : null, this.props.field.selfUpdate ? this.props.field.selfUpdate.lineDependency : null)} >
                    {this.state.options && this.state.options.map((option) =>
                        <Option value={option.value} 
                        disabled={option.disabled} 
                        key={Math.random().toString(36).substring(7)}>{option.label}</Option>
                    )}
                    {this.props.field.id === 'batch' && 
                    <Option
                    key={Math.random().toString(36).substring(7)} 
                    value={' '} >
                     <a onClick={this.openBatch}>   +{plainTranslate(this.props.settings.locale, 'Add')}</a></Option>}
                </Select>
                {
                    this.state.batchForm &&
                    <SlideModal onClose={this.handleClose} title={plainTranslate(this.props.settings.locale, 'Batch')}>
                        <section className="newPanel">
                            <div className="panel-body">
                                <div className="row m-bot15">
                                    <SimpleForm
                                        saveRoute={this.props.settings.db + '/api/batches'}
                                        formRoute={this.props.settings.db + '/api/batches/'}
                                        match={{ params: { id: null } }}
                                        matchAdditionalId={{ params: { id: this.state.item } }}
                                        onSuccessfulSave={(data) => this.onSave(data)}
                                        isNew={true}
                                        popup={true}
                                        onCancel={this.handleClose}
                                        form={'initializeFromState'}
                                    />
                                </div>
                            </div>
                        </section>
                    </SlideModal>
                }
            </span>
        )
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
    formData: state,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UpdatedSelect);
