import React, { useEffect, useState } from 'react';
import Translate, { plainTranslate } from '../common/translate';
import { connect } from 'react-redux';
import client from "../../actions/client";
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Table, Menu, Dropdown, Select, Progress } from 'antd';
import showAToast from '../common/showAToast';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import GridV2 from '../../components/gridV2/grid.jsx';
import SlideModal from '../common/SlideModal';
import FiltersSelect from '../../components/booking/select';

const { Option } = Select;

const ProjectBoardProfile = (props) => {
	const [loading, setLoading] = useState(false);
	const [activeTab, setActiveTab] = useState(null);
    const [showCalendarFilters, setShowCalendarFilters] = useState(null);
    const [calendarFilters, setCalendarFilters] = useState({});
    const [calendarTasks, setCalendarTasks] = useState(null);
    const [data, setData] = useState(null);

	useEffect(() => {
		setLoading(true);
		setActiveTab('table');

        client.get('/' + props.match.params.db + '/api/project-board/' + props.match.params.boardId + '/profile-info')
        .then(response => response.data)
        .then(response => {
            setData(response);
        });
    }, []);

	useEffect(() => {
		setLoading(true);
        client.get('/' + props.match.params.db + '/api/project-board/' + props.match.params.boardId + '/profile-info')
        .then(response => response.data)
        .then(response => {
            setData(response);
        });
    }, [props.match.params.boardId]);

    function changeTab(tab) {
    	setActiveTab(tab);
    }

	const style = {
	    margin: '0 5px',
	};

	const formats = {
      eventTimeRangeFormat: () => { 
        return "";
      },
    };

    const eventStyleGetter = (event, start, end, isSelected) => {
      return {
        style: {
          opacity: isSelected ? 1 : 0.9,
          borderColor: event.color,
          backgroundColor: event.color,
          color: 'black'
        },
      };
    };

    function handleFilterChange(value, type){
        calendarFilters[type] = value;
        setCalendarFilters(calendarFilters);
    }

    function applyFilters(){
        client
        .post('/' + props.match.params.db + '/api/project-board/' + props.match.params.id + '/find-tasks', {calendarFilters})
        .then(response => response.data)
        .then(response => {
            setCalendarTasks(response);
        });

        setShowCalendarFilters(false);
    }

    function cancelFilters(){
      setCalendarFilters({});
      setShowCalendarFilters(false);
    }

    const monthNames = [
      plainTranslate(props.settings.locale, 'January'),
      plainTranslate(props.settings.locale, 'February'),
      plainTranslate(props.settings.locale, 'March'),
      plainTranslate(props.settings.locale, 'April'),
      plainTranslate(props.settings.locale, 'May'),
      plainTranslate(props.settings.locale, 'June'),
      plainTranslate(props.settings.locale, 'July'),
      plainTranslate(props.settings.locale, 'August'),
      plainTranslate(props.settings.locale, 'September'),
      plainTranslate(props.settings.locale, 'October'),
      plainTranslate(props.settings.locale, 'November'),
      plainTranslate(props.settings.locale, 'December')
    ];

    moment.locale(props.settings.locale);
    const localizer = momentLocalizer(moment);
    const messages = {
      today: plainTranslate(props.settings.locale, 'Today'),
      month: plainTranslate(props.settings.locale, 'Month'),
      week: plainTranslate(props.settings.locale, 'Week'),
      day: plainTranslate(props.settings.locale, 'Day'),
      monthNames: monthNames
    };

	let projectTasks = data ? data.tasks : [];
	let taskStages = data ? data.taskStages : [];
	let users = data ? data.users : [];
    let accounts = data ? data.accounts : [];
    let projects = data ? data.projects : [];
    let color = data ? data.color : "#f0eae7";

    if(calendarTasks){
        projectTasks = calendarTasks;
    }
    
    {projectTasks && projectTasks.map(task =>(
      task.start = new Date(task.start),
      task.end = new Date(task.end)
      ))}

    props.match.params.refs = props.match.params.boardId;

	return (
        <div className="project-board" style={{paddingTop: 100, paddingLeft: 20, height: "100vh", backgroundColor: color }}>
	        <div className="nav nav-tabs" id="tabBtn" style={{backgroundColor: "white"}}>
	            <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 basic no-gutter">
	                <span style={{marginRight: 10, cursor: "pointer"}} onClick={() => changeTab('table')}>{plainTranslate(props.settings.locale, 'Table View')}</span>
	                <span style={{marginRight: 10, cursor: "pointer"}} onClick={() => changeTab('calendar')}>{plainTranslate(props.settings.locale, 'Calendar View')}</span>
	            </div>
	        </div>

	        {activeTab === 'table' && 
            <GridV2 
                gridRoute={'/api/grid/settings/grid-properties/board-tasks'}
                gridDataRoute={'/api/grid/settings/grid-data/board-tasks'}
                gridExportRoute={'/api/grid/board-tasks/export/'}
                gridSaveRoute={'/api/grid/board-tasks/save'}
                title={"Tasks"}
                {...props}
            />}
	        {activeTab === 'calendar' &&
                <div className="activity-calendar" style={{height: "100%"}}>
                <span className="filters" onClick={() => setShowCalendarFilters(true)}><img src='/images/filter.svg' alt="email settings"/>{plainTranslate(props.settings.locale, 'Filter')}</span>          
    	        <Calendar      
                  messages={messages}
                  localizer={localizer}
                  events={projectTasks}
                  settings={props.settings}
                  defaultView={localStorage.getItem("calendarView") ? localStorage.getItem("calendarView") : window.innerWidth > 780 ? Views.MONTH : Views.DAY}
                  defaultDate={new Date()}
                  eventPropGetter={eventStyleGetter}
                  formats={formats}
                  selectable
                  style={{backgroundColor: "white", paddingTop: 40}}
                />
                </div>}
            {showCalendarFilters === true &&
                <SlideModal onClose={() => setShowCalendarFilters(false)} title={plainTranslate(props.settings.locale, 'Filters')}>
                  <section className="newPanel">
                    <div className="panel-body">
                      <div className="col-md-10 col-sm-12 row m-bot15">
                        <div className="col-md-12 filter-type" style={{marginTop: 20}}>
                            <h3>{plainTranslate(props.settings.locale, 'Accounts')}</h3>
                            <FiltersSelect 
                                    //updateRoute={this.updateRoute}
                                    id={'accounts'}
                                    isMulti={true}
                                    change={(value) => handleFilterChange(value, 'accounts')}
                                    options={accounts}
                                     //isDisabled={this.state.isDisabledEvents}
                                    url={props.match.params.db +"/api/billing-data-accounts-filter"}
                                    value={calendarFilters.accounts}/>
                        </div>     
                        <div className="clearfix"></div>    
                        <div className="col-md-12 filter-type" style={{marginTop: 20}}>
                            <h3>{plainTranslate(props.settings.locale, 'Projects')}</h3>
                            <FiltersSelect 
                                    //updateRoute={this.updateRoute}
                                    id={'projects'}
                                    isMulti={true}
                                    change={(value) => handleFilterChange(value, 'projects')}
                                    options={projects}
                                    url={props.match.params.db + "/api/projects"}
                                     //isDisabled={this.state.isDisabledEvents}
                                    value={calendarFilters.projects}/>
                        </div>
                        <div className="clearfix"></div>         
                        <div className="col-md-12 filter-type" style={{marginTop: 20}}>
                            <h3>{plainTranslate(props.settings.locale, 'Users')}</h3>
                                <FiltersSelect 
                                    //updateRoute={this.updateRoute}
                                    id={'users'}
                                    isMulti={true}
                                    change={(value) => handleFilterChange(value, 'users')}
                                    options={users}
                                     //isDisabled={this.state.isDisabledEvents}
                                    url={props.match.params.db + "/api/find/users/search"}
                                    value={calendarFilters.users}/>
                        </div>
                        <div className="clearfix"></div>    
                        <div className="col-md-12 filter-type" style={{marginTop: 20}}>
                            <h3>{plainTranslate(props.settings.locale, 'Stages')}</h3>
                            <FiltersSelect 
                                    //updateRoute={this.updateRoute}
                                    id={'stages'}
                                    isMulti={true}
                                    change={(value) => handleFilterChange(value, 'stages')}
                                    options={taskStages}
                                     //isDisabled={this.state.isDisabledEvents}
                                    value={calendarFilters.stages}/>
                        </div>                 
                        <div style={{display: "table"}}>
                          <button type="button" className="button-primary" onClick={applyFilters} id="apply">{plainTranslate(props.settings.locale, 'Apply Filters')}</button>
                          <button type="button" className="button-outline grey" onClick={cancelFilters} id="cancel">{plainTranslate(props.settings.locale, 'Cancel')}</button>
                        </div>
                      </div>
                    </div>
                  </section>
                </SlideModal>
            }
        </div>
    );
}

const mapStateToProps = state => ({
    settings: state.settings
});
const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectBoardProfile);