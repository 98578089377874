import React, {Component} from 'react';
import Translate, {plainTranslate} from '../../../common/translate';
import {connect} from 'react-redux';
import {onUpdateBlockRecords, saveRecord} from "../../../../actions/actions";
import BlockResultRow from './blockResultRow';
import SlideModal from '../../../common/SlideModal';
import { withRouter } from 'react-router-dom'
import client from '../../../../actions/client';
import showAToast from '../../../common/showAToast';

class CustomBlock extends Component {

    constructor(props) {
        super(props);
        this.state = {
            blocks: [],
            thead: [],
            records: [],
            currentBlock: null,
            newRecordForm: false,
            showEdit: false,
            formFields: [],
            order: [],
            error: false
        };

        this.onLoad = this.onLoad.bind(this);
        this.editRecord = this.editRecord.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
    }
    
    componentDidMount() {
        this.onLoad(); 
    }
    
    componentDidUpdate(prevProps, prevState) {
        if (this.props.id) {
            if (prevProps.id !== this.props.id) {
                this.onLoad();
            }
        }
    }
    
    editRecord(update = false) {
        this.setState({
            showEdit: !this.state.showEdit,
        });
    }
    
    onLoad() {
        let schema = this.props.schema;
        let schemas = JSON.parse(schema);
        schemas = schemas.fields ? schemas.fields : [];

        var thead = schemas.map(function (item, key) {
            return item.name;
        });
        
        var order = schemas.map(function (item, key) {
            return item.id;
        });
        
        this.setState({
                thead: thead,
                order: order,
                formFields: schemas
            }); 
            
        this.props.updateRecords(this.props.match.params.db, this.props.id, this.props.accountId);
    }
     saveChanges() {
        var schema = this.state.formFields;
        var length = schema.length;
        var values = {};
        for (var i = 0; i < length; i++) {
            var x = document.getElementsByName(schema[i].id);
            for (var j = 0; j < x.length; j++) {
                if (x[j].type == 'checkbox') {
                    values[schema[i].id] = x[j].checked ? true : false;
                }
                else {
                    values[schema[i].id] = x[j].value;
                }
            }
        }

        let error = false;
        schema.map(field => {
            if (field.required === 'true') {
                if (!(field.id in values && values[field.id])) {
                    error = true;
                }
            }
        });

        if (error) { 
            showAToast(plainTranslate(this.props.settings.locale, 'Please fill all required fields!'), 'errSettings', 'error');
    
            return;
        }
        
        client
        .post('/' + this.props.match.params.db + '/api/data-entry/entry/new/' +this.props.id, {
            'fields' : values,
            'accountId': this.props.accountId
        })
        .then(response => response.data) 
        .then(response => {
            this.props.updateRecord(response.id, response, false)
            if ('errors' in response) {
                showAToast(plainTranslate(this.props.settings.locale, response.errors), 'errSettings', 'error');
            }
            else {
                showAToast(plainTranslate(this.props.settings.locale, 'Data saved successfully!'), 'succSettings', 'success');
            }
        })
        .then(this.editRecord());

        this.setState({
            originalFields: this.state.fields
        });
    }

    render() {
        return (
                <div>
                    <div className="custom-data btn-group">
                        <button  className="btn-round btn-blue"
                                type="button" onClick={this.editRecord}>
                            <i className="ion-android-add "></i></button>
                    </div>
                    {
                        this.state.showEdit &&
                    <SlideModal onClose={() => this.editRecord()} title={plainTranslate(this.props.settings.locale, 'Edit record')}>
                    <section className="newPanel">
                        <div className="panel-body">
                            <div className="row m-bot15">                             
                                                <div className="form-group">
                                                    {
                                                        this.state.formFields && this.state.formFields.map(function (field, i) {
                                                            let input = null;
                                                            let inputType = null;

                                                            if (typeof field.type === 'object')
                                                                inputType = field.type.value.toLowerCase();
                                                            else
                                                                inputType = field.type.toLowerCase();

                                                            if (inputType === 'dropdown') {
                                                                input = <select className="form-control"
                                                                                name={field.id}
                                                                                id={i}
                                                                                onChange={this.onChange}>
                                                                    {
                                                                        field.type.options.map((option, key) => {
                                                                            return <option value={option}
                                                                                           key={key}>{option}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                            }
                                                            else {
                                                                input = <input type={field.type.toLowerCase()}
                                                                               name={field.id}
                                                                               className="form-control"
                                                                               id={i}
                                                                               onChange={this.onChange}
                                                                               
                                                                />;
                                                            }


                                                            return (
                                                                <div
                                                                    className={(field.required === 'true' ? 'col-sm-12 col-xs-12 form-row required' : 'col-sm-12 col-xs-12 form-row')}
                                                                    key={i}>
                                                                    <label className="col-md-12">{field.name}</label>
                                                                    {input}
                                                                </div>
                                                            );
                                                        }.bind(this))
                                                    }
                                                </div>
                                            </div>

                                            <button className="button-primary-edited text-center btn-ok"
                                                    onClick={this.saveChanges}>
                                                <Translate locale={this.props.locale} value="Save"/>
                                            </button>
                                            <button className="button-outlines green text-center"
                                                    onClick={this.editRecord}>
                                                <Translate locale={this.props.locale} value="Cancel"/>
                                            </button>
                        </div>
                    </section>
                </SlideModal>
                    }
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            {
                                this.state && this.state.thead && this.state.thead.map(function (item, i) {
                                    return <th key={i}>{item}</th>
                                })
                            }
                           
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                            
                        {this.props.accountProfileInformation && this.props.accountProfileInformation.dataEntry && this.props.accountProfileInformation.dataEntry.length > 0 && this.props.accountProfileInformation.dataEntry.map(function (record, i) {
                           
                             return <BlockResultRow
                                    locale={this.props.settings.locale}
                                    key={i}
                                    record={record ? record : []}
                                    order={this.state.order}
                                    db={this.props.match.params.db}
                                    currentBlock={this.state.currentBlock}
                                    formFields={this.state.formFields}
                                    ></BlockResultRow>
                            }.bind(this))
                        }
                        </tbody>
                    </table>
                </div>
                </div>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
    accountProfileInformation: state.accountProfileInformation
});
const mapDispatchToProps = dispatch => ({
    updateRecords: (db, blockId, accountId) =>
            dispatch(onUpdateBlockRecords(db, blockId, accountId)),
    updateRecord: (id, key, value) =>
            dispatch(saveRecord(id, key, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CustomBlock));
